import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppRoutes } from './app.routes'

import { LoginComponent } from './components/account/login/login.component';
import { FirstTimePasswordChangeComponent } from './components/account/first-password-change/first-password-change.component'
import { SecurityQuestionComponent } from './components/account/security-question/security-question.component'
import { SignupComponent } from './components/account/signup/signup.component';

import { LayoutComponent } from './components/home/layout/layout.component'
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { TransfersComponent } from './components/home/transfers/transfers/transfers.component';
import { PendingTransactionsComponent } from './components/home/pending-transactions/pending-transactions.component';
import { TransactionHistoryComponent } from './components/home/transaction-history/transaction-history.component';
import { BulkPaymentComponent } from './components/home/transfers/bulk-payment/bulk-payment.component';
import { ManageWorkflowComponent } from './components/home/settings/manage-workflow/manage-workflow.component';
import { UsersComponent } from './components/home/settings/users/users.component';
import { BillsPaymentComponent } from './components/home/bills-payment/bills-payment.component';
import { AccountStatementComponent } from './components/home/account-statement/account-statement.component';
import { BeneficiariesComponent } from './components/home/beneficiaries/beneficiaries.component';
import { ChangePasswordComponent } from './components/home/settings/change-password/change-password.component';
import { LogoutComponent } from './components/account/logout/logout.component';
import { ChequeBookComponent } from './components/home/cheque-book/cheque-book.component';
import { StaffsComponent } from './components/home/payroll/staffs/staffs.component';
import { BulkUploadComponent } from './components/home/payroll/bulk-upload/bulk-upload.component';
import { SalarySchedulesComponent } from './components/home/payroll/schedules/schedules.component';
import { OnLendingComponent } from './components/home/on-lending/on-lending.component';
import { ChangePinComponent } from './components/home/settings/change-pin/change-pin.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'user/login',
  //   pathMatch: 'full'
  // },

  // {
  //   path: 'user',
  //   redirectTo: 'user/login',
  //   pathMatch: 'full'
  // },

  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'user/login', component: LoginComponent },
  { path: 'user/logout', component: LogoutComponent },
  { path: 'user/change-default-password', component: FirstTimePasswordChangeComponent },
  { path: 'user/security-question', component: SecurityQuestionComponent },
  { path: 'user/signup', component: SignupComponent },

  {
    path: 'user',
    component: LayoutComponent,
    children: [
      { path: AppRoutes.dashboard, component: DashboardComponent },
      { path: AppRoutes.transfers, component: TransfersComponent },
      { path: AppRoutes.pendingTransactions, component: PendingTransactionsComponent },
      { path: AppRoutes.transactionHistory, component: TransactionHistoryComponent },
      { path: AppRoutes.bulkPayment, component: BulkPaymentComponent },
      { path: AppRoutes.staffs, component: StaffsComponent },
      { path: AppRoutes.staffBulk, component: BulkUploadComponent },
      { path: AppRoutes.salarySchedules, component: SalarySchedulesComponent },
      { path: AppRoutes.manageWorkflow, component: ManageWorkflowComponent },
      { path: AppRoutes.manageUsers, component:  UsersComponent},
      { path: AppRoutes.billsPayment, component: BillsPaymentComponent },
      { path: AppRoutes.accountStatement, component: AccountStatementComponent },
      { path: AppRoutes.beneficiaries, component: BeneficiariesComponent },
      { path: AppRoutes.changePassword, component: ChangePasswordComponent },
      { path: AppRoutes.chequeBook, component: ChequeBookComponent },
      { path: AppRoutes.onLending, component: OnLendingComponent },
      { path: AppRoutes.changePin, component: ChangePinComponent },
    ]   
  },

  // otherwise redirect to home
  // {
  //   path: "**",
  //   redirectTo: ""
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
