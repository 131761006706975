import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { HttpClient } from '@angular/common/http';


// define template
let template = 'bulk-payment.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class BulkPaymentComponent implements OnInit {
	accounts: any[] = []
	config = config
	workflows: any[] = []
	hasUploaded: boolean = false
	fileToUpload: File | null = null;
	corporateCustomerId: string = ""
	accountsListSource: any = []
	bulkEntries: any = []
	bulkData: any
	authorizationType: any
	formBulkPayment: FormGroup
	formTransferOtp: FormGroup
	authLimit: number
	constructor(
		private ui: UiService,
		private logger: LogService,
		private fb: FormBuilder,
		private http: HttpClient) {
		// get corporate id. 
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')

		// check if user has permission and redirect if not
		// config.currentUserHasPermissionByKey('InitiateTransaction', config.unAuthRedirectUrl)
	}

	ngOnInit() {

		this.formBulkPayment = this.fb.group({
			sourceAccountNumber: ['', Validators.required],
			narration: ['', Validators.required],
			currency: [''],
			workflowId: [''],
			otp: [''],
			pin: ['']

		})
		this.formTransferOtp = this.fb.group({
			otp: [''],
			pin: ['']
		})

		// init template
		this.initTemplate()
		this.fetchAllCorporateAccounts()
	}

	// initialize template
	initTemplate() {
		// initialize dropzone
		// this.dropzone()
	}


	back() {
		this.hasUploaded = false
	}
	// bring up otp screen
	bringUpAutheticationModal() {
		// show modal
		modal.show(`#modal-authentication-token`)
	}

	replaceLettersWithSpace(event: any, key: string) {
		const inputElement = event.target;
		const inputValue = inputElement.value;

		// Replace letters with spaces using a regular expression
		const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

		// Update the input value
		inputElement.value = sanitizedValue;

		// Update the model variable
		this.formTransferOtp.patchValue({
			[key]: sanitizedValue
		})
	}

	async fetchBulkUploadSample() {

	}

	// fetch corporate accounts
	async fetchAllCorporateAccounts() {
		// pass the profile
		let id = this.corporateCustomerId

		// loading
		this.ui.blockUiOn(`Fetching corporate accounts... please wait`)

		let payload = await store.preparePayload({
			id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.getCorporateAccounts + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// notification
					this.ui.info(`Accounts loaded successfully.`)
					// pass on data
					this.accounts = data?.records

					//fetch workflows
					if (config.checkIfNotSingleSignatory(this.authorizationType)) {
						this.fetchAllCorporateCustomerWorkflows()
					}
					// auto-select account
					// this.formFilter.patchValue({
					// 	account: this.accounts[0].accountNumber
					// })
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// fetch template sample
	async fetchSampleTemplate() {

		// loading
		this.ui.blockUiOn(`Fetching bulk sample template... please wait`)


		// network call
		this.http.get<any>(config.base + routes.transactions.getBulkTemplate, { ...config.httpOptions(), responseType: 'blob' as 'json' })
			.subscribe({
				next: (data: any) => {
					let dataType = data.type;
					let binaryData = []
					binaryData.push(data)
					let downloadLink = document.createElement('a')
					downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
					downloadLink.setAttribute('download', "template")
					document.body.appendChild(downloadLink)
					downloadLink.click()
					document.body.removeChild(downloadLink)

					// notification
					this.ui.info(`template loaded successfully.`)

				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// fetch corporate accounts
	async fetchBankCodeList() {

		// loading
		this.ui.blockUiOn(`Fetching bank code list ... please wait`)


		// network call
		this.http.get<any>(config.base + routes.transactions.getBankCodeList, { ...config.httpOptions(), responseType: 'blob' as 'json' })
			.subscribe({
				next: (data: any) => {
					// output data
					let dataType = data.type;
					let binaryData = []
					binaryData.push(data)
					let downloadLink = document.createElement('a')
					downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
					downloadLink.setAttribute('download', "template")
					document.body.appendChild(downloadLink)
					downloadLink.click()
					document.body.removeChild(downloadLink)
					// notification
					this.ui.info(`bank code list loaded successfully.`)

				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// fetch all workflows
	async fetchAllCorporateCustomerWorkflows() {
		// loading
		this.ui.blockUiOn(`Fetching all workflows... please wait`)

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.workflows.getWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// pass on data
					this.workflows = data
					// notification
					this.ui.info(`Workflows loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => { });
	}

	async verifyBulkTransaction(target: any) {

		// get form
		let form = find(target)



		const formData = new FormData();

		let values = this.formBulkPayment.value
		if (!values.sourceAccountNumber) {
			// warning
			this.ui.warning(`Kindly select account number.`)
			// Rogue.Form.flag(form)
			return
		}

		if (!this.fileToUpload) {
			// warning
			this.ui.warning(`Please select file to upload`)
			// Rogue.Form.flag(form)
			return
		}

		if (!values.narration) {
			// warning
			this.ui.warning(`Kindly fill narration.`)
			// Rogue.Form.flag(form)
			return
		}
		this.ui.blockUiOn(`verifying bulk transaction.`)

		let payload = await store.preparePayload({
			sourceAccountNumber: values.sourceAccountNumber,
			narration: values.narration,
			currency: values.currency,
			workflowId: values.workflowId,
			// transactionType: "multiple_signatory"
		})


		formData.append("SourceAccountNumber", payload.sourceAccountNumber);
		formData.append("Narration", payload.narration);
		formData.append("Currency", payload.currency);
		formData.append("WorkflowId", payload.workflowId);
		// formData.append("TransactionType", payload.transactionType);
		formData.append("files", this.fileToUpload);


		this.http.post<any>(config.base + routes.transactions.verifyBulkTransfer, formData, config.httpOptionsFormData())
			.subscribe({
				next: (data: any) => {
					// output data
					// check data returned
					if (data?.data) {
						this.bulkEntries = data?.data.transaction
						this.bulkData = data?.data
						this.hasUploaded = true
						this.authLimit = data?.data?.authLimitIsEnable
					}
				},
				error: (error) => {
					if (!(new RegExp(/A connection attempt failed because the connected/i)).test(JSON.stringify(error))) {
						// error
						this.ui.error(error)
					} else {
						// notification
						this.ui.info(`Dummy transaction successfully verified.`)
						this.hasUploaded = true


						// bring up otp screen
						// this.bringUpAutheticationModal()
					}
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	async initiateBulkTransfer(target: any) {
		// get form
		let form = find(target)

		if (this.authLimit == 1 && !this.formTransferOtp.value.pin) {
			this.ui.warning(`Please provide your pin!`)
			Rogue.Form.flag(form)
			return
		}
		if (!this.formTransferOtp.value.otp) {
			// warning
			this.ui.warning(`Please provide your Token!`)
			Rogue.Form.flag(form)
			return
		}

		if (this.authLimit == 1 && this.formTransferOtp.value.pin?.length != 4) {
			this.ui.warning(`Pin must be 4 digits!`)
			return
		}

		if (this.formTransferOtp.value.otp?.length != 8) {
			// warning
			this.ui.warning(`Token must be 8 digits!!`)
			return
		}

		// processing modal
		this.ui.blockUiOn(`Initiating bulk transfer.... Please wait`)

		const formData = new FormData();

		let values = this.formBulkPayment.value
		let payload = await store.preparePayload({
			sourceAccountNumber: values.sourceAccountNumber,
			narration: values.narration,
			currency: values.currency,
			workflowId: values.workflowId,
			otp: this.formTransferOtp.value.otp,
			pin: this.formTransferOtp.value.pin,
			TransactionLocation: "9.000705,7.578728",
			AuthLimitIsEnable: this.authLimit
		}, false)


		formData.append("SourceAccountNumber", payload.sourceAccountNumber);
		formData.append("Narration", payload.narration);
		formData.append("Currency", payload.currency);
		formData.append("WorkflowId", payload.workflowId);
		formData.append("AuthLimitIsEnable", payload.AuthLimitIsEnable);
		formData.append("otp", payload.otp);
		formData.append("pin", payload.pin);
		formData.append("files", this.fileToUpload);
		formData.append("TransactionLocation", payload.TransactionLocation)

		modal.hide(`#modal-authentication-token`)
		this.formTransferOtp.reset()


		this.http.post<any>(config.base + routes.transactions.initiateBulkTransfer, formData, config.httpOptionsFormData())
			.subscribe({
				next: (data: any) => {
					// output data

					// check data returned
					// if (data == true) {
					// notification
					this.ui.info(`Transaction successfully initiated.`)

					// hide modal
					modal.hide(`#modal-processing`)

					// reset forms
					this.formBulkPayment.reset()
					this.hasUploaded = false

					// reset otp form
					// this.resetFormOtp()
					// }
				},
				error: error => {
					// error
					this.ui.error(error)
					// processing modal
					modal.hide(`#modal-processing`)
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				modal.hide(`#modal-processing`)
			});
	}
	openConfirmModal() {
		modal.show('#modal-confirm')
	}
	openAuthModal() {
		modal.hide('#modal-confirm')
		modal.show(`#modal-authentication-token`)
	}
	hideAuthModal() {
		modal.hide(`#modal-authentication-token`)
	}
	hideModalConfirm() {
		modal.hide('#modal-confirm')
	}
	// get selected account information
	getSelectedAccountInformation(target: any) {
		// get index
		let controlIndex = target['selectedIndex']

		// check if index is 0
		if (controlIndex < 1) {
			// reset the lists
			this.accountsListSource = this.accounts
		}


		this.formBulkPayment.patchValue({
			currency: target.options[controlIndex].getAttribute("currency"),
			sourceAccountNumber: target.value,
		})

		// unide anything hidden
		this.accountsListSource = this.accounts
	}

	onFileSelected(event: any) {
		const file: File = event.target.files[0];
		this.fileToUpload = file;
	}


}