import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { HttpClient } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.css']
})
export class StaffsComponent implements OnInit {
  constructor(private table: DataTablesService,
    private ui: UiService,
    private http: HttpClient,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe
  ) {
    this.corporateCustomerId = store.get('corporateCustomerId')
    this.authorizationType = store.get('authorizationType')
    this.currentUser = store.get('userId')
  }

  config = config
  payments: any = []
  salaryPaymentStatus: boolean = false
  staffs = []
  pendingStaffs = []
  bankList: any
  corporateCustomerId: string = ""
  authorizationType: any
  currentUser: any
  formAddStaff: FormGroup
  formOtp: FormGroup
  destinationAccountDetails: any
  currentlySelectedStaff: any
  reasonType: string;
  declineReason: FormControl = new FormControl('')

  ngOnInit() {

    this.formAddStaff = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      staffId: ['', Validators.required],
      accountNumber: ['', Validators.required],
      accountName: ['', Validators.required],
      bankName: ['', Validators.required],
      bankCode: ['', Validators.required],
      salaryAmount: ['', Validators.required],
      gradeLevel: ['', Validators.required],
      description: ['', Validators.required],
      department: ['', Validators.required]
    })

    this.formAddStaff.valueChanges.subscribe(form => {
      if (form.salaryAmount) {
        this.formAddStaff.patchValue({
          salaryAmount: this.currencyPipe.transform(form.salaryAmount.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
        }, { emitEvent: false })
      }
    })
    // initialize table
    this.table.init()
    // this.fetchInterBankList()
    if (this.config.currentUserHasPermissionByKey('CanViewStaff') || !config.checkIfNotSingleSignatory(this.authorizationType)) {
      this.fetchAllStaffs()
    }
  }



  // fetch all staffs
  async fetchAllStaffs() {
    // loading
    this.ui.blockUiOn(`Fetching all staffs... please wait`)


    // network call
    this.http.get<any>(config.base + routes.salary.getStaffs, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          this.table.destroy(`salary-payment`)
          // pass on data
          this.staffs = data?.data

          this.table.reInit(`salary-payment`)

          // notification
          this.ui.info(`Staffs loaded successfully.`)

          this.ui.blockUiOff()
        },
        error: error => {
          // error
          this.ui.error(error)
          this.ui.blockUiOff()
        }
      })
      .add(() => {
        this.fetchAllPendingStaffs()
      });
  }

  // fetch all staffs
  async fetchAllPendingStaffs() {
    // loading
    this.ui.blockUiOn(`Fetching pending staffs... please wait`)


    // network call
    this.http.get<any>(config.base + routes.salary.getPendingStaffs, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          this.table.destroy(`pending-salary-payment`)
          // pass on data
          this.pendingStaffs = data?.data

          this.table.reInit(`pending-salary-payment`)
          // notification
          this.ui.info(`Pending staffs loaded successfully.`)

          this.ui.blockUiOff()
        },
        error: error => {
          // error
          this.ui.error(error)
          this.ui.blockUiOff()
        }
      })
      .add(() => {
      });
  }
  getSelectedAccountInformation(target: any, key: string) {
    // get index
    let controlIndex = target['selectedIndex']

    // patch values
    this.formAddStaff.patchValue({
      bankName: target.options[controlIndex].getAttribute("bankName"),
      bankCode: target.options[controlIndex].getAttribute("bankCode"),
    })
  }
  // fetch inter bank list
  fetchInterBankList() {
    // loading
    this.ui.blockUiOn(`Almost There... please wait`)

    // network call
    this.http.post<any>(config.base + routes.transactions.interbankList, {}, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // pass on data
          // this.bankList = [{ institutionCode: "999998", institutionName: "Pseudo" }]
          // this.bankList = [...data?.data?.banks]
          const b = data?.data?.banks.sort((a: any, b: any) => a.institutionName?.localeCompare(b.institutionName))
          this.bankList = [{ institutionCode: "999015", institutionName: "Parallex" }, { institutionCode: "999998", institutionName: "Pseudo" }, ...b]

          // unblock
          this.ui.blockUiOff()
        },
        error: error => {
          // error  
          this.ui.error(error)

          // unblock
          this.ui.blockUiOff()
        }
      })
      .add(() => { });
  }

  // get account information
  async getAccountInformation(target: any, type?: string) {
    // get account number
    let account = String(target.value).replace(/\s*/g, "")

    // check length of account number
    if (account.length != 10) {
      // empty store
      this.destinationAccountDetails = {}
      return
    }
    // progress
    this.ui.blockUiOn(`Fetching account information... please wait`)

    // define payload
    let payload = await store.preparePayload({
      accountNumber: String(account)
    }, true)

    let payloadInterBank = {
      accountNumber: String(account),
      destinationBankCode: String(this.formAddStaff.value.bankCode),
    }

    let encryptedInterBankPayload = await store.preparePayload({
      accountNumber: String(account),
      destinationBankCode: String(this.formAddStaff.value.bankCode),
    })



    // network call
    if (type === "inter") {
      this.http.post<any>(config.base + routes.transactions.interbankNameEnquiry, encryptedInterBankPayload, config.httpOptions())
        .subscribe({
          next: (data: any) => {
            // log


            // pass on data
            this.destinationAccountDetails = data?.data

            // insert destination account name
            this.formAddStaff.patchValue({
              accountName: this.destinationAccountDetails.accountName,
              accountNumber: payloadInterBank.accountNumber,
              // bankName: this.formAddStaff.value.destinationBankName || ''
            })

            // notification
            this.ui.success(`Name enquiry was successful.`)
          },
          error: error => {
            // error
            this.ui.error(error)

          }
        }).add(() => this.ui.blockUiOff());
    } else {
      this.http.get<any>(config.base + routes.manageAccounts.customerNameEnquiry + `?accountNumber=${payload.accountNumber}`, config.httpOptions())
        .subscribe({
          next: (data: any) => {
            // log


            // pass on data
            this.destinationAccountDetails = data?.data

            // insert destination account name
            this.formAddStaff.patchValue({
              accountName: this.destinationAccountDetails.accountName,
              // bankName: this.destinationAccountDetails.bankName || ''
            })

            // notification
            this.ui.success(`Name enquiry was successful.`)
          },
          error: error => {
            // error
            this.ui.error(error)

          }
        }).add(() => this.ui.blockUiOff());
    }
  }

  openStaffForm(selected?: any) {
    this.fetchInterBankList()

    if (selected) {
      this.currentlySelectedStaff = selected
      this.formAddStaff.patchValue({
        ...selected
      })
    } else {
      this.currentlySelectedStaff = undefined;
      this.formAddStaff.reset()
    }
    modal.show('#modal-new-staff')

  }
  initiateCreateStaff(event: any) {

  }
  async createOrEditStaff() {

    if (!this.formAddStaff.valid) {
      this.ui.warning('Please fill all fields')
      return
    }
    this.ui.blockUiOn(`Saving staff... please wait`)



    if (this.currentlySelectedStaff) {

      let payload = await store.preparePayload({
        ...this.formAddStaff.value,
        corporateCustomerId: this.corporateCustomerId,
        id: this.currentlySelectedStaff.id
      })
      this.http.post(config.base + routes.salary.updateSingleStaff, payload, config.httpOptions())
        .subscribe({
          next: (data: any) => {

            this.ui.success(`Staff updated successfully.`)
            this.ui.blockUiOff()
            modal.hide('#modal-new-staff')
          },
          error: error => {
            this.ui.error(error)
            this.ui.blockUiOff()
          }
        })
        .add(() => {
          this.ui.blockUiOff()
          this.fetchAllStaffs()
        });
    }
    else {
      let payload = await store.preparePayload({
        ...this.formAddStaff.value,
        corporateCustomerId: this.corporateCustomerId,
      })
      this.http.post(config.base + routes.salary.createSingleStaff, payload, config.httpOptions())
        .subscribe({
          next: (data: any) => {

            this.ui.success(`Staff created successfully.`)
            this.ui.blockUiOff()
            modal.hide('#modal-new-staff')
          },
          error: error => {
            this.ui.error(error)
            this.ui.blockUiOff()
          }
        })
        .add(() => {
          this.ui.blockUiOff()
          this.fetchAllStaffs()
        });
    }

  }
  initiateStaffRequestApproval(s: any) {
    // pass on data
    this.currentlySelectedStaff = s
    // approve role
    this.requestStaffApproval()
  }
  // initiate approve schedule
  initiateStaffApprove(s: any) {
    // pass on data
    this.currentlySelectedStaff = s
    // approve role
    this.approveStaff()
  }

  // initiate decline role
  initiateStaffDecline(s: any) {
    // pass on data
    this.currentlySelectedStaff = s
    this.reasonType = "decline"
    // reset form
    this.declineReason.reset()
    // bring up modal
    modal.show(`#modal-staff-reason`)
  }


  initiateStaffReactivate(s: any) {
    // pass on data
    this.currentlySelectedStaff = s
    // approve role
    this.reactivateStaff()
  }

  // initiate deactivate role
  initiateStaffDeactivate(s: any) {
    // pass on data
    this.currentlySelectedStaff = s
    this.reasonType = "deactivate"
    // reset form
    this.declineReason.reset()
    // bring up modal
    modal.show(`#modal-staff-reason`)
  }
  async requestStaffApproval() {
    // blocker
    this.ui.blockUiOn(`Requesting approval schedule... please wait`)
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedStaff.id
    })

    // network
    this.http.post<any>(config.base + routes.salary.requestStaffApproval, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`approval requested for schedule.`)

        },
        error: e => {
          // error
          this.ui.error(e)
          this.ui.blockUiOff()
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        this.fetchAllStaffs()
      })
  }
  async approveStaff() {
    // blocker
    this.ui.blockUiOn(`Approving... please wait`)
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedStaff.id
    })

    // network
    this.http.post<any>(config.base + routes.salary.approveStaff, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`staff successfully approved.`)

        },
        error: e => {
          // error
          this.ui.error(e)
          this.ui.blockUiOff()
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        this.fetchAllStaffs()
      })
  }

  // decline 
  async declineStaff() {
    // check if form is valid
    if (!this.declineReason.valid) {
      // warning
      this.ui.warning(`Please provide reason for declining !`)
      return
    }


    // blocker
    this.ui.blockUiOn(`Declining schedule... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedStaff.id,
      reason: this.declineReason.value
    })


    // network
    this.http.post<any>(config.base + routes.salary.declineStaff, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`schedule successfully declined.`)

          // dismiss form
          this.declineReason.reset()

          // dismiss modal
          modal.hide(`#modal-schedule-reason`)
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        this.fetchAllStaffs()
      })
  }
  async reactivateStaff() {
    // blocker
    this.ui.blockUiOn(`Reactivating Staff... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedStaff.id
    })

    // network
    this.http.post<any>(config.base + routes.salary.reactivateStaff, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Reactivated Staff.`)

        },
        error: e => {
          // error
          this.ui.error(e)
          this.ui.blockUiOff()
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        this.fetchAllStaffs()
      })
  }
  async deactivateStaff() {
    // check if form is valid
    if (!this.declineReason.valid) {
      // warning
      this.ui.warning(`Please provide reason for deactivating Staff!`)
      return
    }

    // blocker
    this.ui.blockUiOn(`Deactivating Staff... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedStaff.id,
      reason: this.declineReason.value
    })



    // network
    this.http.post<any>(config.base + routes.salary.deactivateStaff, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Staff successfully deactivated.`)

          // dismiss form
          this.declineReason.reset()

          // dismiss modal
          modal.hide(`#modal-staff-reason`)
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        this.fetchAllStaffs()
      })
  }


}
