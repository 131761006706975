import { Injectable, Inject } from '@angular/core';
import { LogService, UiService } from '@app/services';

// jquery operator
declare var $: any;

@Injectable({ providedIn: 'root' })
export class DataTablesService {
	constructor (private logger: LogService,
		private ui: UiService) {}

	options = {
	  "oLanguage": {
	    "oPaginate": {
	      "sPrevious": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-arrow-left\"><line x1=\"19\" y1=\"12\" x2=\"5\" y2=\"12\"></line><polyline points=\"12 19 5 12 12 5\"></polyline></svg>",
	      "sNext": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-arrow-right\"><line x1=\"5\" y1=\"12\" x2=\"19\" y2=\"12\"></line><polyline points=\"12 5 19 12 12 19\"></polyline></svg>"
	    },
	    "sInfo": "Showing page _PAGE_ of _PAGES_",
	    "sSearch": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-search\"><circle cx=\"11\" cy=\"11\" r=\"8\"></circle><line x1=\"21\" y1=\"21\" x2=\"16.65\" y2=\"16.65\"></line></svg>",
	    "sSearchPlaceholder": "Search...",
	    "sLengthMenu": "Rows: _MENU_"
	  },
	  "stripeClasses": [],
	  "lengthMenu": [
	    7,
	    10,
	    20,
	    50
	  ],
	  "pageLength": 7
	}
	optionsCustom = {
	  "oLanguage": {
	    "oPaginate": {
	      "sPrevious": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-arrow-left\"><line x1=\"19\" y1=\"12\" x2=\"5\" y2=\"12\"></line><polyline points=\"12 19 5 12 12 5\"></polyline></svg>",
	      "sNext": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-arrow-right\"><line x1=\"5\" y1=\"12\" x2=\"19\" y2=\"12\"></line><polyline points=\"12 5 19 12 12 19\"></polyline></svg>"
	    },
	    "sInfo": "Showing page _PAGE_ of _PAGES_",
	    "sSearch": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-search\"><circle cx=\"11\" cy=\"11\" r=\"8\"></circle><line x1=\"21\" y1=\"21\" x2=\"16.65\" y2=\"16.65\"></line></svg> <select class='form-control d-none'><option>27 Jul, 2021</option></select> <select class='form-control d-none'><option>29 Jul, 2022</option></select>",
	    "sSearchPlaceholder": "Search...",
	    "sLengthMenu": "Rows: _MENU_"
	  },
	  "stripeClasses": [],
	  "lengthMenu": [
	    7,
	    10,
	    20,
	    50
	  ],
	  "pageLength": 7
	}

	// initialize
	init (table?: string){
		// get logger
		let logger = this.logger
		setTimeout(() => {
	    // loop through all datatable cobtrols
	    document.querySelectorAll('.datatable').forEach(table => {
	    	// get table id
	    	let id = `#${$(table).attr('id')}`
	    	// check if not already datatable
	    	if (!$(id).parents('.dataTables_wrapper').length) {
		    	// logger.log(id)
		    	// initialize datatable
		      $(id).DataTable(table.getAttribute('data-control-scope') == 'transaction-history' ? this.optionsCustom : this.options);
		    }
    	});

	    // reanimate tooltips
	    this.ui.tooltips()
	  }, 10)
	}

	// re-init table
	reInit (id: any): void {
		// re-initialize table
		setTimeout(() => {
			// check if not already datatable
  		if (!$(`table[data-control-id="${id}"]`).parents('.dataTables_wrapper').length) {
  			// re-init table
				$(`table[data-control-id="${id}"]`).DataTable(($(`table[data-control-id="${id}"]`).attr('data-control-scope') == 'transaction-history') ? this.optionsCustom : this.options)
			}

			// reanimate tooltips
	    this.ui.tooltips()
		}, 10);
	}

	// destroy data table
	destroy (id: any): void {
		// destroy table
		$(`table[data-control-id="${id}"]`).DataTable().destroy();
	}
}