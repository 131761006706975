//export permissions
export const permissions = [
    {
        "id": "9b1e1bf4-3551-457c-8cf0-07785a462a05",
        "sn": 70,
        "name": "Can Reactivate Staff",
        "key": "CanReactivateStaff",
        "code": "CanReactivateStaff",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "925c1dcf-cca1-497d-96c1-09e5011dc92c",
        "sn": 2,
        "name": "Create Corporate Role",
        "key": "CreateCorporateRole",
        "code": "CreateCorporateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "2af1cce5-6200-4a80-861a-0af86c60bd1b",
        "sn": 3,
        "name": "Decline Corporate Role",
        "key": "DeclineCorporateRole",
        "code": "DeclineCorporateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "11db662c-35d9-4ea8-b7b2-0b1ed173cd30",
        "sn": 69,
        "name": "Can Deactivate Staff",
        "key": "CanDeactivateStaff",
        "code": "CanDeactivateStaff",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "eaf9f95c-1410-4e73-a6e5-1561233aac07",
        "sn": 4,
        "name": "View Corporate Role Permissions",
        "key": "ViewCorporateRolePermissions",
        "code": "ViewCorporateRolePermissions",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "4cf4c929-1bbe-47e2-b0ba-169451b6149f",
        "sn": 5,
        "name": "Add Role Permissions",
        "key": "AddRolePermissions",
        "code": "AddRolePermissions",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "cb9fd84f-c2d9-46d4-beff-1a3ad749858a",
        "sn": 6,
        "name": "Update Role",
        "key": "UpdateRole",
        "code": "UpdateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "1af59f05-b903-4f4a-b250-1a5d2d862f1a",
        "sn": 83,
        "name": "Can Decline Schedule Beneficiary",
        "key": "CanDeclineScheduleBeneficiary",
        "code": "CanDeclineScheduleBeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "50f821b3-ef06-41ee-b02c-1b6a97251365",
        "sn": 90,
        "name": "Can Create Onlending beneficiary",
        "key": "CanCreateOnlendingbeneficiary",
        "code": "CanCreateOnlendingbeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "b677be6a-3803-4409-9138-1d8d1879c7c9",
        "sn": 7,
        "name": "Request Role Approval",
        "key": "RequestRoleApproval",
        "code": "RequestRoleApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "2fbf51ef-f746-4392-ba1c-2178c5a1e823",
        "sn": 9,
        "name": "Decline Workflow",
        "key": "DeclineWorkflow",
        "code": "DeclineWorkflow",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "bd46daad-0354-4f1f-b53e-219f8a46cac1",
        "sn": 88,
        "name": "Can Approve Onlending Repayment Date Extension",
        "key": "CanApproveOnlendingRepaymentDateExtension",
        "code": "CanApproveOnlendingRepaymentDateExtension",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "055a3c63-3496-4365-b5bf-21f64ca8acd2",
        "sn": 10,
        "name": "Decline Corporate User Profile",
        "key": "DeclineCorporateUserProfile",
        "code": "DeclineCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "fee32c30-a85f-467d-883a-2409f02d9108",
        "sn": 80,
        "name": "Can Create Schedule Beneficiary",
        "key": "CanCreateScheduleBeneficiary",
        "code": "CanCreateScheduleBeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "24b11985-d8a2-424d-a809-28d66fa55509",
        "sn": 12,
        "name": "View Corporate Account",
        "key": "ViewCorporateAccount",
        "code": "ViewCorporateAccount",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "a294f73f-4a60-42a0-9a51-29af565f72fc",
        "sn": 73,
        "name": "Can Create Schedule",
        "key": "CanCreateSchedule",
        "code": "CanCreateSchedule",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "96db2b28-244c-47eb-8ad7-2b8288de0153",
        "sn": 13,
        "name": "Request Corporate User Profile Approval",
        "key": "RequestCorporateUserProfileApproval",
        "code": "RequestCorporateUserProfileApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "0bac309d-e984-4681-b5e8-38dc424106c3",
        "sn": 15,
        "name": "View Role Permissions",
        "key": "ViewRolePermissions",
        "code": "ViewRolePermissions",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "47c4c453-e5ed-4101-8d00-38f1f5c7d15e",
        "sn": 16,
        "name": "Update Corporate Customer Account Limit",
        "key": "UpdateCorporateCustomerAccountLimit",
        "code": "UpdateCorporateCustomerAccountLimit",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "a7f216ea-c942-49ef-b0cb-3bba738b8d5a",
        "sn": 71,
        "name": "Can Decline Staff",
        "key": "CanDeclineStaff",
        "code": "CanDeclineStaff",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "1e2c2eba-973d-4827-ae82-3fe22421aea9",
        "sn": 96,
        "name": "Can Initiate Onlending Payment Disbursement",
        "key": "CanInitiateOnlendingPaymentDisbursement",
        "code": "CanInitiateOnlendingPaymentDisbursement",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "1b454642-1f46-436b-aaac-45172fa41c6f",
        "sn": 18,
        "name": "Set Corporate User Access",
        "key": "SetCorporateUserAccess",
        "code": "SetCorporateUserAccess",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "bec525af-855d-46df-b96a-45e6d00b39e5",
        "sn": 86,
        "name": "Can Extend Onlending Repayment Date",
        "key": "CanExtendOnlendingRepaymentData",
        "code": "CanExtendOnlendingRepaymentData",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "86480fe8-f49b-4387-a699-461e6d4b728b",
        "sn": 19,
        "name": "Request Corporate Customer Approval",
        "key": "RequestCorporateCustomerApproval",
        "code": "RequestCorporateCustomerApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "595e715b-0feb-4e04-9c7c-4dc44a816835",
        "sn": 87,
        "name": "Can Decline Onlending Repayment Date Extension",
        "key": "CanDeclineOnlendingRepaymentDateExtension",
        "code": "CanDeclineOnlendingRepaymentDateExtension",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "1631d4ed-6241-489e-9370-4fe307aeeeda",
        "sn": 20,
        "name": "Create Corporate User Profile",
        "key": "CreateCorporateUserProfile",
        "code": "CreateCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "4c6ab9b5-4ef3-4f54-a5ee-5026aca6f979",
        "sn": 21,
        "name": "Update Corporate Role",
        "key": "UpdateCorporateRole",
        "code": "UpdateCorporateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "f4141dc0-602c-4022-b3cd-5055e022feae",
        "sn": 22,
        "name": "Decline Role",
        "key": "DeclineRole",
        "code": "DeclineRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "60df77f4-39b8-46ad-a79b-51e534e6bc90",
        "sn": 23,
        "name": "Activate Corporate Role",
        "key": "ActivateCorporateRole",
        "code": "ActivateCorporateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "378f4251-999c-4cd2-95b3-59b1bbd9503f",
        "sn": 25,
        "name": "Approve Corporate User Profile",
        "key": "ApproveCorporateUserProfile",
        "code": "ApproveCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "faeb7b64-e076-4fde-8274-5d4b30dc5056",
        "sn": 75,
        "name": "Can Initiate Schedule",
        "key": "CanInitiateSchedule",
        "code": "CanInitiateSchedule",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "6e48c694-1d06-4805-a8ea-609353807798",
        "sn": 67,
        "name": "Can View Staff",
        "key": "CanViewStaff",
        "code": "CanViewStaff",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "61be5374-ec2b-4f38-8be2-6203078da0a5",
        "sn": 26,
        "name": "Approve Role",
        "key": "ApproveRole",
        "code": "ApproveRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "f630e28a-368c-43fc-902e-66c782f32fa3",
        "sn": 68,
        "name": "Can Approve Staff",
        "key": "CanApproveStaff",
        "code": "CanApproveStaff",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "bfd5ad2d-cb8f-4216-87c5-6a33c6bc38e5",
        "sn": 28,
        "name": "Reset User Password",
        "key": "ResetUserPassword",
        "code": "ResetUserPassword",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "437b2e82-2c22-4618-a20b-6c8bafa3228b",
        "sn": 29,
        "name": "Create Bank Admin Profile",
        "key": "CreateBankAdminProfile",
        "code": "CreateBankAdminProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "121bf8f2-67a5-471d-bd52-6f5037a3e235",
        "sn": 30,
        "name": "Deactivate Role",
        "key": "DeactivateRole",
        "code": "DeactivateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "1a4fd8c7-b978-4987-a6c6-70282958e473",
        "sn": 31,
        "name": "Request Corporate Role Approval",
        "key": "RequestCorporateRoleApproval",
        "code": "RequestCorporateRoleApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "2d236583-ba59-46f0-925c-71558b4eb255",
        "sn": 66,
        "name": "Can Create Staff",
        "key": "CanCreateStaff",
        "code": "CanCreateStaff",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "c36dcdf3-ade7-4808-a9d1-7f23112f751f",
        "sn": 34,
        "name": "Update Corporate User Profile",
        "key": "UpdateCorporateUserProfile",
        "code": "UpdateCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "f059af89-6682-4147-b64a-80301427f589",
        "sn": 35,
        "name": "Request Workflow Approval",
        "key": "RequestWorkflowApproval",
        "code": "RequestWorkflowApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "a68fcae7-04a7-4d5e-854b-80bec4a2df1b",
        "sn": 74,
        "name": "Can View Schedule",
        "key": "CanViewSchedule",
        "code": "CanViewSchedule",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "01510072-bbf8-413d-b2bc-8755b9f49286",
        "sn": 36,
        "name": "Add Corporate Role Permissions",
        "key": "AddCorporateRolePermissions",
        "code": "AddCorporateRolePermissions",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "15b3c19a-5f70-4ec9-bad1-884f3ed21f47",
        "sn": 84,
        "name": "Can Request Schedule Beneficiary Approval",
        "key": "CanApprovedScheduleBeneficiary",
        "code": "CanApprovedScheduleBeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "84da76ea-ea52-4026-92f5-8a626ede1476",
        "sn": 37,
        "name": "Enable Logged Out Corporate User",
        "key": "EnableLoggedOutCorporateUser",
        "code": "EnableLoggedOutCorporateUser",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "540daf1c-841c-4221-ad08-8b4d2cf27f38",
        "sn": 72,
        "name": "Can Request Staff Approval",
        "key": "CanRequestStaffApproval",
        "code": "CanRequestStaffApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "2c2f26f0-8ddc-4f82-9778-8ba72987a76c",
        "sn": 92,
        "name": "Can Request Approval For Onlending Beneficiary",
        "key": "CanRequestForApprovalofOnlendingbeneficiary",
        "code": "CanRequestForApprovalofOnlendingbeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "bd0e0aa5-e821-4d23-a861-8ec48e3e09c0",
        "sn": 38,
        "name": "Deactivate Corporate User Profile",
        "key": "DeactivateCorporateUserProfile",
        "code": "DeactivateCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "c1467591-2a33-4d8b-983b-91e1816f0285",
        "sn": 39,
        "name": "Update Corporate User Role",
        "key": "UpdateCorporateUserRole",
        "code": "UpdateCorporateUserRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "b7f2680b-0581-411a-b92e-9336ba0d31bd",
        "sn": 81,
        "name": "Can Request Beneficiary Approval",
        "key": "CanRequestScheduleBeneficiaryApproval",
        "code": "CanRequestScheduleBeneficiaryApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "99c8239f-88e1-4d13-9e06-9763801386b2",
        "sn": 78,
        "name": "Can Deactivate Schedule",
        "key": "CanDeactivateSchedule",
        "code": "CanDeactivateSchedule",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "0bd2ddd3-3029-482d-b144-97fe9e86bb92",
        "sn": 42,
        "name": "Create Role",
        "key": "CreateRole",
        "code": "CreateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "cab9c904-d711-4073-8aa7-984752e93ebc",
        "sn": 76,
        "name": "Can Approved Schedule",
        "key": "CanApprovedSchedule",
        "code": "CanApprovedSchedule",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "d5bdcfa5-d372-408a-a19c-9c4d66be86cc",
        "sn": 44,
        "name": "View Corporate Role",
        "key": "ViewCorporateRole",
        "code": "ViewCorporateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "b0015b82-c198-47a3-bbc6-a0f267b7ce51",
        "sn": 89,
        "name": "Can Request Approval For Onlending Repayment Date Extension",
        "key": "CanRequestForApprovalOnlendingRepaymentDateExtension",
        "code": "CanRequestForApprovalOnlendingRepaymentDateExtension",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "580b5893-3d5e-49c1-8f98-a5a82a88e2de",
        "sn": 46,
        "name": "View Corporate User Profile",
        "key": "ViewCorporateUserProfile",
        "code": "ViewCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "38225edb-908b-4e10-9598-abfd5bea8e49",
        "sn": 48,
        "name": "View Corporate Customer",
        "key": "ViewCorporateCustomer",
        "code": "ViewCorporateCustomer",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "9eb9116c-2aac-428b-8159-adde57c7251e",
        "sn": 49,
        "name": "View Transaction History",
        "key": "ViewTransactionHistory",
        "code": "ViewTransactionHistory",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "1248a862-1e8e-4613-8efc-afe437566f80",
        "sn": 91,
        "name": "Can Approve Onlending beneficiary",
        "key": "CanApproveOnlendingbeneficiary",
        "code": "CanApproveOnlendingbeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "bc014fe4-371c-4c5e-918a-b0265e1d3949",
        "sn": 82,
        "name": "Can Deactivate Schedule Beneficiary",
        "key": "CanDeactivateScheduleBeneficiary",
        "code": "CanDeactivateScheduleBeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "645fa746-8046-4d6e-a3be-b2523cc32885",
        "sn": 51,
        "name": "Change Password",
        "key": "ChangePassword",
        "code": "ChangePassword",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "57df323b-ded7-4728-8fc5-b61efe118832",
        "sn": 77,
        "name": "Can Decline Schedule",
        "key": "CanDeclineSchedule",
        "code": "CanDeclineSchedule",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "f5aec7fc-1fb3-46e9-8376-bc424e9e31ef",
        "sn": 93,
        "name": "Can Decline Onlending Beneficiary",
        "key": "CanDeclineOnlendingBeneficiary",
        "code": "CanDeclineOnlendingBeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "5d9534d8-12eb-442c-afb7-c4044c5f0973",
        "sn": 54,
        "name": "ReActivate Corporate User Profile",
        "key": "ReActivateCorporateUserProfile",
        "code": "ReActivateCorporateUserProfile",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "2b04933f-c03b-403c-9e70-cb86256cb9f7",
        "sn": 55,
        "name": "View User Accesses",
        "key": "ViewUserAccesses",
        "code": "ViewUserAccesses",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "4addbe60-85d0-4b50-869c-cbbf9be65706",
        "sn": 56,
        "name": "Activate Role",
        "key": "ActivateRole",
        "code": "ActivateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "d47710de-4546-4cca-9c35-cc3d5fd16c81",
        "sn": 57,
        "name": "View Workflow",
        "key": "ViewWorkflow",
        "code": "ViewWorkflow",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "a9843de4-c4ba-4b96-b11e-d66b80e3e548",
        "sn": 85,
        "name": "Can Remove Schedule Beneficiary",
        "key": "CanRemoveScheduleBeneficiary",
        "code": "CanRemoveScheduleBeneficiary",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "f4ffdf1b-f241-42e0-a08d-de54e52c39c9",
        "sn": 79,
        "name": "Can Request Schedule Approval",
        "key": "CanRequestScheduleApproval",
        "code": "CanRequestScheduleApproval",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "de4cea9f-2a8c-410f-86bf-e052c3568e40",
        "sn": 94,
        "name": "Can Initiate Onlending Liquidation",
        "key": "CanInitiateOnlendingLiquidation",
        "code": "CanInitiateOnlendingLiquidation",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "01c1be08-669b-440e-b8a5-f7b1b1b9042d",
        "sn": 61,
        "name": "View Audit Trail",
        "key": "ViewAuditTrail",
        "code": "ViewAuditTrail",
        "isCorporate": true,
        "description": "View Audit Trail Information"
    },
    {
        "id": "1b28a3fa-298d-432c-bcf1-f8cc17b7c089",
        "sn": 95,
        "name": "Can Approve Onlending Payment Disbursement",
        "key": "CanApproveOnlendingPaymentDisbursement",
        "code": "CanApproveOnlendingPaymentDisbursement",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "983ce2a1-face-40a0-b4c8-fa32ec7386a3",
        "sn": 62,
        "name": "View Role",
        "key": "ViewRole",
        "code": "ViewRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "83db8a87-1df5-4e43-a7c7-fa5ec19322c9",
        "sn": 63,
        "name": "Approve Workflow",
        "key": "ApproveWorkflow",
        "code": "ApproveWorkflow",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "7bf7dd5a-373e-4fd4-b352-fce61c6c9daf",
        "sn": 64,
        "name": "Approve Corporate Role",
        "key": "ApproveCorporateRole",
        "code": "ApproveCorporateRole",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "d00e7a5b-10cd-4195-9afd-fe65d8a48c1a",
        "sn": 65,
        "name": "Create Workflow",
        "key": "CreateWorkflow",
        "code": "CreateWorkflow",
        "isCorporate": true,
        "description": null
    },
    {
        "id": "2D236583-BA59-46F0-925C-71558B4EB255",
        "name": "Can Create Staff",
        "code": "CanCreateStaff",
        "isCorporate": null,
        "description": "Can Create Staff",
        "key": "CanCreateStaff"
    },
    {
        "id": "6E48C694-1D06-4805-A8EA-609353807798",
        "name": "Can View Staff",
        "code": "CanViewStaff",
        "isCorporate": null,
        "description": "Can View Staff",
        "key": "CanViewStaff"
    },
    {
        "id": "F630E28A-368C-43FC-902E-66C782F32FA3",
        "name": "Can Approve Staff",
        "code": "CanApproveStaff",
        "isCorporate": null,
        "description": "Can Approve Staff",
        "key": "CanApproveStaff"
    },
    {
        "id": "11DB662C-35D9-4EA8-B7B2-0B1ED173CD30",
        "name": "Can Deactivate Staff",
        "code": "CanDeactivateStaff",
        "isCorporate": null,
        "description": "Can Deactivate Staff",
        "key": "CanDeactivateStaff"
    },
    {
        "id": "9B1E1BF4-3551-457C-8CF0-07785A462A05",
        "name": "Can Reactivate Staff",
        "code": "CanReactivateStaff",
        "isCorporate": null,
        "description": "Can Reactivate Staff",
        "key": "CanReactivateStaff"
    },
    {
        "id": "A7F216EA-C942-49EF-B0CB-3BBA738B8D5A",
        "name": "Can Decline Staff",
        "code": "CanDeclineStaff",
        "isCorporate": null,
        "description": "Can Decline Staff",
        "key": "CanDeclineStaff"
    },
    {
        "id": "540DAF1C-841C-4221-AD08-8B4D2CF27F38",
        "name": "Can Request Staff Approval",
        "code": "CanRequestStaffApproval",
        "isCorporate": null,
        "description": "Can Request Staff Approval",
        "key": "CanRequestStaffApproval"
    },
    {
        "id": "A294F73F-4A60-42A0-9A51-29AF565F72FC",
        "name": "Can Create Schedule",
        "code": "CanCreateSchedule",
        "isCorporate": null,
        "description": "Can Create Schedule",
        "key": "CanCreateSchedule"
    },
    {
        "id": "A68FCAE7-04A7-4D5E-854B-80BEC4A2DF1B",
        "name": "Can View Schedule",
        "code": "CanViewSchedule",
        "isCorporate": null,
        "description": "Can View Schedule",
        "key": "CanViewSchedule"
    },
    {
        "id": "FAEB7B64-E076-4FDE-8274-5D4B30DC5056",
        "name": "Can Initiate Schedule",
        "code": "CanInitiateSchedule",
        "isCorporate": null,
        "description": "Can Initiate Schedule",
        "key": "CanInitiateSchedule"
    }
]

//export permissions
// export const permissions = [
//     {
//         "id": "e300540e-01d0-4c2c-978d-0001bcfb4014",
//         "sn": 7,
//         "name": "Update Bank Admin Profile",
//         "code": "UpdateBankAdminProfile",
//         "isCorporate": null,
//         "description": "Update Bank Admin Profile",
//         "key": "UpdateBankAdminProfile"
//     },
//     {
//         "id": "925c1dcf-cca1-497d-96c1-09e5011dc92c",
//         "sn": 32,
//         "name": "Create Corporate Role",
//         "code": "CreateCorporateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateCorporateRole"
//     },
//     {
//         "id": "2af1cce5-6200-4a80-861a-0af86c60bd1b",
//         "sn": 59,
//         "name": "Decline Corporate Role",
//         "code": "DeclineCorporateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeclineCorporateRole"
//     },
//     {
//         "id": "eaf9f95c-1410-4e73-a6e5-1561233aac07",
//         "sn": 60,
//         "name": "View Corporate Role Permissions",
//         "code": "ViewCorporateRolePermissions",
//         "isCorporate": null,
//         "description": "View Corporate Role Permissions",
//         "key": "ViewCorporateRolePermissions"
//     },
//     {
//         "id": "4cf4c929-1bbe-47e2-b0ba-169451b6149f",
//         "sn": 61,
//         "name": "Add Role Permissions",
//         "code": "AddRolePermissions",
//         "isCorporate": false,
//         "description": null,
//         "key": "AddRolePermissions"
//     },
//     {
//         "id": "cb9fd84f-c2d9-46d4-beff-1a3ad749858a",
//         "sn": 34,
//         "name": "Update Role",
//         "code": "UpdateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateRole"
//     },
//     {
//         "id": "b677be6a-3803-4409-9138-1d8d1879c7c9",
//         "sn": 50,
//         "name": "Request Role Approval",
//         "code": "RequestRoleApproval",
//         "isCorporate": null,
//         "description": "Request Role Approval",
//         "key": "RequestRoleApproval"
//     },
//     {
//         "id": "aacfe88f-c41b-4c18-8989-1ed5ec970199",
//         "sn": 10,
//         "name": "Create Corporate Customer",
//         "code": "CreateCorporateCustomer",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateCorporateCustomer"
//     },
//     {
//         "id": "2fbf51ef-f746-4392-ba1c-2178c5a1e823",
//         "sn": 40,
//         "name": "Decline Workflow",
//         "code": "DeclineWorkflow",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeclineWorkflow"
//     },
//     {
//         "id": "055a3c63-3496-4365-b5bf-21f64ca8acd2",
//         "sn": 21,
//         "name": "Decline Corporate User Profile",
//         "code": "DeclineCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeclineCorporateUserProfile"
//     },
//     {
//         "id": "756af369-a929-4ea3-a73d-28c6cb37e588",
//         "sn": 38,
//         "name": "Update Workflow",
//         "code": "UpdateWorkflow",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateWorkflow"
//     },
//     {
//         "id": "24b11985-d8a2-424d-a809-28d66fa55509",
//         "sn": 64,
//         "name": "View Corporate Account",
//         "code": "ViewCorporateAccount",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewCorporateAccount"
//     },
//     {
//         "id": "96db2b28-244c-47eb-8ad7-2b8288de0153",
//         "sn": 23,
//         "name": "Request Corporate User Profile Approval",
//         "code": "RequestCorporateUserProfileApproval",
//         "isCorporate": false,
//         "description": null,
//         "key": "RequestCorporateUserProfileApproval"
//     },
//     {
//         "id": "0673011e-bcaf-4281-bfcb-357ae986cd2b",
//         "sn": 14,
//         "name": "Deactivate Corporate Customer",
//         "code": "DeactivateCorporateCustomer",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeactivateCorporateCustomer"
//     },
//     {
//         "id": "0bac309d-e984-4681-b5e8-38dc424106c3",
//         "sn": 55,
//         "name": "View Role Permissions",
//         "code": "ViewRolePermissions",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewRolePermissions"
//     },
//     {
//         "id": "47c4c453-e5ed-4101-8d00-38f1f5c7d15e",
//         "sn": 28,
//         "name": "Update Corporate Customer Account Limit",
//         "code": "UpdateCorporateCustomerAccountLimit",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateCorporateCustomerAccountLimit"
//     },
//     {
//         "id": "1764f67e-1736-4a48-887a-43bfa603aa3b",
//         "sn": 1,
//         "name": "View Bank Admin Profile",
//         "code": "ViewBankAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewBankAdminProfile"
//     },
//     {
//         "id": "1b454642-1f46-436b-aaac-45172fa41c6f",
//         "sn": 67,
//         "name": "Set Corporate User Access",
//         "code": "SetCorporateUserAccess",
//         "isCorporate": false,
//         "description": null,
//         "key": "SetCorporateUserAccess"
//     },
//     {
//         "id": "86480fe8-f49b-4387-a699-461e6d4b728b",
//         "sn": 15,
//         "name": "Request Corporate Customer Approval",
//         "code": "RequestCorporateCustomerApproval",
//         "isCorporate": false,
//         "description": null,
//         "key": "RequestCorporateCustomerApproval"
//     },
//     {
//         "id": "1631d4ed-6241-489e-9370-4fe307aeeeda",
//         "sn": 5,
//         "name": "Create Corporate User Profile",
//         "code": "CreateCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateCorporateUserProfile"
//     },
//     {
//         "id": "4c6ab9b5-4ef3-4f54-a5ee-5026aca6f979",
//         "sn": 31,
//         "name": "Update Corporate Role",
//         "code": "UpdateCorporateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateCorporateRole"
//     },
//     {
//         "id": "f4141dc0-602c-4022-b3cd-5055e022feae",
//         "sn": 53,
//         "name": "Decline Role",
//         "code": "DeclineRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeclineRole"
//     },
//     {
//         "id": "60df77f4-39b8-46ad-a79b-51e534e6bc90",
//         "sn": 58,
//         "name": "Activate Corporate Role",
//         "code": "ActivateCorporateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "ActivateCorporateRole"
//     },
//     {
//         "id": "87680c33-130f-473b-8b3f-54f6507b89e2",
//         "sn": 12,
//         "name": "Approve Corporate Customer",
//         "code": "ApproveCorporateCustomer",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveCorporateCustomer"
//     },
//     {
//         "id": "378f4251-999c-4cd2-95b3-59b1bbd9503f",
//         "sn": 20,
//         "name": "Approve Corporate User Profile",
//         "code": "ApproveCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveCorporateUserProfile"
//     },
//     {
//         "id": "61be5374-ec2b-4f38-8be2-6203078da0a5",
//         "sn": 51,
//         "name": "Approve Role",
//         "code": "ApproveRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveRole"
//     },
//     {
//         "id": "bfd5ad2d-cb8f-4216-87c5-6a33c6bc38e5",
//         "sn": 49,
//         "name": "Reset User Password",
//         "code": "ResetUserPassword",
//         "isCorporate": false,
//         "description": null,
//         "key": "ResetUserPassword"
//     },
//     {
//         "id": "437b2e82-2c22-4618-a20b-6c8bafa3228b",
//         "sn": 4,
//         "name": "Create Bank Admin Profile",
//         "code": "CreateBankAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateBankAdminProfile"
//     },
//     {
//         "id": "121bf8f2-67a5-471d-bd52-6f5037a3e235",
//         "sn": 54,
//         "name": "Deactivate Role",
//         "code": "DeactivateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeactivateRole"
//     },
//     {
//         "id": "1a4fd8c7-b978-4987-a6c6-70282958e473",
//         "sn": 56,
//         "name": "Request Corporate Role Approval",
//         "code": "RequestCorporateRoleApproval",
//         "isCorporate": false,
//         "description": null,
//         "key": "RequestCorporateRoleApproval"
//     },
//     {
//         "id": "d3dc2005-872f-45de-a097-7500385e9bd8",
//         "sn": 27,
//         "name": "Enable Logged Out Bank Admin User",
//         "code": "EnableLoggedOutBankAdminUser",
//         "isCorporate": false,
//         "description": null,
//         "key": "EnableLoggedOutBankAdminUser"
//     },
//     {
//         "id": "c2a9978c-d97d-4f1d-a4db-7a182c6bead2",
//         "sn": 19,
//         "name": "Request Bank Admin Profile Approval",
//         "code": "RequestBankAdminProfileApproval",
//         "isCorporate": false,
//         "description": null,
//         "key": "RequestBankAdminProfileApproval"
//     },
//     {
//         "id": "c36dcdf3-ade7-4808-a9d1-7f23112f751f",
//         "sn": 8,
//         "name": "Update Corporate User Profile",
//         "code": "UpdateCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateCorporateUserProfile"
//     },
//     {
//         "id": "f059af89-6682-4147-b64a-80301427f589",
//         "sn": 41,
//         "name": "Request Workflow Approval",
//         "code": "RequestWorkflowApproval",
//         "isCorporate": false,
//         "description": null,
//         "key": "RequestWorkflowApproval"
//     },
//     {
//         "id": "01510072-bbf8-413d-b2bc-8755b9f49286",
//         "sn": 62,
//         "name": "Add Corporate Role Permissions",
//         "code": "AddCorporateRolePermissions",
//         "isCorporate": false,
//         "description": null,
//         "key": "AddCorporateRolePermissions"
//     },
//     {
//         "id": "84da76ea-ea52-4026-92f5-8a626ede1476",
//         "sn": 26,
//         "name": "Enable Logged Out Corporate User",
//         "code": "EnableLoggedOutCorporateUser",
//         "isCorporate": false,
//         "description": null,
//         "key": "EnableLoggedOutCorporateUser"
//     },
//     {
//         "id": "bd0e0aa5-e821-4d23-a861-8ec48e3e09c0",
//         "sn": 22,
//         "name": "Deactivate Corporate User Profile",
//         "code": "DeactivateCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeactivateCorporateUserProfile"
//     },
//     {
//         "id": "c1467591-2a33-4d8b-983b-91e1816f0285",
//         "sn": 25,
//         "name": "Update Corporate User Role",
//         "code": "UpdateCorporateUserRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateCorporateUserRole"
//     },
//     {
//         "id": "8f7af661-83d5-4dce-b275-930a14f5d81e",
//         "sn": 65,
//         "name": "Initiate Transaction",
//         "code": "InitiateTransaction",
//         "isCorporate": false,
//         "description": null,
//         "key": "InitiateTransaction"
//     },
//     {
//         "id": "7025bb8b-0e0c-4477-b575-94eec8478b5b",
//         "sn": 13,
//         "name": "Decline Corporate Customer",
//         "code": "DeclineCorporateCustomer",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeclineCorporateCustomer"
//     },
//     {
//         "id": "0bd2ddd3-3029-482d-b144-97fe9e86bb92",
//         "sn": 35,
//         "name": "Create Role",
//         "code": "CreateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateRole"
//     },
//     {
//         "id": "c3deb98c-7de9-4d26-b829-9b285c984b0d",
//         "sn": 16,
//         "name": "Approve Bank Admin Profile",
//         "code": "ApproveBankAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveBankAdminProfile"
//     },
//     {
//         "id": "d5bdcfa5-d372-408a-a19c-9c4d66be86cc",
//         "sn": 30,
//         "name": "View Corporate Role",
//         "code": "ViewCorporateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewCorporateRole"
//     },
//     {
//         "id": "effc9a71-5531-4334-8893-a1ccb5f62a39",
//         "sn": 29,
//         "name": "Set Corporate Customer Account Limit",
//         "code": "SetCorporateCustomerAccountLimit",
//         "isCorporate": false,
//         "description": null,
//         "key": "SetCorporateCustomerAccountLimit"
//     },
//     {
//         "id": "580b5893-3d5e-49c1-8f98-a5a82a88e2de",
//         "sn": 2,
//         "name": "View Corporate User Profile",
//         "code": "ViewCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewCorporateUserProfile"
//     },
//     {
//         "id": "6d00c5ec-cd79-4671-af49-aa0da2e5e7ed",
//         "sn": 17,
//         "name": "Decline Bank Admin Profile",
//         "code": "DeclineBankAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeclineBankAdminProfile"
//     },
//     {
//         "id": "38225edb-908b-4e10-9598-abfd5bea8e49",
//         "sn": 9,
//         "name": "View Corporate Customer",
//         "code": "ViewCorporateCustomer",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewCorporateCustomer"
//     },
//     {
//         "id": "35884ebe-084c-4227-9ce6-b0bb197f5ae1",
//         "sn": 11,
//         "name": "Update Corporate Customer",
//         "code": "UpdateCorporateCustomer",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateCorporateCustomer"
//     },
//     {
//         "id": "645fa746-8046-4d6e-a3be-b2523cc32885",
//         "sn": 48,
//         "name": "Change Password",
//         "code": "ChangePassword",
//         "isCorporate": false,
//         "description": null,
//         "key": "ChangePassword"
//     },
//     {
//         "id": "06022548-f876-49bc-ba74-bb5be9f83c6f",
//         "sn": 43,
//         "name": "Create Sector",
//         "code": "CreateSector",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateSector"
//     },
//     {
//         "id": "a8dd6268-2edc-4527-b2b5-bf6453208b42",
//         "sn": 24,
//         "name": "Update Bank Admin User Role",
//         "code": "UpdateBankAdminUserRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "UpdateBankAdminUserRole"
//     },
//     {
//         "id": "5d9534d8-12eb-442c-afb7-c4044c5f0973",
//         "sn": 47,
//         "name": "ReActivate Corporate User Profile",
//         "code": "ReActivateCorporateUserProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "ReActivateCorporateUserProfile"
//     },
//     {
//         "id": "2b04933f-c03b-403c-9e70-cb86256cb9f7",
//         "sn": 63,
//         "name": "View User Accesses",
//         "code": "ViewUserAccesses",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewUserAccesses"
//     },
//     {
//         "id": "4addbe60-85d0-4b50-869c-cbbf9be65706",
//         "sn": 52,
//         "name": "Activate Role",
//         "code": "ActivateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "ActivateRole"
//     },
//     {
//         "id": "d47710de-4546-4cca-9c35-cc3d5fd16c81",
//         "sn": 36,
//         "name": "View Workflow",
//         "code": "ViewWorkflow",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewWorkflow"
//     },
//     {
//         "id": "ae501475-0d3b-4a7d-b264-d039d51bfcea",
//         "sn": 18,
//         "name": "Deactivate Bank Admin Profile",
//         "code": "DeactivateBankAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "DeactivateBankAdminProfile"
//     },
//     {
//         "id": "ec0d3c3c-979c-4ef2-b674-d65f7b0b6c1e",
//         "sn": 6,
//         "name": "Create Corporate User Admin Profile",
//         "code": "CreateCorporateUserAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateCorporateUserAdminProfile"
//     },
//     {
//         "id": "72f2ed80-af94-4360-b68f-e29dbd432be6",
//         "sn": 46,
//         "name": "ReActivate Bank Admin Profile",
//         "code": "ReActivateBankAdminProfile",
//         "isCorporate": false,
//         "description": null,
//         "key": "ReActivateBankAdminProfile"
//     },
//     {
//         "id": "16e7ccab-1703-4f9c-85d3-e6cc5195e0e3",
//         "sn": 66,
//         "name": "Approve Transaction",
//         "code": "ApproveTransaction",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveTransaction"
//     },
//     {
//         "id": "983ce2a1-face-40a0-b4c8-fa32ec7386a3",
//         "sn": 33,
//         "name": "View Role",
//         "code": "ViewRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "ViewRole"
//     },
//     {
//         "id": "83db8a87-1df5-4e43-a7c7-fa5ec19322c9",
//         "sn": 39,
//         "name": "Approve Workflow",
//         "code": "ApproveWorkflow",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveWorkflow"
//     },
//     {
//         "id": "7bf7dd5a-373e-4fd4-b352-fce61c6c9daf",
//         "sn": 57,
//         "name": "Approve Corporate Role",
//         "code": "ApproveCorporateRole",
//         "isCorporate": false,
//         "description": null,
//         "key": "ApproveCorporateRole"
//     },
//     {
//         "id": "d00e7a5b-10cd-4195-9afd-fe65d8a48c1a",
//         "sn": 37,
//         "name": "Create Workflow",
//         "code": "CreateWorkflow",
//         "isCorporate": false,
//         "description": null,
//         "key": "CreateWorkflow"
//     },
//     {
//         "id": "78f580c0-6c7b-4d93-9cc0-b0025f5461b4",
//         "sn": 68,
//         "name": "Update Bank Admin Branch",
//         "code": "UpdateBankAdminBranch",
//         "isCorporate": null,
//         "description": "Update Bank Admin Branch",
//         "key": "UpdateBankAdminBranch",
//     },
//     {
//         "id": "7524037e-b0e9-42b6-90af-e70b749b8495",
//         "sn": 69,
//         "name": "View Branch",
//         "code": "ViewBranch",
//         "isCorporate": null,
//         "description": "View Branch",
//         "key": "ViewBranch",
//     },
//     {
//         "id": "b8fc87e0-a8e5-4429-b773-2dd98f60fcc7",
//         "sn": 70,
//         "name": "Update Branch",
//         "code": "UpdateBranch",
//         "isCorporate": null,
//         "description": "Update Branch",
//         "key": "UpdateBranch",
//     },
//     {
//         "id": "72368bd8-adec-4b87-8003-0747f3e703d1",
//         "sn": 71,
//         "name": "Create Branch",
//         "code": "CreateBranch",
//         "isCorporate": null,
//         "description": "Create Branch",
//         "key": "CreateBranch",
//     },
//     {
//         "id": "64b4ac53-6193-4a7e-a508-686ac82b89f8",
//         "sn": 72,
//         "name": "Set Branch HQ",
//         "code": "SetBranchHQ",
//         "isCorporate": null,
//         "description": "Set Branch HQ",
//         "key": "SetBranchHQ",
//     },
//     {
//         "id": "9aec6873-c959-40cd-98f3-26cf7cedfecb",
//         "sn": 73,
//         "name": "View Active Directory",
//         "code": "ViewActiveDirectory",
//         "isCorporate": null,
//         "description": "View Active Directory",
//         "key": "ViewActiveDirectory",
//     },
//     {
//         "id": "1097024a-7d31-481c-9f20-3de406a788eb",
//         "sn": 74,
//         "name": "Update Active Directory",
//         "code": "UpdateActiveDirectory",
//         "isCorporate": null,
//         "description": "Update Active Directory",
//         "key": "UpdateActiveDirectory",
//     },
//     {
//         "id": "d837f4ad-95d1-4766-96af-20087f53bbe5",
//         "sn": 75,
//         "name": "Create Active Directory",
//         "code": "CreateActiveDirectory",
//         "isCorporate": null,
//         "description": "Create Active Directory",
//         "key": "CreateActiveDirectory",
//     },
//     {
//         "id": "8ba93eae-1734-464b-98d6-5f6ab77f4027",
//         "sn": 76,
//         "name": "Onboard Corporate Customer",
//         "code": "OnboardCorporateCustomer",
//         "isCorporate": null,
//         "description": "Onboard Corporate Customer",
//         "key": "OnboardCorporateCustomer",
//     },
//     {
//         "id": "2D236583-BA59-46F0-925C-71558B4EB255",
//         "name": "Can Create Staff",
//         "code": "CanCreateStaff",
//         "isCorporate": null,
//         "description": "Can Create Staff",
//         "key": "CanCreateStaff"
//     },
//     {
//         "id": "6E48C694-1D06-4805-A8EA-609353807798",
//         "name": "Can View Staff",
//         "code": "CanViewStaff",
//         "isCorporate": null,
//         "description": "Can View Staff",
//         "key": "CanViewStaff"
//     },
//     {
//         "id": "F630E28A-368C-43FC-902E-66C782F32FA3",
//         "name": "Can Approve Staff",
//         "code": "CanApproveStaff",
//         "isCorporate": null,
//         "description": "Can Approve Staff",
//         "key": "CanApproveStaff"
//     },
//     {
//         "id": "11DB662C-35D9-4EA8-B7B2-0B1ED173CD30",
//         "name": "Can Deactivate Staff",
//         "code": "CanDeactivateStaff",
//         "isCorporate": null,
//         "description": "Can Deactivate Staff",
//         "key": "CanDeactivateStaff"
//     },
//     {
//         "id": "9B1E1BF4-3551-457C-8CF0-07785A462A05",
//         "name": "Can Reactivate Staff",
//         "code": "CanReactivateStaff",
//         "isCorporate": null,
//         "description": "Can Reactivate Staff",
//         "key": "CanReactivateStaff"
//     },
//     {
//         "id": "A7F216EA-C942-49EF-B0CB-3BBA738B8D5A",
//         "name": "Can Decline Staff",
//         "code": "CanDeclineStaff",
//         "isCorporate": null,
//         "description": "Can Decline Staff",
//         "key": "CanDeclineStaff"
//     },
//     {
//         "id": "540DAF1C-841C-4221-AD08-8B4D2CF27F38",
//         "name": "Can Request Staff Approval",
//         "code": "CanRequestStaffApproval",
//         "isCorporate": null,
//         "description": "Can Request Staff Approval",
//         "key": "CanRequestStaffApproval"
//     },
//     {
//         "id": "A294F73F-4A60-42A0-9A51-29AF565F72FC",
//         "name": "Can Create Schedule",
//         "code": "CanCreateSchedule",
//         "isCorporate": null,
//         "description": "Can Create Schedule",
//         "key": "CanCreateSchedule"
//     },
//     {
//         "id": "A68FCAE7-04A7-4D5E-854B-80BEC4A2DF1B",
//         "name": "Can View Schedule",
//         "code": "CanViewSchedule",
//         "isCorporate": null,
//         "description": "Can View Schedule",
//         "key": "CanViewSchedule"
//     },
//     {
//         "id": "FAEB7B64-E076-4FDE-8274-5D4B30DC5056",
//         "name": "Can Initiate Schedule",
//         "code": "CanInitiateSchedule",
//         "isCorporate": null,
//         "description": "Can Initiate Schedule",
//         "key": "CanInitiateSchedule"
//     },
// ]