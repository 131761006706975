<div class="scrollable-content">
    <div class="card-body position-relative">
        <div class="w-100 mb-5 d-flex flex-column px-5 text-white"
            style="height: 83px; background: linear-gradient(92.09deg, #242492 13.35%, #D1AE6C 114.27%); border-radius: 14px; box-shadow: 0px 0px 40px rgba(48, 73, 191, 0.04); place-content: center;">
            <div class="mb-1">Transfers</div>
            <h3 class="text-white">Send Money With EASE</h3>
        </div>
        <img src="assets/images/transfer-money.svg"
            style="position: absolute; width: 138.13px; height: 100px; top: 20px; right: 30px; " />

        <!-- Nav tabs -->
        <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3 mx-5" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#own" role="tab" aria-selected="false">
                    Own Account
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#intrabank" role="tab" aria-selected="false">
                    Intrabank
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#interbank" role="tab" aria-selected="false">
                    Interbank
                </a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#bulk" role="tab" aria-selected="false">
                    Bulk
                </a>
            </li> -->
            <!-- <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#scheduled" role="tab" aria-selected="true">
                    Sheduled
                </a>
            </li> -->
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
            <div class="tab-pane active" id="own" role="tabpanel">
                <div class="d-flex">
                    <form [formGroup]="formTransferOwnAccount" class="p-5 w-100 needs-validation"
                        (ngSubmit)="initiateOwnBankTransfer($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="row g-3">
                                    <div class="col-lg-6 mb-2">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="sourceAccountNumber"
                                                (change)="getSelectedAccountInformation($event.target, 'own-source')"
                                                required>
                                                <option value="">Select Account</option>
                                                <option *ngFor="let item of accountsListSource"
                                                    [value]="item?.accountNumber" [attr.bankName]="item?.bankName"
                                                    [attr.accountName]="item?.customerName">{{ item?.accountNumber }} ({{
                                                    item?.accountName }} | N{{ item?.availableBalance | number }})
                                                </option>
                                            </select>
                                            <label>Select Source Account</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-2">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="destinationAccountNumber"
                                                (change)="getSelectedAccountInformation($event.target, 'own-destination')"
                                                required>
                                                <option value="">Select Account</option>
                                                <option *ngFor="let item of accountsListDestination"
                                                    [value]="item?.accountNumber" [attr.bankName]="item?.bankName"
                                                    [attr.accountName]="item?.customerName">{{ item?.accountNumber }} ({{
                                                    item?.accountName }} | N{{ item?.availableBalance | number }})
                                                </option>
                                            </select>
                                            <label>Select Destination Account</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-2"
                                        *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="workflowId" required>
                                                <option value="">Select Workflow</option>
                                                <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                                                </option>
                                            </select>
                                            <label for="floatingSelect">Workflow</label>
                                        </div>
                                        <!-- <div *ngIf="formTransferOwnAccount.value.workflowId"><label
                                                class="form-check-label mt-1 text-primary cursor-pointer"
                                                (click)="fetchApproversListForWorkflow('own')">View Authorizers
                                                List</label></div> -->
                                    </div>
                                    <div class="col-lg-6 mb-2">
                                        <div class="form-floating">
                                            <input type="text" formControlName="amount" class="form-control" required
                                                placeholder="Amount (e.g. 0.00)">
                                            <label for="passwordfloatingInput">Amount (e.g. 0.00)</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-2">
                                        <div class="form-floating">
                                            <textarea class="form-control h-auto" formControlName="narration" required
                                                rows="5"></textarea>
                                            <label for="cityfloatingInput">Transaction Description</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 mb-2 px-5">
                                <div class="row">
                                    <svg width="332" height="147" viewBox="0 0 332 147" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <rect width="332" height="147" rx="12" fill="url(#paint0_linear_134_7115)"/>
                                       <defs>
                                          <linearGradient id="paint0_linear_134_7115" x1="4.94719e-06" y1="147" x2="257.853" y2="-83.0926" gradientUnits="userSpaceOnUse">
                                             <stop/>
                                             <stop offset="0.492708"/>
                                             <stop offset="1" stop-color="#EF1A3A"/>
                                          </linearGradient>
                                       </defs>
                                    </svg>

                                    <img src="assets/images/transfers-card-1.svg" />
                                </div>
                                <div class="row mt-5">
                                    <svg width="332" height="148" viewBox="0 0 332 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <rect width="332" height="148" rx="12" fill="url(#paint0_linear_357_5259)"/>
                                       <mask id="mask0_357_5259" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="332" height="136">
                                          <rect width="332" height="135.163" rx="12" fill="url(#paint1_linear_357_5259)"/>
                                       </mask>
                                       <g mask="url(#mask0_357_5259)">
                                          <rect width="93.4926" height="182.467" transform="matrix(0.918533 -0.395343 0.716123 0.697975 -90.6968 50.1553)" fill="white" fill-opacity="0.05"/>
                                       </g>
                                       <defs>
                                          <linearGradient id="paint0_linear_357_5259" x1="321.5" y1="227.5" x2="265.724" y2="17.6121" gradientUnits="userSpaceOnUse">
                                             <stop offset="0.0201273" stop-color="#126505"/>
                                             <stop offset="0.0202273" stop-color="#136605"/>
                                             <stop offset="0.133333" stop-color="#217A14"/>
                                             <stop offset="0.534375" stop-color="#54C247"/>
                                             <stop offset="0.883333" stop-color="#2F2E41"/>
                                          </linearGradient>
                                          <linearGradient id="paint1_linear_357_5259" x1="4.94719e-06" y1="135.163" x2="238.523" y2="-96.3198" gradientUnits="userSpaceOnUse">
                                             <stop stop-color="#280537"/>
                                             <stop offset="0.185417" stop-color="#56034C"/>
                                             <stop offset="0.419792" stop-color="#890058"/>
                                             <stop offset="0.669792" stop-color="#BC005B"/>
                                             <stop offset="1" stop-color="#EB1254"/>
                                          </linearGradient>
                                       </defs>
                                    </svg>

                                    <img src="assets/images/transfers-card-2.svg" />
                                </div>
                            </div> -->
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-6 mt-5">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-5"></div>

                        </div>
                    </form>
                </div>
            </div>
            <div class="tab-pane" id="intrabank" role="tabpanel">
                <div class="d-flex">
                    <form [formGroup]="formTransferIntrabank" class="p-5 w-100 needs-validation"
                        (ngSubmit)="initiateIntrabankTransfer($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        formControlName="sourceAccountNumber"
                                        (change)="getSelectedAccountInformation($event.target, 'intrabank')" required>
                                        <option value="">Select Account</option>
                                        <option *ngFor="let item of accountsList" [value]="item?.accountNumber"
                                            [attr.bankName]="item?.bankName" [attr.accountName]="item?.customerName">{{
                                            item?.accountNumber }} ({{ item?.accountName }} | N{{ item?.availableBalance |
                                            number }})</option>
                                    </select>
                                    <label for="floatingSelect">Select Source Account</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="destinationAccountNumber"
                                        required placeholder="Enter Beneficiary's Account Number"
                                        (keyup)="getAccountInformation($event.target)">
                                    <label for="emailfloatingInput">Beneficiary's Account Number</label>
                                </div>
                                <div class="text-right"><label (click)="initiateIntraBeneficiary()"
                                        class="form-check-label mt-1 color-base-green">Select from saved
                                        Beneficiaries</label></div>
                            </div>
                            <div class="col-lg-12 mb-2" *ngIf="destinationAccountDetails?.accountName">
                                <div class="form-floating">
                                    <input type="text" class="form-control" readonly
                                        formControlName="destinationAccountName"
                                        placeholder="Beneficiary's Account Name">
                                    <label for="emailfloatingInput">Beneficiary's Account Name</label>
                                </div>
                                <!-- <div class="text-right"><label class="form-check-label mt-1 color-base-green">Select from saved Beneficiaries</label></div> -->
                            </div>
                            <div class="col-lg-6 mb-2" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect" formControlName="workflowId"
                                        required>
                                        <option value="">Select Workflow</option>
                                        <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                                        </option>
                                    </select>
                                    <label for="floatingSelect">Workflow</label>
                                </div>

                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="amount" required
                                        placeholder="Amount (e.g. 0.00)">
                                    <label for="passwordfloatingInput">Amount (e.g. 0.00)</label>
                                </div>
                                <!-- <div class="form-check-label mt-1 text-right" for="customSwitchsizelg">Available Limit: <span class="color-base-green">4,500,000</span></div> -->
                            </div>
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <textarea class="form-control h-auto" formControlName="narration" required
                                        rows="5"></textarea>
                                    <label for="cityfloatingInput">Transaction Description</label>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12">
                                <label for="exampleInputtime" class="form-label">Save Beneficiary</label>
                                <div class="form-check form-switch form-switch-lg" dir="ltr">
                                    <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
                                </div>
                                <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Activating this will have this beneficary’s details for your future transactions.</div>
                            </div> -->
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-6 mt-5">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-5"></div>

                        </div>
                    </form>
                </div>
            </div>
            <div class="tab-pane" id="interbank" role="tabpanel">
                <div class="d-flex">
                    <form class="p-5 w-100" [formGroup]="formTransferInterbank"
                        (ngSubmit)="initiateInterbankTransfer($event.target)"> 
                        <div class="row g-3">
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example" formControlName="sourceAccountNumber"
                                        (change)="getSelectedAccountInformation($event.target, 'own-source')" required>
                                        <option value="">Select Account</option>
                                        <option *ngFor="let item of accountsList" [value]="item?.accountNumber"
                                            [attr.bankName]="item?.bankName" [attr.accountName]="item?.customerName">{{
                                            item?.accountNumber }} ({{ item?.accountName }} | N{{ item?.availableBalance |
                                            number }})</option>
                                    </select>
                                    <label for="floatingSelect">Select Source Account</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example" formControlName="destinationBankName"
                                        (change)="getSelectedAccountInformation($event.target, 'interbank')" required>
                                        <option value="">Select Bank</option>
                                        <option *ngFor="let item of bankList" [value]="item?.institutionName"
                                            [attr.bankCode]="item?.institutionCode"
                                            [attr.bankName]="item?.institutionName">
                                            {{ item?.institutionName }} </option>
                                    </select>
                                    <label for="floatingSelect">Select Beneficiary Bank</label>
                                </div>
                                <div class="text-right"><label class="form-check-label mt-1 color-base-green"
                                        (click)="initiateInterBeneficiary()">Select from saved Beneficiaries</label>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="number" class="form-control" id="emailfloatingInput"
                                        formControlName="destinationAccountNumber" required
                                        placeholder="Enter Beneficiary's Account Number"
                                        (keyup)="getAccountInformation($event.target,'inter')">
                                    <label for="emailfloatingInput">Beneficiary's Account Number</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2" *ngIf="destinationAccountDetails?.accountName">
                                <div class="form-floating">
                                    <input type="text" class="form-control" readonly
                                        formControlName="destinationAccountName"
                                        placeholder="Beneficiary's Account Name">
                                    <label for="emailfloatingInput">Beneficiary's Account Name</label>
                                </div>
                                <!-- <div class="text-right"><label class="form-check-label mt-1 color-base-green">Select from saved Beneficiaries</label></div> -->
                            </div>
                            <div class="col-lg-6 mb-2" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect" formControlName="workflowId"
                                        aria-label="Floating label select example">
                                        <option value="">Select Workflow</option>
                                        <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                                        </option>
                                    </select>
                                    <label for="floatingSelect">Workflow</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="passwordfloatingInput"
                                        placeholder="Amount (e.g. 0.00)" formControlName="amount" required>
                                    <label for="passwordfloatingInput">Amount (e.g. 0.00)</label>
                                </div>
                                <!-- <div class="form-check-label mt-1 text-right" for="customSwitchsizelg">Available Limit: <span class="color-base-green">4,500,000</span></div> -->
                            </div>
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <textarea class="form-control h-auto" rows="5"
                                        formControlName="narration" required></textarea>
                                    <label for="cityfloatingInput">Transaction Description</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-6 mt-5">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                                </div>
                                <div class="col-lg-3 mt-5"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="tab-pane" id="scheduled" role="tabpanel">
                <div class="d-flex">
                    <form action="#" class="p-5 w-100">
                        <div class="row g-3">
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example">
                                        <option value="">Select Account</option>
                                    </select>
                                    <label for="floatingSelect">Select Source Account</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example">
                                        <option value="">Select Transfer Type</option>
                                    </select>
                                    <label for="floatingSelect">Transfer Type</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="number" class="form-control" id="emailfloatingInput"
                                        placeholder="Enter Beneficiary's Account">
                                    <label for="emailfloatingInput">Beneficiary's Account</label>
                                </div>
                                <div class="text-right"><label class="form-check-label mt-1 text-muted">Select from
                                        saved Beneficiaries</label></div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="passwordfloatingInput"
                                        placeholder="Amount (e.g. 0.00)">
                                    <label for="passwordfloatingInput">Amount (e.g. 0.00)</label>
                                </div>
                                <div class="form-check-label mt-1 text-right" for="customSwitchsizelg">Available Limit:
                                    <span class="text-muted">4,500,000</span>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <textarea class="form-control h-auto" rows="5"></textarea>
                                    <label for="cityfloatingInput">Transaction Description</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control flatpickr-input"
                                        placeholder="Schedule Date & Time" data-provider="flatpickr"
                                        data-date-format="d/m/y" data-enable-time="" readonly="readonly">
                                    <label>Schedule Date & Time</label>
                                </div>
                                <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Select the Date &
                                    Time you want this transactions to be initiated.</div>
                            </div>
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-6 mt-5">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-5"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- workflow authorizers -->
<div class="modal fade" id="modal-manage-workflow-authorizers" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Workflow Authorizers
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form class="w-100">
                        <div class="row">
                            <div *ngFor="let item of workflowApproversList; let i = index" class="row mb-4">
                                <div class="col-lg-12">
                                    <div class="text-uppercase fw-bold text-muted">Approver {{ item?.authorizationLevel
                                        }}</div>
                                    <div class="fs-4">{{ item?.approverName || '-' }} (Level: {{ item?.authorizationLevel
                                        }})</div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-7">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="attemptTransaction($event.target)">
                    <div *ngIf="activeTransferTypeName == 'interbank'">
                        <p class="text-center">Please note that there will be charges which includes <br />
                            <b>VAT ({{charges.vat | currency : '₦'}})</b> and <b>FEE ({{charges.fee | currency :
                                '₦'}})</b>
                        </p>
                    </div>
                    <div class="row g-3" style="align-items: center;">
                        <div *ngIf="authLimit == 1" class="col-lg-5 mb-2 mx-0 px-0">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="pin" required
                                    placeholder="Enter your pin" maxlength="4" (input)="replaceLettersWithSpace($event,'pin')">
                                <label for="emailfloatingInput" class="text-uppercase">PIN</label>
                            </div>
                        </div>
                        <div *ngIf="authLimit == 1" class="col-lg-1 mx-2">
                            <h1> + </h1>
                        </div>
                        <div [class]="authLimit == 1 ? 'col-lg-5 mb-2 mx-0 px-0': 'col-lg-12 mb-2'">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your token" maxlength="8" (input)="replaceLettersWithSpace($event,'otp')">
                                <label for="emailfloatingInput" class="text-uppercase">Token</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2" (click)="hideAuthModal()">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- congratulatory message -->
<div class="modal fade" id="modal-contgratulations" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/gif/1103-confetti-outline.gif" width="200">

                <div class="mt-4">
                    <h4 class="mb-3">Congratulations! You've made it.</h4>
                    <p class="text-muted mb-4"> The transfer was successful.</p>
                    <div class="hstack gap-2 justify-content-center">
                        <a class="btn btn-link link-primary fw-medium" (click)="hideCongratsModal()"><i class="ri-close-line me-1 align-middle"></i> Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- congratulatory Initiation message -->
<div class="modal fade" id="modal-initiate-contgratulations" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/gif/1103-confetti-outline.gif" width="200">
 
                <div class="mt-4">
                    <h4 class="mb-3">Congratulations!</h4>
                    <p class="text-muted mb-4"> The transfer Initiation was successful.</p>
                    <div class="hstack gap-2 justify-content-center">
                        <a href="javascript:void(0);" class="btn btn-link link-primary fw-medium"
                            data-bs-dismiss="modal"><i class="ri-close-line me-1 align-middle"></i> Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-intra-beneficiary" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Intra-Bank Beneficiary
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formIntraBeneficiary" class="w-100 needs-validation">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect" formControlName="accountNumber"
                                        (change)="getSelectedIntraBeneficiary($event.target)" required>
                                        <option value="">Select Beneficiary</option>
                                        <option *ngFor="let item of beneficiariesIntra" [value]="item?.accountNumber"
                                            [attr.accountName]="item?.accountName">{{
                                            item?.accountNumber }} | {{ item?.accountName}}</option>
                                    </select>
                                    <label for="floatingSelect">Select Beneficiary</label>
                                </div>
                            </div>

                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                    <button type="reset" data-network-action="hide"
                                        class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button class="btn btn-main btn-lg w-50" data-bs-dismiss="modal">Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-inter-beneficiary" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Inter-Bank Beneficiary
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formInterBeneficiary" class="w-100 needs-validation">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect" formControlName="accountNumber"
                                        (change)="getSelectedInterBeneficiary($event.target)" required>
                                        <option value="">Select Beneficiary</option>
                                        <option *ngFor="let item of beneficiariesInter" [value]="item?.accountNumber"
                                            [attr.institutionName]="item?.destinationInstitutionName "
                                            [attr.accountName]="item?.accountName"
                                            [attr.institutionCode]="item?.destinationInstitutionCode">
                                            ({{ item?.accountNumber }} | {{item?.destinationInstitutionName}}) | {{
                                            item?.accountName}}</option>
                                    </select>
                                    <label for="floatingSelect">Select Beneficiary</label>
                                </div>
                            </div>

                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                    <button type="reset" data-network-action="hide"
                                        class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button class="btn btn-main btn-lg w-50" data-bs-dismiss="modal"
                                        data-bs-dismiss="modal">Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>