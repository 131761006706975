import { Component } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { ThemeService } from '@app/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'parallex';
  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage!: string;
  connectionStatus!: string;

  constructor(private theme: ThemeService) {}

  //check for internet connection
  ngOnInit(): void {
    // initial check on enter
    if (navigator.onLine) {
      this.connectionStatus = 'online'
      setTimeout(() => {
        this.connectionStatus = undefined
      }, 1000);
    } else {
      this.connectionStatus = 'offline'
    }

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Internet Connection Restored!';
      this.connectionStatus = 'online';
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Internet Connection Lost! Offline';
      this.connectionStatus = 'offline';
    }));

    
    this.theme.theme$.subscribe((theme) => {
      document.body.setAttribute('data-theme', theme);
    });

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
