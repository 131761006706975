import { HttpHeaders } from '@angular/common/http'
import * as CryptoJS from 'crypto-js'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { permissions } from '@app/config'
import { LogService } from '@app/services'

// jquery operator
declare var $: any

// define network worker
let networkWorkerIsBusy = null

// get node object
let el = document.querySelector(window.atob(String('iUHgsYm9keQ==').substr(5)))

// jquery selector
export const find = (target) => $(target)

// define global config values
export const config = {
  base: environment.remoteBase,
  auth: environment.remoteAuth,
  timeoutSessionFlag: 'lbSessionTimeoutIndicator',
  signedInUserFlag: 'lbSessionFreshEntryIndicator',
  dp: '77c8c495-6992-4a38-9cbd-dc3398a5dd160',
  users: {
    sole: 'Corporate Admin',
    initiator: 'Corporate Maker',
    authorizer: 'Corporate Authorizer'
  },
  unAuthRedirectUrl: '/user/dashboard',
  addCommas(x: string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  removeCommas(x: string): string {
    return x.replace(/,/g, '')
  },
  network: {
    occupy: (ui?: any) => {
      // check status
      if (networkWorkerIsBusy) {
        // bring up modal
        modal.show(`#modal-network-busy-message`)
        // check if ui object is valid
        if (ui) {
          // warning
          ui.warning(`Duplicate operation detected.`)
        }
        // error
        throw new DOMException(`The network worker is busy! This error could be due to a double click or a duplicate operation performed within very short interval.`)
      } else {
        // change status
        networkWorkerIsBusy = true
      }
    },
    release: () => {
      // change status
      networkWorkerIsBusy = false
      // hide possible modal
      modal.hide(`#modal-network-busy-message`)
    }
  },
  string(item: any) {
    return String(item)
  },
  stringLower(item: any) {
    return String(item).toLowerCase()
  },
  reformatDateForView(date: string, pipe?: any, format?: string) {
    // check if value exists
    if (!date) return '-'

    // return formatted date
    return pipe.transform(date, format || 'full')
  },
  async getMachineIdentity(withLocation?: boolean): Promise<any> {
    // get ip
    let ip = await machineIdentityService.getIPAddress()
    // define location object
    let location: any = {
      details: {}
    }

    // check if location is requested
    if (withLocation) {
      // get location
      location = await machineIdentityService.getLocationCoordinates()
      // adjust details
      location.details = { TransactionLocation: location.status ? `${location.data.coords.latitude},${location.data.coords.longitude}` : '' }
    }

    // return data
    return {
      IPAddress: ip || '',
      ClientStaffIPAddress: ip || '',
      MACAddress: '',
      HostName: window.document.domain,
      // ...location.details,
      TransactionLocation: "9.000705,7.578728",
    }
  },
  async getMachineIdentityAsParameters(withLocation?: boolean): Promise<any> {
    // get ip
    let ip = await machineIdentityService.getIPAddress()
    // define location object
    let location: any = {
      details: ''
    }

    // check if location is requested
    if (withLocation) {
      // get location
      location = await machineIdentityService.getLocationCoordinates()
      // adjust details
      location.details = `&TransactionLocation=${(location.status ? `${encodeURIComponent(store.encrypt('${location.data.coords.latitude},${location.data.coords.longitude}'))}` : '')}`
    }

    return `&IPAddress=${ip}&ClientStaffIPAddress=${ip}&MACAddress=&HostName=${encodeURIComponent(store.encrypt(window.document.domain))}` + location.details
  },
  checkIfNotSingleSignatory(type: string): boolean {
    return (String(type).replace(/[^a-z]/ig, '').toLowerCase() != 'singlesignatory')
  },
  currentUser(): any {
    return store.get('role') || this.users.sole
  },
  loadPermissionKeysIntoMemory() {
    // check if existing
    if (!store.has('globalPermissionKeys')) {
      // get permission keys
      let keys = permissions.map(item => item?.key)
      // store keys
      store.set('globalPermissionKeys', JSON.stringify(keys))
    }
  },
  currentUserPermissions(justPermissionIds?: boolean): any {
    // get permissions
    const userPermissions = JSON.parse(store.get('permissions') || '[]')
    // check if to send back just permission ids
    if (justPermissionIds) {
      // get ids
      let ids = userPermissions.map(item => item?.id)
      // return ids
      return ids
    }
    return userPermissions
  },
  currentUserHasPermissionById(id: string): boolean {
    // get ids
    let ids = JSON.parse(store.get('permissionsIds') || '[]')
    // return status
    return (Array.from(ids).indexOf(id) != -1)
  },
  currentUserHasPermissionByCode(code: string): boolean {
    // get codes
    let codes = JSON.parse(store.get('permissionsCodes') || '[]')
    // return status
    return (Array.from(codes).indexOf(code) != -1)
  },
  currentUserHasPermissionByKey(key: string, redirectUrl?: string): any {
    // get codes
    let keyIndex = JSON.parse(store.get('globalPermissionKeys') || '[]').indexOf(key)
    // check if key is not present
    if (keyIndex == -1) return false
    // get permission id at index
    let id = permissions[keyIndex].id
    // get ids
    let ids = JSON.parse(store.get('permissionsIds') || '[]')
    // get status
    let status = (Array.from(ids).indexOf(id) != -1)

    // check if redirect url is set
    if ((status == false) && redirectUrl) {
      // redirect
      this.router.navigate([redirectUrl])
    }

    // return status
    return status
  },
  currentUserUnauthorizationContentUi(): any {
    return `<div class="alert alert-warning text-center w-100 p-5" role="alert">
                <div class="fs-4 mt-5">You're not authorized to view this information</div>
            </div>`
  },
  httpOptions(): any {
    // get token
    let token = store.get('token')
    // return headers object
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        // "Access-Control-Allow-Headers" : "Authorization, X-Requested-With",
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    }
  },
  httpOptionsFormData(): any {
    // get token
    let token = store.get('token')
    // return headers object
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        // "Access-Control-Allow-Headers" : "Authorization, X-Requested-With",
        'Accept': 'text/plain',
        'Authorization': `Bearer ${token}`
      })
    }
  },
  httpOptionsNoAuth: (): any => {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        // "Access-Control-Allow-Headers" : "Authorization, X-Requested-With",
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    }
  },
}


// define routes
export const routes = {
  authentication: {
    loginMain: '/api/CorporateAdmin/v1/Account/Login',
    logout: '/api/CorporateAdmin/v1/Account/LogOut',
    forgotPassword: '/api/CorporateAdmin/v1/Account/ForgotPassword',
    resetPassword: '/api/CorporateAdmin/v1/Account/ResetPassword',
    getSecurityQuestions: '/api/CorporateAdmin/v1/Account/GetSecurityQuestions',
    firstLoginPasswordChange: '/api/CorporateAdmin/v1/Account/FirstLoginPasswordChange',
    setSecurityQuestion: '/api/CorporateAdmin/v1/Account/SetSecurityQuestion',
    changePassword: '/api/CorporateAdmin/v1/Account/ChangePassword',
    changePin: '/api/CorporateAdmin/v1/Account/ChangePin',
    signPolicy: '/api/CorporateAdmin/v1/Account/SignPrivacyPolicy',
  },
  manageAccounts: {
    customerNameEnquiry: '/api/CorporateAdmin/v1/ManageAccount/CustomerNameInquiry',
  },
  corporateCustomers: {
    getAccountStatement: '/api/CorporateAdmin/v1/CorporateCustomer/AccountStatements',
    getAccountHistory: '/api/CorporateAdmin/v1/CorporateCustomer/AccountHistory',
    intraBankBeneficiaries: '/api/CorporateAdmin/v1/CorporateCustomer/IntrabankBeneficiaries',
    interBankBeneficiaries: '/api/CorporateAdmin/v1/CorporateCustomer/InterbankBeneficiaries',
    addBeneficiary: '/api/CorporateAdmin/v1/CorporateCustomer/AddBeneficiary',
    removeBeneficiaryIntra: '/api/CorporateAdmin/v1/CorporateCustomer/RemoveIntrabankBeneficiary',
    removeBeneficiaryInter: '/api/CorporateAdmin/v1/CorporateCustomer/RemoveInterbankBeneficiary',
  },
  corporateCustomerUsers: {
    getAllCorporateUsers: '/api/CorporateAdmin/v1/CorporateProfile/GetAllProfiles',
    getPendingCorporateUsers: '/api/CorporateAdmin/v1/CorporateProfile/PendingApproval',
    createCorporateUser: '/api/CorporateAdmin/v1/CorporateProfile/CreateProfile',
    updateCorporateUser: '/api/CorporateAdmin/v1/CorporateProfile/UpdateProfile',
    approveCorporateUser: '/api/CorporateAdmin/v1/CorporateProfile/ApproveProfile',
    declineCorporateUser: '/api/CorporateAdmin/v1/CorporateProfile/DeclineProfile',
    requestApprovalUser: '/api/CorporateAdmin/v1/CorporateProfile/RequestProfileApproval',
    deactivateCorporateUser: '/api/CorporateAdmin/v1/CorporateProfile/DeactivateProfile',
    reactivateCorporateUser: '/api/CorporateAdmin/v1/CorporateProfile/ReActivateProfile',
    resetPassword: '/api/CorporateAdmin/v1/CorporateProfile/ResetUserPassword',
    enableLoggedOutProfile: '/api/CorporateAdmin/v1/CorporateProfile/EnableLoggedOutProfile',
  },
  corporateRoles: {
    getAllCorporateRoles: '/api/CorporateAdmin/v1/CorporateRole/GetRoles',
    createCorporateRole: '/api/CorporateAdmin/v1/CorporateRole/CreateRole',
    updateCorporateRole: '/api/CorporateAdmin/v1/CorporateRole/UpdateRole',
    approveCorporateRole: '/api/CorporateAdmin/v1/CorporateRole/ApproveRole',
    declineCorporateRole: '/api/CorporateAdmin/v1/CorporateRole/DeclineRole',
    reactivateCorporateRole: '/api/CorporateAdmin/v1/CorporateRole/ActivateRole',
    deactivateCorporateRole: '/api/CorporateAdmin/v1/CorporateRole/DeactivateRole',
    requestApprovalRole: '/api/CorporateAdmin/v1/CorporateRole/RequestRoleApproval',
    getRolePermissions: '/api/CorporateAdmin/v1/CorporateRole/GetRolePermissions',
  },
  corporateRolesByCorporate: {
    getAllRoles: '/api/CorporateAdmin/v1/CorporateRoleByCorporate/GetRoles',
    createNewRole: '/api/CorporateAdmin/v1/CorporateRoleByCorporate/CreateRole',
    updateRole: '/api/CorporateAdmin/v1/CorporateRoleByCorporate/UpdateRole',
    getRolePermissions: '/api/CorporateAdmin/v1/CorporateRoleByCorporate/GetRolePermissions',
    getUserAccesses: '/api/CorporateAdmin/v1/CorporateRoleByCorporate/GetUserAccesses',
    addUserAccess: '/api/CorporateAdmin/v1/CorporateRoleByCorporate/AddRoleAccess',
  },
  chequeBook: {
    getAllCheques: '/api/CorporateAdmin/v1/Cheque/RequestChequeBookHistory',
    requestChequeBook: '/api/CorporateAdmin/v1/Cheque/RequestChequeBook',
  },
  branches: {
    getAllBranches: '/api/CorporateAdmin/v1/Branch/GetBranches'
  },
  workflows: {
    getWorkflows: '/api/CorporateAdmin/v1/Workflow/GetWorkflows',
    getPendingWorkflows: '/api/CorporateAdmin/v1/Workflow/PendingApproval',
    createWorkflows: '/api/CorporateAdmin/v1/Workflow/CreateWorkflow',
    modifyWorkflows: '/api/CorporateAdmin/v1/Workflow/UpdateTempWorkflow',
    updatedWorkflows: '/api/CorporateAdmin/v1/Workflow/UpdateWorkflow',
    approveWorkflow: '/api/CorporateAdmin/v1/Workflow/ApproveWorkflow',
    declineWorkflow: '/api/CorporateAdmin/v1/Workflow/DeclineWorkflow',
    requestApproval: '/api/CorporateAdmin/v1/Workflow/RequestWorkflowApproval',
  },
  workflowHierarchy: {
    createHierarchy: '/api/CorporateAdmin/v1/WorkflowHierarchy/CreateWorkflowHierarchies',
    modifyHierarchy: '/api/CorporateAdmin/v1/Workflow/ModifyWorkflowHierarchies',
    getWorkflowHierarchy: '/api/CorporateAdmin/v1/WorkflowHierarchy/GetWorkflowHierarchies',
    getTempWorkflowHierarchy: '/api/CorporateAdmin/v1/WorkflowHierarchy/GetTempWorkflowHierarchies',
  },
  transactions: {
    getTransactionTypes: '/api/CorporateAdmin/v1/Transaction/GetTransactionTypes',
    getCorporateAccounts: '/api/CorporateAdmin/v1/Transaction/GetCorporateAccounts',
    verifyIntraTransaction: '/api/CorporateAdmin/v1/Transaction/VerifyIntraBankTransaction',
    verifyInterTransaction: '/api/CorporateAdmin/v1/Transaction/VerifyInterBankTransaction',
    initiateIntraTransaction: '/api/CorporateAdmin/v1/Transaction/InitiateIntraBankTransaction',
    initiateInterTransaction: '/api/CorporateAdmin/v1/Transaction/InitiateInterBankTransaction',
    pendingTransactionLogs: '/api/CorporateAdmin/v1/Transaction/PendingTransactionLogs',
    transactionLogs: '/api/CorporateAdmin/v1/Transaction/TransactionHistory',
    approvalHistory: '/api/CorporateAdmin/v1/Transaction/TransactionApprovalHistory',
    creditAccounts: '/api/CorporateAdmin/v1/Transaction/PendingCreditLogs',
    approveTransaction: '/api/CorporateAdmin/v1/Transaction/ApproveTransaction',
    declineTransaction: '/api/CorporateAdmin/v1/Transaction/DeclineTransaction',
    interbankNameEnquiry: '/api/CorporateAdmin/v1/Transaction/InterbankNameInquiry',
    interbankList: '/api/CorporateAdmin/v1/Transaction/InterbankBankList',
    verifyBulkTransfer: '/api/CorporateAdmin/v1/BulkTransaction/VerifyBulkTransfer',
    initiateBulkTransfer: '/api/CorporateAdmin/v1/BulkTransaction/InitiateBulkTransfer',
    approveBulkTransfer: '/api/CorporateAdmin/v1/BulkTransaction/ApproveBulkTransfer',
    declineBulkTransfer: '/api/CorporateAdmin/v1/BulkTransaction/DeclineTransaction',
    getBulkTemplate: '/api/CorporateAdmin/v1/BulkTransaction/DownloadTemplate',
    getBankCodeList: '/api/CorporateAdmin/v1/BulkTransaction/DownloadBankCodeTemplate',
    bulkPendingTransaction: '/api/CorporateAdmin/v1/BulkTransaction/PendingBulkTransactionLogs',
    bulkCreditAccounts: '/api/CorporateAdmin/v1/BulkTransaction/BulkCreditLogs',
    bulkApprovalHistory: '/api/CorporateAdmin/v1/BulkTransaction/BulkTransactionApprovalHistory',
    bulkTransactionHistory: '/api/CorporateAdmin/v1/BulkTransaction/AuthorizedBulkTransactionLogs',
  },
  salary: {
    salaryTemplate: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/DownloadCorporateEmployeeTemplate',
    verifyStaffs: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/VerifyCorporateEmployeeBulkUpload',
    addStaffs: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/InitiateCorporateEmployeeBulkUpload',
    getStaffs: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/GetCorporateEmployee',
    getPendingStaffs: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/GetPendingCorporateEmployee',
    getSchedules: '/api/CorporateAdmin/v1/ComporateSalarySchedule/GetSalarySchedules',
    getPendingSchedules: '/api/CorporateAdmin/v1/ComporateSalarySchedule/GetPendingSalarySchedules',
    createSingleStaff: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/CreateCorporateEmployee',
    updateSingleStaff: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/UpdateCorporateEmployee',
    createSalarySchedule: '/api/CorporateAdmin/v1/ComporateSalarySchedule/CreateSalarySchedule',
    requestScheduleApproval: '/api/CorporateAdmin/v1/ComporateSalarySchedule/RequestSalaryScheduleApproval',
    initiateSalarySchedule: '/api/CorporateAdmin/v1/ComporateSalarySchedule/InitiateSalarySchedule',
    approveSalarySchedule: '/api/CorporateAdmin/v1/ComporateSalarySchedule/ApproveSalarySchedule',
    declineSalarySchedule: '/api/CorporateAdmin/v1/ComporateSalarySchedule/DeclineSalarySchedule',
    reactivateSalarySchedule: '/api/CorporateAdmin/v1/ComporateSalarySchedule/ReactivateSalarySchedule',
    deactivateSalarySchedule: '/api/CorporateAdmin/v1/ComporateSalarySchedule/DeactivateSalarySchedule',
    reactivateStaff: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/ReactivateCorporateEmployee',
    deactivateStaff: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/DeactivateCorporateEmployee',
    requestStaffApproval: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/RequestCorporateEmployeeApproval',
    approveStaff: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/ApproveCorporateEmployee',
    declineStaff: '/api/CorporateAdmin/v1/ComporateCustomerEmployee/DeclineCorporateEmployee',
    addBeneficiaries: '/api/CorporateAdmin/v1/ComporateScheduleBeneficiary/AddScheduleBeneficiary',
    editBeneficiaries: '/api/CorporateAdmin/v1/ComporateScheduleBeneficiary/RemoveScheduleBeneficiary',
    getBeneficiaries: '/api/CorporateAdmin/v1/ComporateScheduleBeneficiary/GetScheduleBeneficiaries',
  },
  dashboard: {
    dashboardDetails: '/api/CorporateAdmin/v1/Dashboard/GetDashboardInfo'
  },
  onlending: {
    beneficiaryTemplate: "/api/CorporateAdmin/v1/OnlendingBeneficiary/DownloadTemplate",
    beneficiaryUpload: "/api/CorporateAdmin/v1/OnlendingBeneficiary/BatchUpload",
    downloadInvalid: "/api/CorporateAdmin/v1/OnlendingBeneficiary/DownloadInvalidBatch",
    downloadBatch: "/api/CorporateAdmin/v1/OnlendingBeneficiary/DownloadBatch",
    initiateValid: "/api/CorporateAdmin/v1/OnlendingBeneficiary/InitiateValidBatch",
    getBatches: "/api/CorporateAdmin/v1/OnlendingBeneficiary/GetBatches",
    // getValidBatch: "/api/CorporateAdmin/v1/OnlendingBeneficiary/GetValidBatch",
    getVaildBatchBeneficiares: "/api/CorporateAdmin/v1/OnlendingBeneficiary/GetValidBatcheBeneficiaries",
    getProcessedBatch: "/api/CorporateAdmin/v1/OnlendingBeneficiary/GetProcessBatches",
    getProcessedBatchBeneficiares: "/api/CorporateAdmin/v1/OnlendingBeneficiary/GetProcessBatcheBeneficiaries",
    getPendingProcessedBatchBeneficiares: "/api/CorporateAdmin/v1/OnlendingDisbursment/GetPendingBatchDisbursmentBeneficiaries",
    initiateDisbursement: "/api/CorporateAdmin/v1/OnlendingDisbursment/InitiateDisbursment",
    approveDisbursement: "/api/CorporateAdmin/v1/OnlendingDisbursment/ApproveBatchDisbursment",
    declineDisbursement: "/api/CorporateAdmin/v1/OnlendingDisbursment/DeclineBatchDisbursment",
    getExtensionRequest: "/api/CorporateAdmin/v1/OnlendingLiquidation/GetExtensionRequest",
    getPendingExtensionRequest: "/api/CorporateAdmin/v1/OnlendingLiquidation/GetPendingBatchRepaymentDateExtensionBeneficiaries",
    getLiquidation: "/api/CorporateAdmin/v1/OnlendingLiquidation/GetLiquidationRequest",
    getExtensionDate: "/api/CorporateAdmin/v1/OnlendingLiquidation/GetExtensionDate",
    getLiquidationType: "/api/CorporateAdmin/v1/OnlendingLiquidation/GetLiquidationType",
    initiateDateExtension: "/api/CorporateAdmin/v1/OnlendingLiquidation/InitiateRepaymentDateExtension",
    approveExt: "/api/CorporateAdmin/v1/OnlendingLiquidation/ApproveRepaymentDateExtension",
    declineExt: "/api/CorporateAdmin/v1/OnlendingLiquidation/DeclineRepaymentDateExtension",
    initiateLiquidation: "/api/CorporateAdmin/v1/OnlendingLiquidation/Liquidation",
    initiatePreliquidation: "/api/CorporateAdmin/v1/OnlendingLiquidation/PreLiquidation",
  }

}
// define store functions
export const store = {
  needle: window.atob(String(el.getAttribute('data-action-sum')).substr(4)),
  needleSub: window.atob(String(el.getAttribute('data-action-sub'))),

  // clear storage
  clear: () => sessionStorage.clear(),
  // set store value by encrypting key and value
  set(key: string, value: string) {
    // halt if irregularity in key or value
    if (!String(key).trim().length || !String(value).trim().length) return
    // encrypt key
    key = window.btoa(key).replace(/=/g, '').toUpperCase().split('').reverse().join('');
    // check if value is integer
    if (typeof (value) == "number") {
      // attach flag to value
      value = String(value) + '_IS_INTEGER'
    }
    // store value
    sessionStorage.setItem(key, CryptoJS.AES.encrypt(String(value), this.needle).toString());
  },
  // get store value
  get(key: string): any {
    // halt if irregularity in key
    if (!String(key).trim().length) return
    // encrypt key
    key = window.btoa(key).replace(/=/g, '').toUpperCase().split('').reverse().join('');
    // get value
    let value = (sessionStorage.hasOwnProperty(key)
      ? CryptoJS.AES.decrypt(sessionStorage.getItem(key), this.needle).toString(CryptoJS.enc.Utf8)
      : '');

    // check if value is integer
    if (String(value).length && (new RegExp(/_IS_INTEGER/)).test(value)) {
      return Number(String(value).replace(/_IS_INTEGER/g, ''))
    }

    return value
  },
  // remove value with key
  remove(key: string) {
    // halt if irregularity in key
    if (!String(key).trim().length) return
    // encrypt key
    key = window.btoa(key).replace(/=/g, '').toUpperCase().split('').reverse().join('');
    // remove value
    sessionStorage.removeItem(key);
  },
  // check if store has value
  has(key: string): any {
    // halt if irregularity in key
    if (!String(key).trim().length) return
    // hold initial key
    let holder = key
    // encrypt key
    key = window.btoa(key).replace(/=/g, '').toUpperCase().split('').reverse().join('');
    // return if store has value
    return (String(this.get(holder)).length > 0)
  },
  // log value
  log(key: string): any {
    // logger object
    let logger = new LogService()
    // log value
    logger.log(this.get(key))
  },
  // encrypt data
  encrypt(value: any, sub?: boolean): any {
    // bounce back if no data
    if (!String(value).trim().length) return ''

    // return cipher
    if (sub) {
      return CryptoJS.AES.encrypt(String(value), this.needleSub).toString();
    } else {
      return CryptoJS.AES.encrypt(String(value), this.needle).toString();
    }
  },
  // encrypt data object
  encryptObject(obj: any, paramStatus?: boolean): any {
    // define new object
    let newObject = {}
    // loop through object
    for (let key in obj) {
      // check for data type
      if (obj[key] && typeof (obj[key]) == 'object' && !isNaN((obj[key]).length)) {
        // define new array
        let newTempArray = []
        // counter
        let counter = 0
        // loop through array
        Array.from(obj[key]).forEach(item => {
          // pass data
          newTempArray[counter] = paramStatus ? encodeURIComponent(this.encrypt(item)) : this.encrypt(item)

          // increment counter
          counter++
        })

        // pass array back
        newObject[key] = newTempArray
      }
      else if (obj[key] == null) newObject[key] = obj[key]
      else newObject[key] = paramStatus ? encodeURIComponent(this.encrypt(obj[key])) : this.encrypt(obj[key])
    }

    // return data
    return newObject
  },
  // encrypt data array objects
  encryptArrayObjects(array: any): any {
    // define new array
    let newArray = []
    // define new object
    let newObject = {}
    // counter
    let counter = 0

    // loop through object
    Array.from(array).forEach((obj: any) => {
      // reset object
      newObject = {}

      // loop through object
      for (let key in obj) {
        newObject[key] = this.encrypt(obj[key])
      }

      // pass object
      newArray[counter] = newObject

      // increment counter
      counter++
    })

    // return data
    return newArray
  },
  // decrypt data
  decrypt(textToDecrypt: string): any {
    // bounce back if no data
    if (!String(textToDecrypt).trim().length) return ''

    // return decrypted text
    return CryptoJS.AES.decrypt(textToDecrypt, this.needle).toString(CryptoJS.enc.Utf8);
  },
  // encrypt data object and add necessary info
  async preparePayload(obj: any, paramStatus?: boolean, withLocation?: boolean): Promise<any> {
    // logger object
    let logger = new LogService()
    // log value before encryption
    logger.log(obj)

    // return data
    return {
      ...this.encryptObject({
        ...obj,
        ...(await config.getMachineIdentity(withLocation))
      }, paramStatus)
    }
  },
  // encrypt data array and add necessary info
  preparePayloadArray(array: any): any {
    // return data
    return this.encryptArrayObjects(array)
  },
}

export const modal = {
  show(selector: string) {
    // show modal after 100 milliseconds
    setTimeout(() => find(selector).length && find(selector).modal('show'), 100)
  },
  hide(selector: string) {
    // hide modal
    find(selector).modal('hide')
  }
}

// machine identity service
const machineIdentityService = {
  // get ip address
  async getIPAddress() {
    // promisify
    let ip = await new Promise(async (resolve) => {
      // define network
      let network = null
      try {
        if (environment.production) {
          // const storedIp = store.get('UserIP')

          // if (!storedIp) {
          //   // // network call
          //   let network = await fetch("http://api.ipify.org/?format=json")
          //   // // data to json
          //   let ipData = await network.json()
          //   // // // resolve
          //   store.set('UserIP', ipData.ip)
          //   resolve(ipData.ip)
          // } else {
          //   resolve(storedIp)
          // }
          resolve("0.0.0.0")

        } else {
          resolve("0.0.0.0")
        }

      } catch (err) {
        // resolve
        store.set('UserIP', "0.0.0.0")
        resolve("0.0.0.0")
      }
    })

    // return data
    return ip
  },
  // get location
  async getLocationCoordinates(): Promise<any> {
    // get location
    let location: any = await new Promise(async (resolve) => {
      // check for support
      if (navigator.geolocation) {
        // get location details
        navigator.geolocation.getCurrentPosition(async function (position) {
          // resolve
          resolve({ status: true, data: position, error: null })
        }, async function (error: any) {
          // check for error
          switch (error.code) {
            case error.PERMISSION_DENIED:
              resolve({ status: false, data: null, error: "User denied the request for Geolocation." })
              break
            case error.POSITION_UNAVAILABLE:
              resolve({ status: false, data: null, error: "Location information is unavailable." })
              break
            case error.TIMEOUT:
              resolve({ status: false, data: null, error: "The request to get user location timed out." })
              break
            case error.UNKNOWN_ERROR:
              resolve({ status: false, data: null, error: "An unknown error occurred." })
              break
          }
        })
      } else {
        // resolve
        resolve({ status: false, data: null, error: "Geolocation is not supported by this browser." })
      }
    })

    // check if null
    if (!location.status) {
      // alert
      alert("LOCATION ERROR!\n" + location.error + "\n" + "Please try again or contact the administrator.")
      // error
      throw Error(location.error)
    }

    // return location
    return location
  },
}







