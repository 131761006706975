import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';

// define template
let template = 'security-question.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class SecurityQuestionComponent implements OnInit {
  formBankUserDetails: FormGroup
  securityQuestionProcessUsername: string
  // securityQuestionProcessCIB: string
  operationProcessStatus: boolean
  securityQuestions: any = []

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private ui: UiService,
    private router: Router,
    private logger: LogService) {
  }

  ngOnInit() {
    // get customer info
    this.securityQuestionProcessUsername = store.decrypt(store.get('securityQuestionNoAvailableUsername'))
    // this.securityQuestionProcessCIB = store.decrypt(store.get('securityQuestionNoAvailableCIB'))

    // check for status
    if (store.has('securityQuestionNoAvailable')) {
      // remove flag 
      store.remove('securityQuestionNoAvailable')
      store.remove('securityQuestionNoAvailableUsername')
      // store.remove('securityQuestionNoAvailableCIB')
    } else {
      // redirect
      // this.router.navigate(['/user/login'])
      return
    }

    // toggle password
    this.togglePassword()

    // define form
    this.formBankUserDetails = this.fb.group({
      UserName: [this.securityQuestionProcessUsername, Validators.required],
      // CustomerId: [this.securityQuestionProcessCIB, Validators.required],
      Password: ['', Validators.required],
      SecurityQuestionId: ['', Validators.required],
      Answer: ['', Validators.required],
      SecurityQuestionId2: ['', Validators.required],
      Answer2: ['', Validators.required],
      SecurityQuestionId3: ['', Validators.required],
      Answer3: ['', Validators.required],
    })

    // fetch questions
    this.fetchAllSecurityQuestions()
  }

  // fetch security questions
  fetchAllSecurityQuestions() {
    // progress
    this.ui.blockUiOn(`Loading security questions... please wait`)

    // network
    this.http.get<any>(config.auth + routes.authentication.getSecurityQuestions, config.httpOptionsNoAuth())
      .subscribe({
        next: (data: any) => {
          // pass data into container
          this.securityQuestions = data?.data
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => this.ui.blockUiOff())
  }

  // detect selected question 
  detectQuestionSelected(index: number) {
    // get values
    let values = this.formBankUserDetails.value

    // check index
    switch (index) {
      case 1:
        // check that question has not been selected
        if (values.SecurityQuestionId == values.SecurityQuestionId2 || values.SecurityQuestionId == values.SecurityQuestionId3) {
          // reset value
          this.formBankUserDetails.patchValue({
            SecurityQuestionId: ''
          })
          // warning
          this.ui.warning(`Question has already been selected, please select another question.`)
          return
        }
        break
      case 2:
        // check that question has not been selected
        if (values.SecurityQuestionId2 == values.SecurityQuestionId || values.SecurityQuestionId2 == values.SecurityQuestionId3) {
          // reset value
          this.formBankUserDetails.patchValue({
            SecurityQuestionId2: ''
          })
          // warning
          this.ui.warning(`Question has already been selected, please select another question.`)
          return
        }
        break
      case 3:
        // check that question has not been selected
        if (values.SecurityQuestionId3 == values.SecurityQuestionId || values.SecurityQuestionId3 == values.SecurityQuestionId2) {
          // reset value
          this.formBankUserDetails.patchValue({
            SecurityQuestionId3: ''
          })
          // warning
          this.ui.warning(`Question has already been selected, please select another question.`)
          return
        }
        break
    }
  }

  // set security question
  async changeSecurityQuestion(target: any) {

    // get form
    let form = find(target)

    // define payload
    // let payload = {
    //     ...this.formBankUserDetails.value
    // }


    // check if form is valid
    if (!this.formBankUserDetails.valid) {
      // warning
      this.ui.warning(`Please fill all required fields.`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // change status flag
    this.operationProcessStatus = true
    this.ui.blockUiOn(`Saving security questions... please wait`)

    let payload = await store.preparePayload({
      ...this.formBankUserDetails.value
    })


    // network
    this.http.post<any>(config.auth + routes.authentication.setSecurityQuestion, payload, config.httpOptionsNoAuth())
      .subscribe({
        next: (data: any) => {
          // log

          // if success
          if (data == true) {
            // redirect
            this.router.navigate(['/user/login'])
            // notification
            this.ui.info(`Security question was successfully set! You can now sign in to your account.`)
          } else {
            // error
            this.ui.warning(data.responseDescription || `Something went wrong! Please try again.`)
          }
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => {
        this.operationProcessStatus = false
        this.ui.blockUiOff()
      })
  }

  // toggle password
  togglePassword() {
    // timeout
    setTimeout(() => {
      Array.from(document.querySelectorAll("#toggle-password")).forEach(el => {
        el.addEventListener('click', function (e: any) {
          var x = this.previousSibling;
          if (x.type === "password") {
            x.type = "text"
          } else {
            x.type = "password"
          }
        })
      })
    }, 10)
  }
}