<div class="scrollable-content row">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">History</h4>
                <!-- <div class="flex-shrink-0">
                    <div class="btn-group">
                        <button class="btn btn-main dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            Actions <i class="bx bx-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="defaultDropdown" style="">
                            <li><a class="dropdown-item" href="javascript:void(0);">Generate Statement</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0);">Send to third party</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0);">Send to Embassy</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0);">Export</a></li>

                            <li><a class="dropdown-item pointer" *ngIf="currentUser == users.authorizer" data-bs-target="#modal-transaction-approval" data-bs-toggle="modal">Bring Up Approval Modal</a></li>
                        </ul>
                    </div>
                </div> -->
            </div>


            <!-- sole -->
            <div class="card-body">
                <!-- Nav tabs -->
                <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                    <li class="nav-item fs-5 cursor-pointer" (click)="fetchTransactions()">
                        <a class="nav-link active" data-bs-toggle="tab" href="#transactions" role="tab"
                            aria-selected="false">
                            Single Transactions
                        </a>
                    </li>
                    <li class="nav-item fs-5 cursor-pointer" (click)="fetchBulkTransactions()">
                        <a class="nav-link" data-bs-toggle="tab" href="#bulk" role="tab" aria-selected="false">
                            Bulk Transactions
                        </a>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane active" id="transactions" role="tabpanel">
                        <div class="d-flex">
                            <div class="custom-datatable overflow-auto transaction-history-table w-100">

                                <table id="transaction-history" data-control-id='transaction-history'
                                    data-control-scope='transaction-history'
                                    class="table datatable table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Date</th>
                                            <th>Narration</th>
                                            <th>Ref</th>
                                            <th>Amount</th>
                                            <th>Beneficiary's Name</th>
                                            <th>Sender</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <!-- <th></th> -->
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of transactions; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ (item?.tranDate | date) || '-' }}</td>
                                            <td>{{ item?.narration }}</td>
                                            <td>{{ item?.transactionReference }}</td>
                                            <td>{{ item?.tranAmout | currency : 'NGN' }}</td>
                                            <td>{{item?.destinationAcctName || '-'}} {{ item?.destinationAcctNo || '-' }}
                                            </td>
                                            <td>{{item?.sourceAcctName || ''}} {{ item?.sourceAccountNo || '-' }}</td>
                                            <td>{{ item?.tranType || '-' }}</td>
                                            <td>
                                                {{item?.transactionStatus }}
                                            </td>
                                            <!-- <td>
                                                <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                                    *ngIf="item?.status == 0" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Pending"></i>
                                                <i class="mdi mdi-lock-remove fs-4 text-danger" *ngIf="item?.status == 2"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Failed"></i>
                                                <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.status == 1"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Successful"></i>
                                                <i class="mdi mdi-alert-octagram fs-4 text-danger"
                                                    *ngIf="item?.status == 3" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Declined"></i>
                                            </td> -->
                                            <td>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-light dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i class="ri-more-2-fill"></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item"
                                                            (click)="viewTransactionDetails(item)">View Receipt</a>

                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="bulk" role="tabpanel">
                        <div class="d-flex">
                            <div class="custom-datatable overflow-auto transaction-history-table w-100">
                                <table id="bulk-transaction-history" data-control-id='bulk-transaction-history'
                                    data-control-scope='bulk-transaction-history'
                                    class="table datatable table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Date</th>
                                            <th>Reference Number</th>
                                            <th>Debit Account</th>
                                            <th>Narration</th>
                                            <th>Amount</th>
                                            <th>Credit Number</th>
                                            <th>Status</th>
                                            <!-- <th></th> -->
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of bulkTransactions; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ (item?.dateInitiated | date) || '-' }}</td>
                                            <td>{{ item?.transactionReference }}</td>
                                            <td>{{item?.debitAccountName}}-{{ item?.debitAccountNumber || '-' }}</td>
                                            <td>{{ item?.narration }}</td>
                                            <td>{{ item?.debitAmount | currency : item?.currency }}</td>
                                            <td>{{item?.totalCredits || "0"}} of {{ item?.noOfCredits || '-' }}</td>
                                            <!-- <td>
                                                <span *ngIf="item?.transactionStatus == 0">Pending</span>
                                                <span *ngIf="item?.transactionStatus == 1">Successful</span>
                                                <span *ngIf="item?.transactionStatus == 2">Failed</span>
                                                <span *ngIf="item?.transactionStatus == 3">Declined</span>
                                            </td> -->
                                            <td>
                                                <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                                    *ngIf="item?.transactionStatus == 0" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Pending"></i>
                                                <i class="mdi mdi-lock-remove fs-4 text-danger"
                                                    *ngIf="item?.transactionStatus == 2" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Failed"></i>
                                                <i class="mdi mdi-check-bold fs-4 text-success"
                                                    *ngIf="item?.transactionStatus == 1" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Successful"></i>
                                                <i class="mdi mdi-alert-octagram fs-4 text-danger"
                                                    *ngIf="item?.transactionStatus == 3" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Declined"></i>

                                                <i class="mdi mdi-arrow-left-bold-circle fs-4 text-info"
                                                    *ngIf="item?.transactionStatus == 4" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Reversed"></i>
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-light dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i class="ri-menu-2-fill"></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item pointer"
                                                            (click)="fetchCreditAccountsForBulkTransaction(item)">View
                                                            Credit
                                                            Accounts</a>
                                                        <!-- <a class="dropdown-item pointer"
                                                            (click)="initiateFetchBulkTransactionApprovalHistory(item)">Approval
                                                            History</a> -->
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- credit accounts -->
<div class="modal fade" id="modal-transaction-credit-accounts" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Credit Accounts
                </h5>
                <div class="btn-group ml-3">
                    <button type="button" class=" btn  btn-light mx-4" data-bs-dismiss="modal" aria-label="Close">
                        Close</button>
                    <button class="btn btn-sec dropdown-toggle" type="button" id="defaultDropdown"
                        data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                        *ngIf="currentUser != config.users.sole">
                        <i class="bx bx-download mr-1"></i> Download As <i class="bx bx-chevron-down"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li><a class="dropdown-item pointer" (click)="downloadCreditAccountAsExcel()">Spreadsheet
                                (XLSX)</a></li>
                    </ul>
                </div>
            </div>
            <div class="modal-body p-1">

                <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                    <table class="table datatable table-striped table-bordered w-100"
                        data-control-id="transaction-credit-accounts">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Account Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Ref</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Bank Code</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of creditAccountsList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.creditAccountName }}</td>
                                <td>{{ item?.creditAccountNumber }}</td>
                                <td>{{ item?.transactionReference || "-" }}</td>
                                <td>{{ item?.creditBankName }}</td>
                                <td>{{ item?.creditBankCode }}</td>
                                <td>{{ item?.creditAmount | currency: 'NGN' }}</td>
                                <td>{{ item?.narration }}</td>
                                <td>{{ (item?.initiateDate | date) || '-' }}</td>
                                <td>
                                    <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.creditStatus  == 1"></i>
                                    <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                        *ngIf="item?.creditStatus == 0 "></i>
                                    <i class="mdi mdi-lock-remove fs-4 text-danger" *ngIf="item?.creditStatus == 2"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Failed"></i>
                                    <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.creditStatus == 3"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                                    <i class="mdi mdi-arrow-left-bold-circle fs-4 text-info"
                                        *ngIf="item?.creditStatus == 4" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Reversed"></i>
                                </td>
                                <td>
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-light dropdown-toggle"
                                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="ri-more-2-fill"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" (click)="viewTransactionDetailsBulk(item)">View
                                                Receipt</a>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style="display: none;" id="transaction-credit-accounts">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Account Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Bank Code</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of creditAccountsList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.creditAccountName }}</td>
                                <td>{{ item?.creditAccountNumber }}</td>
                                <td>{{ item?.creditBankName }}</td>
                                <td>{{ item?.creditBankCode }}</td>
                                <td>{{ item?.creditAmount | currency: 'NGN' }}</td>
                                <td>{{ item?.narration }}</td>
                                <td>{{ (item?.initiateDate | date) || '-' }}</td>
                                <td>{{ item?.responseMessage }}</td>
                                <td>
                                    <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.creditStatus  == 1"></i>
                                    <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                        *ngIf="item?.creditStatus == 0 " title="Pending"></i>
                                    <i class="mdi mdi-lock-remove fs-4 text-danger" *ngIf="item?.creditStatus == 2"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Failed"></i>
                                    <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.creditStatus == 3"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                                    <i class="mdi mdi-arrow-left-bold-circle fs-4 text-info"
                                        *ngIf="item?.creditStatus == 4" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Reversed"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- view transaction record -->
<div class="modal fade" id="modal-view-transaction-history" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="border: none;" id="receipt-container">
            <div class="modal-header" style="background: #2e2dad;">
                <button type="button" *ngIf="!receiptDownloadingStatus" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" style="filter: invert(1); opacity: 1;"></button>
            </div>
            <div class="modal-body p-0">

                <div class="d-flex flex-column align-items-center text-center p-5" style="background: #2e2dad;">
                    <div>
                        <img src="assets/images/main-logo.png" style="height: 80px; width: auto;" />
                    </div>
                    <div class="mt-3">
                        <label class="text-white fs-5"
                            style="background: #d1ad6c; padding: 10px 15px; border-radius: 100px; -webkit-border-radius: 100px; -moz-border-radius: 100px;">You
                            transferred</label>
                    </div>
                    <div class="mt-3">
                        <label class="text-white display-4 fw-semibold">
                            <small class="fs-1" style="vertical-align: top; position: relative; top: 7px;">{{
                                currentlySelectedTransaction?.currency ? currentlySelectedTransaction?.currency + ' ' :
                                '' }}</small>{{ currentlySelectedTransaction?.tranAmout | currency : 'NGN' }}
                        </label>
                    </div>
                    <div class="mt-3 pb-4">
                        <label class="text-white fs-5">
                            {{ currentlySelectedTransaction?.tranDate | date : 'fullDate' }} at {{
                            currentlySelectedTransaction?.tranDate | date : 'shortTime' }}
                        </label>
                    </div>
                </div>

                <div class="d-flex p-5">

                    <table class="table table-striped table-bordered" id="transfer-summary-table">
                        <tbody>
                            <tr>
                                <td>Sender</td>
                                <td class="fw-semibold">
                                    <div class="text-dark mb-2">{{ currentlySelectedTransaction?.sourceAccountName }}
                                    </div>
                                    <div class="text-muted">{{ currentlySelectedTransaction?.sourceAccountNo }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Recipient</td>
                                <td class="fw-semibold">
                                    <div class="text-dark mb-2">{{ currentlySelectedTransaction?.destinationAcctName }}
                                    </div>
                                    <div class="text-muted mb-2">{{ currentlySelectedTransaction?.destinationAcctNo }}
                                    </div>
                                    <div class="text-muted">{{ currentlySelectedTransaction?.desctionationBank ||
                                        currentlySelectedTransaction?.destionationBank }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.tranAmout | currency : 'NGN' }}
                                </td>
                            </tr>
                            <tr>
                                <td>Transaction Type</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.tranType || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Narration</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.narration || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Reference</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.transactionReference ||
                                    currentlySelectedTransaction?.providerRef || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Status</td>

                                <td class="fw-semibold">
                                    {{ currentlySelectedTransaction.transactionStatus }}
                                </td>

                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="text-center mt-10 mb-10" *ngIf="!receiptDownloadingStatus">
                    <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                    <div class="btn-group w-50" *ngIf="currentUser != config.users.sole">
                        <button class="btn btn-main btn-lg dropdown-toggle" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            Download <i class="bx bx-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="defaultDropdown" style="">
                            <li><a class="dropdown-item pointer" (click)="downloadTransactionReceipt()">As PDF</a></li>
                            <li><a class="dropdown-item pointer" (click)="downloadTransactionReceiptAsImage()">As
                                    Image</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- view transaction record bulk -->
<div class="modal fade" id="modal-view-transaction-history-bulk" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="border: none;" id="receipt-container-bulk">
            <div class="modal-header" style="background: #2e2dad;">
                <button type="button" *ngIf="!receiptDownloadingStatus" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" style="filter: invert(1); opacity: 1;"></button>
            </div>
            <div class="modal-body p-0">

                <div class="d-flex flex-column align-items-center text-center p-5" style="background: #2e2dad;">
                    <div>
                        <img src="assets/images/main-logo.png" style="height: 80px; width: auto;" />
                    </div>
                    <div class="mt-3">
                        <label class="text-white fs-5"
                            style="background: #d1ad6c; padding: 10px 15px; border-radius: 100px; -webkit-border-radius: 100px; -moz-border-radius: 100px;">You
                            transferred</label>
                    </div>
                    <div class="mt-3">
                        <label class="text-white display-4 fw-semibold">
                            <small class="fs-1" style="vertical-align: top; position: relative; top: 7px;">{{
                                currentlySelectedTransaction?.currency ? currentlySelectedTransaction?.currency + 'NGN'
                                :
                                '' }}</small>{{ currentlySelectedTransaction?.creditAmount | currency : 'NGN' }}
                        </label>
                    </div>
                    <div class="mt-3 pb-4">
                        <label class="text-white fs-5">
                            {{ currentlySelectedTransaction?.creditDate | date : 'fullDate' }} at {{
                            currentlySelectedTransaction?.creditDate | date : 'shortTime' }}
                        </label>
                    </div>
                </div>

                <div class="d-flex p-5">

                    <table class="table table-striped table-bordered" id="transfer-summary-table">
                        <tbody>
                            <!-- <tr>
                                <td>Sender</td>
                                <td class="fw-semibold">
                                    <div class="text-dark mb-2">{{ currentlySelectedTransaction?.creditAccountName }}
                                    </div>
                                    <div class="text-muted">{{ currentlySelectedTransaction?.sourceAccountNo }}</div>
                                </td>
                            </tr> -->
                            <tr>
                                <td>Recipient</td>
                                <td class="fw-semibold">
                                    <div class="text-dark mb-2">{{ currentlySelectedTransaction?.creditAccountName }}
                                    </div>
                                    <div class="text-muted mb-2">{{ currentlySelectedTransaction?.creditAccountNumber }}
                                    </div>
                                    <div class="text-muted">{{ currentlySelectedTransaction?.creditBankName ||
                                        currentlySelectedTransaction?.creditBankName }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.creditAmount | currency : 'NGN'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Reference</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.transactionReference || "-"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>Narration</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.narration || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td class="fw-semibold">
                                    {{ currentlySelectedTransaction.creditStatus == 1 ? "Successful" :
                                    currentlySelectedTransaction.creditStatus == 0 ? "Pending" :
                                    currentlySelectedTransaction.creditStatus == 2 ? "Failed" :
                                    currentlySelectedTransaction.creditStatus == 3 ? "Declined" : "Reversed" }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="text-center mt-10 mb-10" *ngIf="!receiptDownloadingStatus">
                    <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                    <div class="btn-group w-50" *ngIf="currentUser != config.users.sole">
                        <button class="btn btn-main btn-lg dropdown-toggle" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            Download <i class="bx bx-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="defaultDropdown" style="">
                            <li><a class="dropdown-item pointer" (click)="downloadTransactionReceiptBulk()">As PDF</a>
                            </li>
                            <li><a class="dropdown-item pointer" (click)="downloadTransactionReceiptAsImageBulk()">As
                                    Image</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>