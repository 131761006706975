// define routes for /
export const AppRoutes = {
	root									: '/user/',
	home									: '',
	login									: 'login',
	forgotPassword				: 'forgot-password',
	resetPassword					: 'reset-password',
	dashboard							: 'dashboard',
	overview							: 'overview',
	transfers							: 'transfers',
	bulkPayment						: 'bulk-payment',
	staffs					: 'staffs',
	staffBulk					: 'staffs-bulk',
	salaryPayment					: 'salary-payment',
	salarySchedules 						: 'schedules',
	manageWorkflow				: 'manage-workflow',
	manageUsers						: 'manage-users',
	onLending						: 'on-lending',
	profileSettings				: 'profile-settings',
	billsPayment					: 'bills-payment',
	accountSummary				: 'account-summary',
	transactionHistory		: 'transaction-history',
	pendingTransactions		: 'pending-transactions',
	accountStatement			: 'account-statement',
	beneficiaries					: 'beneficiaries',
	payments							: 'payments',
	singlePayment					: 'single-inter-payment',
	singleIntraPayment		: 'single-intra-payment',
	selfPayment						: 'self-payment',
	multiplePayments			: 'multiple-payments',
	bulkBankPaymentFile		: 'bulk-bank-payment-file',
	reports								: 'reports',
	profile								: 'profile',
	usefulLinks						: 'links',
	feedback							: 'feedback',
	category							: 'category',
	airtime								: 'airtime',
	dataBundle						: 'data-bundle',
	profileComplete				: 'complete-profile',
	transactions					: 'transactions',
	transactionsPending		: 'pending-transactions',
	transactionsDeclined	: 'declined-transactions',
	changePassword	: 'change-password',
	changePin	: 'change-pin',
	chequeBook: "cheque-book",
}