<div class="row">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">Manage Workflow</h4>
                <div class="flex-shrink-0">
                    <div class="btn-group">
                        <button class="btn btn-main" type="button"
                            *ngIf="config.currentUserHasPermissionByKey('CreateWorkflow')"
                            (click)="initiateCreateOrUpdateCorporateWorkflow(true)">
                            <i class="bx bx-plus fs-5 mr-2 position-relative" style="top: 3px;"></i> Create Workflow
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div *ngIf="config.currentUserHasPermissionByKey('ViewWorkflow')"
                    class="custom-datatable manage-workflow-table w-100">

                    <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                        <li class="nav-item fs-5">
                            <a class="nav-link active" data-bs-toggle="tab" href="#allworkflows" role="tab"
                                aria-selected="false">
                                All
                            </a>
                        </li>
                        <li class="nav-item fs-5">
                            <a class="nav-link" data-bs-toggle="tab" href="#pendingworkflows" role="tab"
                                aria-selected="false">
                                Pending
                            </a>
                        </li>
                    </ul>
                    <hr />
                    <div class="tab-content">
                        <div class="tab-pane active" id="allworkflows" role="tabpanel">
                            <table id="manage-workflow" data-control-id='manage-workflow'
                                class="table datatable table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Approvers</th>
                                        <th>Approval Limit</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of corporateCustomerWorkflows; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>
                                            <div>{{ (item?.date | date) || '-' }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item?.name }}</div>
                                        </td>
                                        <td>{{ item?.noOfAuthorizers || 0 }}</td>
                                        <td>{{ (item?.approvalLimit || 0) | number }}</td>
                                        <td>{{ item?.description || '-' }}</td>
                                        <td>
                                            <i class="mdi mdi-check-bold fs-4 text-success pointer"
                                                *ngIf="item?.status == 1" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Approved"></i>
                                            <i class="mdi mdi-alert-circle-outline fs-4 text-warning pointer"
                                                *ngIf="item?.status == 0 || !item?.status" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Pending"></i>
                                            <i class="mdi mdi-alert-octagram fs-4 text-danger pointer"
                                                *ngIf="item?.status == 3" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Declined"></i>
                                            <i class="mdi mdi-alert-remove fs-4 text-danger pointer"
                                                *ngIf="item?.status < 0" data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Deactivated"></i>
                                            <i class="mdi mdi-pencil-plus fs-4 text-primary pointer"
                                                *ngIf="item?.status == 2" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Being updated"></i>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                                <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                    id="defaultDropdown" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true" aria-expanded="false">
                                                    <i class="ri-menu-2-line"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="config.currentUserHasPermissionByKey('UpdateWorkflow')"
                                                            (click)="initiateCreateOrUpdateCorporateWorkflow(false, item,'all')">Edit</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            (click)="initiateCorporateWorkflowAuthorizersManage(item,'all')">Authorizers</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status == 3 && config.currentUserHasPermissionByKey('RequestWorkflowApproval')"
                                                            (click)="initiateCorporateWorkflowApprovalRequest(item)">Request
                                                            Approval</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-pane" id="pendingworkflows" role="tabpanel">
                            <table id="manage-workflow-pending" data-control-id='manage-workflow-pending'
                                class="table datatable table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Approvers</th>
                                        <th>Approval Limit</th>
                                        <th>Description</th>
                                        <th>Initiator</th>
                                        <th>Action</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of corporateCustomerWorkflowsPending; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>
                                            <div>{{ (item?.date | date) || '-' }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item?.name }}</div>
                                        </td>
                                        <td>{{ item?.noOfAuthorizers || 0 }}</td>
                                        <td>{{ (item?.approvalLimit || 0) | number }}</td>
                                        <td>{{ item?.description || '-' }}</td>
                                        <td>{{ item?.initiatorUsername || '-' }}</td>
                                        <td>{{ item?.action || '-' }}</td>
                                        <td>
                                            <i class="mdi mdi-check-bold fs-4 text-success pointer"
                                                *ngIf="item?.status == 1" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Approved"></i>
                                            <i class="mdi mdi-alert-circle-outline fs-4 text-warning pointer"
                                                *ngIf="item?.status == 0 || !item?.status" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Pending"></i>
                                            <i class="mdi mdi-alert-octagram fs-4 text-danger pointer"
                                                *ngIf="item?.status == 3" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Declined"></i>
                                            <i class="mdi mdi-alert-remove fs-4 text-danger pointer"
                                                *ngIf="item?.status < 0" data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Deactivated"></i>
                                            <i class="mdi mdi-pencil-plus fs-4 text-primary pointer"
                                                *ngIf="item?.status == 2" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Being updated"></i>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                                <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                    id="defaultDropdown" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true" aria-expanded="false">
                                                    <i class="ri-menu-2-line"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="config.currentUserHasPermissionByKey('UpdateWorkflow')"
                                                            (click)="initiateCreateOrUpdateCorporateWorkflow(false, item,'pending')">Edit</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            (click)="initiateCorporateWorkflowAuthorizersManage(item,'pending')">Authorizers</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status == 2 && config.currentUserHasPermissionByKey('RequestWorkflowApproval')"
                                                            (click)="initiateCorporateWorkflowApprovalRequest(item)">Request
                                                            Approval</a></li>
                                                    <!-- <li><a class="dropdown-item pointer"
                                                            *ngIf="(item?.status == 0 || !item?.status) && config.currentUserHasPermissionByKey('ApproveWorkflow')"
                                                            (click)="initiateCorporateWorkflowApprove(item)">Approve</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="(item?.status == 0 || !item?.status) && config.currentUserHasPermissionByKey('DeclineWorkflow')"
                                                            (click)="initiateCorporateWorkflowDecline(item)">Decline</a>
                                                    </li> -->
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Warning Alert -->
                    <div class="d-flex" *ngIf="!config.currentUserHasPermissionByKey('ViewWorkflow')"
                        [innerHtml]="config.currentUserUnauthorizationContentUi()"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex" *ngIf="!config.currentUserHasPermissionByKey('ViewWorkflow')"
[innerHtml]="config.currentUserUnauthorizationContentUi()">
</div>
<!-- create workflow -->
<div class="modal fade" id="modal-new-corporate-workflow" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    {{ workflowOperationStatusCreate ? 'Create' : 'Update' }} Workflow
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formCreateCorporateWorkflow" class="w-100 needs-validation"
                        (ngSubmit)="createOrUpdateCorporateWorkflow($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="name" required
                                        placeholder="Enter Workflow Account">
                                    <label for="emailfloatingInput">Workflow Name</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="number" class="form-control" formControlName="noOfAuthorizers"
                                        placeholder="Enter No. of Authorizers">
                                    <label>No. of Authorizers</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="approvalLimit"
                                        placeholder="Enter Approval Limit">
                                    <label>Approval Limit</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <textarea class="form-control h-auto" rows="5"
                                        formControlName="description"></textarea>
                                    <label>Workflow Description</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- workflow authorizers -->
<div class="modal fade" id="modal-manage-workflow-authorizers" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Manage Workflow Authorizers
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form class="w-100">
                        <div class="row">
                            <!-- <div class="col-lg-12 my-5">
                              <div class="d-flex">
                                  <div class="flex-shrink-0 d-flex align-items-center fw-semibold">Add Authorizers</div>
                                  <div class="flex-grow-1 px-3 d-flex align-items-center"><hr class="w-100" /></div>
                                  <div class="flex-shrink-0">
                                      <button type="button" class="btn btn-warning" (click)="addNewAuthorizer()"><i class="bx bx-plus"></i> Add Level</button>
                                  </div>
                              </div>
                          </div> -->
                            <div *ngFor="let item of authorizers; let i = index" class="row mb-4">
                                <div class="col-lg-8">
                                    <div class="form-floating">
                                        <select class="form-select"
                                            (change)="detectAndGrabValue('approver', $event.target, i)">
                                            <option value="">Select Approver</option>
                                            <option *ngFor="let approver of corporateUsersForWorkflow"
                                                [selected]="item?.approverID == approver.id"
                                                [attr.approverName]="approver.firstName + ' ' + approver.middleName + ' ' + approver.lastName"
                                                [value]="approver.id">{{ approver.firstName }} {{
                                                approver.middleName }} {{ approver.lastName }} || {{approver.userRoleName }}</option>
                                        </select>
                                        <label>Approver {{ i + 1 }}</label>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-floating">
                                        <select class="form-select"
                                            (change)="detectAndGrabValue('level', $event.target, i)">
                                            <option value="">Select Level</option>
                                            <option *ngFor="let level of workflowAuthorizationLevels"
                                                [selected]="item?.authorizationLevel == level" [value]="level">Level
                                                {{ level }}</option>
                                        </select>
                                        <label>Level</label>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-1 d-flex align-items-center text-center">
                                  <i class="bx bx-trash text-danger fs-2 m-auto pointer" *ngIf="authorizers.length > 1" (click)="removeAuthorizerRow(i)"></i>
                              </div> -->
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50"
                                        (click)="createWorkflowHierarchy()">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- decline workflow -->
<div class="modal fade" id="modal-user-workflow-decline-reason" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formDeclineCorporateWorkflowReason">
                    <div class="fw-semibold fs-3">Decline Workflow</div>
                    <div class="fs-6 mt-4">You are about to decline this workflow, Please provide your reason.</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="reason"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Enter your reason for declining this workflow </label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="submit" class="btn btn-main btn-lg w-50"
                                (click)="declineCorporateWorkflow()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>