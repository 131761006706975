import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CurrencyPipe } from '@angular/common';

// define template
let template = 'change-pin.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})

export class ChangePinComponent implements OnInit {


  formBankUserResetPin: FormGroup
  firstTimeProcessStatus: boolean
  customerId: string = ""
  username: string = ""

  constructor(
    private ui: UiService,
    private logger: LogService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.customerId = store.get('customerId')
		this.username = store.get('customerIdentity')


  }

  ngOnInit(): void {
    // toggle password
    this.togglePassword()

    // define form
    this.formBankUserResetPin = this.fb.group({
      UserName: [this.username, Validators.required],
      Password: ['', Validators.required],
      defaultPin: ['', [Validators.required, Validators.maxLength(4)]],
      newPin: ['', [Validators.required, Validators.maxLength(4)]],
      ConfirmPin: ['', [Validators.required, Validators.maxLength(4)]],
    })
  }

  replaceLettersWithSpace(event: any, key:string) {
    const inputElement = event.target;
    const inputValue = inputElement.value;

    // Replace letters with spaces using a regular expression
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    // Update the input value
    inputElement.value = sanitizedValue;

    // Update the model variable
      this.formBankUserResetPin.patchValue({
        [key]: sanitizedValue
      })
  }


 async resetUserPin(target: any) {
  // get form
  let form = find(target)

  // define payload
  let payload = {
    ...this.formBankUserResetPin.value,
  }

  // check if form is valid
  if (!payload.UserName.length
    || !payload.defaultPin.length
    || !payload.newPin.length
    || !payload.ConfirmPin.length
    || ((new RegExp(/(\s)+/)).test(payload.newPin))
    || (!(new RegExp(/[0-9]+/)).test(payload.newPin))
    || payload.newPin.length != 4) {
    // warning
    if (!payload.UserName.length) this.ui.warning(`Please provide your username.`)
    else if (!payload.newPin.length) this.ui.warning(`Please provide your transaction pin.`)
    else if (payload.newPin.length != 4) this.ui.warning(`Please pin must be 4 digits.`)
    else if ((new RegExp(/(\s)+/)).test(payload.newPin)) this.ui.warning(`Pin must not contain a space.`)
    else if (!(new RegExp(/[0-9]+/)).test(payload.newPin)) this.ui.warning(`Pin must be digits only.`)
    else this.ui.warning(`Please re-enter your new pin.`)
    // flag errors
    // Rogue.Form.flag(form)
    return
  }

  // check if passwords match
  if (payload.newPin != payload.ConfirmPin) {
    // warning
    this.ui.warning(`Pin do not match!`)
    // flag errors
    Rogue.Form.flag(form)
    return
  }

  // encrypt payload
  payload = await store.preparePayload(payload)

  // change status flag
  this.firstTimeProcessStatus = true

  // network
  this.http.post<any>(config.auth + routes.authentication.changePin, payload, config.httpOptions())
    .subscribe({
      next: (data: any) => {
        // check for status
        if (data.responsecode == 16) {
          // set status
          store.set('securityQuestionNoAvailable', '1')
          store.set('securityQuestionNoAvailableUsername', payload.UserName)
          // store.set('securityQuestionNoAvailableCIB', payload.CustomerId)
          // navigate
          this.router.navigate(['/user/security-question'])
          // error
          this.ui.info(data.responseDescription || `You're required to set security questions.`)
        } else if (data.responseCode != 16) {
          // redirect
          this.router.navigate(['/user/login'])
          // notification
          this.ui.info(data.message || `Pin change was successful! `)
        } else {
          // error
          this.ui.error(data.responseDescription)
        }
      },
      error: e => {
        // error
        this.ui.error(e)
      }
    }).add(() => this.firstTimeProcessStatus = false)
}

  // toggle password
  togglePassword() {
    // timeout
    setTimeout(() => {
      Array.from(document.querySelectorAll("#toggle-password")).forEach(el => {
        el.addEventListener('click', function (e: any) {
          var x = this.previousSibling;
          if (x.type === "password") {
            x.type = "text"
          } else {
            x.type = "password"
          }
        })
      })
    }, 10)
  }
}