import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CookieService } from 'ngx-cookie-service';

// define template
let template = 'login.component'

declare var $: any

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class LoginComponent implements OnInit {
  formBankUserLogin: FormGroup
  loginProcessStatus: boolean
  slider: number = 1
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private ui: UiService,
    private router: Router,
    private logger: LogService,
    private cookieService: CookieService) {
  }

  ngOnInit() {

    // toggle password
    this.togglePassword()

    const storedUsername = store.decrypt(this.cookieService.get('username')) || '';
    // const storedPassword = store.decrypt(this.cookieService.get('password')) || '';
    // const storedCustId = store.decrypt(this.cookieService.get('customerId')) || '';
    const rememberMe = store.decrypt(this.cookieService.get('rememberMe')) || false;

    // define form
    this.formBankUserLogin = this.fb.group({
      username: [storedUsername, Validators.required],
      password: ['', Validators.required],
      // customerID: [storedCustId, Validators.required],
      // otp: ['', Validators.required],
      rememberMe: [Boolean(rememberMe)]
    })

    const s = setInterval(() => {
      if (this.slider <= 3 && this.slider > 0) {
        this.slider += 1
      } else {
        this.slider = 1
      }
    }, 7000)
    // clearInterval(s)
    // check for session timeout
    this.checkForSessionTimeoutAndShowAlert()

    // $('.carousel').carousel({
    //   interval: 2000
    // })
  }



  // sign in bank user
  async signInBankUser(target: any) {
    // get form
    let form = find(target)

    // define payload
    // let payload = {
    //     ...this.formBankUserLogin.value
    // }
    let payload = {
      ...this.formBankUserLogin.value,
      username: this.formBankUserLogin.value.username.replaceAll(' ', ''),
      // customerID: this.formBankUserLogin.value.username.replaceAll(' ', ''),
      password: this.formBankUserLogin.value.password.replaceAll(' ', ''),
      // otp: this.formBankUserLogin.value.otp.replaceAll(' ', '')
    }


    // check if form is valid
    if (!payload.username.length || !payload.password.length || payload.password.length < 8) {
      if (payload.password.length < 8) this.ui.warning(`Password is a minimum of 8 characters.`)
      // warning
      if (!payload.username.length) this.ui.warning(`Please provide your username.`)
      // else if (!payload.customerID.length) this.ui.warning(`Please provide your corporate id.`)
      // else if (!payload.otp.length) this.ui.warning(`Please provide your otp.`)
      else this.ui.warning(`Please provide your password.`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }
    // change status flag
    this.loginProcessStatus = true

    payload = await store.preparePayload({
      ...this.formBankUserLogin.value
    })

    // network
    this.http.post<any>(config.auth + routes.authentication.loginMain, payload, config.httpOptionsNoAuth())
      .subscribe({
        next: (data: any) => {


          // if success
          if (data['responsecode'] == '00') {

            // clear store
            store.clear()
            this.cookieService.deleteAll()

            // store login credentials using cookies
            if (this.formBankUserLogin.value.rememberMe) {
              this.cookieService.set('username', payload.username);
              // this.cookieService.set('password', payload.password);
              // this.cookieService.set('customerId', payload.customerID);
              this.cookieService.set('rememberMe', payload.rememberMe);
            }
            this.formBankUserLogin.reset()

            // load permission keys into memory
            config.loadPermissionKeysIntoMemory()

            // save data
            store.set('hasSignPrivacyPolicy', data?.hasSignPrivacyPolicy)
            store.set('userId', data?.userId)
            store.set('token', data?.access_token)
            store.set('bvn', data?.bvn)
            store.set('companyName', data?.companyName)
            store.set('customerId', data?.customerID)
            store.set('corporateCustomerId', data?.corporateCustomerId)
            store.set('customerIdentity', data?.customerIdentity)
            store.set('authorizationType', data?.authorizationType)
            store.set('defaultAccountBalance', data?.defaultAccountBalance)
            store.set('defaultAccountName', data?.defaultAccountName)
            store.set('defaultAccountNumber', data?.defaultAccountNumber)
            store.set('expires_in', data?.expires_in)
            store.set('indemnitySigned', data?.indemnitySigned)
            store.set('lastLoginDate', data?.lastLoginDate)
            store.set('phone', data?.phone)
            store.set('regStage', data?.regStage)
            store.set('branchId', data?.branchId)
            store.set('roleId', data?.roleId)
            store.set('role', data?.role)
            store.set('securityQuestion', data?.securityQuestion)
            store.set('tokenType', data?.token_type)
            store.set('userPasswordChanged', data?.userpasswordChanged)
            store.set('status', data?.status)
            store.set('permissions', JSON.stringify(data?.permissions))
            store.set('permissionsIds', JSON.stringify((data.permissions || []).map(item => item?.id)))
            store.set('permissionsCodes', JSON.stringify((data.permissions || []).map(item => item?.code)))

            if (data.hasSignPrivacyPolicy == '0') {
              modal.show('#modal-disclaimer')
            } else {
              // info
              this.ui.info(`Signed in... redirecting`)
              // timeout -> redirect
              setTimeout(() => window.location.href = '/user/dashboard', 2000)
            }


          } else {
            // check for status
            if (data.responsecode == 14) {
              // set status
              store.set('firstTimeLoginStatus', '1')
              store.set('firstTimeLoginUsername', payload.username)
              store.set('firstTimeLoginCIB', payload.customerID)
              // navigate
              this.router.navigate(['/user/change-default-password'])
              // error
              this.ui.info(data.responseDescription)
            } else if (data.responsecode == 15) {
              // set status
              store.set('resetPasswordStatus', '1')
              store.set('firstTimeLoginUsername', payload.username)
              store.set('firstTimeLoginCIB', payload.customerID)
              // navigate
              this.router.navigate(['/user/change-default-password'])
              // error
              this.ui.info(data.responseDescription)
            } else if (data.responsecode == 16) {
              // set status
              store.set('securityQuestionNoAvailable', '1')
              store.set('securityQuestionNoAvailableUsername', payload.username)
              store.set('securityQuestionNoAvailableCIB', payload.customerID)

              // navigate
              this.router.navigate(['/user/security-question'])
              // error
              this.ui.info(data.responseDescription)
            } else if (data.responsecode == 20) {
              store.set('resetPin', '1');
              store.set('resetPinUsername', payload.username);
              store.set('resetPinPassword', payload.password);
              this.router.navigate(['/user/change-default-password'])
            } else {
              // error
              this.ui.error(data.responseDescription)
              store.set('securityQuestionNoAvailableUsername', payload.username)

            }
          }
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => this.loginProcessStatus = false)
  }


  async removeDisclaimerModal() {
    this.ui.blockUiOn(`Signing Agreement... please wait`)

    const payload = await store.preparePayload({})

    this.http.post<any>(config.auth + routes.authentication.signPolicy, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          this.ui.success('You have successfully agreed to our terms')
          // info
          this.ui.info(`Signed in... redirecting`)
          setTimeout(() => window.location.href = '/user/dashboard', 2000)


        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => {
        modal.hide('#modal-disclaimer')
        store.remove('firstTimeLoginStatus')
        this.ui.blockUiOff()
      })

  }

  // toggle password
  togglePassword() {
    // timeout
    setTimeout(() => {
      Array.from(document.querySelectorAll("#toggle-password")).forEach(el => {
        el.addEventListener('click', function (e: any) {
          var x = this.previousSibling;
          if (x.type === "password") {
            x.type = "text"
          } else {
            x.type = "password"
          }
        })
      })
    }, 10)
  }


  // check for session timeout and show alert
  checkForSessionTimeoutAndShowAlert() {
    // check if flag is available
    if (sessionStorage.getItem(config.timeoutSessionFlag)) {
      // flush flag
      sessionStorage.removeItem(config.timeoutSessionFlag)

      // info
      this.ui.warning(`Your session timed out and you were signed out.`)

      // modal
      setTimeout(() => modal.show(`#modal-session-timeout-message`), 1000)
    }
  }

  // remove modal for session timeout
  removeSessionTimeoutModal() {
    // remove modal
    modal.hide('.modal')
    // remove overlays
    find(`.modal-backdrop`).remove()
  }
}