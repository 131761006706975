import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CurrencyPipe } from '@angular/common';

// define template
let template = 'first-password-change.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class FirstTimePasswordChangeComponent implements OnInit {
  formBankUserFirstTimeLogin: FormGroup
  formBankUserResetPin: FormGroup
  firstTimeProcessStatus: boolean;
  firstTimeProcessUsername: string
  firstTimeProcessCIB: string
  firstTimeProcessPIN: boolean = true
  resetPasswordStatus: string
  resetPinStatus: string
  resetPinUsername: string
  resetPinPassword: string


  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private ui: UiService,
    private router: Router,
    private logger: LogService,
    private currencyPipe: CurrencyPipe) {
  }

  ngOnInit() {
    // get username
    this.firstTimeProcessUsername = store.decrypt(store.get('firstTimeLoginUsername'))
    this.firstTimeProcessCIB = store.decrypt(store.get('firstTimeLoginCIB'))

    this.resetPasswordStatus = (store.get('resetPasswordStatus'));

    this.resetPinStatus = (store.get('resetPin'));
    this.resetPinUsername = store.decrypt(store.get('resetPinUsername'));
    this.resetPinPassword = store.decrypt(store.get('resetPinPassword'));

    // check for status
    if (store.has('firstTimeLoginStatus') || store.has('resetPinStatus')) {
      // remove flag
      store.remove('firstTimeLoginUsername')
      store.remove('firstTimeLoginCIB')
    } else {
      // redirect
      // this.router.navigate(['/user/login'])
      // return
    }

    // toggle password
    this.togglePassword()

    // define form
    this.formBankUserFirstTimeLogin = this.fb.group({
      UserName: [this.firstTimeProcessUsername, Validators.required],
      // CustomerId: [this.firstTimeProcessCIB, Validators.required],
      CurrentPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      ConfirmNewPassword: ['', Validators.required],
      newPin: ['', [Validators.maxLength(4)]],
      ConfirmPin: ['', [Validators.maxLength(4)]],
    })


    // define form
    this.formBankUserResetPin = this.fb.group({
      UserName: [this.resetPinUsername, Validators.required],
      Password: [this.resetPinPassword, Validators.required],
      defaultPin: ['', [Validators.required, Validators.maxLength(4)]],
      newPin: ['', [Validators.required, Validators.maxLength(4)]],
      ConfirmPin: ['', [Validators.required, Validators.maxLength(4)]],
    })
  }

  replaceLettersWithSpace(event: any, key: string, t: boolean) {
    const inputElement = event.target;
    const inputValue = inputElement.value;

    // Replace letters with spaces using a regular expression
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    // Update the input value
    inputElement.value = sanitizedValue;

    // Update the model variable
    if (t) {
      this.formBankUserFirstTimeLogin.patchValue({
        [key]: sanitizedValue
      })
    } else {
      this.formBankUserResetPin.patchValue({
        [key]: sanitizedValue
      })
    }

  }

  // change password
  async changeDefaultPassword(target: any) {
    // get form
    let form = find(target)

    // define payload
    let payload = {
      ...this.formBankUserFirstTimeLogin.value
    }

    // check if form is valid
    if (!payload.UserName.length
      // || !payload.CustomerId.length
      || !payload.CurrentPassword.length
      || !payload.NewPassword.length
      || !payload.ConfirmNewPassword.length
      || (this.resetPasswordStatus != '1' && !payload.newPin.length)
      || (this.resetPasswordStatus != '1' && !payload.ConfirmPin.length)
      || ((new RegExp(/(\s)+/)).test(payload.NewPassword))
      || (!(new RegExp(/[^a-z0-9]+/i)).test(payload.NewPassword))
      || payload.NewPassword.length < 8
      || (this.resetPasswordStatus != '1' && (new RegExp(/(\s)+/)).test(payload.newPin))
      || (this.resetPasswordStatus != '1' && !(new RegExp(/[0-9]+/)).test(payload.newPin))
      || (this.resetPasswordStatus != '1' && payload.newPin.length != 4)
      || (this.resetPasswordStatus != '1' && payload.ConfirmPin.length != 4)) {
      // warning
      if (!payload.UserName.length) this.ui.warning(`Please provide your username.`)
      else if (!payload.CurrentPassword.length) this.ui.warning(`Please provide your current password.`)
      else if (!payload.NewPassword.length) this.ui.warning(`Please provide your new password.`)
      else if (this.resetPasswordStatus != '1' && !payload.newPin.length) this.ui.warning(`Please provide your transaction pin.`)
      else if (this.resetPasswordStatus != '1' && payload.newPin.length != 4) this.ui.warning(`Please pin must be 4 digits.`)
      else if (this.resetPasswordStatus != '1' && payload.ConfirmPin.length != 4) this.ui.warning(`Please pin confirmation must be 4 digits.`)
      else if ((new RegExp(/(\s)+/)).test(payload.NewPassword)) this.ui.warning(`Password must contain a space.`)
      else if (!(new RegExp(/[^a-z0-9]+/i)).test(payload.NewPassword)) this.ui.warning(`Password must contain at least one SPECIAL character.`)
      else if (this.resetPasswordStatus != '1' && (new RegExp(/(\s)+/)).test(payload.newPin)) this.ui.warning(`Pin must not contain a space.`)
      else if (this.resetPasswordStatus != '1' && !(new RegExp(/[0-9]+/)).test(payload.newPin)) this.ui.warning(`Pin must be digits only.`)
      else this.ui.warning(`Please re-enter your new password and pin.`)
      if (payload.NewPassword.length < 8) this.ui.warning(`Password must be a minimum of 8 characters.`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // check if passwords match
    if (payload.NewPassword != payload.ConfirmNewPassword) {
      // warning
      this.ui.warning(`Passwords do not match!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // check if passwords match
    if (this.resetPasswordStatus != '1' && (payload.newPin != payload.ConfirmPin)) {
      // warning
      this.ui.warning(`Pin do not match!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // encrypt payload
    payload = {
      ...this.formBankUserFirstTimeLogin.value,
      responseCode: this.resetPasswordStatus != '1' ? 14 : 15,
      newPin: this.resetPasswordStatus != '1' ? this.formBankUserFirstTimeLogin.value.newPin : undefined,
      ConfirmPin: this.resetPasswordStatus != '1' ? this.formBankUserFirstTimeLogin.value.ConfirmPin : undefined
    }
    payload = await store.preparePayload(payload)

    // change status flag
    this.firstTimeProcessStatus = true

    // network
    this.http.post<any>(config.auth + routes.authentication.firstLoginPasswordChange, payload, config.httpOptionsNoAuth())
      .subscribe({
        next: (data: any) => {
          // check for status
          if (data.responsecode == 16) {
            // set status
            store.set('securityQuestionNoAvailable', '1')
            store.set('securityQuestionNoAvailableUsername', payload.UserName)
            // store.set('securityQuestionNoAvailableCIB', payload.CustomerId)
            // navigate
            this.router.navigate(['/user/security-question'])
            // error
            this.ui.info(data.responseDescription || `You're required to set security questions.`)
          } else if (data.responseCode != 16) {
            // redirect
            this.router.navigate(['/user/login'])
            // notification
            this.ui.info(data.message || `Password change was successful! You can now sign in with your new password.`)
          } else {
            // error
            this.ui.error(data.responseDescription)
          }
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => this.firstTimeProcessStatus = false)
  }

  // change password
  async resetUserPin(target: any) {
    // get form
    let form = find(target)

    // define payload
    let payload = {
      ...this.formBankUserResetPin.value,
    }

    // check if form is valid
    if (!payload.UserName.length
      || !payload.defaultPin.length
      || !payload.newPin.length
      || !payload.ConfirmPin.length
      || ((new RegExp(/(\s)+/)).test(payload.newPin))
      || (!(new RegExp(/[0-9]+/)).test(payload.newPin))
      || payload.newPin.length != 4) {
      // warning
      if (!payload.UserName.length) this.ui.warning(`Please provide your username.`)
      else if (!payload.newPin.length) this.ui.warning(`Please provide your transaction pin.`)
      else if (payload.newPin.length != 4) this.ui.warning(`Please pin must be 4 digits.`)
      else if (payload.ConfirmPin.length != 4) this.ui.warning(`Please pin confirmation must be 4 digits.`)
      else if ((new RegExp(/(\s)+/)).test(payload.newPin)) this.ui.warning(`Pin must not contain a space.`)
      else if (!(new RegExp(/[0-9]+/)).test(payload.newPin)) this.ui.warning(`Pin must be digits only.`)
      else this.ui.warning(`Please re-enter your new pin.`)
      // flag errors
      // Rogue.Form.flag(form)
      return
    }

    // check if passwords match
    if (payload.newPin != payload.ConfirmPin) {
      // warning
      this.ui.warning(`Pin do not match!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // encrypt payload
    payload = await store.preparePayload(payload)

    // change status flag
    this.firstTimeProcessStatus = true

    // network
    this.http.post<any>(config.auth + routes.authentication.changePin, payload, config.httpOptionsNoAuth())
      .subscribe({
        next: (data: any) => {
          // check for status
          if (data.responsecode == 16) {
            // set status
            store.set('securityQuestionNoAvailable', '1')
            store.set('securityQuestionNoAvailableUsername', payload.UserName)
            // store.set('securityQuestionNoAvailableCIB', payload.CustomerId)
            // navigate
            this.router.navigate(['/user/security-question'])
            // error
            this.ui.info(data.responseDescription || `You're required to set security questions.`)
          } else if (data.responseCode != 16) {
            // redirect
            this.router.navigate(['/user/login'])
            // notification
            this.ui.info(data.message || `Pin change was successful! `)
          } else {
            // error
            this.ui.error(data.responseDescription)
          }
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => this.firstTimeProcessStatus = false)
  }
  // toggle password
  togglePassword() {
    // timeout
    setTimeout(() => {
      Array.from(document.querySelectorAll("#toggle-password")).forEach(el => {
        el.addEventListener('click', function (e: any) {
          var x = this.previousSibling;
          if (x.type === "password") {
            x.type = "text"
          } else {
            x.type = "password"
          }
        })
      })
    }, 10)
  }
}