<div class="scrollable-content row" *ngIf="(currentUser != users.sole || !config.checkIfNotSingleSignatory(authorizationType))">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">Account Statement</h4>
                <div class="flex-shrink-0">
                    <div class="btn-group" *ngIf="statement?.statement">
                        <button class="btn btn-main dropdown-toggle" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            <i class="mdi mdi-filter-menu-outline"></i> Filter
                        </button>
                        <div class="dropdown-menu dropdown-menu-md p-4">
                            <form [formGroup]="formFilter" (ngSubmit)="filterRecords($event.target)">
                                <div class="mb-2">
                                    <label class="form-label">Account</label>
                                    <select class="form-control" formControlName="accountNumber"
                                        (change)="getSelectedAccountInformation($event.target)">
                                        <option *ngIf="!accounts.length" value="">Select Account</option>
                                        <option *ngFor="let item of accounts" [value]="item?.accountNumber">{{
                                            item?.accountNumber }}</option>
                                    </select>
                                </div>
                                <div class="mb-2">
                                    <label class="form-label">Period</label>
                                    <select class="form-control" formControlName="period">
                                        <option value="">Select Period</option>
                                        <option value="This Week">This Week</option>
                                        <option value="Last Week">Last Week</option>
                                        <option value="Last Month">Last Month</option>
                                        <option value="This Month">This Month</option>
                                        <option value="30 days">30 days</option>
                                        <option value="60 days">60 days</option>
                                        <option value="90 days">90 days</option>
                                        <option value="Specify Period">Specify Period</option>
                                    </select>
                                </div>
                                <div class="mb-2" *ngIf="formFilter.value.period == 'Specify Period'">
                                    <label class="form-label">Start Date</label>
                                    <input type="date" formControlName="startDate" class="form-control"
                                        placeholder="Start Date">
                                </div>
                                <div class="mb-2" *ngIf="formFilter.value.period == 'Specify Period'">
                                    <label class="form-label">End Date</label>
                                    <input type="date" formControlName="endDate" class="form-control"
                                        placeholder="End Date">
                                </div>
                                <button type="submit" class="btn btn-main mt-2">Continue</button>
                            </form>
                        </div>
                    </div>

                    <div *ngIf="statement?.statement && (currentUser != users.sole || !config.checkIfNotSingleSignatory(authorizationType))"
                        class="btn-group ml-3">
                        <button class="btn btn-sec dropdown-toggle" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            <i class="bx bx-download mr-1"></i> Download As <i class="bx bx-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                            <li><a class="dropdown-item pointer" (click)="downloadAccountStatementAsExcel()">Spreadsheet
                                    (XLSX)</a></li>
                            <li><a class="dropdown-item pointer" (click)="downloadAccountStatementAsPDF()">PDF</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row" *ngIf="statement.accountNumber">
                    <div class="col-lg-3">
                        <div class="card p-2">
                            <h5>Account Number</h5>
                            <p class="fs-6">{{statement.accountNumber}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-2">
                            <h5>Account Type</h5>
                            <p class="fs-6">{{statement.accountType}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-2">
                            <h5>Opening Balance</h5>
                            <p class="fs-6">₦{{statement.openingBal}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-2">
                            <h5>Closing Balance</h5>
                            <p class="fs-6">{{(statement?.closingBal || '0.00') | currency : '₦'}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-2">
                            <h5>Total Credit</h5>
                            <p class="fs-6">₦{{statement.totalCredit}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-2">
                            <h5>Total Debit</h5>
                            <p class="fs-6">₦{{statement.totalDebit}}</p>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div *ngIf="statement?.statement"
                        class="custom-datatable transaction-history-table- transaction-history-records w-100">

                        <table data-control-id='transaction-account-statement'
                            class="table datatable table-striped table-bordered " style="width:100%">
                            <thead>
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Value Date</th>
                                    <th>Narration</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of statement.statement">
                                    <td>{{ item?.transdate }}</td>
                                    <td>{{ item?.valuedate }}</td>
                                    <td>{{ item?.remarks || '-' }}</td>
                                    <td>{{ item?.credit | number }}</td>
                                    <td>{{ item?.debit | number }}</td>
                                    <td>{{ item?.balance | number }}</td>

                                </tr>
                            </tbody>
                        </table>
                        <!-- for exporting to excel -->
                        <table style="display: none;" id="transaction-account-statement">
                            <thead>
                                <tr>
                                    <th>Transaction Date</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Balance</th>
                                    <th>Remarks</th>
                                    <th>Value Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of statement.statement">
                                    <td>{{ item?.transdate }}</td>
                                    <td>{{ item?.credit | number }}</td>
                                    <td>{{ item?.debit | number }}</td>
                                    <td>{{ item?.balance | number }}</td>
                                    <td>{{ item?.remarks || '-' }}</td>
                                    <td>{{ item?.valuedate }}</td>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div *ngIf="!statement?.statement" class="text-center w-100 mt-20">
                        <div>
                            <!-- <h2 class="text-center text-secondary mb-2">Nothing to see </h2> -->
                            <h4 class="text-center text-secondary mb-4"> Filter to generate statement.</h4>
                            <form [formGroup]="formFilter" (ngSubmit)="filterRecords($event.target)" style="width: 300px; text-align: left;margin: auto;">
                                <div class="mb-2">
                                    <label class="form-label">Account</label>
                                    <select class="form-control" formControlName="accountNumber"
                                        (change)="getSelectedAccountInformation($event.target)">
                                        <option *ngIf="!accounts.length" value="">Select Account</option>
                                        <option *ngFor="let item of accounts" [value]="item?.accountNumber">{{
                                            item?.accountNumber }}</option>
                                    </select>
                                </div>
                                <div class="mb-2">
                                    <label class="form-label">Period</label>
                                    <select class="form-control" formControlName="period">
                                        <option value="">Select Period</option>
                                        <option value="This Week">This Week</option>
                                        <option value="Last Week">Last Week</option>
                                        <option value="Last Month">Last Month</option>
                                        <option value="This Month">This Month</option>
                                        <option value="30 days">30 days</option>
                                        <option value="60 days">60 days</option>
                                        <option value="90 days">90 days</option>
                                        <option value="Specify Period">Specify Period</option>
                                    </select>
                                </div>
                                <div class="mb-2" *ngIf="formFilter.value.period == 'Specify Period'">
                                    <label class="form-label">Start Date</label>
                                    <input type="date" formControlName="startDate" class="form-control"
                                        placeholder="Start Date">
                                </div>
                                <div class="mb-2" *ngIf="formFilter.value.period == 'Specify Period'">
                                    <label class="form-label">End Date</label>
                                    <input type="date" formControlName="endDate" class="form-control"
                                        placeholder="End Date">
                                </div>
                                <button type="submit" class="btn btn-main mt-2 " style="width: 100%;">Continue</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- modal / account statement -->
<div class="modal fade" id="modal-account-statement" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
    <!--modal content-->
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title uppercase" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="height: auto;">
                <style>
                    .blue-bar {
                        display: flex;
                        background-color: #2E2DAD;
                    }

                    .blue-bar hr {
                        width: 100%;
                        background-color: #2E2DAD;
                        margin: 0;
                    }

                    .text-center {
                        text-align: center;
                    }

                    .font-bold {
                        font-weight: bold;
                    }

                    .font-bolder {
                        font-weight: bolder;
                    }

                    .uppercase {
                        text-transform: uppercase;
                    }

                    #statement-container table {
                        border-collapse: separate;
                    }

                    #statement-container div {
                        margin-bottom: 20px;
                    }

                    .royal-blue {
                        color: #1e0089;
                    }

                    table.statement td {
                        padding: 10px 0;
                        width: 10%;
                    }

                    /* table.statement td:last-child {
                        width: 20%;
                    }
                    table.statement td:nth-child(3) {
                        width: 35%;
                        word-break: break-word;
                    } */
                    table.statement thead td {
                        background: #2f444e;
                        padding: 7px;
                        color: #39bac2;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 10px;
                    }

                    table.statement tbody tr:nth-child(odd) td {
                        /* background: #dddddd44; */
                    }

                    table.statement tbody tr td {
                        padding: 7px;
                        font-size: 10px;
                    }

                    .biodata table tbody tr td:first-child {
                        padding: 6px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 11px;
                        width: 40%;
                    }

                    .biodata table tbody tr td:last-child {
                        /* background: #d9dddf44; */
                        padding: 6px;
                        font-size: 11px;
                    }

                    .biodata table tbody tr td {
                        font-size: 11px !important;
                    }

                    tr.table-left {
                        color: white;
                        background-color: transparent;
                    }

                    tr.table-left td {
                        color: white;
                        background-color: transparent !important;
                    }

                    .golden-bar {
                        border: 7px solid #cd9a18 !important;
                        margin-bottom: 0 !important;

                    }

                    .s-table tr td {
                        border-bottom: 1px solid rgb(195, 195, 195);
                    }

                    .r-table tr td {
                        border-bottom: 1px solid #cd9a18;
                    }

                    .s-table tr td:nth-child(2) {
                        position: relative;
                        left: -2px;
                    }

                    .s-table tr:nth-child(odd) {
                        background: #fff
                    }

                    .s-table tr:nth-child(even) {
                        background: #eeeeee
                    }
                </style>

                <div id="statement-container"
                    style="padding: 5px; position: relative; max-width: 1200px; margin: auto;">
                    <div class="golden-bar"></div>
                    <div class="blue-bar">
                        <hr />
                        <img src="../../../../assets/images/main-logo.png" />
                        <!-- <img src="assets/images/main-logo.png" style="position: absolute; height: 50px; width: auto;" /><br/><br/> -->
                    </div>


                    <div class=" font-bold uppercase" style="color: #2e2dad; font-size: 28px; margin-top: 5px;">
                        {{statement.customerName}}</div>
                    <p>This is your account statement {{statement.period}}</p>
                    <div style="margin-top: 20px; margin-bottom: 5px; display: flex;" class="biodata">
                        <div style="flex-grow: 1; min-width: 50%; background-color: #2E2DAD;">
                            <table style="width: 100%;" class="r-table">
                                <tbody>
                                    <tr class="table-left">
                                        <td>Account Number : {{ statement?.accountNumber?.slice(0,3) }}****{{
                                            statement?.accountNumber?.slice(7) }}</td>
                                    </tr>
                                    <tr class="table-left">
                                        <td>Branch : {{ statement.branch }}</td>
                                    </tr>
                                    <tr class="table-left">
                                        <td>Address : {{ statement.address }}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div style="flex-grow: 1;">
                            <table style="width: 100%;" class="s-table">
                                <tbody>
                                    <tr>
                                        <td>Account Type</td>
                                        <td>{{ statement.accountType }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Credit</td>
                                        <td>₦{{statement.totalCredit}}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Debit</td>
                                        <td>₦{{statement.totalDebit}}</td>
                                    </tr>
                                    <tr>
                                        <td>Available Balance</td>
                                        <td>{{(statement?.availableBal || '0.00') | currency : '₦'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Opening Balance</td>
                                        <td>₦{{statement.openingBal}}</td>
                                    </tr>
                                    <tr>
                                        <td>Closing Balance</td>
                                        <td>{{(statement?.closingBal || '0.00') | currency : '₦'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Effective Balance</td>
                                        <td>{{(statement?.effectiveBal || '0.00') | currency : '₦'}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <table style="width: 100%;" class="statement s-table">
                            <thead>
                                <tr style="color: #2e2dad; font-weight: bold;">
                                    <th>Transaction Date</th>
                                    <th>Value Date</th>
                                    <th>Narration</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of statement.statement">
                                    <td>{{ item?.transdate }}</td>
                                    <td>{{ item?.valuedate }}</td>
                                    <td>{{ item?.remarks || '-' }}</td>
                                    <td>{{ item?.credit | number }}</td>
                                    <td>{{ item?.debit | number }}</td>
                                    <td>{{ item?.balance | number }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="margin-top: 60px; margin-bottom: 100px; color: #304450; font-weight: bold;">
                        <div class="font-bolder" style="font-size: 14px;">DISCLAIMER</div>
                        <div style="font-size: 11px; line-height: 17px;">
                            This is an automated transaction alert service. For enquiries, please call the Parallex

                            <br />
                            <br />
                            © 2022 Parallex
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Warning Alert -->
<div class="d-flex" *ngIf="(config.checkIfNotSingleSignatory(authorizationType) && currentUser == users.sole)"
    [innerHtml]="config.currentUserUnauthorizationContentUi()"></div>