import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AppRoutes } from '@app/app.routes';

import { config, find, store, routes, modal } from '@app/config';
import { LogService, TimeoutService, Rogue, UiService, ThemeService } from '@app/services';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

// feather icon handler
declare var feather: any

// define template
let template = 'layout.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class LayoutComponent implements OnInit {
  config = config
  currentRoute: string = ''
  firstTimeLogin: string
  hasSignPrivacyPolicy: any
  users: any = config.users
  authorizationType: string
  currentUser: any = {
    name: 'James Ayodele',
    role: ''
  }
  page: any = {
    context: 'Dashboard',
    sub: 'Overview'
  }
  currTheme: string

  constructor(private router: Router,
    private logger: LogService,
    private timeoutService: TimeoutService,
    private http: HttpClient,
    private ui: UiService,
    private theme: ThemeService) {
    // get user
    this.currentUser.name = store.get('customerIdentity')
    this.currentUser.role = store.get('role')
    this.authorizationType = store.get('authorizationType')
    this.currTheme = this.theme.currentTheme
    // get current url
    this.subscribeToRouterUrl()

    // log data
    store.log('token')
    // log data
    this.firstTimeLogin = JSON.parse(store.get('firstTimeLoginStatus') || '{}')
    this.hasSignPrivacyPolicy = JSON.parse(store.get('hasSignPrivacyPolicy') || '0')

    this.logger.log(JSON.parse(store.get('permissions') || '{}'))
    this.logger.log(JSON.parse(store.get('globalPermissionKeys') || '{}'))

    // check if production or development
    if (environment.production) {
      // start watching for inactivity
      this.timeoutService.start({
        timeoutInSeconds: 300,
        redirectUrl: '/user/login',
        router: this.router,
        actionIndicatorStoreName: config.timeoutSessionFlag
      })
    }
    Rogue.DetectMultiTabOpened.init(this.router)

  }

  toggleTheme() {
    this.theme.toggleTheme();
    this.currTheme = this.theme.currentTheme
  }

  // grab current url based on window location
  grabCurrentUrlByLocation() {
    // get url object
    let url = new URL(location.href);
    // get the current route
    this.currentRoute = url.pathname
    // process page context title based on route
    this.switchPageContext()
  }

  // watch router events
  subscribeToRouterUrl() {
    // pipe and filter navigation end event to get current route
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd | any) => {
        this.grabCurrentUrlByLocation()
      });
  }

  // process page context title based on route
  switchPageContext() {
    // get stripped route
    let stripped = this.currentRoute.replace(/(\/user(\/*))|(\/corporate\/)/, '');
    // switch case
    switch (stripped) {
      case AppRoutes.dashboard:
        // update
        this.setPageContext('Dashboard', 'Overview');
        break;
      case AppRoutes.transfers:
        // update
        this.setPageContext('Transfers', 'All Transfers');
        break;
      case AppRoutes.bulkPayment:
        // update
        this.setPageContext('Transfers', 'Bulk Payment');
        break;
      case AppRoutes.transactionHistory:
        // update
        this.setPageContext('Transactions', 'History');
        break;
      case AppRoutes.manageWorkflow:
        // update
        this.setPageContext('Settings', 'Manage Workflow');
        break;
      case AppRoutes.manageUsers:
        // update
        this.setPageContext('Settings', 'Manage Users');
        break;
      case AppRoutes.billsPayment:
        // update
        this.setPageContext('Bills', 'Bills Payment');
        break;
      case AppRoutes.accountStatement:
        // update
        this.setPageContext('My Account', 'Account Statement');
        break;
    }

    // navigation control
    // let navigationControl = null
    // setTimeout(() => {
    //   // get control
    //   navigationControl = $(`ul.navbar-nav a.nav-link[routerLink="${this.currentRoute}"]`)
    //   // check if sub menu nav
    //   if (navigationControl.parents('li').find('div.menu-dropdown').length) {
    //     // apply style to parent
    //     navigationControl.parents('li').find('div.menu-dropdown').addClass('show')
    //   }
    //   // remove all previous nav styles
    //   navigationControl.parents('ul.navbar-nav').find(`a.menu-link`).removeClass('active')
    //   // apply style to nav control
    //   navigationControl.addClass('active')
    // }, 1)
  }

  // update the page header titles
  setPageContext(context: string, sub: string) {
    // update the page context titles
    this.page.context = context
    this.page.sub = sub
  }

  ngOnInit() {
    // replace feather icons
    feather.replace()
    // if (this.hasSignPrivacyPolicy == '0') {
    //   this.router.navigate(['/user/login'])
    //   modal.show('#modal-disclaimer')
    // }
  }

  // sign out
  signoutUser() {
    this.ui.blockUiOn(`Signing Out... please wait`)
    // network
    this.http.post<any>(config.auth + routes.authentication.logout, {}, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // clear sessionStorage
          sessionStorage.clear()
          // redirect
          this.router.navigate(['/user/login'])
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => {
        this.ui.blockUiOff()
      })
    // // clear sessionStorage
    // sessionStorage.clear()
    // // redirect
    // this.router.navigate(['/user/login'])
  }

  sidebar() {
    if (window.innerWidth < 767) {
      const m = document.getElementById("app-menu");
      if (m.style.display != "none") {
        m.style.display = "none";
      }else{
        m.style.display = "block";
      }
    }
    // var e = document.documentElement.clientWidth;
    // 767 < e && document.querySelector(".hamburger-icon").classList.toggle("open"), "horizontal" === document.documentElement.getAttribute("data-layout") && (document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu")), "vertical" === document.documentElement.getAttribute("data-layout") && (e < 1025 && 767 < e ? (document.body.classList.remove("vertical-sidebar-enable"), "sm" == document.documentElement.getAttribute("data-sidebar-size") ? document.documentElement.setAttribute("data-sidebar-size", "") : document.documentElement.setAttribute("data-sidebar-size", "sm")) : 1025 < e ? (document.body.classList.remove("vertical-sidebar-enable"), "lg" == document.documentElement.getAttribute("data-sidebar-size") ? document.documentElement.setAttribute("data-sidebar-size", "sm") : document.documentElement.setAttribute("data-sidebar-size", "lg")) : e <= 767 && (document.body.classList.add("vertical-sidebar-enable"), document.documentElement.setAttribute("data-sidebar-size", "lg"))), "twocolumn" == document.documentElement.getAttribute("data-layout") && (document.body.classList.contains("twocolumn-panel") ? document.body.classList.remove("twocolumn-panel") : document.body.classList.add("twocolumn-panel"))
  }

  // hideSidebar(): void {
  //   this.sidebar()
  //   const m = document.getElementById("app-menu");
  //   const o = document.getElementById("vertical-overlay")
  //   m.style.display = "none";
  //   o.style.display = "none"
  // }
}