// theme.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private t = localStorage.getItem('theme') || 'light'
  private themeSubject = new BehaviorSubject<string>(this.t);
  public theme$ = this.themeSubject.asObservable();

  get currentTheme(): string {
    return this.themeSubject.value;
  }

  toggleTheme() {
    const newTheme = this.themeSubject.value === 'light' ? 'dark' : 'light';
    this.themeSubject.next(newTheme);
    localStorage.setItem('theme', this.themeSubject.value)
  }
}
