import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '@app/services';
import { modal } from '@app/config';

declare var $: any
declare var bootstrap: any

@Injectable({ providedIn: 'root' })
export class UiService {
	constructor(private toastrService: ToastrService,
		private logger: LogService,
		private router: Router) {
		// listener for tooltips
		this.addListenerForTooltips()
	}

	// show message
	success(msg: string) {
		this.toastrService.success(msg)
	}
	// show message
	info(msg: string) {
		this.toastrService.info(msg)
	}
	// show message
	warning(msg: string) {
		this.toastrService.warning(msg)
	}
	// show message
	error(e: any) {
		// get message

		let message = ((typeof e === 'object')
			? ((typeof e.error !== 'object') ? e.error : e.error.responseDescription || e.message) : e)
		// get filter
		let filter = (new RegExp(/One or more validation errors occurred/i)).test(JSON.stringify(e))
			? `An error occurred! Please try again or contact the administrator.`
			: message

		// check for status and code
		if (e.status == '401' && (new RegExp(/User is not authenticated/i)).test(JSON.stringify(e))) {
			// hide all modals
			modal.hide('.modal')
			// clear sessionStorage
			sessionStorage.clear()
			// redirect
			this.router.navigate(['/user/login'])
			// show error message
			this.toastrService.warning(`Session has expired! You can sign back in with your credentials.`)
		} else if (e.status == '400') {
			// show error message
			this.toastrService.error(message || `An error occurred! Please try again. \n if it persists, contact the administrator.`)
			// log error
			this.logger.log(e)
		} else {
			this.toastrService.error(`An error occurred! Please try again. \n if it persists, contact the administrator.`)
		}
	}
	// block ui
	blockUiOn(msg: string) {
		// block screen with message
		$.blockUI({
			message: `<div class="block-ui-spinner">
						    	<div class="spinner-border text-main2" role="status">
							    	<span class="sr-only">Loading...</span>
									</div>
									<span style="margin-left: 15px;">${msg}</span>
								</div>`
		})
	}
	// unblock ui
	blockUiOff() {
		// unblock screen
		$.unblockUI()
	}

	// tooltips
	tooltips() {
		setTimeout(() => {
			Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
				.map(function (e) { return new bootstrap.Tooltip(e) })
		}, 100)
	}

	// event listener for tooltips
	addListenerForTooltips() {
		// event listener for document
		$(document).on('click', () => {
			// timeout
			setTimeout(() => this.tooltips(), 100)
		})
	}
}