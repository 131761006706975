import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-on-lending',
  templateUrl: './on-lending.component.html',
  styleUrls: ['./on-lending.component.css']
})
export class OnLendingComponent implements OnInit {

  config = config
  corporateCustomerId: string = ""
  authorizationType: string

  hasUploaded: boolean = false;
  fileToUpload: File | null = null;

  isLoadingProcessed = false
  isLoadingExtensions = false
  isLoadingLiquidationss = false

  formUploadBeneficiary: FormGroup
  formTransferOtp: FormGroup
  formExtensionDate: FormGroup
  formLiquidationType: FormGroup
  formApprovalComment: FormGroup
  account: any;
  accountsList: any
  merchantAccountList: any
  workflows: any
  allBatches = []
  currentlySelectedBatch: any
  validBatch: any = []
  processedBatches: any = []
  processedBatchBen: any = []
  processedBatchBenPending: any = []
  extensionRequests: any = []
  pendingExtensionRequests: any
  extensionDates: any = []
  preliquidationrequests: any = []
  preliquidationType: any = []
  selectedBeneficiaries: any = []
  currentlySelectedBeneficiary: any
  currentlySelectedExtension: any

  constructor(
    private table: DataTablesService,
    private ui: UiService,
    private logger: LogService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient) {
    // get corporate id. 
    this.corporateCustomerId = store.get('corporateCustomerId')
    this.authorizationType = store.get('authorizationType')

    // check if user has permission and redirect if not
    // config.currentUserHasPermissionByKey('InitiateTransaction', config.unAuthRedirectUrl)
  }

  ngOnInit(): void {

    this.formUploadBeneficiary = this.fb.group({
      SourceAccountNumber: [''],
      OperatingAccountNumber: [''],
      Narration: [''],
      WorkflowId: [""],
    })

    // define form
    this.formTransferOtp = this.fb.group({
      otp: ['', Validators.required],
    })
    this.formExtensionDate = this.fb.group({
      date: ['', Validators.required],
    })
    this.formLiquidationType = this.fb.group({
      type: ['', Validators.required],
      amount: []
    })

    // define form
    this.formApprovalComment = this.fb.group({
      comment: ['']
    })
    // initialize table
    this.table.init()

    this.fetchBatches()
  }

  // fetch template sample
  async fetchSampleTemplate() {

    // loading
    this.ui.blockUiOn(`Fetching sample template... please wait`)


    // network call
    this.http.get<any>(config.base + routes.onlending.beneficiaryTemplate, { ...config.httpOptions(), responseType: 'blob' as 'json' })
      .subscribe({
        next: (data: any) => {
          let dataType = data.type;
          let binaryData = []
          binaryData.push(data)
          let downloadLink = document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
          downloadLink.setAttribute('download', "template")
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)

          // notification
          this.ui.info(`template loaded successfully.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  onSelectInitiated() {
    this.fetchAccountsList()

  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.fileToUpload = file;
  }

  async uploadBeneficiaries() {
    if (!this.fileToUpload) {
      this.ui.warning('Please Upload document')
      return
    }
    this.ui.blockUiOn('Uploading Beneficiaries')
    const payload = await store.preparePayload(this.formUploadBeneficiary.value)

    const formData = new FormData();
    formData.append("files", this.fileToUpload);
    formData.append("SourceAccountNumber", payload.SourceAccountNumber)
    formData.append("OperatingAccountNumber", payload.OperatingAccountNumber)
    formData.append("WorkflowId", payload.WorkflowId)
    formData.append("Narration", payload.Narration)
    formData.append("TransactionLocation", payload.TransactionLocation)

    this.http.post<any>(config.base + routes.onlending.beneficiaryUpload, formData, config.httpOptionsFormData())
      .subscribe({
        next: (data: any) => {

          this.ui.info(`Beneficiaries successfully uploaded.`)
          modal.hide("#modal-upload-ben")
          this.formUploadBeneficiary.reset()
          this.fetchBatches()

          // reset forms
          this.hasUploaded = false

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // fetch template sample
  async downloadInvalidBatch(b: any) {

    // loading
    this.ui.blockUiOn(`Fetching Invalid Batches... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: b?.batchId
    }, true)



    // network call
    this.http.get<any>(config.base + routes.onlending.downloadInvalid + `?batchId=${payload.batchId}`, { ...config.httpOptions(), responseType: 'blob' as 'json' })
      .subscribe({
        next: (data: any) => {
          let dataType = data.type;
          let binaryData = []
          binaryData.push(data)
          let downloadLink = document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
          downloadLink.setAttribute('download', "template")
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)

          // notification
          this.ui.info(`badges loaded successfully.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  async downloadAllBatch(b: any) {

    // loading
    this.ui.blockUiOn(`Fetching All ... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: b?.batchId
    }, true)



    // network call
    this.http.get<any>(config.base + routes.onlending.downloadBatch + `?batchId=${payload.batchId}`, { ...config.httpOptions(), responseType: 'blob' as 'json' })
      .subscribe({
        next: (data: any) => {
          let dataType = data.type;
          let binaryData = []
          binaryData.push(data)
          let downloadLink = document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
          downloadLink.setAttribute('download', "template")
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)

          // notification
          this.ui.info(`loaded successfully.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // fetch accounts list
  async fetchAccountsList() {
    // loading
    this.ui.blockUiOn(`Fetching accounts list... please wait`)

    let payload = await store.preparePayload({
      id: this.corporateCustomerId
    }, true)

    // network call
    this.http.get<any>(config.base + routes.transactions.getCorporateAccounts + `?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          // pass on data
          this.merchantAccountList = data?.records?.filter((acct) => acct.productCode === 'PBOLD')
          this.accountsList = data?.records?.filter((acct) => acct.productCode !== 'PBOLD')

          // notification
          this.ui.info(`Accounts loaded successfully.`)

        },
        error: error => {
          // error
          this.ui.error(error)

          // unblock
          this.ui.blockUiOff()
        }
      })
      .add(() => {

        // unblock
        this.ui.blockUiOff()
        if (config.checkIfNotSingleSignatory(this.authorizationType)) {
          this.fetchAllCorporateCustomerWorkflows()
        } else {
          modal.show('#modal-upload-ben')
        }


      });
  }

  // fetch all workflows
  async fetchAllCorporateCustomerWorkflows() {
    // loading
    this.ui.blockUiOn(`Fetching all workflows... please wait`)

    let payload = await store.preparePayload({
      id: this.corporateCustomerId
    }, true)

    // network call
    this.http.get<any>(config.base + routes.workflows.getWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          // pass on data
          this.workflows = data
          // notification
          this.ui.info(`Workflows loaded successfully.`)


        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        modal.show('#modal-upload-ben')

      });
  }

  // fetch batches
  async fetchBatches() {
    // define payload
    let payload = {
      id: this.corporateCustomerId
    }

    // progress
    this.ui.blockUiOn(`Fetching all batches... please wait`)

    // network call
    this.http.get<any>(config.base + routes.onlending.getBatches, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log

          // destroy table
          this.table.destroy(`lending-batches`)

          // pass data
          this.allBatches = data?.data

          // reinit table
          this.table.reInit(`lending-batches`)

          // notification
          if (!this.allBatches.length) this.ui.warning(`No batch available.`)
          else this.ui.success(`Batches successfully fetched.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        this.fetchProcessedBatches()
      });

  }

  async fetchProcessedBatches() {

    this.isLoadingProcessed = true
    // define payload
    let payload = {
      id: this.corporateCustomerId
    }

    // progress
    // this.ui.blockUiOn(`Fetching processed batches... please wait`)

    // network call
    this.http.get<any>(config.base + routes.onlending.getProcessedBatch, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log

          // destroy table
          this.table.destroy(`report-table`)

          // pass data
          this.processedBatches = data?.data

          // reinit table
          this.table.reInit(`report-table`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.isLoadingProcessed = false
      });

  }


  openExtensionRequests(b: any) {
    this.currentlySelectedBatch = b
    // this.fetchExtensionRequests()
    this.fetchPendingExtensionRequests()
  }

  openPreLiquidationRequests(b: any) {
    this.currentlySelectedBatch = b
    this.fetchPreLiquidationRequests()
  }
  // fetch batches
  async fetchExtensionRequests() {
    // progress
    this.ui.blockUiOn(`Fetching extension requests... please wait`)
    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId
    }, true)




    // network call
    this.http.get<any>(config.base + routes.onlending.getExtensionRequest + `?batchId=${payload.batchId}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log

          // destroy table
          this.table.destroy(`extension-table`)

          // pass data
          this.extensionRequests = data?.data

          // reinit table
          this.table.reInit(`extension-table`)


          this.ui.success(data?.responseDescription)


        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        modal.show("#modal-ext-requests")
      });

  }

  // fetch batches
  async fetchPendingExtensionRequests() {
    this.ui.blockUiOn(`Fetching pending extension requests... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId
    }, true)

    // progress

    let d = [];
    // network call
    this.http.get<any>(config.base + routes.onlending.getPendingExtensionRequest + `?batchId=${payload.batchId}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {

          d = data?.data
          // destroy table
          this.table.destroy(`pending-extension-table`)

          // pass data
          this.pendingExtensionRequests = data?.data

          // reinit table
          this.table.reInit(`pending-extension-table`)

          this.ui.success(data?.message)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        this.fetchExtensionRequests()

      });
      this.pendingExtensionRequests = d
  }

  // fetch batches
  async fetchPreLiquidationRequests() {
    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId
    }, true)

    // progress
    this.isLoadingLiquidationss = true

    // network call
    this.http.get<any>(config.base + routes.onlending.getLiquidation + `?batchId=${payload.batchId}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log

          // destroy table
          this.table.destroy(`liquidation-table`)

          // pass data
          this.preliquidationrequests = data?.data

          // reinit table
          this.table.reInit(`liquidation-table`)


          modal.show("#modal-preliq-requests")

          this.ui.success(data?.responseDescription)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.isLoadingLiquidationss = false
      });

  }

  //open\
  openExtensionRequest(b: any) {
    this.currentlySelectedBeneficiary = b
    this.fetchExtensionDate()
  }

  openLiquidationType(b: any) {
    this.currentlySelectedBeneficiary = b
    this.fetchLiquidationType()
  }

  async fetchExtensionDate() {
    // define payload
    let payload = {
      id: this.corporateCustomerId
    }

    // progress
    this.ui.blockUiOn(`Fetching extension dates... please wait`)

    // network call
    this.http.get<any>(config.base + routes.onlending.getExtensionDate, config.httpOptions())
      .subscribe({
        next: (data: any) => {

          // pass data
          this.extensionDates = data
          modal.show("#modal-extension-date")


          // notification
          if (!this.extensionDates.length) this.ui.warning(`No extension date available.`)
          else this.ui.success(`extension date successfully fetched.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
      });

  }


  async fetchLiquidationType() {
    // define payload
    let payload = {
      id: this.corporateCustomerId
    }

    // progress
    this.ui.blockUiOn(`Fetching preliquidation types... please wait`)

    // network call
    this.http.get<any>(config.base + routes.onlending.getLiquidationType, config.httpOptions())
      .subscribe({
        next: (data: any) => {

          // pass data
          this.preliquidationType = data
          modal.show("#modal-liquidation-type")

          // notification
          if (!this.preliquidationType.length) this.ui.warning(`No type available.`)
          else this.ui.success(`Types successfully fetched.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
      });

  }


  //open\
  openValidBatch(b: any) {
    this.currentlySelectedBatch = b
    this.fetchValidBatchBeneficiaries()
  }

  openProcessedBatch(b: any) {
    this.currentlySelectedBatch = b
    this.fetchProcessedBatchBeneficiaries()
  }
  // fetch batches
  async fetchValidBatchBeneficiaries() {
    // progress
    this.ui.blockUiOn(`Fetching batch beneficiaries... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId
    }, true)



    // network call
    this.http.get<any>(config.base + routes.onlending.getVaildBatchBeneficiares + `?batchId=${payload.batchId}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // destroy table
          this.table.destroy(`lending-batch`)

          // pass data
          this.validBatch = data?.data

          // reinit table
          this.table.reInit(`lending-batch`)

          this.ui.success(`Batches successfully fetched.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        modal.show('#modal-initiate-batch')
      });

  }

  async fetchProcessedBatchBeneficiaries() {
    // progress
    this.ui.blockUiOn(`Fetching batch beneficiaries... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId
    }, true)



    // network call
    this.http.get<any>(config.base + routes.onlending.getProcessedBatchBeneficiares + `?batchId=${payload.batchId}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // destroy table
          this.table.destroy(`processed-ben`)

          // pass data
          this.processedBatchBen = data?.data

          // reinit table
          this.table.reInit(`processed-ben`)

          this.ui.success(`Beneficiaries successfully fetched.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        this.fetchPendingProcessedBatchBeneficiaries()
      });

  }

  async fetchPendingProcessedBatchBeneficiaries() {
    // progress
    this.ui.blockUiOn(`Fetching pending batch beneficiaries... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId
    }, true)



    // network call
    this.http.get<any>(config.base + routes.onlending.getPendingProcessedBatchBeneficiares + `?batchId=${payload.batchId}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // destroy table
          this.table.destroy(`pen-processed-ben`)

          // pass data
          this.processedBatchBenPending = data?.data

          // reinit table
          this.table.reInit(`pen-processed-ben`)

          this.ui.success(`Pending Beneficiaries successfully fetched.`)

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        modal.show('#modal-processed-ben')
      });

  }
  toggleFromBeneficiaryList(e: any, s: any) {
    if (e.checked) {
      this.selectedBeneficiaries.push(s?.id)
    } else {
      this.selectedBeneficiaries = this.selectedBeneficiaries.filter((ben) => ben !== s?.id)
    }

  }
  toggleAllBeneficiary(e: any) {
    if (e.checked) {
      this.selectedBeneficiaries = this.processedBatchBen?.map((b => { return b?.id }))
    } else {
      this.selectedBeneficiaries = []
    }

  }

  findBeneficiary(b: any) {
    if (this.selectedBeneficiaries?.find(ben => ben === b)) {
      return true
    }
    return false
  }

  processDisbursement() {
    if (this.selectedBeneficiaries?.length < 1) {
      this.ui.warning('Please select beneficiaries');
      return
    }
    modal.show('#modal-authentication-token-d')
  }
  async initiateBatch() {
    // progress
    this.ui.blockUiOn(`Initiating Batch... please wait`)

    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId,
      otp: this.formTransferOtp.value.otp
    })

    // network call
    this.http.post<any>(config.base + routes.onlending.initiateValid, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          modal.hide('#modal-authentication-token')
          modal.hide('#modal-initiate-batch')
          this.ui.success(data?.responseDescription)
          this.formTransferOtp.reset()

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        this.fetchBatches()
      });

  }

  async initiateDisbursement() {
    if (this.selectedBeneficiaries?.length < 1) {
      this.ui.warning('Please select beneficiaries');
      return
    }
    // progress
    this.ui.blockUiOn(`Initiating Disbursement... please wait`)

    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId,
      beneficiaries: JSON.stringify(this.selectedBeneficiaries?.map((id) => { return { id } })),
      otp: this.formTransferOtp.value.otp
    })

    // network call
    this.http.post<any>(config.base + routes.onlending.initiateDisbursement, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          modal.hide('#modal-authentication-token-d')
          modal.hide('#modal-initiate-batch')
          this.ui.success(data?.responseDescription)
          this.formTransferOtp.reset()

        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        this.fetchProcessedBatches()
      });

  }

  async approveDisbursement() {

    // blocker
    this.ui.blockUiOn(`Approving... please wait`)
    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId,
      beneficiaries: JSON.stringify(this.selectedBeneficiaries?.map((id) => { return { id } })),
      otp: this.formTransferOtp.value.otp
    })


    // network
    this.http.post<any>(config.base + routes.onlending.approveDisbursement, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Disbursement successfully approved.`)


        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  async declineDisbursement() {
    // check if form is valid
    if (!this.formApprovalComment.valid) {
      // warning
      this.ui.warning(`Please provide reason for declining disbursement!`)
      return
    }
    // blocker
    this.ui.blockUiOn(`Declining... please wait`)
    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId,
      beneficiaries: JSON.stringify(this.selectedBeneficiaries?.map((id) => { return { id } })),
      comment: this.formApprovalComment.value.comment
    })



    // network
    this.http.post<any>(config.base + routes.onlending.declineDisbursement, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Disbursement successfully declined.`)

          // dismiss form
          this.formApprovalComment.reset()

          // dismiss modal
          modal.hide(`#modal-disbursement-decline-reason`)


        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }
  async iniateExtensionRequest() {
    // progress
    this.ui.blockUiOn(`Initiating Extension... please wait`)

    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      id: this.currentlySelectedBeneficiary.id,
      duration: this.formExtensionDate.value.date
    })
    // id: this.currentlySelectedBatch.batchId,

    // network call
    this.http.post<any>(config.base + routes.onlending.initiateDateExtension, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          this.ui.success(data?.responseDescription)
          this.formExtensionDate.reset()
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => {
        this.ui.blockUiOff()
        this.fetchProcessedBatches()
      });
  }

  openExtension(b: any) {
    this.currentlySelectedExtension = b

  }

  async approveExtension() {

    // blocker
    this.ui.blockUiOn(`Approving... please wait`)
    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId,
      id: this.currentlySelectedExtension?.id,
      otp: this.formTransferOtp.value.otp
    })


    // network
    this.http.post<any>(config.base + routes.onlending.approveExt, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Extension successfully approved.`)


        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  async declineExtension() {
    // check if form is valid
    if (!this.formApprovalComment.valid) {
      // warning
      this.ui.warning(`Please provide reason for declining disbursement!`)
      return
    }
    // blocker
    this.ui.blockUiOn(`Declining... please wait`)
    // define payload
    let payload = await store.preparePayload({
      // id: this.corporateCustomerId,
      batchId: this.currentlySelectedBatch?.batchId,
      id: this.currentlySelectedExtension?.id,
      comment: this.formApprovalComment.value.comment
    })



    // network
    this.http.post<any>(config.base + routes.onlending.declineExt, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Extension successfully declined.`)

          // dismiss form
          this.formApprovalComment.reset()

          // dismiss modal
          modal.hide(`#modal-disbursement-decline-reason`)


        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  async iniateLiquidationRequest() {
    // progress
    this.ui.blockUiOn(`Initiating... please wait`)

    if (this.formLiquidationType.value.type !== 'Partial_Pre_Liquidation') {
      // define payload
      let payload = await store.preparePayload({
        // id: this.corporateCustomerId,
        id: this.currentlySelectedBeneficiary.id,
        otp: this.formTransferOtp.value.otp
      })
      // network call
      this.http.post<any>(config.base + routes.onlending.initiateLiquidation, payload, config.httpOptions())
        .subscribe({
          next: (data: any) => {

            this.ui.success(data?.responseDescription)
            this.formTransferOtp.reset()
          },
          error: error => {
            // error
            this.ui.error(error)
          }
        }).add(() => {
          this.ui.blockUiOff()

        });
    } else {
      // define payload
      let payload = await store.preparePayload({
        // id: this.corporateCustomerId,
        id: this.currentlySelectedBeneficiary.id,
        amount: this.formLiquidationType.value.amount,
        otp: this.formTransferOtp.value.otp
      })
      // network call
      this.http.post<any>(config.base + routes.onlending.initiatePreliquidation, payload, config.httpOptions())
        .subscribe({
          next: (data: any) => {
            this.ui.success(data?.responseDescription)
            this.formTransferOtp.reset()
            this.formLiquidationType.reset()

          },
          error: error => {
            // error
            this.ui.error(error)
          }
        }).add(() => {
          this.ui.blockUiOff()
          this.fetchProcessedBatches()
        });
    }

  }
}
