import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CurrencyPipe } from '@angular/common';

// define template
let template = 'users.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class UsersComponent implements OnInit {
  config = config
  users: any = []
  corporateCustomerUsers: any = []
  corporateCustomerUsersPending: any = []
  currentlySelectedCorporateUserProfile: any
  formCreateNewRole: FormGroup
  formCreateCorporateUser: FormGroup
  formDeclineUserRoleReason: FormGroup
  formDeactivateUserRoleReason: FormGroup
  formDeclineCorporateUserProfileReason: FormGroup
  formDeactivateCorporateUserProfileReason: FormGroup
  profileOperationStatusCreate: boolean
  profileOperationStatusUpdate: boolean
  profileOperationStatusRole: boolean
  corporateUserRoles: any = []
  userProfileRoles: any = []
  defaultCorporateUserId: string = '3fa85f64-5717-4562-b3fc-2c963f66afa6'
  corporateCustomerId: any
  userId: any
  currentlySelectedUserRole: any
  roleOperationStatusCreate: boolean
  roleOperationStatusUpdate: boolean
  permittedAccessesByPermission: any
  permittedAccessesByPermissionId: any
  userProfileRolePermissions: any = []

  constructor(private table: DataTablesService,
    private ui: UiService,
    private logger: LogService,
    private http: HttpClient,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe) {
    // get corporate id
    this.corporateCustomerId = store.get('corporateCustomerId')
    this.userId = store.get('userId')
  }

  ngOnInit() {
    // define form
    this.formCreateCorporateUser = this.fb.group({
      // id: [this.defaultCorporateUserId],
      title: ['', Validators.required],
      corporateCustomerId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      username: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      approvalLimit: [1, Validators.required],
      corporateRoleId: ['', Validators.required],
    })

    this.formCreateCorporateUser.valueChanges.subscribe(form => {
      if (form.approvalLimit) {
        this.formCreateCorporateUser.patchValue({
          approvalLimit: this.currencyPipe.transform(form.approvalLimit.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
        }, { emitEvent: false })
      }
    })

    // define form
    this.formCreateNewRole = this.fb.group({
      roleName: ['', Validators.required],
      approvalLimit: [1],
    })

    // define form
    this.formDeclineUserRoleReason = this.fb.group({
      reason: ['', Validators.required]
    })

    // define form
    this.formDeactivateUserRoleReason = this.fb.group({
      reason: ['', Validators.required]
    })

    // define form
    this.formDeclineCorporateUserProfileReason = this.fb.group({
      reason: ['', Validators.required]
    })

    // define form
    this.formDeactivateCorporateUserProfileReason = this.fb.group({
      reason: ['', Validators.required]
    })

    // initialize table
    this.table.init()

    if (config.currentUserHasPermissionByKey('ViewCorporateUserProfile')) {
      // fetch corporate users
      this.fetchAllCorporateCustomerUsers()
    }


  }

  // fetch corporate customer users
  async fetchAllCorporateCustomerUsers() {
    // pass the profile
    let id = this.corporateCustomerId
    // loading
    this.ui.blockUiOn(`Fetching corporate users... please wait`)

    let payload = await store.preparePayload({
      id
    }, true)



    // network call
    this.http.get<any>(`${config.base}${routes.corporateCustomerUsers.getAllCorporateUsers}?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          // destroy
          this.table.destroy(`manage-users`)
          // pass on data
          this.corporateCustomerUsers = data?.data?.filter(item => item?.id != store.get('userId'))
          // reinit
          this.table.reInit(`manage-users`)

          // notification
          this.ui.info(`Corporate users loaded successfully.`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => {
        this.ui.blockUiOff()
        this.fetchPendingCorporateCustomerUsers()
      });
  }

  // fetch corporate customer users
  async fetchPendingCorporateCustomerUsers() {
    // pass the profile
    let id = this.corporateCustomerId
    // loading
    this.ui.blockUiOn(`Fetching corporate users... please wait`)

    let payload = await store.preparePayload({
      id
    }, true)



    // network call
    this.http.get<any>(`${config.base}${routes.corporateCustomerUsers.getPendingCorporateUsers}?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          // destroy
          this.table.destroy(`manage-users-pending`)
          // pass on data
          this.corporateCustomerUsersPending = data?.data?.filter(item => item?.id != store.get('userId'))
          // reinit
          this.table.reInit(`manage-users-pending`)
          // notification
          this.ui.info(`Corporate users loaded successfully.`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // fetch corporate user roles
  fetchAllCorporateUserRoles() {
    // loading
    this.ui.blockUiOn(`Fetching corporate user roles... please wait`)

    // network call
    this.http.get<any>(config.base + routes.corporateRoles.getAllCorporateRoles, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // pass on data
          this.corporateUserRoles = data?.data.filter(item => item?.roleName != 'Corporate Admin')

          // notification
          this.ui.info(`Corporate user roles loaded successfully.`)

          // check if update operation active
          if (this.profileOperationStatusUpdate) {
            // get profile
            let profile = this.currentlySelectedCorporateUserProfile
            // update form
            this.formCreateCorporateUser.patchValue({
              id: profile.id,
              corporateCustomerId: profile.corporateCustomerId,
              firstName: profile.firstName,
              lastName: profile.lastName,
              middleName: profile.middleName,
              username: profile.username,
              email: profile.email,
              phone: profile.phone1,
              approvalLimit: config.addCommas(profile.approvalLimit),
              corporateRoleId: profile.corporateRole
            })
          }

          // unblock
          this.ui.blockUiOff()

          // bring up modal
          modal.show(`#modal-new-corporate-user`)
        },
        error: error => {
          // error
          this.ui.error(error)

          // unblock
          this.ui.blockUiOff()
        }
      })
      .add(() => { });
  }

  // fetch roles
  fetchBankUserRolesIntoList() {
    // loading
    this.ui.blockUiOn(`Fetching all user roles... please wait`)

    // network call
    this.http.get<any>(config.base + routes.corporateRolesByCorporate.getAllRoles, config.httpOptions())
      .subscribe({
        next: (data: any) => {


          // destory table
          this.table.destroy(`corporate-user-roles`)
          // pass on data
          this.userProfileRoles = data?.data
          // reinit table
          this.table.reInit(`corporate-user-roles`)
          // notification
          this.ui.info(`User roles fetched successfully.`)

          // bring up modal
          modal.show(`#modal-corporate-user-roles`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // fetch permissions for role
  fetchAllPermissionsForRole(role: any) {
    // pass role
    this.currentlySelectedUserRole = role

    // loading
    this.ui.blockUiOn(`Fetching permissions for '${role.roleName}'... please wait`)

    // network call
    this.http.get<any>(config.base + routes.corporateRolesByCorporate.getRolePermissions + `?roleId=${role.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // pass selected permissions into container
          this.permittedAccessesByPermission = data?.data
          this.permittedAccessesByPermissionId = data?.data.map(item => item?.id)
        },
        error: error => {
          // check type of error
          if (!(new RegExp(/Invalid id. UserAccess not found/i)).test(JSON.stringify(error))) {
            // show error
            this.ui.error(error)
          } else {
            // empty selected permissions
            this.permittedAccessesByPermission = []
            this.permittedAccessesByPermissionId = []
          }
        }
      })
      .add(() => {
        // unblock
        this.ui.blockUiOff()
        // fetch all permissions
        this.fetchAllPermissions()
      });
  }

  // fetch permissions
  fetchAllPermissions() {
    // loading
    this.ui.blockUiOn(`Fetching all permissions... please wait`)

    // network call
    this.http.get<any>(config.base + routes.corporateRolesByCorporate.getUserAccesses, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // pass on data
          this.userProfileRolePermissions = data?.data
          // notification
          this.ui.info(`Permissions fetched successfully.`)

          // bring up modal
          modal.show(`#modal-corporate-user-role-permissions`)

          // tooltips
          setTimeout(() => this.ui.tooltips(), 3000)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // detect permission changed
  detectChangedPermission(item: any, target: any) {
    // check status of target
    switch (find(target).get(0).checked) {
      case true:
        // add permission to array
        this.permittedAccessesByPermission.push(item)
        this.permittedAccessesByPermissionId.push(item?.id)
        break
      case false:
        // get index
        let index = this.permittedAccessesByPermissionId.indexOf(item?.id)
        // remove item from array
        this.permittedAccessesByPermission.splice(index, 1)
        this.permittedAccessesByPermissionId.splice(index, 1)
        break
    }
  }

  // save all permissions for role
  saveAllSelectedPermissionsForRole() {
    // define payload
    let payload = {
      roleId: this.currentlySelectedUserRole.id,
      accessIds: this.permittedAccessesByPermissionId
    }

    // loading
    this.ui.blockUiOn(`Saving selected permissions for '${this.currentlySelectedUserRole.roleName}'... please wait`)

    // network call
    this.http.post<any>(config.base + routes.corporateRolesByCorporate.addUserAccess, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Permissions granted to '${this.currentlySelectedUserRole.roleName}'.`)

          // bring up modal
          modal.hide(`#modal-corporate-user-role-permissions`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // initiate create or update bank user
  initiateCreateOrUpdateCorporateUser(mode: boolean, profile?: any) {
    // clear form
    this.formCreateCorporateUser.reset()
    // auto-select
    this.formCreateCorporateUser.patchValue({
      approvalLimit: 1,
      corporateRoleId: ''
    })

    // define status flags
    this.profileOperationStatusCreate = mode
    this.profileOperationStatusUpdate = !mode
    this.profileOperationStatusRole = false

    // pass data if null or not
    this.currentlySelectedCorporateUserProfile = profile

    // load roles
    this.fetchAllCorporateUserRoles()
  }

  // create or update bank user
  async createOrUpdateCorporateUser(target: any) {
    // get form
    let form = find(target)

    // check if form is valid
    if (!this.formCreateCorporateUser.valid) {
      // warning
      this.ui.warning(`Please fill all required fields!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // define endpoint
    let endpoint = {
      true: routes.corporateCustomerUsers.createCorporateUser,
      false: routes.corporateCustomerUsers.updateCorporateUser,
    }

    // blocker
    this.ui.blockUiOn(`Saving corporate user... please wait`)

    // define operation
    let operation = null
    // define payload
    let payload = null

    // switch case
    switch (this.profileOperationStatusCreate) {
      case true:
        // define payload
        payload = await store.preparePayload({
          ...this.formCreateCorporateUser.value,
          // id: this.defaultCorporateUserId,
          corporateCustomerId: this.corporateCustomerId,
          approvalLimit: Number(config.removeCommas(this.formCreateCorporateUser.value.approvalLimit))
        })

        // perform network call
        operation = this.http.post<any>(config.base + endpoint[`${this.profileOperationStatusCreate}`], payload, config.httpOptions())
        break
      case false:
        // define payload
        payload = await store.preparePayload({
          ...this.formCreateCorporateUser.value,
          id: this.currentlySelectedCorporateUserProfile.id,
          approvalLimit: Number(config.removeCommas(this.formCreateCorporateUser.value.approvalLimit)),
          corporateCustomerId: this.corporateCustomerId,
        })

        // perform network call
        operation = this.http.post<any>(config.base + endpoint[`${this.profileOperationStatusCreate}`], payload, config.httpOptions())
        break
    }

    // network
    operation
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate user profile successfully saved.`)

          // dismiss form
          this.formCreateCorporateUser.reset()
          // auto-select
          this.formCreateCorporateUser.patchValue({
            approvalLimit: 1,
            corporateRoleId: ''
          })

          // check if update op
          if (this.profileOperationStatusUpdate) modal.hide(`#modal-new-corporate-user`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // initiate profile approval request
  initiateCorporateUserProfileApprovalRequest(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // request approval
    this.requestApprovalForCorporateUserProfile()
  }

  // request approval for corporate user profile
  async requestApprovalForCorporateUserProfile() {
    // blocker
    this.ui.blockUiOn(`Requesting corporate user approval... please wait`)
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id
    })



    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.requestApprovalUser, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate user profile approval request successfully sent.`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // initiate create or update role
  initiateCreateOrUpdateRole(mode: boolean, role?: any) {
    // clear form
    this.formCreateNewRole.reset()
    // add value if update
    this.formCreateNewRole.patchValue({
      roleName: role?.roleName || ''
    })

    // pass selected role
    this.currentlySelectedUserRole = role

    // reset status flags
    this.roleOperationStatusCreate = mode
    this.roleOperationStatusUpdate = !mode

    // bring up modal
    modal.show(`#modal-new-user-role`)
  }

  // create or update role
  createOrUpdateUserRole(target: any) {
    // get form
    let form = find(target)

    // check if form is valid
    if (!this.formCreateNewRole.valid) {
      // warning
      this.ui.warning(`Please fill all required fields!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // define endpoint
    let endpoint = {
      true: routes.corporateRolesByCorporate.createNewRole,
      false: routes.corporateRolesByCorporate.updateRole,
    }

    // blocker
    this.ui.blockUiOn(`Saving role... please wait`)

    // define operation
    let operation = null
    // define payload
    let payload = null

    // switch case
    switch (this.roleOperationStatusCreate) {
      case true:
        // define payload
        payload = {
          ...this.formCreateNewRole.value,
          approvalLimit: 1
        }

        // perform network call
        operation = this.http.post<any>(config.base + endpoint[`${this.roleOperationStatusCreate}`], payload, config.httpOptions())
        break
      case false:
        // define payload
        payload = {
          ...this.formCreateNewRole.value,
          id: this.currentlySelectedUserRole.id,
          approvalLimit: this.currentlySelectedUserRole?.approvalLimit || 1
        }

        // perform network call
        operation = this.http.post<any>(config.base + endpoint[`${this.roleOperationStatusCreate}`], payload, config.httpOptions())
        break
    }

    // network
    operation
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Role successfully saved.`)

          // dismiss form
          this.formCreateNewRole.reset()

          // fetch roles
          this.fetchBankUserRolesIntoList()

          // check if update op
          if (this.roleOperationStatusUpdate) modal.hide(`#modal-new-user-role`)
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // initiate approve profile
  initiateCorporateUserProfileApprove(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // approve profile
    this.approveCorporateUserProfile()
  }

  // initiate decline profile
  initiateCorporateUserProfileDecline(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // bring up modal
    modal.show(`#modal-corporate-user-decline-reason`)
  }

  // initiate reset password
  initiateCorporateUserProfileResetPassword(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // reset password
    this.resetCorporateUserProfilePassword()
  }

  // initiate deactivate profile
  initiateCorporateUserProfileReactivate(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // reactivate profile
    this.reactivateCorporateUserProfile()
  }

  // initiate deactivate profile
  initiateCorporateUserProfileDeactivate(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // reset form
    this.formDeactivateCorporateUserProfileReason.reset()
    // bring up modal
    modal.show(`#modal-corporate-user-deactivate-reason`)
  }

  // initiate profile log out
  initiateProfileEnableLoggedOutProfile(profile: any) {
    // pass on data
    this.currentlySelectedCorporateUserProfile = profile
    // request approval
    this.enableLogOutStatusForCorporateUserProfile()
  }

  // approve corporate user profile
  async approveCorporateUserProfile() {
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id
    })

    // blocker
    this.ui.blockUiOn(`Approving user profile... please wait`)

    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.approveCorporateUser, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`User profile successfully approved.`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // decline corporate user profile
  async declineCorporateUserProfile() {
    // check if form is valid
    if (!this.formDeclineCorporateUserProfileReason.valid) {
      // warning
      this.ui.warning(`Please provide reason for declining corporate user profile!`)
      return
    }

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id,
      reason: this.formDeclineCorporateUserProfileReason.value.reason
    })

    // blocker
    this.ui.blockUiOn(`Declining user profile... please wait`)

    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.declineCorporateUser, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`User profile successfully declined.`)

          // dismiss form
          this.formDeclineCorporateUserProfileReason.reset()

          // dismiss modal
          modal.hide(`#modal-corporate-user-decline-reason`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // reset password
  async resetCorporateUserProfilePassword() {
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id
    })

    // blocker
    this.ui.blockUiOn(`Resetting user password... please wait`)

    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.resetPassword, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`User password reset successfully.`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // reactivate profile
  async reactivateCorporateUserProfile() {
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id
    })

    // blocker
    this.ui.blockUiOn(`Reactivating user profile... please wait`)

    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.reactivateCorporateUser, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`User profile successfully reactivated.`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // deactivate profile
  async deactivateCorporateUserProfile() {
    // check if form is valid
    if (!this.formDeactivateCorporateUserProfileReason.valid) {
      // warning
      this.ui.warning(`Please provide reason for deactivating user profile!`)
      return
    }

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id,
      reason: this.formDeactivateCorporateUserProfileReason.value.reason
    })

    // blocker
    this.ui.blockUiOn(`Deactivating user profile... please wait`)

    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.deactivateCorporateUser, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate profile successfully deactivated.`)

          // dismiss form
          this.formDeactivateCorporateUserProfileReason.reset()

          // dismiss modal
          modal.hide(`#modal-corporate-user-deactivate-reason`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // enable logged out profile
  async enableLogOutStatusForCorporateUserProfile() {
    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateUserProfile.id
    })

    // blocker
    this.ui.blockUiOn(`Enabling logged out status for profile... please wait`)

    // network
    this.http.post<any>(config.base + routes.corporateCustomerUsers.enableLoggedOutProfile, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Profile successfully enabled as logged out.`)

          // fetch profiles
          this.fetchAllCorporateCustomerUsers()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }
}