import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, find, routes, store, modal } from '@app/config';

import * as XLSX from 'xlsx';
import * as html2pdf from '../../../../assets/js/html2pdf.bundle.js';
import * as html2canvas from '../../../../assets/js/html2canvas.min.js';
// define template
let template = 'transaction-history.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class TransactionHistoryComponent implements OnInit {
	corporateCustomerId: string
	currentUserId: string
	currentUser: any
	users: any = config.users
	transactions: any = []
	bulkTransactions: any = []
	config = config
	// formApprovalComment: FormGroup 
	// formTransferOtp: FormGroup
	approvalHistoryDataStatus: boolean
	pendingTransactions: any = []
	creditAccountsList: any = []
	approvalUsersList: any = []
	filteredApprovalUsersList: any = []
	approvalHistoryDataStatusBulk: boolean
	currentlySelectedTransaction: any = {}
	currentUserIsAuthorizerAndShouldApprove: boolean
	authorizationType: any
	receiptDownloadingStatus: boolean

	constructor(private table: DataTablesService, private ui: UiService, private logger: LogService, private http: HttpClient) {
		this.currentUser = config.currentUser()
		// get user
		// this.currentUser = store.get('customerIdentity')
		// get corporate id
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')
		// get user id
		this.currentUserId = store.get('userId')

	}

	ngOnInit() {
		// initialize table
		this.table.init()

		// fetch history
		this.fetchTransactions()
		// if (this.currentUser == this.users.initiator) {
		// 	this.fetchTransactions()
		// } else if (this.currentUser == this.users.authorizer) {
		// 	this.fetchTransactionApprovalHistory()
		// } else {
		// 	this.fetchTransactions()
		// 	this.fetchTransactionApprovalHistory()
		// }
	}

	fetchTransactions() {
		// define payload
		let payload = {
			id: this.corporateCustomerId
		}

		// progress
		this.ui.blockUiOn(`Fetching pending transactions... please wait`)

		// network call
		this.http.get<any>(config.base + routes.transactions.transactionLogs, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					// pass data
					this.transactions = data

					// destroy table
					this.table.destroy(`transaction-history`)

					// reinit table
					this.table.reInit(`transaction-history`)

					// notification
					this.ui.success(`Transactions successfully fetched.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => {
				// this.fetchBulkTransactions()

				this.ui.blockUiOff()
			});
	}

	async fetchBulkTransactions() {

		// progress
		this.ui.blockUiOn(`Fetching pending transactions... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)



		// network call
		this.http.get<any>(config.base + routes.transactions.bulkTransactionHistory + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					// pass data
					this.bulkTransactions = data

					// destroy table
					this.table.destroy(`bulk-transaction-history`)

					// reinit table
					this.table.reInit(`bulk-transaction-history`)

					// notification
					this.ui.success(`Transactions successfully fetched.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// fetch credit accounts
	async fetchCreditAccountsForBulkTransaction(transaction: any) {

		// progress
		this.ui.blockUiOn(`Fetching credit account(s) for transaction... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: transaction.id
		}, true)


		// network call
		this.http.get<any>(config.base + routes.transactions.bulkCreditAccounts + `?bulkFileId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// notification
					this.ui.success(`Credit accounts successfully fetched.`)

					// unblock
					this.ui.blockUiOff()

					// check mode
					if (this.approvalHistoryDataStatusBulk) {
						// change status flag
						this.approvalHistoryDataStatusBulk = false
						// pass data
						this.creditAccountsList = data
						// destroy table
						this.table.destroy(`transaction-credit-accounts-approval`)

						// reinit table
						this.table.reInit(`transaction-credit-accounts-approval`)
						// fetch approval history
						// this.fetchBulkTransactionApprovalHistory(transaction)
					} else {
						// pass data
						this.creditAccountsList = data
						// destroy table
						this.table.destroy(`transaction-credit-accounts`)

						// reinit table
						this.table.reInit(`transaction-credit-accounts`)

						// bring up modal
						modal.show(`#modal-transaction-credit-accounts`)
					}
				},
				error: error => {
					// error
					this.ui.error(error)

					// unblock
					this.ui.blockUiOff()
				}
			}).add(() => { });
	}
	// fetch transaction approval history
	async fetchTransactionApprovalHistory() {
		// progress
		this.ui.blockUiOn(`Fetching approval history for transaction... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)



		// network call
		this.http.get<any>(config.base + routes.transactions.approvalHistory + `?transLogId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// notification
					this.ui.success(`Approval history successfully fetched.`)

					// pass data
					this.approvalUsersList = data

					// check if record available
					// if (this.approvalUsersList.length && transaction.transactionStatus != 1) {
					//     // check if user is present in authorizers list
					//     let currentUserAsApproverRecord = this.approvalUsersList.find(item => item?.userId == this.currentUserId)
					//     // check if record found
					//     if (currentUserAsApproverRecord) {
					//         // change status
					//         this.currentUserIsAuthorizerAndShouldApprove = currentUserAsApproverRecord.approvalLevel == transaction.approvalStage
					//       }

					//       // re-arrange approvers list
					//     let bufferArrayStore = new Array(this.approvalUsersList.length)

					//     // loop through list of approval
					//     this.approvalUsersList.forEach(item => {
					//     	// pass data
					//     	bufferArrayStore[(item?.approvalLevel - 1)] = item
					//     })

					//     // pass data
					//     this.filteredApprovalUsersList = bufferArrayStore
					//   }

					// bring up modal
					// modal.show(`#modal-transaction-approval`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// view details
	viewTransactionDetails(transaction: any) {
		// pass data
		this.currentlySelectedTransaction = transaction

		// modal
		modal.show(`#modal-view-transaction-history`)
	}

	// view details
	viewTransactionDetailsBulk(transaction: any) {
		// pass data
		this.currentlySelectedTransaction = transaction

		// modal
		modal.show(`#modal-view-transaction-history-bulk`)
	}

	// download as excel
	downloadCreditAccountAsExcel() {
		// get table object
		let element = document.getElementById('transaction-credit-accounts');
		const workSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		// generate workbook and add the worksheet
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

		// save to file
		XLSX.writeFile(workBook, `transaction-credit-accounts.xlsx`);
	}

	downloadTransactionHistoryAsExcel() {
		// get table object
		let element = document.getElementById('transaction-credit-accounts');
		const workSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		// generate workbook and add the worksheet
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

		// save to file
		XLSX.writeFile(workBook, `transaction-credit-accounts.xlsx`);
	}

	// download receipt
	downloadTransactionReceipt() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container');
			// get opts
			var opt = {
				margin: 1,
				filename: 'transaction-receipt.pdf',
				image: { type: 'jpeg', quality: 1 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
			};
			// get workder
			let worker = html2pdf()
			// chain the worker functions and process the file to be downloaded
			worker.set(opt).from(element, 'element').save().then(() => {
				// change status
				this.receiptDownloadingStatus = false
			})
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptAsImage() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container');

			html2canvas(element, {
				scrollY: -window.scrollY,
				useCORS: true,
			}).then(canvas => {
				// create element
				let a = document.createElement('a')
				// set href attribute of anchor tag with datauri
				a.setAttribute('href', canvas.toDataURL('image/jpeg', 1.0))
				// set download attribute with name
				a.setAttribute('download', 'transaction-receipt.jpg')
				// add element to document body
				document.body.appendChild(a)
				// trigger click on anchor tag
				a.click()
				// remove anchor tag from document
				document.body.removeChild(a)
				// change status
				this.receiptDownloadingStatus = false
			});
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptBulk() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history-bulk').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container-bulk');
			// get opts
			var opt = {
				margin: 1,
				filename: 'transaction-receipt.pdf',
				image: { type: 'jpeg', quality: 1 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
			};
			// get workder
			let worker = html2pdf()
			// chain the worker functions and process the file to be downloaded
			worker.set(opt).from(element, 'element').save().then(() => {
				// change status
				this.receiptDownloadingStatus = false
			})
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptAsImageBulk() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history-bulk').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container-bulk');

			html2canvas(element, {
				scrollY: -window.scrollY,
				useCORS: true,
			}).then(canvas => {
				// create element
				let a = document.createElement('a')
				// set href attribute of anchor tag with datauri
				a.setAttribute('href', canvas.toDataURL('image/jpeg', 1.0))
				// set download attribute with name
				a.setAttribute('download', 'transaction-receipt.jpg')
				// add element to document body
				document.body.appendChild(a)
				// trigger click on anchor tag
				a.click()
				// remove anchor tag from document
				document.body.removeChild(a)
				// change status
				this.receiptDownloadingStatus = false
			});
		}, 1000)
	}
}