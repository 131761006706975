import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { HttpClient } from '@angular/common/http';


@Component({
	selector: 'app-bulk-upload',
	templateUrl: './bulk-upload.component.html',
	styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent implements OnInit {
	accounts: any[] = []
	config = config
	workflows: any[] = []
	hasUploaded: boolean = false
	fileToUpload: File | null = null;
	corporateCustomerId: string = ""
	accountsListSource: any = []
	bulkEntries: any = []
	bulkData: any
	authorizationType: any
	formBulkPayment: FormGroup
	formOtp: FormGroup
	constructor(
		private ui: UiService,
		private logger: LogService,
		private router: Router,
		private fb: FormBuilder,
		private http: HttpClient) {
		// get corporate id. 
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')

		// check if user has permission and redirect if not
		// config.currentUserHasPermissionByKey('InitiateTransaction', config.unAuthRedirectUrl)
	}

	ngOnInit() {

		// this.formBulkPayment = this.fb.group({
		// 	sourceAccountNumber: ['', Validators.required],
		// 	narration: ['', Validators.required],
		// 	currency: [''],
		// 	workflowId: [''],
		// 	otp: [''],

		// })
		this.formOtp = this.fb.group({
			otp: ['', Validators.required],
		})

		// init template
		this.initTemplate()
		// this.fetchAllCorporateAccounts()
	}

	// initialize template
	initTemplate() {
		// initialize dropzone
		// this.dropzone()
	}


	back() {
		this.hasUploaded = false
	}
	// bring up otp screen
	bringUpAutheticationModal() {
		// show modal
		modal.show(`#modal-authentication-token`)
	}


	// fetch template sample
	async fetchSampleTemplate() {

		// loading
		this.ui.blockUiOn(`Fetching bulk sample template... please wait`)


		// network call
		this.http.get<any>(config.base + routes.salary.salaryTemplate, { ...config.httpOptions(), responseType: 'blob' as 'json' })
			.subscribe({
				next: (data: any) => {
					let dataType = data.type;
					let binaryData = []
					binaryData.push(data)
					let downloadLink = document.createElement('a')
					downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
					downloadLink.setAttribute('download', "template")
					document.body.appendChild(downloadLink)
					downloadLink.click()
					document.body.removeChild(downloadLink)

					// notification
					this.ui.info(`template loaded successfully.`)

				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// fetch corporate accounts
	async fetchBankCodeList() {

		// loading
		this.ui.blockUiOn(`Fetching bank code list ... please wait`)


		// network call
		this.http.get<any>(config.base + routes.transactions.getBankCodeList, { ...config.httpOptions(), responseType: 'blob' as 'json' })
			.subscribe({
				next: (data: any) => {
					// output data
					let dataType = data.type;
					let binaryData = []
					binaryData.push(data)
					let downloadLink = document.createElement('a')
					downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
					downloadLink.setAttribute('download', "template")
					document.body.appendChild(downloadLink)
					downloadLink.click()
					document.body.removeChild(downloadLink)
					// notification
					this.ui.info(`bank code list loaded successfully.`)

				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// fetch all workflows
	async fetchAllCorporateCustomerWorkflows() {
		// loading
		this.ui.blockUiOn(`Fetching all workflows... please wait`)

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.workflows.getWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// pass on data
					this.workflows = data
					// notification
					this.ui.info(`Workflows loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => { });
	}

	async verifyBulkTransaction(target: any) {

		// get form
		let form = find(target)



		const formData = new FormData();



		if (!this.fileToUpload) {
			// warning
			this.ui.warning(`Please select file to upload`)
			// Rogue.Form.flag(form)
			return
		}


		this.ui.blockUiOn(`verifying Staff List.`)

		formData.append("files", this.fileToUpload);


		this.http.post<any>(config.base + routes.salary.verifyStaffs, formData, config.httpOptionsFormData())
			.subscribe({
				next: (data: any) => {
					// output data
					// check data returned
					if (data?.data) {
						this.bulkEntries = data?.data
						// this.bulkData = data?.data
						this.hasUploaded = true
					}
				},
				error: (error) => {
					if (!(new RegExp(/A connection attempt failed because the connected/i)).test(JSON.stringify(error))) {
						// error
						this.ui.error(error)
					} else {
						// notification
						this.ui.info(`Dummy transaction successfully verified.`)
						this.hasUploaded = true


						// bring up otp screen
						// this.bringUpAutheticationModal()
					}
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	async initiateBulkAdd() {
		// get form
		// let form = find(target)
		modal.hide(`#modal-confirm`)

		// processing modal
		this.ui.blockUiOn('Adding Staffs to Payroll')

		const formData = new FormData();

		// let values = this.formBulkPayment.value
		const TransactionLocation = "9.000705,7.578728"
		// let payload = await store.preparePayload({
		// 	sourceAccountNumber: values.sourceAccountNumber,
		// 	narration: values.narration,
		// 	currency: values.currency,
		// 	workflowId: values.workflowId,
		// 	otp: this.formTransferOtp.value.otp,
		// 	// transactionType: "multiple_signatory",
		// 	TransactionLocation: 
		// }, false)


		// formData.append("SourceAccountNumber", payload.sourceAccountNumber);
		// formData.append("Narration", payload.narration);
		// formData.append("Currency", payload.currency);
		// formData.append("WorkflowId", payload.workflowId);
		// formData.append("TransactionType", payload.transactionType);
		// formData.append("otp", payload.otp);
		formData.append("files", this.fileToUpload);
		formData.append("TransactionLocation", TransactionLocation)
		formData.append("otp", this.formOtp.value.otp)


		this.http.post<any>(config.base + routes.salary.addStaffs, formData, config.httpOptionsFormData())
			.subscribe({
				next: (data: any) => {
					// output data

					// check data returned
					// if (data == true) {
					// notification
					this.ui.info(`Staffs successfully added.`)

					// reset forms
					this.hasUploaded = false

					this.router.navigate(["/user/staffs"])

				},
				error: error => {
					// error
					this.ui.error(error)
					// processing modal
					modal.hide(`#modal-processing`)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// get selected account information
	getSelectedAccountInformation(target: any) {
		// get index
		let controlIndex = target['selectedIndex']

		// check if index is 0
		if (controlIndex < 1) {
			// reset the lists
			this.accountsListSource = this.accounts
		}


		this.formBulkPayment.patchValue({
			currency: target.options[controlIndex].getAttribute("currency"),
			sourceAccountNumber: target.value,
		})

		// unide anything hidden
		this.accountsListSource = this.accounts
	}

	onFileSelected(event: any) {
		const file: File = event.target.files[0];
		this.fileToUpload = file;
	}


}
