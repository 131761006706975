import { NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './components/account/login/login.component';
import { FirstTimePasswordChangeComponent } from './components/account/first-password-change/first-password-change.component'
import { SecurityQuestionComponent } from './components/account/security-question/security-question.component'
import { SignupComponent } from './components/account/signup/signup.component';

import { LayoutComponent } from './components/home/layout/layout.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { TransfersComponent } from './components/home/transfers/transfers/transfers.component';
import { PendingTransactionsComponent } from './components/home/pending-transactions/pending-transactions.component';
import { TransactionHistoryComponent } from './components/home/transaction-history/transaction-history.component';
import { BulkPaymentComponent } from './components/home/transfers/bulk-payment/bulk-payment.component';
import { ManageWorkflowComponent } from './components/home/settings/manage-workflow/manage-workflow.component';
import { UsersComponent } from './components/home/settings/users/users.component';
import { ChangePasswordComponent } from './components/home/settings/change-password/change-password.component';
import { BillsPaymentComponent } from './components/home/bills-payment/bills-payment.component';
import { AccountStatementComponent } from './components/home/account-statement/account-statement.component';
import { BeneficiariesComponent } from './components/home/beneficiaries/beneficiaries.component';
import { LogoutComponent } from './components/account/logout/logout.component'
import { ChequeBookComponent } from './components/home/cheque-book/cheque-book.component';
import { StaffsComponent } from './components/home/payroll/staffs/staffs.component';
import { BulkUploadComponent } from './components/home/payroll/bulk-upload/bulk-upload.component';
import { SalarySchedulesComponent } from './components/home/payroll/schedules/schedules.component';
import { OnLendingComponent } from './components/home/on-lending/on-lending.component';
import { ChangePinComponent } from './components/home/settings/change-pin/change-pin.component';



@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    TransfersComponent,
    PendingTransactionsComponent,
    TransactionHistoryComponent,
    BulkPaymentComponent,
    ManageWorkflowComponent,
    UsersComponent,
    BillsPaymentComponent,
    AccountStatementComponent,
    LoginComponent,
    FirstTimePasswordChangeComponent,
    SecurityQuestionComponent,
    SignupComponent,
    BeneficiariesComponent,
    ChangePasswordComponent,
    ChequeBookComponent,
    LogoutComponent,
    StaffsComponent,
    BulkUploadComponent,
    SalarySchedulesComponent,
    OnLendingComponent,
    ChangePinComponent
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastNoAnimationModule.forRoot({
      enableHtml: true,
      preventDuplicates: true,
      includeTitleDuplicates: true
    }),
  ],
  providers: [
    DatePipe,
    CookieService,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
