import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { DatePipe } from '@angular/common'
import { environment } from '@environments/environment'


import * as html2pdf from '../../../../assets/js/html2pdf.bundle.js';

// define template
let template = 'beneficiaries.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class BeneficiariesComponent implements OnInit {
  currentUser: any
  formNewBeneficiary: FormGroup
  formNewBeneficiaryInterbank: FormGroup
  corporateCustomerId: string
  authorizationType: string
  currentUserId: string
  currentlySelectedView: string
  currentlySelectedViewPageTitle: string
  beneficiariesIntra: any = []
  beneficiariesInter: any = []
  nameEnquiryForTransferIntra: boolean
  nameEnquiryForTransferInter: boolean
  destinationAccountDetails: any
  destinationAccountDetailsInterbank: any
  banksList: any = []
  currentlySelectedBeneficiary: any

  constructor(private table: DataTablesService,
    private http: HttpClient,
    private ui: UiService,
    private logger: LogService,
    private fb: FormBuilder,
    private datepipe: DatePipe) {
    // get user
    this.currentUser = config.currentUser()
    // get corporate id
    this.corporateCustomerId = store.get('corporateCustomerId')
    this.authorizationType = store.get('authorizationType')
    // get user id
    this.currentUserId = store.get('userId')

    // set view
    this.currentlySelectedView = 'intra'
    this.currentlySelectedViewPageTitle = 'Parallex Bank Beneficiaries'
  }

  ngOnInit() {
    // initialize table
    this.table.init()

    // define form
    this.formNewBeneficiary = this.fb.group({
      accountNumber: ['', Validators.required],
      accountName: [''],
      bankName: [''],
      bankCode: [''],
    })

    // define form
    this.formNewBeneficiaryInterbank = this.fb.group({
      accountNumber: ['', Validators.required],
      accountName: [''],
      bankName: [''],
      bankCode: [''],
    })

    // fetch intra bank beneficiaries
    this.fetchBeneficiariesIntra()
  }

  // switch the view
  changeCurrentScreen(screen: string) {
    // check the screen
    switch (screen) {
      case 'intra':
        // fetch intra bank beneficiaries
        this.fetchBeneficiariesIntra()
        break
      case 'inter':
        // fetch inter bank beneficiaries
        this.fetchBeneficiariesInter()
        break
    }
  }

  // fetch all banks
  async fetchBanksList() {
    // define payload
    let payload = {}

    // blocker
    this.ui.blockUiOn(`Fetching required information... please wait`)

    // network call
    this.http.post<any>(config.base + routes.transactions.interbankList, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data
          this.logger.log(data)

          // auto select nip banks by default
          this.banksList = [...data?.data?.banks]

          // info
          this.ui.info(`Information successfully fetched.`)

          // modal
          modal.show(`#modal-new-beneficiary-interbank`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // fetch beneficiaries / intra
  async fetchBeneficiariesIntra() {
    // progress
    this.ui.blockUiOn(`Fetching  Parallex bank beneficiaries... please wait`)

    // network call
    this.http.get<any>(config.base + routes.corporateCustomers.intraBankBeneficiaries, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log
          this.logger.log(data)

          // change the screen
          this.currentlySelectedView = 'intra'
          this.currentlySelectedViewPageTitle = ' Parallex Bank Beneficiaries'

          // destroy table
          this.table.destroy(`beneficiaries-intra`)
          // pass data
          this.beneficiariesIntra = data?.data
          // reinit table
          this.table.reInit(`beneficiaries-intra`)

          // notification
          if (this.beneficiariesIntra?.length) this.ui.success(` Parallex bank beneficiaries successfully fetched.`)
          else this.ui.warning(`No beneficiaries available at the moment for  Parallex bank.`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => this.ui.blockUiOff());
  }

  // fetch beneficiaries / inter
  async fetchBeneficiariesInter() {
    // progress
    this.ui.blockUiOn(`Fetching other banks beneficiaries... please wait`)

    // network call
    this.http.get<any>(config.base + routes.corporateCustomers.interBankBeneficiaries, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log
          this.logger.log(data)

          // change the screen
          this.currentlySelectedView = 'inter'
          this.currentlySelectedViewPageTitle = 'Other Banks Beneficiaries'

          // destroy table
          this.table.destroy(`beneficiaries-inter`)
          // pass data
          this.beneficiariesInter = data?.data
          // reinit table
          this.table.reInit(`beneficiaries-inter`)

          // notification
          if (this.beneficiariesInter?.length) this.ui.success(`Other banks beneficiaries successfully fetched.`)
          else this.ui.warning(`No beneficiaries available at the moment for other banks.`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      }).add(() => this.ui.blockUiOff());
  }

  // initiate add new beneficiary
  initiateAddBeneficiary() {
    // empty account information
    this.destinationAccountDetails = {}
    this.destinationAccountDetailsInterbank = {}

    // modal
    if (this.currentlySelectedView == 'intra') modal.show(`#modal-new-beneficiary`)
    else this.fetchBanksList()
  }

  // initiate remove beneficiary
  initiateRemoveBeneficiary(item: any) {
    // pass data
    this.currentlySelectedBeneficiary = item

    // remove
    this.removeBeneficiary()
  }

  // get account information
  async getAccountInformation(target: any) {
    // get account number
    let account = String(target.value).replace(/\s*/g, "")

    // check length of account number
    if (account.length != 10) {
      // change status
      this.nameEnquiryForTransferIntra = false
      // empty store
      this.destinationAccountDetails = {}
      return
    }

    // check if status is on
    if (this.nameEnquiryForTransferIntra) return

    // turn on status
    this.nameEnquiryForTransferIntra = true

    // progress
    this.ui.blockUiOn(`Fetching account information... please wait`)

    // define payload
    let payload = await store.preparePayload({
      accountNumber: String(account)
    }, true)



    // network call
    this.http.get<any>(config.base + routes.manageAccounts.customerNameEnquiry + `?accountNumber=${payload.accountNumber}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log
          this.logger.log(data)

          // pass on data
          this.destinationAccountDetails = data?.data

          // check if null
          if (!data || Object.keys(data).length < 1) {
            // turn off name enquiry
            this.nameEnquiryForTransferIntra = false
            // reset value
            this.formNewBeneficiary.patchValue({
              accountNumber: '',
              accountName: '',
            })
            // error
            this.ui.error(`Account information was not returned. You might want to try again.`)
          } else {
            // insert destination account name
            this.formNewBeneficiary.patchValue({
              accountName: this.destinationAccountDetails.accountName,
            })

            // notification
            this.ui.success(`Name enquiry was successful.`)
          }
        },
        error: error => {
          // error
          this.ui.error(error)
          // turn off name enquiry
          this.nameEnquiryForTransferIntra = false
          // reset value
          this.formNewBeneficiary.patchValue({
            accountNumber: '',
            accountName: '',
          })
        }
      }).add(() => this.ui.blockUiOff());
  }

  // get account information
  async getAccountInformationForInterbank(target: any) {
    // values
    let values = this.formNewBeneficiaryInterbank.value

    // get account number
    let account = String(target.value).replace(/\s*/g, "")

    // check length of account number
    if (account.length != 10) {
      // change flag
      this.nameEnquiryForTransferInter = false
      // empty store
      this.destinationAccountDetailsInterbank = {}
      return
    }

    // check if status is on
    if (this.nameEnquiryForTransferInter) return

    // turn on status
    this.nameEnquiryForTransferInter = true

    // progress
    this.ui.blockUiOn(`Fetching account information... please wait`)

    // define payload
    let payload = await store.preparePayload({
      accountNumber: String(account),
      destinationBankCode: values.bankCode
    })



    // network call
    this.http.post<any>(config.base + routes.transactions.interbankNameEnquiry, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // log
          this.logger.log(data)

          // pass on data
          this.destinationAccountDetailsInterbank = data?.data

          // check if null
          if (!data || Object.keys(data).length < 1) {
            // turn off name enquiry
            this.nameEnquiryForTransferInter = false
            // reset value
            this.formNewBeneficiaryInterbank.patchValue({
              accountNumber: '',
              accountName: '',
              bankName: '',
              bankCode: ''
            })
            // error
            this.ui.error(`Account information was not returned. You might want to try again.`)
          } else {
            // insert destination account name
            this.formNewBeneficiaryInterbank.patchValue({
              accountName: this.destinationAccountDetailsInterbank?.accountName || '',
            })

            // check if account name is valid
            if (!this.destinationAccountDetailsInterbank.accountName) {
              // warning
              // this.ui.warning(`Destination account name was not returned. You may want to check again.`)
            }

            // notification
            this.ui.success(`Name enquiry was successful.`)
          }
        },
        error: error => {
          // error
          this.ui.error(error)
          // turn off name enquiry
          this.nameEnquiryForTransferInter = false
          // reset value
          this.formNewBeneficiaryInterbank.patchValue({
            accountNumber: '',
            accountName: '',
            bankName: '',
            bankCode: ''
          })
        }
      }).add(() => this.ui.blockUiOff());
  }

  // get selected account information
  getSelectedAccountInformation(target: any) {
    // get index
    let controlIndex = target['selectedIndex']

    // patch values
    this.formNewBeneficiaryInterbank.patchValue({
      bankName: target.options[controlIndex].getAttribute("bankName"),
      accountNumber: '',
    })
  }

  // add new beneficiary
  async addNewBeneficiary(target: any) {
    //occupy
    // config.network.occupy(this.ui)

    // get form
    let form = find(target)

    // check if form is valid
    if (!this.formNewBeneficiary.valid) {
      // warning
      this.ui.warning(`Please fill all required fields!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // blocker
    this.ui.blockUiOn(`Saving beneficiary... please wait`)

    // define payload
    let payload = await store.preparePayload({
      ...this.formNewBeneficiary.value,
      bankCode: '000030'
    })

    // log
    this.logger.log(payload)

    // perform network call
    this.http.post<any>(config.base + routes.corporateCustomers.addBeneficiary, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Beneficiary successfully saved.`)

          // dismiss form
          this.formNewBeneficiary.reset()
          // auto-select
          this.formNewBeneficiary.patchValue({
            accountNumber: '',
            accountName: '',
            bankName: '',
            bankCode: '',
          })

          // empty store
          this.destinationAccountDetails = {}

          // hide
          modal.hide(`#modal-new-beneficiary`)

          // fetch intra beneficiaries
          this.fetchBeneficiariesIntra()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // add new beneficiary
  async addNewBeneficiaryInterbank(target: any) {
    //occupy
    // config.network.occupy(this.ui)

    // get form
    let form = find(target)

    // check if form is valid
    if (!this.formNewBeneficiaryInterbank.valid) {
      // warning
      this.ui.warning(`Please fill all required fields!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // blocker
    this.ui.blockUiOn(`Saving beneficiary... please wait`)

    // define payload
    let payload = await store.preparePayload({
      ...this.formNewBeneficiaryInterbank.value
    })

    // log
    this.logger.log(payload)

    // perform network call
    this.http.post<any>(config.base + routes.corporateCustomers.addBeneficiary, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Beneficiary successfully saved.`)

          // dismiss form
          this.formNewBeneficiaryInterbank.reset()
          // auto-select
          this.formNewBeneficiaryInterbank.patchValue({
            accountNumber: '',
            accountName: '',
            bankName: '',
            bankCode: '',
          })

          // empty store
          this.destinationAccountDetailsInterbank = {}

          // hide
          modal.hide(`#modal-new-beneficiary-interbank`)

          // fetch inter beneficiaries
          this.fetchBeneficiariesInter()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // remove beneficiary
  async removeBeneficiary() {
    //occupy
    // config.network.occupy(this.ui)

    // blocker
    this.ui.blockUiOn(`Removing beneficiary... please wait`)

    // define payload
    let payload = await store.preparePayload({
      beneficiaryId: this.currentlySelectedBeneficiary.id
    })

    // define endpoints
    let endpoint = {
      'intra': routes.corporateCustomers.removeBeneficiaryIntra,
      'inter': routes.corporateCustomers.removeBeneficiaryInter,
    }

    // log
    this.logger.log(payload)

    // perform network call
    this.http.post<any>(config.base + endpoint[this.currentlySelectedView], payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Beneficiary successfully removed.`)

          // fetch intra beneficiaries
          if (this.currentlySelectedView == 'intra') this.fetchBeneficiariesIntra()
          else this.fetchBeneficiariesInter()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }
}