<div class="scrollable-content con">
   <div class="d-flex widgets-container">
      <div class="mx-auto">
         <div class="block h-fit content">
            <div class="mt-4 text-center">
               <span style="color: #010201;" class="fs-4">Change password</span>
            </div>
   
            <div class="w-100 text-center ">
               <form [formGroup]="formPasswordChange" class="m-auto needs-validation"
                  (ngSubmit)="changeDefaultPassword($event.target)">
                  <div class="row text-left mt-5">
                     <div class="col-12 mt-3">
                        <div class="mb-3">
                           <label class="form-label fw-semibold">Current Password</label>
                           <div class="input-group">
                              <input type="password" class="form-control" formControlName="oldPassword"
                                 required placeholder="Enter your current password">
                              <span class="input-group-text pointer" id="toggle-password"><i
                                    class="ri-eye-line"></i></span>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 mt-3">
                        <div class="mb-3">
                           <label class="form-label fw-semibold">New Password</label>
                           <div class="input-group">
                              <input type="password" class="form-control" formControlName="newPassword" required
                                 placeholder="minimum of 8 characters containing at least 1 special character">
                              <span class="input-group-text pointer" id="toggle-password"><i
                                    class="ri-eye-line"></i></span>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 mt-3">
                        <div class="mb-3">
                           <label class="form-label fw-semibold">Confirm Password</label>
                           <div class="input-group">
                              <input type="password" class="form-control" formControlName="confirmPassword"
                                 required placeholder="Must match New Password">
                              <span class="input-group-text pointer" id="toggle-password"><i
                                    class="ri-eye-line"></i></span>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-12 mt-3">
                        <div class="text-end">
   
                           <!-- regular utton -->
                           <button type="submit" *ngIf="!firstTimeProcessStatus"
                              class="btn btn-main figma-dark w-100 mb-5">
                              Change Password
                           </button>
   
                           <!-- loader button -->
                           <button type="button" *ngIf="firstTimeProcessStatus" [disabled]="firstTimeProcessStatus"
                              class="btn btn-main figma-dark w-100 mb-5">
                              <span *ngIf="firstTimeProcessStatus" class="spinner-border spinner-border-sm mr-1"
                                 role="status" aria-hidden="true"></span> <span *ngIf="firstTimeProcessStatus">
                                 please wait...</span>
                           </button>
   
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         
   
      </div>
      <!-- <div class="footer-links p-2 mt-5">
         <a href="#">Company</a>
         <a href="#">About Us</a>
         <a href="#">Team</a>
         <a href="#">Products</a>
         <a href="#">Blogs</a>
      </div> -->
   
   </div>
   <!-- <img src="assets/images/reset.png" class="bg-img" /> -->
   
   </div>