<div class="d-flex widgets-container">
	<div class="flex-grow-1 p-5 d-flex flex-column align-items-center position-relative text-white">
		<div class="w-100 text-center">
			<div class="fs-1">Sign Up <span class="fw-semibold">Today!</span></div>
			<div class="fs-5 mt-4">Enjoy the Next Generation of Inclusive Corporate online & <span style="color: #EF1A3A;">Secured</span> Banking.</div>
		</div>

		<div class="position-absolute" style="bottom: 10px;">
			Copyright © 2022 Parallex Bank
		</div>
	</div>
	<div class="d-flex flex-grow-1 flex-column align-items-center position-relative">
		<img src="assets/images/main-logo.png" class="position-absolute" style="top: 20px; right: 20px;" />
		
		<div class="p-3 mb-4" style="margin-top: 15rem;">
			<div class="fw-semibold fs-2">Welcome to the Future!</div>
			<div class="fs-6 mt-2">Please sign up with your Corporate details to get started.</div>
			<div class="mt-4" style="max-width: 580px;">
				<form action="javascript:void(0);">
			    <div class="row">
			        <div class="col-12">
			            <div class="mb-3">
			                <label class="form-label">Profile</label>
			                <select class="form-select">
			                	<option value="">Select Profile</option>
			                </select>
			            </div>
			        </div>
			        <div class="col-12 mt-1">
			            <div class="mb-3">
			                <label class="form-label">Company Name</label>
			                <input type="text" class="form-control" placeholder="Enter your company name">
			            </div>
			        </div>
			        <div class="col-12 mt-1">
			            <div class="mb-3">
			                <label class="form-label">Email Address</label>
			                <input type="text" class="form-control" placeholder="Enter your email address">
			            </div>
			        </div>
			        <div class="col-12 mt-1">
			            <div class="mb-3">
			                <label class="form-label">Username</label>
			                <input type="text" class="form-control" placeholder="Enter your username">
			            </div>
			        </div>
			        <div class="col-12 mt-1">
			            <div class="mb-3">
			                <label class="form-label">Password</label>
			                <div class="input-group">
			                	<input type="password" class="form-control" placeholder="Enter your password">
			                	<span class="input-group-text pointer" id="toggle-password"><i class="ri-eye-line"></i></span>
			                </div>
			            </div>
			        </div>
			        <div class="col-lg-12 mt-3">
			            <div class="text-end">
			                <button type="submit" class="btn btn-dark figma-dark w-100" routerLink="/verification">Sign Up</button>
			            </div>
			        </div>
			        <div class="col-lg-12 mt-3">
			            <div class="fw-semibold">
			            	Already have an Account? <span class="text-danger pointer" routerLink="/login">Sign In</span>
			            </div>
			        </div>
			    </div>
				</form>
			</div>
		</div>
	</div>
</div>