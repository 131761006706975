<div class="scrollable-content">
    <div class="col-sm-12 col-lg-12 col-xxl-12 text-center pb-6" style="border-bottom: 1px solid #e9ebec; height: 100px;">
        <div class="btn-group">
            <button class="btn {{ currentlySelectedView == 'intra' ? 'btn-dark' : 'btn-light' }}" (click)="changeCurrentScreen('intra')" type="button">
                Parallex Bank
            </button>
            <button class="btn {{ currentlySelectedView == 'inter' ? 'btn-dark' : 'btn-light' }} ml-2" (click)="changeCurrentScreen('inter')" type="button">
                Other Banks
            </button>
        </div>
    </div>
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">{{ currentlySelectedViewPageTitle }}</h4>
                <div class="flex-shrink-0">
                    <div class="btn-group">
                        <button class="btn btn-main ml-2" (click)="initiateAddBeneficiary()" type="button">
                            <i class="mdi mdi-account-plus-outline fs-5 mr-2"></i> Add Beneficiary
                        </button>
                    </div>
                </div>
            </div>

            <!-- beneficiaries / intra -->
            <div class="card-body" *ngIf="currentlySelectedView == 'intra'">
                <div class="d-flex">
                    <div class="custom-datatable overflow-auto vertical-align-top-table transaction-history-records w-100">

                        <table id="beneficiaries-intra" data-control-id='beneficiaries-intra' data-control-scope='transaction-history' class="table datatable table-striped table-bordered" style="width:100%">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Account Name</th>
                                    <th>Account Number</th>
                                    <th>Date Added</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of beneficiariesIntra; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item?.accountName}}</td>
                                    <td>{{ item?.accountNumber }}</td>
                                    <td>{{ item?.dateAdded | date }}</td>
                                    <td>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="ri-menu-2-fill"></i>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item pointer" (click)="initiateRemoveBeneficiary(item)">Remove</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <!-- beneficiaries / inter -->
            <div class="card-body" *ngIf="currentlySelectedView == 'inter'">
                <div class="d-flex">
                    <div class="custom-datatable overflow-auto vertical-align-top-table transaction-history-records w-100">

                        <table id="beneficiaries-inter" data-control-id='beneficiaries-inter' data-control-scope='transaction-history' class="table datatable table-striped table-bordered" style="width:100%">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Account Name</th>
                                    <th>Account Number</th>
                                    <th>Bank Name</th>
                                    <th>Bank Code</th>
                                    <th>Date Added</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of beneficiariesInter; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item?.accountName}}</td>
                                    <td>{{ item?.accountNumber }}</td>
                                    <td>{{ item?.destinationInstitutionCode }}</td>
                                    <td>{{ item?.destinationInstitutionName }}</td>
                                    <td>{{ item?.dateAdded | date }}</td>
                                    <td>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="ri-menu-2-fill"></i>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item pointer" (click)="initiateRemoveBeneficiary(item)">Remove</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            
        </div>
    </div>
</div>

<!-- create beneficiary -->
<div class="modal fade" id="modal-new-beneficiary" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    New Beneficiary
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formNewBeneficiary" class="w-100 needs-validation" (ngSubmit)="addNewBeneficiary($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="accountNumber" (keyup)="getAccountInformation($event.target)" required placeholder="Enter Account Number">
                                    <label>Account Number</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-2" *ngIf="destinationAccountDetails?.accountName">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="accountName" readonly placeholder="Enter Account Name">
                                    <label>Account Name</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5" data-bs-dismiss="modal">Back</button>
                                    <button type="reset" data-network-action="hide"  class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- create beneficiary -->
<div class="modal fade" id="modal-new-beneficiary-interbank" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    New Beneficiary
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formNewBeneficiaryInterbank" class="w-100 needs-validation" (ngSubmit)="addNewBeneficiaryInterbank($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" formControlName="bankCode" required (change)="getSelectedAccountInformation($event.target)">
                                      <option value="">Select Bank</option>
                                      <option *ngFor="let item of banksList"
                                        [value]="item?.institutionCode"
                                        [attr.neftMainCode]="item?.code"
                                        [attr.neftBankNipCode]="item?.nipCode"
                                        [attr.bankName]="item?.institutionName"
                                        [attr.category]="item?.category">{{ item?.institutionName || item?.institutionCode }}</option>
                                    </select>
                                    <label for="floatingSelect">Select Beneficiary Bank</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="accountNumber" (keyup)="getAccountInformationForInterbank($event.target)" required placeholder="Enter Account Number">
                                    <label>Account Number</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-2" *ngIf="destinationAccountDetailsInterbank?.accountName">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="accountName" readonly placeholder="Enter Account Name">
                                    <label>Account Name</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5" data-bs-dismiss="modal">Back</button>
                                    <button type="reset" data-network-action="hide"  class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>