import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CurrencyPipe } from '@angular/common';

// define template
let template = 'manage-workflow.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})
export class ManageWorkflowComponent implements OnInit {
  config = config
  workflows: any = []
  formCreateCorporateWorkflow: FormGroup
  formDeclineCorporateWorkflowReason: FormGroup
  corporateCustomerWorkflows: any = []
  corporateCustomerWorkflowsPending: any = []
  workflowOperationStatusCreate: boolean
  workflowOperationStatusUpdate: boolean
  currentlySelectedCorporateWorkflow: any
  currentlySelectedProfile: any
  currentlySelectedTab: any
  authorizers: any = []
  workflowHierarchy: any = []
  transactionTypes: any = []
  workflowAuthorizationLevels: any = []
  corporateUsersForWorkflow: any = []
  corporateUserRolesForWorkflow: any = []
  workflowAuthorizersApproverIDs: any = []
  workflowAuthorizersApproverLevels: any = []
  corporateCustomerId: any

  constructor(private table: DataTablesService,
    private ui: UiService,
    private logger: LogService,
    private http: HttpClient,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe) {
    // get corporate id
    this.corporateCustomerId = store.get('corporateCustomerId')
  }

  ngOnInit() {
    // define form
    this.formCreateCorporateWorkflow = this.fb.group({
      corporateCustomerId: [''],
      name: ['', Validators.required],
      description: ['', Validators.required],
      noOfAuthorizers: [1, Validators.required],
      approvalLimit: ['', Validators.required],
    })

    this.formCreateCorporateWorkflow.valueChanges.subscribe(form => {
      if (form.approvalLimit) {
        this.formCreateCorporateWorkflow.patchValue({
          approvalLimit: this.currencyPipe.transform(form.approvalLimit.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
        }, { emitEvent: false })
      }
    })
    // define form
    this.formDeclineCorporateWorkflowReason = this.fb.group({
      reason: ['', Validators.required]
    })
    // initialize table
    this.table.init()

    // fetch workflows
    if (config.currentUserHasPermissionByKey('ViewWorkflow')) {
      this.fetchAllCorporateCustomerWorkflows()
    }
  }

  // add new authorizer
  addNewAuthorizer() {
    // populate store
    this.authorizers.push(this.authorizers.length)
  }



  // remove authorizer
  removeAuthorizerRow(index: any) {
    // check if last row remaining
    if (this.authorizers.length < 2) return
    // remove index
    this.authorizers.splice(index, 1)
  }

  // fetch corporate customer workflows
  async fetchAllCorporateCustomerWorkflows() {
    // pass the profile
    let id = this.corporateCustomerId
    // loading
    this.ui.blockUiOn(`Fetching corporate customer workflows... please wait`)

    let payload = await store.preparePayload({ id }, true)

    // network call
    this.http.get<any>(config.base + routes.workflows.getWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data
          this.corporateCustomerWorkflows = data

          // destroy
          this.table.destroy(`manage-workflow`)
          // pass on data
          // reinit
          this.table.reInit(`manage-workflow`)

          // notification
          this.ui.info(`Workflow loaded successfully.`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
    await this.fetchPendingCorporateCustomerWorkflows()

  }

  // fetch corporate customer workflows
  async fetchPendingCorporateCustomerWorkflows() {
    // pass the profile
    let id = this.corporateCustomerId
    // loading
    this.ui.blockUiOn(`Fetching corporate customer workflows... please wait`)

    let payload = await store.preparePayload({ id }, true)

    // network call
    this.http.get<any>(config.base + routes.workflows.getPendingWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data
          this.corporateCustomerWorkflowsPending = data.data

          // destroy
          this.table.destroy(`manage-workflow-pending`)
          // pass on data

          // reinit
          this.table.reInit(`manage-workflow-pending`)
          // notification
          this.ui.info(`Workflow loaded successfully.`)
        },
        error: error => {
          // error
          this.ui.error(error)
        }
      })
      .add(() => this.ui.blockUiOff());
  }

  // fetch corporate customer users / for workflow
  async fetchAllCorporateCustomerUsersForWorkflow() {
    // get corporate id
    let id = this.corporateCustomerId

    // loading
    this.ui.blockUiOn(`Fetching corporate users... please wait`)
    let payload = await store.preparePayload({
      id
    }, true)
    // network call
    this.http.get<any>(config.base + routes.corporateCustomerUsers.getAllCorporateUsers + `?corporateCustomerId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // output data

          // pass on data
          this.corporateUsersForWorkflow = data?.data

          // notification
          this.ui.info(`Corporate users loaded successfully.`)

          // unblock
          this.ui.blockUiOff()

          // fetch workflow hierarchy
          this.fetchWorkflowHierarchy()
        },
        error: error => {
          // error
          this.ui.error(error)

          // unblock
          this.ui.blockUiOff()
        }
      })
      .add(() => { });

  }

  // request approval for corporate workflow
  async requestApprovalForCorporateWorkflow() {
    // blocker
    this.ui.blockUiOn(`Requesting corporate workflow approval... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateWorkflow.id
    })



    // network
    this.http.post<any>(config.base + routes.workflows.requestApproval, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate workflow approval request successfully sent.`)

          // fetch profiles
          this.fetchAllCorporateCustomerWorkflows()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // fetch workflow hierarchy
  async fetchWorkflowHierarchy() {
    // loading
    this.ui.blockUiOn(`Fetching workflow information... please wait`)

    // get workflow
    let workflow = this.currentlySelectedCorporateWorkflow

    let payload = await store.preparePayload(workflow, true)
    const r = this.currentlySelectedTab == 'all' ? routes.workflowHierarchy.getWorkflowHierarchy : routes.workflowHierarchy.getTempWorkflowHierarchy
    // network call
    this.http.get<any>(config.base + r + `?workflowId=${payload.id}`, config.httpOptions())
      .subscribe({
        next: (data: any) => {

          // pass on data
          this.workflowHierarchy = data?.data
          // notification
          this.ui.info(`Workflow hierarchy loaded successfully.`)

          // unblock
          this.ui.blockUiOff()

          // empty stores
          this.authorizers = []
          this.workflowAuthorizersApproverIDs = []
          this.workflowAuthorizersApproverLevels = []

          for (let index = 0; index < this.currentlySelectedCorporateWorkflow.noOfAuthorizers; index++) {
            this.authorizers.push(
              {
                // id: data?.data[i]?.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                workflowID: "",
                authorizationLevel: 0,
                approverID: "",
                approverName: ""
              }
            )
          }

          // create control range
          for (let i = 0; i < this.currentlySelectedCorporateWorkflow.noOfAuthorizers; i++) {
            // get approver
            let approver: any

            // check if role is not empty
            if (data?.data?.length) {
              // get approver
              approver = this.corporateUsersForWorkflow.find(item => item?.id == data?.data[i].approverId)
            }

            // populate array 
            this.authorizers[i] = {
              id: data?.data[i]?.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              workflowID: data?.data[i]?.workflowId || "",
              authorizationLevel: data?.data[i]?.authorizationLevel || 0,
              approverID: data?.data[i]?.approverId || "",
              approverName: String((approver?.firstName || "") + ' ' + (approver?.middleName || "") + ' ' + (approver?.lastName || "")).trim()
            }
          }

          this.logger.log(this.authorizers)

          // show modal
          modal.show(`#modal-manage-workflow-authorizers`)
        },
        error: error => {
          // error
          this.ui.error(error)
          // unblock
          this.ui.blockUiOff()
        }
      })
      .add(() => { });
  }

  // initiate create or update workflow
  initiateCreateOrUpdateCorporateWorkflow(mode: boolean, workflow?: any, tab?: string) {
    // clear form
    this.formCreateCorporateWorkflow.reset()
    // auto-select
    this.formCreateCorporateWorkflow.patchValue({
      noOfAuthorizers: 1,
      approvalLimit: 0,
    })

    // define status flags
    this.workflowOperationStatusCreate = mode
    this.workflowOperationStatusUpdate = !mode

    // pass data if null or not
    this.currentlySelectedTab = tab
    this.currentlySelectedCorporateWorkflow = workflow

    // check if update operation for workflow
    if (this.workflowOperationStatusUpdate) {
      // get workflow
      let workflow = this.currentlySelectedCorporateWorkflow
      // add values
      this.formCreateCorporateWorkflow.patchValue({
        name: workflow.name,
        description: workflow.description,
        noOfAuthorizers: workflow.noOfAuthorizers,
        approvalLimit: config.addCommas(workflow.approvalLimit),
      })
    }

    // bring up modal
    modal.show(`#modal-new-corporate-workflow`)
  }

  // create or update workflow
  async createOrUpdateCorporateWorkflow(target: any) {
    // get form
    let form = find(target)

    // check if form is valid
    if (!this.formCreateCorporateWorkflow.valid) {
      // warning
      this.ui.warning(`Please fill all required fields!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }

    // define endpoint
    let endpoint = {
      true: routes.workflows.createWorkflows,
      false: this.currentlySelectedTab == 'all' ? routes.workflows.updatedWorkflows : routes.workflows.modifyWorkflows,
    }


    // blocker
    this.ui.blockUiOn(`Saving corporate workflow... please wait`)

    // define operation
    let operation = null
    // define payload
    let payload = null

    // switch case
    switch (this.workflowOperationStatusCreate) {
      case true:
        // define payload
        payload = await store.preparePayload({
          ...this.formCreateCorporateWorkflow.value,
          corporateCustomerId: this.corporateCustomerId,
          approvalLimit: config.removeCommas(this.formCreateCorporateWorkflow.value.approvalLimit)
        })

        // perform network call
        operation = this.http.post<any>(config.base + endpoint[`${this.workflowOperationStatusCreate}`], payload, config.httpOptions())
        break
      case false:
        // define payload
        payload = await store.preparePayload({
          ...this.formCreateCorporateWorkflow.value,
          id: this.currentlySelectedCorporateWorkflow.id,
          corporateCustomerId: this.corporateCustomerId,
          approvalLimit: config.removeCommas(this.formCreateCorporateWorkflow.value.approvalLimit)
        })

        // perform network call
        operation = this.http.post<any>(config.base + endpoint[`${this.workflowOperationStatusCreate}`], payload, config.httpOptions())
        break
    }

    // network
    operation
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate customer workflow successfully saved.`)

          // dismiss form
          this.formCreateCorporateWorkflow.reset()
          // auto-select
          this.formCreateCorporateWorkflow.patchValue({
            noOfAuthorizers: 1,
            approvalLimit: 0,
          })

          // check if update op
          if (this.workflowOperationStatusUpdate) modal.hide(`#modal-new-corporate-workflow`)

          // fetch workflow
          this.fetchAllCorporateCustomerWorkflows()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // initiate workflow approval request
  initiateCorporateWorkflowApprovalRequest(workflow: any) {
    // pass on data
    this.currentlySelectedCorporateWorkflow = workflow
    // request approval
    this.requestApprovalForCorporateWorkflow()
  }

  // initiate workflow authorizers
  initiateCorporateWorkflowAuthorizersManage(workflow: any, tab: string) {
    // pass on data
    this.currentlySelectedCorporateWorkflow = workflow
    this.currentlySelectedTab = tab

    // loop and generate values
    for (let i = 0; i < workflow.noOfAuthorizers; i++) {
      // get levels
      this.workflowAuthorizationLevels[i] = i + 1
    }

    // fetch users
    this.fetchAllCorporateCustomerUsersForWorkflow()
  }

  // initiate approve workflow
  initiateCorporateWorkflowApprove(workflow: any) {
    // pass on data
    this.currentlySelectedCorporateWorkflow = workflow
    // approve profile
    this.approveCorporateWorkflow()
  }

  // initiate decline workflow
  initiateCorporateWorkflowDecline(workflow: any) {

    // pass on data
    this.currentlySelectedCorporateWorkflow = workflow
    // bring up modal
    modal.show(`#modal-user-workflow-decline-reason`)
  }


  // approve corporate workflow
  async approveCorporateWorkflow() {
    // blocker
    this.ui.blockUiOn(`Approving corporate workflow... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateWorkflow.id
    })



    // network
    this.http.post<any>(config.base + routes.workflows.approveWorkflow, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate user workflow successfully approved.`)

          // fetch profiles
          this.fetchAllCorporateCustomerWorkflows()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // decline corporate workflow
  async declineCorporateWorkflow() {
    // check if form is valid
    if (!this.formDeclineCorporateWorkflowReason.valid) {
      // warning
      this.ui.warning(`Please provide reason for declining corporate workflow!`)
      return
    }

    // blocker
    this.ui.blockUiOn(`Declining corporate workflow... please wait`)

    // define payload
    let payload = await store.preparePayload({
      id: this.currentlySelectedCorporateWorkflow.id,
      reason: this.formDeclineCorporateWorkflowReason.value.reason
    })


    // network
    this.http.post<any>(config.base + routes.workflows.declineWorkflow, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Corporate workflow successfully declined.`)

          // dismiss form
          this.formDeclineCorporateWorkflowReason.reset()

          // dismiss modal
          modal.hide(`#modal-user-workflow-decline-reason`)

          // fetch profiles
          this.fetchAllCorporateCustomerWorkflows()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }

  // detect and grab value for control
  detectAndGrabValue(control: any, target: any, index: number) {
    // get control
    let controlIndex: number
    let duplicateIndex: number

    // check control
    switch (control) {
      case 'approver':
        // get control
        controlIndex = target['selectedIndex']
        let approverId = target.options[controlIndex].getAttribute("value")
        let approverName = target.options[controlIndex].getAttribute("approverName")

        // get possible duplicate data
        duplicateIndex = Array.from(this.workflowAuthorizersApproverIDs).indexOf(approverId)

        // check that approver has not already been selected
        if (duplicateIndex !== -1) {
          // warning
          this.ui.warning(`Authorizer has already been selected in line ${duplicateIndex + 1}.`)
          // reset value
          target.value = this.authorizers[index].approverID || ""
          return
        }

        // adjust value
        this.authorizers[index].approverID = approverId
        this.authorizers[index].approverName = approverName

        // add to general array
        this.workflowAuthorizersApproverIDs[index] = approverId
        break
      case 'level':
        // get control
        controlIndex = target['selectedIndex']
        let level = Number(target.options[controlIndex].getAttribute("value"))

        // get possible duplicate data
        duplicateIndex = Array.from(this.workflowAuthorizersApproverLevels).indexOf(level)

        // check if already selected
        if (duplicateIndex !== -1) {
          // warning
          this.ui.warning(`Level already selected for authorizer in line ${duplicateIndex + 1}.`)
          // reset value
          target.value = this.authorizers[index].authorizationLevel || ""
          return
        }

        // adjust value
        this.authorizers[index].authorizationLevel = level

        // add to global array
        this.workflowAuthorizersApproverLevels[index] = level
        break
    }

    // adjust workflow id
    this.authorizers[index].workflowID = this.currentlySelectedCorporateWorkflow.id
  }

  // create workflow hierarchy
  async createWorkflowHierarchy() {
    // loop through authorizers
    for (let i = 0; i < this.authorizers.length; i++) {
      // get authorizer
      let authorizer = this.authorizers[i]

      // get line  
      let line = i + 1

      // validate
      if (String(authorizer.approverID).length < 1) {
        // error
        this.ui.warning(`Authorizer not selected in line ${line}!`)
        return
      }

      // validate
      if (authorizer.authorizationLevel < 1) {
        // error
        this.ui.warning(`Authorizeration level required in line ${line}!`)
        return
      }
    }

    // check that number of authorizers provided
    if (this.authorizers.length !== this.currentlySelectedCorporateWorkflow.noOfAuthorizers) {
      // error
      this.ui.error(`Workflow expects ${this.currentlySelectedCorporateWorkflow.noOfAuthorizers} authorizer(s), only ${this.authorizers.length} provided.`)
      return
    }


    // blocker
    this.ui.blockUiOn(`Saving hierarchy for workflow... please wait`)

    const payload = await store.preparePayloadArray(this.authorizers)
    const r = this.currentlySelectedTab == 'all' ? routes.workflowHierarchy.modifyHierarchy : routes.workflowHierarchy.createHierarchy

    // network
    this.http.post<any>(config.base + routes.workflowHierarchy.createHierarchy, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // notification
          this.ui.success(`Hierarchy successfully saved.`)

          // empty authorizers
          this.authorizers = []

          // hide modal
          modal.hide(`#modal-manage-workflow-authorizers`)

          // refresh workflows list
          this.fetchAllCorporateCustomerWorkflows()
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      })
      .add(() => this.ui.blockUiOff())
  }
}