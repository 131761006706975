<div class="scrollable-content con">
	<div class="d-flex widgets-container">
		<div class="mx-auto">
			<img src="assets/images/main-logo.png" class="pointer mt-8" routerLink="/user/login" />
			<div class="block h-fit content">
				<div class="mt-4 text-center">
					<span style="color: #010201;" class="fs-4">Reset Credentials</span>
				</div>

				<div class="w-100 text-center ">
					<form *ngIf="resetPinStatus != '1'" [formGroup]="formBankUserFirstTimeLogin"
						class="m-auto needs-validation" (ngSubmit)="changeDefaultPassword($event.target)">
						<div class="row text-left mt-5">
							<div class="col-12">
								<div class="mb-3">
									<label class="form-label fw-semibold">Username</label>
									<input type="text" class="form-control" formControlName="UserName" readonly required
										placeholder="Enter your Username">
								</div>
							</div>
							<!-- <div class="col-12 mt-3 mb-2">
								<div class="mb-3">
									<label class="form-label fw-semibold">Customer ID</label>
									<div class="input-group">
										<input type="text" class="form-control" formControlName="CustomerId" readonly required
											placeholder="Enter your Customer ID">
									</div>
								</div>
							</div> -->
							<hr />
							<div class="col-12 mt-3">
								<div class="mb-3">
									<label class="form-label fw-semibold">Current Password</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="CurrentPassword" required
											placeholder="Enter your current password">
										<span class="input-group-text pointer" id="toggle-password"><i
												class="ri-eye-line"></i></span>
									</div>
								</div>
							</div>
							<div class="col-12 mt-3">
								<div class="mb-3">
									<label class="form-label fw-semibold">New Password</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="NewPassword" required
											placeholder="minimum of 8 characters containing 1 special character">
										<span class="input-group-text pointer" id="toggle-password"><i
												class="ri-eye-line"></i></span>
									</div>
								</div>
							</div>
							<div class="col-12 mt-3 mb-2">
								<div class="mb-3">
									<label class="form-label fw-semibold">Confirm Password</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="ConfirmNewPassword" required
											placeholder="Must match new password">
										<span class="input-group-text pointer" id="toggle-password"><i
												class="ri-eye-line"></i></span>
									</div>
								</div>
							</div>
							<br />
							<br />
							<hr />
							<div *ngIf="resetPasswordStatus != '1'">
								<div class="col-12 mt-3">
									<div class="mb-3">
										<label class="form-label fw-semibold">New Transaction Pin</label>
										<div class="input-group">
											<input type="password" class="form-control" formControlName="newPin"
												placeholder=" 4 digits" maxlength="4"
												(input)="replaceLettersWithSpace($event,'newPin',true)" />
											<span class="input-group-text pointer" id="toggle-password"><i
													class="ri-eye-line"></i></span>
										</div>
									</div>
								</div>
								<div  class="col-12 mt-3">
									<div class="mb-3">
										<label class="form-label fw-semibold">Confirm Transaction Pin</label>
										<div class="input-group">
											<input type="password" class="form-control" formControlName="ConfirmPin"
												placeholder="Must match new pin" maxlength="4"
												(input)="replaceLettersWithSpace($event,'ConfirmPin',true)" />
											<span class="input-group-text pointer" id="toggle-password"><i
													class="ri-eye-line"></i></span>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-12 mt-3">
								<div class="text-end">

									<!-- regular utton -->
									<button type="submit" *ngIf="!firstTimeProcessStatus"
										class="btn btn-main figma-dark w-100 mb-5">
										Save
									</button>

									<!-- loader button -->
									<button type="button" *ngIf="firstTimeProcessStatus" [disabled]="firstTimeProcessStatus"
										class="btn btn-main figma-dark w-100 mb-5">
										<span *ngIf="firstTimeProcessStatus" class="spinner-border spinner-border-sm mr-1"
											role="status" aria-hidden="true"></span> <span *ngIf="firstTimeProcessStatus">
											please wait...</span>
									</button>

								</div>
							</div>
						</div>
					</form>
					<form *ngIf="resetPinStatus == '1'" [formGroup]="formBankUserResetPin" class="m-auto needs-validation"
						(ngSubmit)="resetUserPin($event.target)">
						<div class="row text-left mt-5">
							<div class="col-12">
								<div class="mb-3">
									<label class="form-label fw-semibold">Username</label>
									<input type="text" class="form-control" formControlName="UserName" readonly required
										placeholder="Enter your Username">
								</div>
							</div>
							<div class="col-12 mt-3 mb-2">
								<div class="mb-3">
									<label class="form-label fw-semibold">Password</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="Password" readonly required
											placeholder="Enter your Password">
									</div>
								</div>
							</div>
							<hr />
							<br />
							<div class="col-12 mt-3">
								<div class="mb-3">
									<label class="form-label fw-semibold">Default Transaction Pin</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="defaultPin"
											placeholder="4 digits" maxlength="4"
											(input)="replaceLettersWithSpace($event,'defaultPin',false)">
										<span class="input-group-text pointer" id="toggle-password"><i
												class="ri-eye-line"></i></span>
									</div>
								</div>
							</div>
							<div class="col-12 mt-3">
								<div class="mb-3">
									<label class="form-label fw-semibold">New Transaction Pin</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="newPin"
											placeholder="4 digits" maxlength="4"
											(input)="replaceLettersWithSpace($event,'newPin',false)">
										<span class="input-group-text pointer" id="toggle-password"><i
												class="ri-eye-line"></i></span>
									</div>
								</div>
							</div>
							<div class="col-12 mt-3">
								<div class="mb-3">
									<label class="form-label fw-semibold">Confirm Transaction Pin</label>
									<div class="input-group">
										<input type="password" class="form-control" formControlName="ConfirmPin"
											placeholder="Must match new pin" maxlength="4"
											(input)="replaceLettersWithSpace($event,'ConfirmPin',false)">
										<span class="input-group-text pointer" id="toggle-password"><i
												class="ri-eye-line"></i></span>
									</div>
								</div>
							</div>
							<div class="col-lg-12 mt-3">
								<div class="text-end">

									<!-- regular utton -->
									<button type="submit" *ngIf="!firstTimeProcessStatus"
										class="btn btn-main figma-dark w-100 mb-5">
										Save
									</button>

									<!-- loader button -->
									<button type="button" *ngIf="firstTimeProcessStatus" [disabled]="firstTimeProcessStatus"
										class="btn btn-main figma-dark w-100 mb-5">
										<span *ngIf="firstTimeProcessStatus" class="spinner-border spinner-border-sm mr-1"
											role="status" aria-hidden="true"></span> <span *ngIf="firstTimeProcessStatus">
											please wait...</span>
									</button>

								</div>
							</div>
						</div>
					</form>
				</div>
			</div>


		</div>
		<!-- <div class="footer-links p-2 mt-5">
		<a href="#">Company</a>
		<a href="#">About Us</a>
		<a href="#">Team</a>
		<a href="#">Products</a>
		<a href="#">Blogs</a>
	</div> -->
		<img src="../../../../assets/images/svg/Arc.svg" class="arc1" />

	</div>
	<!-- <img src="assets/images/reset.png" class="bg-img" /> -->

</div>