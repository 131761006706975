import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';

import * as html2pdf from '../../../../assets/js/html2pdf.bundle.js';
import * as html2canvas from '../../../../assets/js/html2canvas.min.js';
// define template
let template = 'pending-transactions.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class PendingTransactionsComponent implements OnInit {
	config = config
	currentUser: any
	users: any = config.users
	pend: any[] = []
	formApprovalComment: FormGroup
	formTransferOtp: FormGroup
	transactions: any = []
	pendingTransactions: any = []
	pendingBulkTransactions: any = []
	creditAccountsList: any = []
	approvalUsersList: any = []
	filteredApprovalUsersList: any = []
	approvalHistoryDataStatus: boolean
	approvalHistoryDataStatusBulk: boolean
	currentlySelectedTransaction: any = {}
	corporateCustomerId: string
	currentUserId: string
	currentUserIsAuthorizerAndShouldApprove: boolean;
	receiptDownloadingStatus: boolean
	authLimit: number


	constructor(private table: DataTablesService,
		private http: HttpClient,
		private ui: UiService,
		private logger: LogService,
		private fb: FormBuilder) {
		// get user
		this.currentUser = config.currentUser()
		// get corporate id
		this.corporateCustomerId = store.get('corporateCustomerId')
		// get user id
		this.currentUserId = store.get('userId')
	}


	ngOnInit() {

		// define form
		this.formApprovalComment = this.fb.group({
			comment: ['']
		})

		// define form
		this.formTransferOtp = this.fb.group({
			otp: ['', Validators.required],
			pin: ['']
		})

		// initialize table
		this.table.init()

		// fetch transactions
		this.fetchPendingTransactions()
	}

	replaceLettersWithSpace(event: any, key: string) {
		const inputElement = event.target;
		const inputValue = inputElement.value;

		// Replace letters with spaces using a regular expression
		const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

		// Update the input value
		inputElement.value = sanitizedValue;

		// Update the model variable
		this.formTransferOtp.patchValue({
			[key]: sanitizedValue
		})
	}

	// fetch pending transactions 
	async fetchPendingTransactions() {
		// define payload
		let payload = {
			id: this.corporateCustomerId
		}

		// progress
		this.ui.blockUiOn(`Fetching pending transactions... please wait`)

		// network call
		this.http.get<any>(config.base + routes.transactions.pendingTransactionLogs, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log

					// destroy table
					this.table.destroy(`pending-transaction-records`)

					// pass data
					this.pendingTransactions = data

					// reinit table
					this.table.reInit(`pending-transaction-records`)

					// notification
					this.ui.success(`Pending transactions successfully fetched.`)

				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => {
				this.ui.blockUiOff()

				// fetch transactions
				// this.fetchPendingBulkTransactions()
			});

	}

	async fetchPendingBulkTransactions() {
		// define payload
		let payload = {
			id: this.corporateCustomerId
		}

		// progress
		this.ui.blockUiOn(`Fetching pending bulk transactions... please wait`)

		// network call
		this.http.get<any>(config.base + routes.transactions.bulkPendingTransaction, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// destroy table
					this.table.destroy(`pending-bulk-records`)

					// pass data
					this.pendingBulkTransactions = data?.data

					// reinit table
					this.table.reInit(`pending-bulk-records`)

					// notification
					this.ui.success(`Pending bulk transactions successfully fetched.`)

				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());

	}

	// fetch credit accounts
	async fetchCreditAccountsForTransaction(transaction: any) {


		// progress
		this.ui.blockUiOn(`Fetching credit account(s) for transaction... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: transaction.id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.creditAccounts + `?transLogId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// notification
					this.ui.success(`Credit accounts successfully fetched.`)

					// unblock
					this.ui.blockUiOff()

					// check mode
					if (this.approvalHistoryDataStatus) {
						// pass data
						this.creditAccountsList = data
						// change status flag
						this.approvalHistoryDataStatus = false
						// destroy table
						this.table.destroy(`transaction-credit-accounts-approval-l`)

						// reinit table
						this.table.reInit(`transaction-credit-accounts-approval-l`)
						// fetch approval history
						this.fetchTransactionApprovalHistory(transaction)
					} else {

						// pass data
						this.creditAccountsList = data
						// destroy table
						this.table.destroy(`transaction-credit-accounts`)

						// reinit table
						this.table.reInit(`transaction-credit-accounts`)

						// bring up modal
						modal.show(`#modal-transaction-credit-accounts`)
					}
				},
				error: error => {
					// error
					this.ui.error(error)

					// unblock
					this.ui.blockUiOff()
				}
			}).add(() => { });
	}

	// fetch transaction approval history
	async fetchTransactionApprovalHistory(transaction: any) {
		// progress
		this.ui.blockUiOn(`Fetching approval history for transaction... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: transaction.id
		}, true)



		// network call
		this.http.get<any>(config.base + routes.transactions.approvalHistory + `?transLogId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`Approval history successfully fetched.`)

					// pass data
					this.approvalUsersList = data

					// check if record available
					if (this.approvalUsersList.length) {
						// check if user is present in authorizers list
						let currentUserAsApproverRecord = this.approvalUsersList.find(item => item?.userId == this.currentUserId && item?.toApproved == 1)
						// check if record found
						if (currentUserAsApproverRecord) {
							// change status
							this.currentUserIsAuthorizerAndShouldApprove = true
						} else {
							this.currentUserIsAuthorizerAndShouldApprove = false
						}

						// re-arrange approvers list
						let bufferArrayStore = new Array(this.approvalUsersList.length)

						// loop through list of approval
						this.approvalUsersList.forEach(item => {
							// pass data
							bufferArrayStore[(item?.approvalLevel - 1)] = item
						})

						// pass data
						this.filteredApprovalUsersList = this.approvalUsersList
					}

					// bring up modal
					modal.show(`#modal-transaction-approval`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// fetch credit accounts
	async fetchCreditAccountsForBulkTransaction(transaction: any) {
		// progress
		this.ui.blockUiOn(`Fetching credit account(s) for transaction... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: transaction.id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.bulkCreditAccounts + `?bulkFileId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					// pass data
					this.creditAccountsList = data

					// notification
					this.ui.success(`Credit accounts successfully fetched.`)

					// unblock
					this.ui.blockUiOff()

					// check mode
					if (this.approvalHistoryDataStatusBulk) {
						// change status flag
						this.approvalHistoryDataStatusBulk = false
						// destroy table
						this.table.destroy(`transaction-credit-accounts-approval-pending`)

						// reinit table
						this.table.reInit(`transaction-credit-accounts-approval-pending`)
						// fetch approval history
						this.fetchBulkTransactionApprovalHistory(transaction)
					} else {
						// destroy table
						this.table.destroy(`transaction-credit-accounts-pending`)
						// pass data
						this.creditAccountsList = data
						// reinit table
						this.table.reInit(`transaction-credit-accounts-pending`)

						// bring up modal
						modal.show(`#modal-transaction-credit-accounts`)
					}
				},
				error: error => {
					// error
					this.ui.error(error)

					// unblock
					this.ui.blockUiOff()
				}
			}).add(() => { });
	}
	// initiate fetch approval history
	initiateFetchTransactionApprovalHistory(transaction: any) {
		// change status flag
		this.approvalHistoryDataStatus = true

		// pass data
		this.currentlySelectedTransaction = transaction.pendingTransaction
		this.authLimit = transaction.authLimitIsEnable

		// fetch credit accounts
		this.fetchCreditAccountsForTransaction(transaction.pendingTransaction)
	}

	initiateFetchBulkTransactionApprovalHistory(transaction: any) {
		// change status flag
		this.approvalHistoryDataStatusBulk = true

		// pass data
		this.currentlySelectedTransaction = transaction.pendingTransaction
		this.authLimit = transaction.authLimitIsEnable

		// fetch credit accounts
		this.fetchCreditAccountsForBulkTransaction(transaction.pendingTransaction)
	}

	// fetch transaction approval history
	async fetchBulkTransactionApprovalHistory(transaction: any) {
		// progress
		this.ui.blockUiOn(`Fetching approval history for transaction... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: transaction.id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.bulkApprovalHistory + `?bulkFileId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					// pass data
					this.approvalUsersList = data

					// notification
					this.ui.success(`Approval history successfully fetched.`)

					// check if record available
					if (this.approvalUsersList.length) {
						// check if user is present in authorizers list
						let currentUserAsApproverRecord = this.approvalUsersList.find(item => item?.userId == this.currentUserId && item?.toApproved == 1)
						// check if record found
						if (currentUserAsApproverRecord) {
							// change status
							this.currentUserIsAuthorizerAndShouldApprove = true
						} else {
							this.currentUserIsAuthorizerAndShouldApprove = false
						}

						// re-arrange approvers list
						let bufferArrayStore = new Array(this.approvalUsersList.length)

						// loop through list of approval
						this.approvalUsersList.forEach(item => {
							// pass data
							bufferArrayStore[(item?.approvalLevel - 1)] = item
						})

						// pass data
						this.filteredApprovalUsersList = bufferArrayStore
					}

					// bring up modal
					modal.show(`#modal-transaction-approval-bulk`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// initiate approve transaction
	initiateApproveTransaction() {
		// reset form
		this.formApprovalComment.reset()

		// bring up modal
		modal.show(`#modal-approval-comment`)
	}

	initiateApproveBulkTransaction() {
		// reset form
		this.formApprovalComment.reset()

		// bring up modal
		modal.show(`#modal-approval-comment-bulk`)
	}

	initiateDeclineTransaction() {
		// reset form
		this.formApprovalComment.reset()

		// bring up modal
		modal.show(`#modal-decline-comment`)
	}

	initiateDeclineBulkTransaction() {
		// reset form
		this.formApprovalComment.reset()

		// bring up modal
		modal.show(`#modal-decline-comment-bulk`)
	}

	// bring up modal screen
	bringUpAutheticationModal() {
		// reset form
		this.formTransferOtp.reset()

		// bring up modal
		modal.show(`#modal-authentication-token`)
	}

	// bring up modal screen
	bringUpBulkAutheticationModal() {
		// reset form
		this.formTransferOtp.reset()

		// bring up modal
		modal.show(`#modal-authentication-token-bulk`)
	}

	bringUpAutheticationModalDec() {
		// reset form
		this.formTransferOtp.reset()

		// bring up modal
		modal.show(`#modal-authentication-token-dec`)
	}

	// bring up modal screen
	bringUpBulkAutheticationModalDec() {
		// reset form
		this.formTransferOtp.reset()

		// bring up modal
		modal.show(`#modal-authentication-token-bulk-dec`)
	}



	// approve transaction
	async approveTransaction(target: any) {
		// check if otp is valid

		// validate otp and pin first
		if (this.authLimit == 1 && !this.formTransferOtp.value.pin) {
			this.ui.warning(`Please provide your pin!`)
			return
		}
		if (this.authLimit == 1 && this.formTransferOtp.value.pin.length != 4) {
			this.ui.warning(`Pin must be 4 digits!`)
			return
		}
		if (!this.formTransferOtp.value.otp) {
			// warning
			this.ui.warning(`Please provide your Token!`)

			return
		}
		if (this.formTransferOtp.value.otp.length != 8) {
			// warning
			this.ui.warning(`Token must be 8 digits!`)
			return
		}

		// progress
		this.ui.blockUiOn(`Sending approval for authorization level... please wait`)

		// define payload
		let payload = await store.preparePayload({
			authorizerId: this.currentUserId,
			tranLogId: this.currentlySelectedTransaction.id,
			comment: this.formApprovalComment.value.comment,
			otp: this.formTransferOtp.value.otp,
			pin: this.formTransferOtp.value.pin,
			authLimitIsEnable: this?.authLimit
		})

		// network call
		this.http.post<any>(config.base + routes.transactions.approveTransaction, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// check data
					if (data) {
						this.currentlySelectedTransaction = data?.data

						// notification
						this.ui.success(`Approval sent successfully.`)

						// bring up modal
						modal.hide(`#modal-authentication-token`)
						if (data.data) {
							modal.show(`#modal-view-transaction-history`)
						}

					} else this.ui.warning(`Something went wrong! Please try again or contact the administrator.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => {
				this.ui.blockUiOff()
				// reload transactions list
				this.fetchPendingTransactions()
			});
	}

	// approve transaction
	async approveBulkTransaction(target: any) {
		// validate otp and pin first
		if (this.authLimit == 1 && !this.formTransferOtp.value.pin) {
			this.ui.warning(`Please provide your pin!`)
			return
		}
		if (this.authLimit == 1 && this.formTransferOtp.value.pin.length != 4) {
			this.ui.warning(`Pin must be 4 digits!`)
			return
		}
		if (!this.formTransferOtp.value.otp) {
			// warning
			this.ui.warning(`Please provide your Token!`)

			return
		}
		if (this.formTransferOtp.value.otp.length != 8) {
			// warning
			this.ui.warning(`Token must be 8 digits!`)
			return
		}


		// progress
		this.ui.blockUiOn(`Sending approval for authorization level... please wait`)

		// define payload
		let payload = await store.preparePayload({
			authorizerId: this.currentUserId,
			tranLogId: this.currentlySelectedTransaction.id,
			comment: this.formApprovalComment.value.comment,
			otp: this.formTransferOtp.value.otp,
			pin: this.formTransferOtp.value.pin,
			authLimitIsEnable: this?.authLimit
		})

		// network call
		this.http.post<any>(config.base + routes.transactions.approveBulkTransfer, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// check data
					if (data) {
						// notification
						this.ui.success(`Approval sent successfully.`)

						// bring up modal
						modal.hide(`#modal-authentication-token-bulk`)

					} else this.ui.warning(`Something went wrong! Please try again or contact the administrator.`)
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => {
				this.ui.blockUiOff()
				// reload transactions list
				this.fetchPendingBulkTransactions()
			});
	}

	// approve transaction
	async declineTransaction(target: any) {
		// check if otp is valid
		if (!this.formTransferOtp.value.otp) {
			// warning
			this.ui.warning(`Please provide your otp!`)
			return
		}
		if (this.formTransferOtp.value.otp.length != 8) {
			// warning
			this.ui.warning(`Token must be 8 digits!!`)
			return
		}

		// progress
		this.ui.blockUiOn(`Sending decline for authorization level... please wait`)

		// define payload
		let payload = await store.preparePayload({
			authorizerId: this.currentUserId,
			tranLogId: this.currentlySelectedTransaction.id,
			comment: this.formApprovalComment.value.comment,
			otp: this.formTransferOtp.value.otp,
		})

		// log data
		this.logger.log(payload)



		// network call
		this.http.post<any>(config.base + routes.transactions.declineTransaction, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// check data
					if (data) {
						// notification
						this.ui.success(`Transaction has been declined.`)

						// bring up modal
						// modal.hide(`#modal-authentication-token`)
						modal.hide(`#modal-authentication-token-dec`)

						// reload transactions list
						this.fetchPendingTransactions()
					} else this.ui.warning(`Something went wrong! Please try again or contact the administrator.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => {
				this.ui.blockUiOff()

			});
	}

	// approve transaction
	async declineBulkTransaction(target: any) {
		// check if otp is valid
		if (this.formTransferOtp.value.otp?.length != 8) {
			// warning
			this.ui.warning(`Please provide your 8 digits Token!`)
			return
		}

		// progress
		this.ui.blockUiOn(`Sending decline for authorization level... please wait`)

		// define payload
		let payload = await store.preparePayload({
			authorizerId: this.currentUserId,
			tranLogId: this.currentlySelectedTransaction.id,
			comment: this.formApprovalComment.value.comment,
			otp: this.formTransferOtp.value.otp
		})

		// log data
		this.logger.log(payload)

		// network call
		this.http.post<any>(config.base + routes.transactions.declineBulkTransfer, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// check data
					if (data) {
						// notification
						this.ui.success(`Decline sent successfully.`)

						// bring up modal
						modal.hide(`#modal-authentication-token-bulk-dec`)

						// reload transactions list
						this.fetchPendingBulkTransactions()
					} else this.ui.warning(`Something went wrong! Please try again or contact the administrator.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// download receipt
	downloadTransactionReceipt() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container');
			// get opts
			var opt = {
				margin: 1,
				filename: 'transaction-receipt.pdf',
				image: { type: 'jpeg', quality: 1 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
			};
			// get workder
			let worker = html2pdf()
			// chain the worker functions and process the file to be downloaded
			worker.set(opt).from(element, 'element').save().then(() => {
				// change status
				this.receiptDownloadingStatus = false
			})
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptAsImage() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container');

			html2canvas(element, {
				scrollY: -window.scrollY,
				useCORS: true,
			}).then(canvas => {
				// create element
				let a = document.createElement('a')
				// set href attribute of anchor tag with datauri
				a.setAttribute('href', canvas.toDataURL('image/jpeg', 1.0))
				// set download attribute with name
				a.setAttribute('download', 'transaction-receipt.jpg')
				// add element to document body
				document.body.appendChild(a)
				// trigger click on anchor tag
				a.click()
				// remove anchor tag from document
				document.body.removeChild(a)
				// change status
				this.receiptDownloadingStatus = false
			});
		}, 1000)
	}
}