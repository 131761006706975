import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CurrencyPipe } from '@angular/common';

// define template
let template = 'transfers.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class TransfersComponent implements OnInit {
	transactions: any = []
	config = config
	beneficiariesBulk: any = [0]
	corporateCustomerId: string
	workflows: any = []
	charges: any
	accountsList: any = []
	bankList: any = []
	accountsListSource: any = []
	accountsListDestination: any = []
	formTransferIntrabank: FormGroup
	formTransferInterbank: FormGroup
	formTransferOwnAccount: FormGroup
	formTransferOtp: FormGroup
	formIntraBeneficiary: FormGroup
	formInterBeneficiary: FormGroup
	destinationAccountDetails: any
	transferPayloadIntrabank: any
	transferPayloadInterbank: any
	transferPayloadOwnBank: any
	activeTransferTypeName: string
	workflowApproversList: any = []
	authorizationType: any
	beneficiariesInter: any = []
	beneficiariesIntra: any = []
	authLimit: number
	constructor(private http: HttpClient,
		private ui: UiService,
		private logger: LogService,
		private fb: FormBuilder,
		private currencyPipe: CurrencyPipe) {
		// get corporate id
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')

		// check if user has permission and redirect if not
		// config.currentUserHasPermissionByKey('InitiateTransaction', config.unAuthRedirectUrl)
	}

	ngOnInit() {
		// define form
		this.formTransferIntrabank = this.fb.group({
			sourceAccountNumber: ['', Validators.required],
			sourceBankName: [''],
			sourceAccountName: [''],
			destinationAccountNumber: ['', Validators.required],
			destinationBankName: [''],
			destinationAccountName: [''],
			workflowId: [''],
			amount: ['', Validators.required],
			narration: ['', Validators.required],
			transactionType: [''],
			otp: [''],
			pin: [''],
		})


		this.formTransferIntrabank.valueChanges.subscribe(form => {
			if (form.amount) {
				this.formTransferIntrabank.patchValue({
					// amount: this.currencyPipe.transform(form.amount.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
					amount: config.addCommas(form.amount.replace(/[^\d.]/g, ''))
				}, { emitEvent: false })
			}
		})

		// define form
		this.formTransferOwnAccount = this.fb.group({
			sourceAccountNumber: ['', Validators.required],
			sourceBankName: [''],
			sourceAccountName: [''],
			destinationAccountNumber: ['', Validators.required],
			destinationBankName: [''],
			destinationAccountName: [''],
			workflowId: [''],
			amount: ['', Validators.required],
			narration: ['', Validators.required],
			transactionType: [''],
			otp: [''],
			pin: [''],
		})

		this.formTransferOwnAccount.valueChanges.subscribe(form => {
			if (form.amount) {
				this.formTransferOwnAccount.patchValue({
					// amount: this.currencyPipe.transform(form.amount.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
					amount: config.addCommas(form.amount.replace(/[^\d.]/g, ''))
				}, { emitEvent: false })
			}
		})

		// define form
		this.formTransferInterbank = this.fb.group({
			sourceAccountNumber: ['', Validators.required],
			sourceBankName: [''],
			sourceAccountName: [''],
			destinationAccountNumber: ['', Validators.required],
			destinationBankName: ['', Validators.required],
			destinationBankCode: ['', Validators.required],
			destinationAccountName: [''],
			workflowId: [''],
			amount: ['', Validators.required],
			narration: ['', Validators.required],
			transactionType: [''],
			otp: [''],
			pin: [''],
		})

		this.formTransferInterbank.valueChanges.subscribe(form => {
			if (form.amount) {
				this.formTransferInterbank.patchValue({
					// amount: this.currencyPipe.transform(form.amount.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
					amount: config.addCommas(form.amount.replace(/[^\d.]/g, ''))
				}, { emitEvent: false })
			}
		})

		// define form
		this.formTransferOtp = this.fb.group({
			otp: ['', Validators.required],
			pin: [''],
		})

		this.formIntraBeneficiary = this.fb.group({
			accountNumber: ["", Validators.required]
		})

		this.formInterBeneficiary = this.fb.group({
			accountNumber: ["", Validators.required]
		})

		// initialize flatpickr
		Rogue.Flatpickr.init()

		// fetch accounts list
		this.fetchAccountsList()
	}

	replaceLettersWithSpace(event: any, key: string) {
		const inputElement = event.target;
		const inputValue = inputElement.value;

		// Replace letters with spaces using a regular expression
		const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

		// Update the input value
		inputElement.value = sanitizedValue;

		// Update the model variable
		this.formTransferOtp.patchValue({
			[key]: sanitizedValue
		})
	}
	// add new beneficiary row
	addNewBeneficiary() {
		// populate store
		this.beneficiariesBulk.push(this.beneficiariesBulk.length)
	}

	// remove beneficiary row
	removeBeneficiaryRow(index: number) {
		// check if last row remaining
		if (this.beneficiariesBulk.length < 2) return
		// remove index
		this.beneficiariesBulk.splice(index, 1)
	}

	// fetch accounts list
	async fetchAccountsList() {
		// loading
		this.ui.blockUiOn(`Fetching accounts list... please wait`)

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.getCorporateAccounts + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// pass on data
					this.accountsList = data?.records
					this.accountsListSource = data?.records
					this.accountsListDestination = data?.records
					// notification
					this.ui.info(`Accounts loaded successfully.`)




				},
				error: error => {
					// error
					this.ui.error(error)

					// unblock
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.fetchInterBankList()
				// fetch workflows
				if (config.checkIfNotSingleSignatory(this.authorizationType)) {
					this.fetchAllCorporateCustomerWorkflows()
				}

				// unblock
				this.ui.blockUiOff()

			});
	}

	// fetch all workflows
	async fetchAllCorporateCustomerWorkflows() {
		// loading
		this.ui.blockUiOn(`Fetching all workflows... please wait`)

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.workflows.getWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// pass on data
					this.workflows = data
					// notification
					this.ui.info(`Workflows loaded successfully.`)


				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => {


				this.ui.blockUiOff()

			});
	}

	// fetch inter bank list
	fetchInterBankList() {
		// loading
		this.ui.blockUiOn(`Fetching Bank list... please wait`)

		// network call
		this.http.post<any>(config.base + routes.transactions.interbankList, {}, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// pass on data
					// { institutionCode: "999998", institutionName: "Pseudo" },
					this.bankList = [...data?.data?.banks]
					// notification
					this.ui.info(`Banks loaded successfully.`)

					// unblock
					this.ui.blockUiOff()
				},
				error: error => {
					// error  
					this.ui.error(error)

					// unblock
					this.ui.blockUiOff()
				}
			})
			.add(() => { });
	}

	// fetch beneficiaries / intra
	async fetchBeneficiariesIntra() {
		// progress
		this.ui.blockUiOn(`Fetching  Parallex bank beneficiaries... please wait`)

		// network call
		this.http.get<any>(config.base + routes.corporateCustomers.intraBankBeneficiaries, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// pass data
					this.beneficiariesIntra = data?.data

					// notification
					if (this.beneficiariesIntra?.length) {
						this.ui.success(` Parallex bank beneficiaries successfully fetched.`)
						modal.show("#modal-intra-beneficiary")
					}
					else this.ui.warning(`No beneficiaries available at the moment for  Parallex bank.`)
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => this.ui.blockUiOff());
	}

	// fetch beneficiaries / inter
	async fetchBeneficiariesInter() {
		// progress
		this.ui.blockUiOn(`Fetching other banks beneficiaries... please wait`)

		// network call
		this.http.get<any>(config.base + routes.corporateCustomers.interBankBeneficiaries, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// pass data
					this.beneficiariesInter = data?.data

					// notification
					if (this.beneficiariesInter?.length) {
						this.ui.success(`Other banks beneficiaries successfully fetched.`)
						modal.show("#modal-inter-beneficiary")
					}
					else this.ui.warning(`No beneficiaries available at the moment for other banks.`)
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => this.ui.blockUiOff());
	}

	// get account information
	async getAccountInformation(target: any, type?: string) {
		// get account number
		let account = String(target.value).replace(/\s*/g, "")

		// check length of account number
		if (account.length != 10) {
			// empty store
			this.destinationAccountDetails = {}
			return
		}
		// progress
		this.ui.blockUiOn(`Fetching account information... please wait`)

		// define payload
		let payload = await store.preparePayload({
			accountNumber: String(account)
		}, true)

		let payloadInterBank = {
			accountNumber: String(account),
			destinationBankCode: String(this.formTransferInterbank.value.destinationBankCode),
		}

		let encryptedInterBankPayload = await store.preparePayload({
			accountNumber: String(account),
			destinationBankCode: String(this.formTransferInterbank.value.destinationBankCode),
		})



		// network call
		if (type === "inter") {
			this.http.post<any>(config.base + routes.transactions.interbankNameEnquiry, encryptedInterBankPayload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// log


						// pass on data
						this.destinationAccountDetails = data?.data

						// insert destination account name
						this.formTransferInterbank.patchValue({
							destinationAccountName: this.destinationAccountDetails.accountName,
							destinationAccountNumber: payloadInterBank.accountNumber,
							destinationBankName: this.formTransferInterbank.value.destinationBankName || ''
						})

						// notification
						this.ui.success(`Name enquiry was successful.`)
					},
					error: error => {
						// error
						this.ui.error(error)

					}
				}).add(() => this.ui.blockUiOff());
		} else {
			this.http.get<any>(config.base + routes.manageAccounts.customerNameEnquiry + `?accountNumber=${payload.accountNumber}`, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// log


						// pass on data
						this.destinationAccountDetails = data?.data

						// insert destination account name
						this.formTransferIntrabank.patchValue({
							destinationAccountName: this.destinationAccountDetails.accountName,
							destinationBankName: this.destinationAccountDetails.bankName || ''
						})

						// notification
						this.ui.success(`Name enquiry was successful.`)
					},
					error: error => {
						// error
						this.ui.error(error)

					}
				}).add(() => this.ui.blockUiOff());
		}
	}

	// get selected account information
	getSelectedAccountInformation(target: any, key: string) {
		// get index
		let controlIndex = target['selectedIndex']

		// check if index is 0
		if (controlIndex < 1) {
			// reset the lists
			this.accountsListSource = this.accountsList
			this.accountsListDestination = this.accountsList
		}

		// check the key
		switch (key) {
			case 'own-source':
				// patch values
				this.formTransferOwnAccount.patchValue({
					sourceBankName: target.options[controlIndex].getAttribute("bankName"),
					sourceAccountName: target.options[controlIndex].getAttribute("accountName"),
				})

				// hide source from destination
				this.accountsListDestination = this.accountsList.filter(item => item?.accountNumber != this.formTransferOwnAccount.value.sourceAccountNumber)
				// unide anything hidden
				this.accountsListSource = this.accountsList
				// check if selected destination no is same source
				if (this.formTransferOwnAccount.value.destinationAccountNumber == this.formTransferOwnAccount.value.sourceAccountNumber) {
					// patch values
					this.formTransferOwnAccount.patchValue({
						destinationAccountNumber: '',
						destinationBankName: '',
						destinationAccountName: '',
					})
				}
				break
			case 'own-destination':
				// patch values
				this.formTransferOwnAccount.patchValue({
					destinationBankName: target.options[controlIndex].getAttribute("bankName"),
					destinationAccountName: target.options[controlIndex].getAttribute("accountName"),
				})

				// hide source from destination
				this.accountsListSource = this.accountsList.filter(item => item?.accountNumber != this.formTransferOwnAccount.value.destinationAccountNumber)
				// unide anything hidden
				this.accountsListDestination = this.accountsList
				// check if selected destination no is same source
				if (this.formTransferOwnAccount.value.destinationAccountNumber == this.formTransferOwnAccount.value.sourceAccountNumber) {
					// patch values
					this.formTransferOwnAccount.patchValue({
						sourceAccountNumber: '',
						sourceBankName: '',
						sourceAccountName: '',
					})
				}
				break
			case 'intrabank':
				// patch values
				this.formTransferIntrabank.patchValue({
					sourceBankName: target.options[controlIndex].getAttribute("bankName"),
					sourceAccountName: target.options[controlIndex].getAttribute("accountName"),
				})
				break
			case 'interbank':
				// patch values
				this.formTransferInterbank.patchValue({
					destinationBankName: target.options[controlIndex].getAttribute("bankName"),
					destinationBankCode: target.options[controlIndex].getAttribute("bankCode"),
				})
				break
		}
	}

	getSelectedIntraBeneficiary(target: any) {
		let controlIndex = target['selectedIndex']
		this.destinationAccountDetails = {
			accountName: target.options[controlIndex].getAttribute("accountName"),
			accountNumber: this.formIntraBeneficiary.value.accountNumber
		}
		this.formTransferIntrabank.patchValue({
			destinationAccountNumber: this.formIntraBeneficiary.value.accountNumber,
			destinationAccountName: target.options[controlIndex].getAttribute("accountName")
		})
	}

	getSelectedInterBeneficiary(target: any) {
		let controlIndex = target['selectedIndex']
		this.destinationAccountDetails = {
			accountName: target.options[controlIndex].getAttribute("accountName"),
			institutionCode: target.options[controlIndex].getAttribute("institutionCode"),
			accountNumber: this.formIntraBeneficiary.value.accountNumber
		}
		this.formTransferInterbank.patchValue({
			destinationAccountNumber: this.formInterBeneficiary.value.accountNumber,
			destinationAccountName: target.options[controlIndex].getAttribute("accountName"),
			destinationBankCode: target.options[controlIndex].getAttribute("institutionCode"),
			destinationBankName: target.options[controlIndex].getAttribute("institutionName"),
		})
	}

	// initiate own bank transfer
	initiateOwnBankTransfer(target: any) {
		// pass transfer type name
		this.activeTransferTypeName = 'own'

		// get form
		let form = find(target)

		// validate form
		if (!this.formTransferOwnAccount.valid) {
			// warning
			this.ui.warning(`Please fill all required fields.`)
			Rogue.Form.flag(form)
			return
		}

		// get values
		let values = this.formTransferOwnAccount.value

		// check that not same account
		if (values.sourceAccountNumber == values.destinationAccountNumber) {
			// warning
			this.ui.warning(`Transfer to the same account is not possible.`)
			return
		}

		// define payload
		this.transferPayloadOwnBank = {
			sourceAccountNumber: values.sourceAccountNumber,
			sourceBankName: values.sourceBankName || '',
			sourceAccountName: values.sourceAccountName,
			destinationAccountNumber: values.destinationAccountNumber,
			destinationBankName: values.destinationBankName || '',
			destinationAccountName: values.destinationAccountName || '',
			amount: config.removeCommas(values.amount),
			narration: values.narration,
			workflowId: values.workflowId,
			corporateCustomerId: this.corporateCustomerId,
			transactionType: 'Own_Transfer',
			otp: '',
		}

		// log data
		this.logger.log(this.transferPayloadOwnBank)

		// verify transaction
		this.verifyTransaction()
	}

	// initiate intra bank transfer
	initiateIntrabankTransfer(target: any) {
		// pass transfer type name
		this.activeTransferTypeName = 'intrabank'

		// get form
		let form = find(target)

		// validate form
		if (!this.formTransferIntrabank.valid) {
			// warning
			this.ui.warning(`Please fill all required fields.`)
			Rogue.Form.flag(form)
			return
		}

		// get values
		let values = this.formTransferIntrabank.value

		// check that not same account
		if (values.sourceAccountNumber == values.destinationAccountNumber) {
			// warning
			this.ui.warning(`Transfer to the same account is not possible.`)
			return
		}

		// define payload
		this.transferPayloadIntrabank = {
			sourceAccountNumber: values.sourceAccountNumber,
			sourceBankName: values.sourceBankName || '',
			sourceAccountName: values.sourceAccountName,
			destinationAccountNumber: String(values.destinationAccountNumber),
			destinationBankName: values.destinationBankName || '',
			destinationAccountName: values.destinationAccountName || '',
			amount: config.removeCommas(values.amount),
			narration: values.narration,
			workflowId: values.workflowId,
			corporateCustomerId: this.corporateCustomerId,
			transactionType: 'Intra_Bank_Transfer',
			otp: '',
		}

		// log data
		this.logger.log(this.transferPayloadIntrabank)

		// verify transaction
		this.verifyTransaction()
	}
	// initiate intra bank transfer
	initiateInterbankTransfer(target: any) {

		// pass transfer type name
		this.activeTransferTypeName = 'interbank'

		// get form
		let form = find(target)
		// validate form
		if (!this.formTransferInterbank.valid) {
			// warning
			this.ui.warning(`Please fill all required fields.`)
			Rogue.Form.flag(form)
			return
		}

		// get values
		let values = this.formTransferInterbank.value

		// check that not same account
		if (values.sourceAccountNumber == values.destinationAccountNumber) {
			// warning
			this.ui.warning(`Transfer to the same account is not possible.`)
			return
		}

		// define payload
		this.transferPayloadInterbank = {
			sourceAccountNumber: values.sourceAccountNumber,
			sourceBankName: values.sourceBankName || '',
			sourceAccountName: values.sourceAccountName,
			destinationAccountNumber: String(values.destinationAccountNumber),
			destinationBankName: values.destinationBankName || '',
			destinationBankCode: values.destinationBankCode || '',
			destinationAccountName: values.destinationAccountName || '',
			amount: config.removeCommas(values.amount),
			narration: values.narration,
			workflowId: values.workflowId,
			corporateCustomerId: this.corporateCustomerId,
			transactionType: 'Inter_Bank_Transfer',
			otp: '',
		}

		// log data
		this.logger.log(this.transferPayloadInterbank)

		// verify transaction
		this.verifyTransaction()
	}

	initiateIntraBeneficiary() {
		this.fetchBeneficiariesIntra()
	}
	initiateInterBeneficiary() {
		this.fetchBeneficiariesInter()
	}

	// verify transaction
	async verifyTransaction() {
		// define payload
		let payload: any

		// loading
		this.ui.blockUiOn(`Verifying transaction... please wait`)

		let destinationAccountNumber;

		// check transfer type name
		switch (this.activeTransferTypeName) {
			case 'own':
				// pass data
				payload = await store.preparePayload(this.transferPayloadOwnBank)
				destinationAccountNumber = store.encrypt(this.transferPayloadOwnBank.destinationAccountNumber, true)
				payload = { ...payload, destinationAccountNumber }
				break
			case 'intrabank':
				// pass data
				payload = await store.preparePayload(this.transferPayloadIntrabank)
				destinationAccountNumber = store.encrypt(this.transferPayloadIntrabank.destinationAccountNumber, true)
				payload = { ...payload, destinationAccountNumber }
				break
			case 'interbank':
				// pass data
				payload = await store.preparePayload(this.transferPayloadInterbank)
				destinationAccountNumber = store.encrypt(this.transferPayloadInterbank.destinationAccountNumber, true)
				payload = { ...payload, destinationAccountNumber }
				break
		}



		// network call
		if (this.activeTransferTypeName == "interbank") {
			this.http.post<any>(config.base + routes.transactions.verifyInterTransaction, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// output data


						// check data returned
						if (data) {
							this.charges = data
							this.authLimit = data?.authLimitIsEnable

							// notification
							this.ui.info(`Transaction successfully verified. Please provide your otp.`)

							// bring up otp screen
							this.bringUpAutheticationModal()
						}
					},
					error: error => {
						// check for error
						this.ui.error(error)

					}
				})
				.add(() => this.ui.blockUiOff());
		} else {
			this.http.post<any>(config.base + routes.transactions.verifyIntraTransaction, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// output data


						// check data returned
						if (data) {
							this.authLimit = data?.authLimitIsEnable
							// notification
							this.ui.info(`Transaction successfully verified. Please provide your otp.`)

							// bring up otp screen
							this.bringUpAutheticationModal()
						}
					},
					error: error => {
						// check for error
						this.ui.error(error)
					}
				})
				.add(() => this.ui.blockUiOff());
		}

	}

	// bring up otp screen
	bringUpAutheticationModal() {
		// show modal
		modal.show(`#modal-authentication-token`)
	}

	// attempt transaction
	async attemptTransaction(target: any) {
		// get form
		let form = find(target)


		// validate otp and pin first
		if (this.authLimit == 1 && !this.formTransferOtp.value.pin) {
			this.ui.warning(`Please provide your pin!`)
			Rogue.Form.flag(form)
			return
		}
		if (!this.formTransferOtp.value.otp) {
			// warning
			this.ui.warning(`Please provide your Token!`)
			Rogue.Form.flag(form)
			return
		}

		if (this.authLimit == 1 && this.formTransferOtp.value.pin?.length != 4) {
			this.ui.warning(`Pin must be 4 digits!`)
			return
		}

		if (this.formTransferOtp.value.otp?.length != 8) {
			// warning
			this.ui.warning(`Token must be 8 digits!!`)
			return
		}

		// loading
		this.ui.blockUiOn(`Initiating transaction... please wait`)

		// define payload
		let payload: any

		// check transfer type name
		switch (this.activeTransferTypeName) {
			case 'own':
				// pass data
				payload = this.transferPayloadOwnBank
				break
			case 'intrabank':
				// pass data
				payload = this.transferPayloadIntrabank
				break
			case 'interbank':
				// pass data
				payload = this.transferPayloadInterbank
				break
		}

		const destinationAccountNumber = store.encrypt(payload.destinationAccountNumber, true)
		// add otp
		payload.otp = this.formTransferOtp.value.otp
		payload.pin = this.formTransferOtp.value.pin
		payload.authLimitIsEnable = this?.authLimit


		payload = await store.preparePayload(payload)

		payload = { ...payload, destinationAccountNumber }
		// log
		this.logger.log(payload.otp + payload.pin)



		// network call
		if (this.activeTransferTypeName == "interbank") {
			this.http.post<any>(config.base + routes.transactions.initiateInterTransaction, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// output data

						// notification
						this.ui.info(`Transaction successfully initiated.`)

						// hide modal
						modal.hide(`#modal-authentication-token`)
						// reset otp 
						this.resetFormOtp()

						// congratulations modal
						modal.show(`#modal-initiate-contgratulations`)


						// reset forms
						this.formTransferInterbank.reset()
						this.formInterBeneficiary.reset()

						// patch values
						this.formTransferInterbank.patchValue({
							sourceAccountNumber: '',
							destinationAccountNumber: ''
						})

						// empty store for destination data
						this.destinationAccountDetails = null

						// reset otp form
						this.resetFormOtp()
					},
					error: error => {
						// error
						this.ui.error(error)
					}
				})
				.add(() => this.ui.blockUiOff());
		} else {
			this.http.post<any>(config.base + routes.transactions.initiateIntraTransaction, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// output data
						// check data returned
						// if (data == true) {
						// notification
						this.ui.info(`Transaction successfully initiated.`)

						// hide modal
						modal.hide(`#modal-authentication-token`)
						// reset otp 
						this.resetFormOtp()

						// congratulations modal
						modal.show(`#modal-initiate-contgratulations`)

						// reset forms
						this.formTransferOwnAccount.reset()
						this.formTransferIntrabank.reset()
						this.formIntraBeneficiary.reset()

						// patch values
						this.formTransferOwnAccount.patchValue({
							sourceAccountNumber: '',
							destinationAccountNumber: ''
						})
						this.formTransferIntrabank.patchValue({
							sourceAccountNumber: ''
						})

						// empty store for destination data
						this.destinationAccountDetails = null

						// reset otp form
						this.resetFormOtp()
						// }
					},
					error: error => {
						// error
						this.ui.error(error)
					}
				})
				.add(() => this.ui.blockUiOff());
		}


	}

	// fetch approvers for workflow
	async fetchApproversListForWorkflow(key: string) {
		// progress
		this.ui.blockUiOn(`Fetching workflow authorizers... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: (key == 'own') ? this.formTransferOwnAccount.value.workflowId : (key == 'intrabank') ? this.formTransferIntrabank.value.workflowId : ''
		}, true)



		// network call
		this.http.get<any>(config.base + routes.workflowHierarchy.getWorkflowHierarchy + `?workflowId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// pass data
					this.workflowApproversList = data

					// check if authorizer assigned
					if (data.length) {
						// notification
						this.ui.success(`Workflow approvers list successfully loaded.`)

						// bring up modal
						modal.show(`#modal-manage-workflow-authorizers`)
					} else this.ui.warning(`Authorizers not assigned yet for workflow.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// reset form
	resetFormOtp() {
		// reset form
		this.formTransferOtp.reset()
	}

	hideAuthModal() {
		modal.hide(`#modal-authentication-token`)
	}

	hideCongratsModal() {
		modal.hide(`#modal-contgratulations`)
	}
}