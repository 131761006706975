import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// define template
let template = 'signup.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class SignupComponent implements OnInit {
	constructor() {}

	ngOnInit () {
        // toggle password
        this.togglePassword()
	}

    // toggle password
    togglePassword () {
        // timeout
        setTimeout(() => {
          Array.from(document.querySelectorAll("#toggle-password")).forEach(el => {
            el.addEventListener('click', function(e: any) {
                var x = this.previousSibling;
                
                if (x.type === "password") {
                    x.type = "text"
                } else {
                    x.type = "password"
                }
            })
          })
        }, 10)
    }


}