import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { CurrencyPipe } from '@angular/common';

// define template
let template = 'change-password.component'

@Component({
  templateUrl: `${template}.html`,
  styleUrls: [`${template}.css`]
})

export class ChangePasswordComponent implements OnInit {


  formPasswordChange: FormGroup
  firstTimeProcessStatus: boolean
  customerId: string = ""

  constructor(
    private ui: UiService,
    private logger: LogService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.customerId = store.get('customerId')

  }

  ngOnInit(): void {
    // toggle password
    this.togglePassword()

    // define form
    this.formPasswordChange = this.fb.group({
      id: ['', Validators.required],
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    })
  }


  // change password
  async changeDefaultPassword(target: any) {
    // get form
    let form = find(target)

    // define payload
    let payload = {
      ...this.formPasswordChange.value,
      id: this.customerId,
    }


    // check if form is valid
    if (!payload.id.length
      || !payload.oldPassword.length
      || !payload.newPassword.length
      || !payload.confirmPassword.length
      || ((new RegExp(/(\s)+/)).test(payload.newPassword))
      || (!(new RegExp(/[^a-z0-9]+/i)).test(payload.newPassword))
      || payload.newPassword.length < 8) {
      // warning
      if (!payload.id.length) this.ui.warning(`Please provide your corporate customer id.`)
      else if (!payload.oldPassword.length) this.ui.warning(`Please provide your current password.`)
      else if (!payload.newPassword.length) this.ui.warning(`Please provide your new password.`)
      else if ((new RegExp(/(\s)+/)).test(payload.newPassword)) this.ui.warning(`Password must contain a space.`)
      else if (!(new RegExp(/[^a-z0-9]+/i)).test(payload.newPassword)) this.ui.warning(`Password must contain at least one SPECIAL character.`)
      else this.ui.warning(`Please re-enter your new password.`)
      if (payload.newPassword.length < 8) this.ui.warning(`Password must be a minimum of 8 characters.`)

      // flag errors
      // Rogue.Form.flag(form)
      return
    }

    // check if passwords match
    if (payload.newPassword != payload.confirmPassword) {
      // warning
      this.ui.warning(`Passwords do not match!`)
      // flag errors
      Rogue.Form.flag(form)
      return
    }


    // change status flag
    this.firstTimeProcessStatus = true
    // encrypt payload
    payload = await store.preparePayload(payload)


    // network
    this.http.post<any>(config.auth + routes.authentication.changePassword, payload, config.httpOptions())
      .subscribe({
        next: (data: any) => {
          // if success
          if (data) {
            // redirect
            this.router.navigate(['/user/login'])
            // notification
            this.ui.info(data.message || `Password change was successful! You can now sign in with your new password.`)
          }
        },
        error: e => {
          // error
          this.ui.error(e)
        }
      }).add(() => this.firstTimeProcessStatus = false)
  }

  // toggle password
  togglePassword() {
    // timeout
    setTimeout(() => {
      Array.from(document.querySelectorAll("#toggle-password")).forEach(el => {
        el.addEventListener('click', function (e: any) {
          var x = this.previousSibling;
          if (x.type === "password") {
            x.type = "text"
          } else {
            x.type = "password"
          }
        })
      })
    }, 10)
  }
}