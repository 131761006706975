import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { DatePipe } from '@angular/common';

import * as XLSX from 'xlsx';
import * as html2pdf from '../../../../assets/js/html2pdf.bundle.js';
import * as html2canvas from '../../../../assets/js/html2canvas.min.js';
// define template
let template = 'account-statement.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class AccountStatementComponent implements OnInit {
	todayDate = new Date().toDateString()
	currentUser: any
	config = config
	users: any = config.users
	accounts: any = []
	dummyAccounts: any
	statement: any = []
	corporateCustomerId: any
	formFilter: FormGroup
	authorizationType: string
	receiptDownloadingStatus: boolean
	accountStatementDetails: any = {
		accountName: '',
		pdfGeneratedDateTime: '',
		accountNumber: '',
		accountType: '',
		accountBranch: '',
		accountCurrency: '',
		statementStartDate: '',
		statementEndDate: '',
		statementAvailableBalance: '',
		statementBookBalance: '',
	}
	constructor(private table: DataTablesService,
		private ui: UiService,
		private logger: LogService,
		private http: HttpClient,
		private fb: FormBuilder,
		private datepipe: DatePipe) {
		// get corporate id
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')
		this.currentUser = config.currentUser()

		// fill dummy accounts
		// this.dummyAccounts = config.dummyAccountNumbers
	}

	ngOnInit() {
		// define form
		this.formFilter = this.fb.group({
			accountNumber: ['', Validators.required],
			startDate: [''],
			endDate: [''],
			period: ['']
		})

		// initialize table
		this.table.init()

		if (this.currentUser != this.users.sole || !config.checkIfNotSingleSignatory(this.authorizationType)) {
			this.fetchAllCorporateAccounts()
		}
		// fetch accounts

		store.log('token')
	}

	// fetch corporate accounts
	async fetchAllCorporateAccounts() {
		// pass the profile
		let id = this.corporateCustomerId

		// loading
		this.ui.blockUiOn(`Fetching corporate accounts... please wait`)

		let payload = await store.preparePayload({
			id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.getCorporateAccounts + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// notification
					this.ui.info(`Accounts loaded successfully.`)
					// pass on data
					this.accounts = data?.records
					// auto-select account
					this.formFilter.patchValue({
						account: this.accounts[0].accountNumber
					})
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// fetch corporate accounts
	async fetchAllAccountStatement() {

		// get values
		let values = this.formFilter.value

		// check if form is filled
		if (!values.accountNumber.length) {
			// warning
			this.ui.warning(`Please provide an account to get statement for.`)
			return
		}
		if (!values.period.length) {
			// warning
			this.ui.warning(`Please provide an period to get statement for.`)
			return
		}

		// // check if form is filled
		// if (!values.startDate.length) {
		// 	// warning
		// 	this.ui.warning(`Please provide a start date to use to filter the account statment.`)
		// 	return
		// }

		// // check if form is filled
		// if (!values.endDate.length) {
		// 	// warning
		// 	this.ui.warning(`Please provide an end date to use to filter the account statment.`)
		// 	return
		// }
		// loading
		this.ui.blockUiOn(`Fetching account statement records for '${values.accountNumber}'... please wait`)

		// define payload
		let payload = await store.preparePayload({
			accountNumber: values.accountNumber,
			startDate: this.datepipe.transform(values.startDate, 'yyyy-MM-dd'),
			endDate: this.datepipe.transform(values.endDate, 'yyyy-MM-dd'),
			period: values.period
		})



		// network call
		this.http.post<any>(config.base + routes.corporateCustomers.getAccountStatement, payload, config.httpOptions())
			.subscribe({
				next: (res: any) => {
					// output data
					this.logger.log(res)
					// destroy
					this.table.destroy(`transaction-account-statement`)
					// pass on data
					this.statement = res
					// reinit
					this.table.reInit(`transaction-account-statement`)
					// notification
					this.ui.info(`Account statement records loaded successfully.`)
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());
	}

	// filter records
	filterRecords(target: any) {
		// fetch account statement
		this.fetchAllAccountStatement()
	}

	// get selected account information
	getSelectedAccountInformation(target: any) {
		this.formFilter.patchValue({
			account: target['value']
		})
	}


	// reformat date for view
	reformatDateForView(date: string, format?: string) {
		// check if value exists
		if (!date) return '-'

		// return formatted date
		return this.datepipe.transform(date, format || 'full')
	}

	

	// download as pdf
	downloadAccountStatementAsPDF() {
		// define data
		this.accountStatementDetails = {
			accountName: this.accountStatementDetails.accountName,
			pdfGeneratedDateTime: this.reformatDateForView(String(new Date()), 'mediumDate'),
			accountNumber: this.formFilter.value.accountNumber,
			accountType: this.accountStatementDetails.accountType,
			accountBranch: this.accountStatementDetails.accountBranch,
			accountCurrency: this.accountStatementDetails.accountCurrency,
			statementStartDate: this.reformatDateForView(this.formFilter.value.startDate, 'mediumDate'),
			statementEndDate: this.reformatDateForView(this.formFilter.value.endDate, 'mediumDate'),
			statementAvailableBalance: this.accountStatementDetails.statementAvailableBalance,
			statementBookBalance: this.accountStatementDetails.statementBookBalance,
		}

		// show modal
		find('#modal-account-statement').modal('show')

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-account-statement').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('statement-container');
			// get opts
			var opt = {
				margin: 0.1,
				filename: 'account-statement.pdf',
				// image: { type: 'png', quality: 0.8 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
			};
			// get workder
			let worker = html2pdf()
			// chain the worker functions and process the file to be downloaded
			worker.set(opt).from(element, 'element').save()
		}, 2000)
	}



	// download as excel
	downloadAccountStatementAsExcel() {
		// get table object
		let element = document.getElementById('transaction-account-statement');
		const workSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		// generate workbook and add the worksheet
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

		// save to file
		XLSX.writeFile(workBook, `account-statement.xlsx`);
	}
}