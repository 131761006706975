<div class="auth">
        <div class="auth-con">
                <div class="auth-con_top">
                        <div class="auth-main">
                                <div class="auth-main-word">
                                        <h1>
                                                Welcome to Parallex Bank <br />
                                                Corporate Internet Banking
                                        </h1>
                                        <h2>
                                                Sign in to stay connected
                                        </h2>
                                </div>
                                <div>
                                        <img src="../../../../assets/images/new-logo.png" alt="logo">
                                </div>
                        </div>
                </div>
                <div class="auth-con_bottom">
                        <img src="../../../../assets/images/frame-3.png" alt="slide"
                                [class]="+slider == 4 ? 'my-carousel-item item four active': (slider != 3 && slider != 4) ? 'sn' : 'my-carousel-item item four'" />
                        <img src="../../../../assets/images/frame-2.png" alt="slide"
                                [class]="+slider == 3 ? 'my-carousel-item item three active': (slider != 2 && slider != 3) ? 'sn' : 'my-carousel-item item three'" />
                        <img src="../../../../assets/images/frame-1.png" alt="slide"
                                [class]="+slider == 2 ? 'my-carousel-item item two active': 2 < slider ? 'sn' : 'my-carousel-item item two'" />
                        <img src="../../../../assets/images/frame-0.png" alt="slide"
                                [class]="+slider == 1 ? 'my-carousel-item item one active': (1 < slider && slider != 4)? 'sn' : 'my-carousel-item item one'" />

                        <div class="item-con">
                                <div>
                                        <div id="carouselExampleSlidesOnly" class="carousel carousel-fade"
                                                data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="7000">
                                                <div class="carousel-inner">
                                                        <div class="carousel-item it active">
                                                                <div class="slider-text">
                                                                        <h1 class="n2">
                                                                                Unlock your business <br /> growth
                                                                                potential
                                                                        </h1>
                                                                        <h2>
                                                                                Elevate your business with our
                                                                                powerful<br />
                                                                                Suite of tools and resources
                                                                        </h2>
                                                                </div>
                                                        </div>
                                                        <div class="carousel-item it">
                                                                <div class="slider-text">
                                                                        <h1 class="n2">
                                                                                Business Banking<br />
                                                                                Simplified
                                                                        </h1>
                                                                        <h2>
                                                                                Your Digital Business Banking <br />
                                                                                Solution across board
                                                                        </h2>
                                                                </div>
                                                        </div>
                                                        <div class="carousel-item it">
                                                                <div class="slider-text">
                                                                        <h1 class="n2">
                                                                                Join The Business<br />
                                                                                Success Story
                                                                        </h1>
                                                                        <h2>
                                                                                Thousands of businesses around the world
                                                                                <br />
                                                                                trust us
                                                                        </h2>
                                                                </div>
                                                        </div>
                                                        <div class="carousel-item it">
                                                                <div class="slider-text">
                                                                        <h1 class="n2">
                                                                                Customers at the <br />
                                                                                very core
                                                                        </h1>
                                                                        <h2>
                                                                                Craft remarkable experiences that turn<br/>
                                                                                clients to loyal advocates.
                                                                        </h2>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                        <div class="login-form">
                                                <h3>Login to Your Business Account</h3>
                                                <form [formGroup]="formBankUserLogin" class="needs-validation"
                                                        (ngSubmit)="signInBankUser($event.target)" autocomplete="off">

                                                        <div class="col-12">
                                                                <div class="mb-4">
                                                                        <input type="text" class="form-control"
                                                                                formControlName="username" 
                                                                                placeholder="Username" required autocomplete="off">
                                                                </div>
                                                        </div>
                                                        <div class="col-12">
                                                                <div class="mb-2 last ">
                                                                        <div class="input-group">
                                                                                <input type="password"
                                                                                        class="form-control last"
                                                                                        formControlName="password"
                                                                                        placeholder="Password"
                                                                                        minlength="8" required autocomplete="off">
                                                                                <span class="input-group-text pointer"
                                                                                        id="toggle-password"><i
                                                                                                class="ri-eye-line"
                                                                                                style="font-size: 15px; color: black;"></i></span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="mb-2 text-right">
                                                                <label class="pointer text-main fw-light mb-0">
                                                                        <input type="checkbox" name="rememberMe"
                                                                                formControlName="rememberMe">
                                                                        Remember me
                                                                </label>
                                                        </div>
                                                        <div class="col-md-12">
                                                                <div class="text-end">
                                                                        <button type="submit"
                                                                                [disabled]="loginProcessStatus"
                                                                                class="btn btn-main fw-light figma-dark w-100">
                                                                                <span
                                                                                        *ngIf="!loginProcessStatus">Login</span>
                                                                                <span *ngIf="loginProcessStatus"
                                                                                        class="spinner-border spinner-border-sm mr-1"
                                                                                        style="margin-right: 10px;"
                                                                                        role="status"
                                                                                        aria-hidden="true"></span>
                                                                                <span *ngIf="loginProcessStatus">
                                                                                        please
                                                                                        wait...</span>
                                                                        </button>
                                                                </div>
                                                        </div>

                                                </form>
                                        </div>
                                </div>
                        </div>

                </div>
        </div>
</div>


<!-- session timeout message -->
<div class="modal fade" id="modal-session-timeout-message" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
        <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                        <div class="modal-header">
                                <h5 class="modal-title" id="myExtraLargeModalLabel"></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-5">

                                <div class="d-flex flex-column text-center p-10">

                                        <!-- <div class="d-flex align-items-center flex-centered mb-8">
                   <img src="assets/images/special/timeout.png" style="max-height: 200px;" class="m-auto" />
                 </div> -->

                                        <div class="d-flex flex-column flex-centered">
                                                <span class="fs-3 text-dark fw-medium">Your session has timed out,
                                                        please sign in again.</span>
                                                <span class="font-size-h6 text-dark font-weight-bolder mt-4">Due to
                                                        inactivity, you have been
                                                        signed out for your own safety.</span>
                                        </div>

                                        <div class="d-flex flex-column mt-10 text-center">
                                                <button type="button" (click)="removeSessionTimeoutModal()"
                                                        class="btn btn-main btn-lg w-50 m-auto">Ok</button>
                                        </div>

                                </div>

                        </div>
                </div>
        </div>
</div>


<!-- disclaimer for first time login  -->
<div class="modal fade" id="modal-disclaimer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-modal="true" role="dialog" style="display: none;">
        <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                        <div class="modal-header">
                                <h5 class="modal-title" id="myExtraLargeModalLabel"></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-0">

                                <div class="d-flex flex-column text-left p-5 px-10">


                                        <div class="d-flex flex-column flex-centered">
                                                <span class="font-size-h6 text-dark font-weight-bolder mt-0">
                                                        We take your privacy seriously and only process your personal
                                                        information to make your banking experience better. In
                                                        accordance with NDPR, GDPR, and any applicable regulations,
                                                        continuing to use this platform indicates your consent to the
                                                        processing of your personal data by Parallex Bank Limited, its
                                                        subsidiaries and partners as detailed in our <a
                                                                class="hyperlink" data-bs-toggle="modal"
                                                                data-bs-target="#modal-disclaimer-details">Privacy
                                                                Policy</a>.
                                                </span>
                                        </div>

                                        <div class="d-flex flex-column mt-5 text-center">
                                                <div class="col-lg-12 mt-5">
                                                        <div class="text-center">
                                                                <button type="button"
                                                                        class="col-lg-5 btn btn-main btn-lg"
                                                                        (click)="removeDisclaimerModal()">Accept
                                                                        All</button>
                                                                <button type="button"
                                                                        class="col-lg-5 btn btn-danger btn-lg  mx-2"
                                                                        data-bs-dismiss="modal">Reject All</button>

                                                        </div>
                                                </div>
                                        </div>

                                </div>

                        </div>
                </div>
        </div>
</div>
<div class="modal fade" id="modal-disclaimer-details" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-modal="true" role="dialog" style="display: none;">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                        <div class="modal-header">
                                <h5 class="modal-title" id="myExtraLargeModalLabel"></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                        </div>
                        <div class="modal-body privacy-policy">

                                <div class="d-flex flex-column text-left p-5">
                                        <div class="d-flex flex-column flex-centered">
                                                <!-- <iframe src="../../../../assets/PBL-NDPR-POL-PRIVACY POLICY NOTICE-V02.pdf#toolbar=0"
                            frameborder="0"></iframe> -->
                                                <div class="c52 doc-content">
                                                        <h1 class="c43"><span class="c32 c48">YOUR PRIVACY, OUR PRIORITY
                                                                </span></h1>
                                                        <p class="c4"><span class="c1">Parallex Privacy Policy provides
                                                                        information on how we
                                                                        collect, use, share, and
                                                                        process personal information we get when you use
                                                                        websites, our services, download
                                                                        documents, subscribe to our
                                                                        newsletters, interact with us during in-person
                                                                        meetings, at Parallex-sponsored
                                                                        events or events hosted at our
                                                                        premises, and in the context of other marketing
                                                                        and branding activities. This
                                                                        Privacy Policy explains your
                                                                        choices in relation to these processing
                                                                        activities. </span></p>
                                                        <p class="c4"><span class="c1">In this Privacy Policy,
                                                                        &lsquo;personal information&rsquo; or
                                                                        &lsquo;personal
                                                                        data&rsquo; means information related to an
                                                                        identified or identifiable individual.
                                                                        For example, this could
                                                                        include, among other things, your name, address,
                                                                        email address, business contact
                                                                        details, or information
                                                                        gathered through your interactions with us via
                                                                        our websites or at events. Personal
                                                                        information is also referred
                                                                        to as &lsquo;information about you&rsquo;.
                                                                </span></p>
                                                        <p class="c0"><span class="c8">WHEREVER WE&rsquo;VE SAID
                                                                        &lsquo;YOU&rsquo; or
                                                                        &lsquo;YOUR&rsquo; </span></p>
                                                        <p class="c51"><span class="c1">This means: </span></p>
                                                        <ul class="c5 lst-kix_list_2-0 start">
                                                                <li class="c29 li-bullet-0"><span class="c1">You </span>
                                                                </li>
                                                                <li class="c29 li-bullet-0"><span class="c1">Any
                                                                                authorised person on your
                                                                                account</span></li>
                                                                <li class="c29 li-bullet-0"><span class="c1">Anyone who
                                                                                does your banking or deals with
                                                                                us on your behalf, e.g.,
                                                                                trustees, executors or attorneys under a
                                                                                Power of Attorney</span></li>
                                                                <li class="c0 c54 li-bullet-0"><span class="c1">Other
                                                                                related people, for example,
                                                                                authorised signatories,
                                                                                partners, members, and trustees</span>
                                                                </li>
                                                        </ul>
                                                        <p class="c0"><span class="c32 c42">WHEN WE SAY
                                                                        &lsquo;WE&rsquo;</span><span
                                                                        class="c1">&nbsp;</span></p>
                                                        <p class="c0"><span class="c1">We mean Parallex Bank Limited,
                                                                        which acts as a Data
                                                                        Controller for your personal
                                                                        data</span></p>
                                                        <h2 class="c17"><span class="c8">THE PERSONAL INFORMATION WE
                                                                        COLLECT </span></h2>
                                                        <p class="c9"><span class="c1">Parallex uses the personally
                                                                        identifiable information you
                                                                        provide to communicate with
                                                                        you in response to your inquiries, provide the
                                                                        services you request, and manage your
                                                                        account. The types of
                                                                        personal information which we may collect and
                                                                        process are: </span></p>
                                                        <ul class="c5 lst-kix_list_1-0 start">
                                                                <li class="c14 li-bullet-0"><span class="c1">Name,
                                                                                contact address, email addresses, and
                                                                                telephone numbers </span>
                                                                </li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Demographic
                                                                                attributes, when tied to
                                                                                personal information that
                                                                                identifies you </span></li>
                                                                <li class="c14 li-bullet-0"><span class="c1">Employer,
                                                                                corporate contact details, job
                                                                                title </span></li>
                                                                <li class="c14 li-bullet-0"><span
                                                                                class="c1">Transactional data, including
                                                                                financial
                                                                                details, your photograph
                                                                        </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Career
                                                                                history, professional background and
                                                                                other Curriculum Vitae
                                                                                (CV) related information </span></li>
                                                                <li class="c10 li-bullet-0"><span
                                                                                class="c1">International passport,
                                                                                driving license or
                                                                                any other form of proof of
                                                                                identification </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Data from
                                                                                surveys and publicly available
                                                                                information, such as social
                                                                                media posts </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Unique IDs
                                                                                such as your mobile device
                                                                                identifier or cookie ID on your
                                                                                browser </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">IP address
                                                                                and information that may be
                                                                                derived from IP address, such
                                                                                as geographic location </span></li>
                                                                <li class="c34 li-bullet-0"><span class="c1">Information
                                                                                about a device you use, such as
                                                                                browser, device type,
                                                                                operating system </span></li>
                                                        </ul>
                                                        <p class="c4"><span class="c1">Parallex Bank will only collect
                                                                        the necessary information to
                                                                        provide the service(s)
                                                                        that you have requested. </span></p>
                                                        <h2 class="c17"><span class="c8">HOW WE COLLECT YOUR PERSONAL
                                                                        INFORMATION </span></h2>
                                                        <p class="c47"><span class="c1">We collect information about you
                                                                        and any other party whose
                                                                        details you provide to us
                                                                        when you: </span></p>
                                                        <ol class="c5 lst-kix_list_3-0 start" start="1">
                                                                <li class="c10 li-bullet-0"><span class="c1">Register to
                                                                                use our websites and portals
                                                                        </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Complete
                                                                                online forms </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Take part
                                                                                in surveys </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Interact
                                                                                with us using our social media
                                                                                platforms </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Download
                                                                                information such as publications
                                                                                or participate in any other
                                                                                interactive areas that appear on our
                                                                                website or portals </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Contact us
                                                                                offline, for example, by
                                                                                telephone, SMS, email, post or
                                                                                any other means of communication </span>
                                                                </li>
                                                                <li class="c15 c38 li-bullet-0"><span class="c1">Access
                                                                                and use any of our websites or
                                                                                portals from your devices
                                                                                (including mobile devices) and
                                                                                applications. </span></li>
                                                        </ol>
                                                        <h2 class="c17"><span>HOW WE USE OF YOUR PERSONAL
                                                                        INFORMATION</span><span
                                                                        class="c33">&nbsp;</span></h2>
                                                        <p class="c9"><span class="c1">Certain principles guide the
                                                                        collection and use of personal
                                                                        data. The following
                                                                        principles guide the collection and use of your
                                                                        information: </span></p>
                                                        <ul class="c5 lst-kix_list_4-0 start">
                                                                <li class="c14 li-bullet-0"><span class="c1">Process
                                                                                your information in a fairly,
                                                                                lawfully, and transparent
                                                                                manner. </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Obtain
                                                                                information for a specified and
                                                                                lawful purpose and not to be
                                                                                processing it in any manner incompatible
                                                                                with such purposes. </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Adequate,
                                                                                relevant and limited to what is
                                                                                necessary to fulfil the
                                                                                purpose of processing. </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Accurate
                                                                                and, where necessary, up to date.
                                                                                If data is inaccurate,
                                                                                steps will be taken to rectify or erase
                                                                                such data. </span></li>
                                                                <li class="c14 li-bullet-0"><span class="c1">Not to be
                                                                                kept for longer than necessary
                                                                                for the purpose of
                                                                                processing. </span></li>
                                                                <li class="c22 li-bullet-0"><span class="c1">Processed
                                                                                in accordance with the data
                                                                                subject&rsquo;s rights. </span>
                                                                </li>
                                                                <li class="c34 li-bullet-0"><span class="c1">Kept safe
                                                                                from unauthorised processing and
                                                                                accidental loss, damage or
                                                                                destruction using adequate technical and
                                                                                organisational measures. </span></li>
                                                        </ul>
                                                        <p class="c9"><span class="c1">All personal information that we
                                                                        collect and the process is
                                                                        justified by lawful
                                                                        processing on the basis that: </span></p>
                                                        <ul class="c5 lst-kix_list_4-0">
                                                                <li class="c14 li-bullet-0"><span class="c1">You have
                                                                                given consent to the processing
                                                                        </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Processing
                                                                                is necessary for the performance
                                                                                of a contract to which
                                                                                you are a party or take steps to enter a
                                                                                contract </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Processing
                                                                                is necessary for compliance with
                                                                                a legal obligation to
                                                                                which we are subject </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Processing
                                                                                is necessary to protect your
                                                                                vital interests or another
                                                                                natural person </span></li>
                                                                <li class="c15 c38 li-bullet-0"><span
                                                                                class="c1">Processing is necessary for
                                                                                the
                                                                                performance of a task carried out
                                                                                in the public interest </span></li>
                                                        </ul>
                                                        <p class="c53 c55"><span class="c1">Your personal information
                                                                        may be used: </span></p>
                                                        <ul class="c5 lst-kix_list_4-0">
                                                                <li class="c28 li-bullet-0"><span class="c1">To
                                                                                communicate and respond to your requests
                                                                                and enquiries about
                                                                        </span></li>
                                                        </ul>
                                                        <p class="c13"><span class="c1">Parallex </span></p>
                                                        <ul class="c5 lst-kix_list_4-0">
                                                                <li class="c14 li-bullet-0"><span class="c1">To offer
                                                                                and provide our Products and
                                                                                Services tailored to meet your
                                                                                needs </span></li>
                                                        </ul>
                                                        <p class="c15"><span class="c1">To fulfil the terms of any
                                                                        service contract(s), you might
                                                                        have with us</span></p>
                                                        <ul class="c5 lst-kix_list_4-0">
                                                                <li class="c14 li-bullet-0"><span class="c1">To improve
                                                                                your service experience with us
                                                                        </span></li>
                                                                <li class="c14 li-bullet-0"><span class="c1">To conduct
                                                                                our business and manage our
                                                                                relationship with you </span>
                                                                </li>
                                                                <li class="c14 li-bullet-0"><span class="c1">To
                                                                                administer subscriptions to periodic
                                                                                newsletters </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">To deliver
                                                                                technical and functional
                                                                                management on our sites, networks
                                                                                and system </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">To market
                                                                                our services or related services
                                                                                and tailor our marketing
                                                                                and brand activities to you or your
                                                                                company&rsquo;s interests &nbsp;</span>
                                                                </li>
                                                                <li class="c10 li-bullet-0"><span class="c1">To comply
                                                                                with applicable laws and
                                                                                regulations and to operate our
                                                                                business </span></li>
                                                                <li class="c14 li-bullet-0"><span class="c1">To comply
                                                                                with Internal Policies
                                                                                &nbsp;</span></li>
                                                                <li class="c14 li-bullet-0"><span class="c1">To update
                                                                                your records </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">To
                                                                                communicate with you when necessary
                                                                        </span></li>
                                                        </ul>
                                                        <h2 class="c17"><span class="c8">AUTOMATED PROCESSING </span>
                                                        </h2>
                                                        <p class="c18"><span class="c1">We sometimes use automated
                                                                        systems and software to help us
                                                                        reach decisions about you,
                                                                        such as making credit decisions, carrying out
                                                                        security, fraud and money laundering
                                                                        checks, or processing your
                                                                        data when you apply for some of our products and
                                                                        services. </span></p>
                                                        <p class="c24"><span class="c1">This type of processing is
                                                                        carried out on a lawful basis.
                                                                        You can object to your data
                                                                        being subject to automated processing by
                                                                        contacting us to request that a human being
                                                                        review automated
                                                                        processing if you detect any inaccuracies in
                                                                        your personal data. However, objecting
                                                                        may affect the ability to
                                                                        provide certain services as required by
                                                                        law.</span></p>
                                                        <h2 class="c11"><span>DATA ANALYTICS</span><span
                                                                        class="c33">&nbsp;</span></h2>
                                                        <p class="c47"><span class="c1">We may use data analytics tools
                                                                        to analyse the information
                                                                        generated and stored
                                                                        during your use of our services to improve our
                                                                        services, provide content tailored to
                                                                        your personal preferences,
                                                                        and monitor our website&rsquo;s traffic and
                                                                        usage. The other purposes include:
                                                                </span></p>
                                                        <ul class="c5 lst-kix_list_5-0 start">
                                                                <li class="c14 li-bullet-0"><span class="c1">Develop and
                                                                                provide new and existing
                                                                                functionality and services.
                                                                        </span></li>
                                                        </ul>
                                                        <p class="c6"><span class="c1">Determine your general location
                                                                        to evaluate how our known
                                                                        clients engage with
                                                                        different parts of our website</span></p>
                                                        <p class="c6"><span class="c1">Third-party service providers may
                                                                        provide all these tools,
                                                                        including collecting and
                                                                        tracking certain data and information regarding
                                                                        the characteristics and activities
                                                                        of visitors to our website.
                                                                        We may disclose data, including personal
                                                                        information, to such third-party service
                                                                        providers to obtain such
                                                                        services. </span></p>
                                                        <p class="c19"><span class="c1">You have the right to object to
                                                                        processing based on our
                                                                        legitimate activities. Still,
                                                                        this may affect our ability to provide certain
                                                                        services or solutions for your
                                                                        benefit if you object.</span></p>
                                                        <p class="c56"><span class="c8">TRACKING OR RECORDING WHAT YOU
                                                                        SAY OR DO</span></p>
                                                        <p class="c19"><span class="c1">We may store details of your
                                                                        interactions with us. We may
                                                                        also record and keep track
                                                                        of conversations you have with us
                                                                        through:</span></p>
                                                        <ul class="c5 lst-kix_list_6-0 start">
                                                                <li class="c12 li-bullet-0"><span class="c1">Phone
                                                                                calls</span></li>
                                                                <li class="c12 li-bullet-0"><span
                                                                                class="c1">Face-to-face meetings </span>
                                                                </li>
                                                                <li class="c12 li-bullet-0"><span class="c1">letters
                                                                        </span></li>
                                                                <li class="c12 li-bullet-0"><span class="c1">E-mails
                                                                        </span></li>
                                                                <li class="c12 li-bullet-0"><span class="c1">Live chats
                                                                                &nbsp;</span></li>
                                                                <li class="c38 c50 li-bullet-0"><span class="c1">Video
                                                                                chats and any other kinds of
                                                                                communication. </span></li>
                                                        </ul>
                                                        <p class="c50 c53"><span class="c1">We may use these to </span>
                                                        </p>
                                                        <ul class="c5 lst-kix_list_7-0 start">
                                                                <li class="c30 li-bullet-0"><span class="c1">Check your
                                                                                instructions to us </span></li>
                                                                <li class="c30 li-bullet-0"><span class="c1">Assess,
                                                                                analyse and improve our service
                                                                        </span></li>
                                                                <li class="c30 li-bullet-0"><span class="c1">Train our
                                                                                staff </span></li>
                                                                <li class="c30 li-bullet-0"><span class="c1">Manage
                                                                                risk</span></li>
                                                                <li class="c20 li-bullet-0"><span class="c1">Prevent and
                                                                                detect fraud and other
                                                                                crimes.</span></li>
                                                        </ul>
                                                        <p class="c50 c53"><span class="c1">We use closed circuit
                                                                        television (CCTV) in and around
                                                                        our offices and branches
                                                                        and these may collect photos or videos of you or
                                                                        record your voice.</span></p>
                                                        <p class="c59"><span class="c8">COOKIES </span></p>
                                                        <p class="c39"><span>Please note that we may collect information
                                                                        about your computer (or
                                                                        mobile device) for our
                                                                        digital channels, including where available or
                                                                        necessary, your IP address(es),
                                                                        operating system and browser
                                                                        type for system administration, or for our
                                                                        commercial purposes. This is statistical
                                                                        data about our users&rsquo;
                                                                        browsing actions and patterns and does not
                                                                        identify any individual. Further details
                                                                        can be found in our
                                                                </span><span class="c26 c25">cookies policy</span><span
                                                                        class="c25">&nbsp;</span><span
                                                                        class="c1">&nbsp;</span>
                                                        </p>
                                                        <h2 class="c17"><span class="c8">WHOM WE SHARE YOUR PERSONAL
                                                                        INFORMATION WITH </span></h2>
                                                        <p class="c27"><span class="c1">We may share the information
                                                                        about you to the extent
                                                                        permitted by law, with the
                                                                        following: </span></p>
                                                        <ul class="c5 lst-kix_list_8-0 start">
                                                                <li class="c10 li-bullet-0"><span class="c1">Service
                                                                                providers who process and store
                                                                                data on our behalf include
                                                                                internet service providers, platform
                                                                                providers, cloud storage providers,
                                                                                payment
                                                                                processing providers, and
                                                                                those engaged in communicating with you.
                                                                        </span></li>
                                                                <li class="c10 li-bullet-0"><span
                                                                                class="c1">Regulators/Supervisors,
                                                                                Government
                                                                                Agencies/Courts - It may be
                                                                                necessary by law, legal process,
                                                                                litigation, or requests from public and
                                                                                governmental authorities within or
                                                                                outside your country of residence for
                                                                                Parallex Bank to disclose your personal
                                                                                information. We may also
                                                                                disclose information about you if we
                                                                                determine that for purposes of national
                                                                                security, law enforcement, or
                                                                                other issues of public importance,
                                                                                disclosure is necessary or appropriate.
                                                                        </span></li>
                                                                <li class="c14 li-bullet-0"><span class="c1">Parallex
                                                                                Bank Staff </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">Analytics
                                                                                and service engine providers
                                                                                assist us in improving and
                                                                                optimising our website. </span></li>
                                                                <li class="c38 c40 li-bullet-0"><span
                                                                                class="c1">External Auditors </span>
                                                                </li>
                                                        </ul>
                                                        <p class="c6"><span class="c1">We may share non-personally
                                                                        identifiable information (e.g.,
                                                                        the pages you visit, which
                                                                        links you click, which ads you see and click on,
                                                                        and the categories of search terms
                                                                        you enter) about using our
                                                                        website or portal publicly or with third
                                                                        parties. This will not include information
                                                                        that can be used to
                                                                        identify you. </span></p>
                                                        <p class="c6"><span class="c1">Your Personal information will
                                                                        not be shared with third
                                                                        parties for marketing
                                                                        purposes. </span></p>
                                                        <p class="c31"><span class="c1">&nbsp;</span></p>
                                                        <h2 class="c35"><span class="c8">HOW WE PROTECT YOUR INFORMATION
                                                                </span></h2>
                                                        <p class="c7"><span class="c1">The security of your personal
                                                                        information is important to us.
                                                                        Parallex Bank,
                                                                        therefore, takes reasonable precautions to
                                                                        protect your personal information. We
                                                                        generally follow accepted
                                                                        industry standards to protect the personal
                                                                        information submitted to us during
                                                                        transmission and once we receive
                                                                        it. No method of transmission over the Internet
                                                                        or electronic storage method is 100%
                                                                        secure. However, we have
                                                                        implemented appropriate technical, physical, and
                                                                        organisational measures designed to
                                                                        protect personal
                                                                        information against accidental or unlawful
                                                                        destruction, accidental loss, damage,
                                                                        alteration, unauthorised
                                                                        disclosure or access, as well as all other forms
                                                                        of unlawful processing. </span></p>
                                                        <p class="c46"><span class="c1">&nbsp;</span></p>
                                                        <p class="c24"><span>Where we have provided you (or where you
                                                                        have chosen) a password that
                                                                        grants you access to
                                                                        specific areas on our site, you are responsible
                                                                        for keeping this password
                                                                        confidential. We request that you do
                                                                        not share your password or other authentication
                                                                        details (e.g., token-generated
                                                                        codes) with anyone. Other
                                                                        security measures you can take that will help us
                                                                        protect your data can be found at
                                                                </span><span class="c25 c26">Security
                                                                        Information</span><span class="c1">&nbsp;</span>
                                                        </p>
                                                        <p class="c0"><span class="c32">&nbsp;</span></p>
                                                        <h2 class="c17"><span class="c8">WHERE WE STORE YOUR INFORMATION
                                                                </span></h2>
                                                        <p class="c24"><span class="c1">All Personal Information you
                                                                        provide to us is stored on our
                                                                        secure servers and secure
                                                                        physical locations and cloud infrastructure
                                                                        (where applicable) to provide seamless
                                                                        services to you. Whenever
                                                                        your information is transferred to other
                                                                        locations, we will take all necessary steps
                                                                        to handle your data
                                                                        securely. </span></p>
                                                        <p class="c16"><span class="c1">&nbsp;</span></p>
                                                        <p class="c44"><span class="c32 c42">&nbsp;</span></p>
                                                        <h2 class="c35"><span class="c8">HOW LONG DO WE STORE YOUR
                                                                        INFORMATION </span></h2>
                                                        <p class="c18"><span class="c1">We retain your data for as long
                                                                        as necessary for the
                                                                        purpose(s) it was collected.
                                                                        &nbsp;In some circumstances, we may retain your
                                                                        personal information for more
                                                                        extended periods following
                                                                        regulatory, legal, tax or accounting
                                                                        obligations. Data that is not retained is
                                                                        securely destroyed when
                                                                        identified that is no longer needed for the
                                                                        purposes for which it was collected.
                                                                </span></p>
                                                        <p class="c18"><span class="c1">We will retain your personal
                                                                        information for such length of
                                                                        time as may be required
                                                                        by law, regulation, and the internal policies of
                                                                        Parallex Bank. </span></p>
                                                        <p class="c36"><span class="c1">&nbsp;</span></p>
                                                        <h2 class="c57"><span class="c8">YOUR RIGHTS AND CHOICES UNDER
                                                                        THIS POLICY </span></h2>
                                                        <p class="c4"><span>Subject to conditions specified by
                                                                        applicable law, you have the
                                                                        following rights in respect of
                                                                        your personal information:</span><span
                                                                        class="c32">&nbsp;</span></p>
                                                        <ul class="c5 lst-kix_list_9-0 start">
                                                                <li class="c23 li-bullet-1"><span class="c1">The right
                                                                                to access information we hold
                                                                                about you and to get
                                                                                information about what we do with it.
                                                                        </span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">In some
                                                                                circumstances, you have the right
                                                                                to withdraw your permission
                                                                                to processing of your information, that
                                                                                you can do at any time. We may continue
                                                                                to process your information
                                                                                if we have another legitimate reason for
                                                                                doing so. &nbsp; &nbsp; &nbsp;
                                                                                &nbsp;</span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">In some
                                                                                circumstances, the right to receive
                                                                                certain information you
                                                                                have provided to us in an electronic
                                                                                format and/or ask that we send it to a
                                                                                third party.</span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">The right
                                                                                to ask that we rectify your
                                                                                information if it&rsquo;s
                                                                                inaccurate or incomplete.</span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">In some
                                                                                circumstances, you have the right
                                                                                to ask that we delete your
                                                                                information. We may continue to keep
                                                                                your information if we&rsquo;re entitled
                                                                                or
                                                                                required to retain
                                                                                it.</span></li>
                                                                <li class="c10 li-bullet-0"><span class="c1">The right
                                                                                to object to, and to ask that we
                                                                                limit our processing of
                                                                                your information in some circumstances.
                                                                                Again, there may be situations where you
                                                                                object to, or ask us to
                                                                                limit our processing of your information
                                                                                but we&rsquo;re entitled to continue
                                                                                and/or to refuse that request.
                                                                        </span></li>
                                                        </ul>
                                                        <p class="c21 c2"><span class="c1"></span></p>
                                                        <p class="c21"><span class="c1">You also have the right to
                                                                        exercise your rights by
                                                                        contacting us using the details
                                                                        set out in the &lsquo;Dispute Resolution/Filing
                                                                        a complaint&rsquo; section. You also
                                                                        have a right to complain
                                                                        to the data protection regulator in the country
                                                                        where you live or work</span></p>
                                                        <p class="c24 c2"><span class="c1"></span></p>
                                                        <p class="c0 c2"><span class="c1"></span></p>
                                                        <h2 class="c35"><span class="c8">MAINTAINING ACCURATE
                                                                        INFORMATION </span></h2>
                                                        <p class="c3"><span class="c1">We will endeavour to keep your
                                                                        personal information accurate,
                                                                        complete and up to date.
                                                                        You have access to your account information,
                                                                        including your contact information,
                                                                        account balances, transactions
                                                                        and similar information through various means,
                                                                        such as account statements and
                                                                        Internet Banking. If you discover
                                                                        any inaccuracies in your personal information,
                                                                        please promptly notify us and provide
                                                                        the required documentary
                                                                        evidence to implement the necessary updates or
                                                                        changes. </span></p>
                                                        <p class="c2 c3"><span class="c1"></span></p>
                                                        <h2 class="c37"><span class="c8">PRIVACY OF CHILDREN </span>
                                                        </h2>
                                                        <p class="c41"><span class="c1">We understand the importance of
                                                                        protecting children&rsquo;s
                                                                        privacy. We do not
                                                                        knowingly collect names, email addresses, or
                                                                        personally identifiable information
                                                                        from children. We do not
                                                                        knowingly market to children, nor do we allow
                                                                        children under 18 to open online
                                                                        accounts </span></p>
                                                        <h2 class="c11"><span>DISPUTE RESOLUTION OR FILING A
                                                                        COMPLAINT</span><span class="c33">&nbsp;</span>
                                                        </h2>
                                                        <p class="c24"><span>If you have any complaints regarding our
                                                                        compliance with this Privacy
                                                                        Policy, please contact us
                                                                        on 0700 727 25539 OR 0912 180 4317 OR send an
                                                                        email to </span><span
                                                                        class="c25">dataprotection@parallexbank.com</span><span
                                                                        class="c1">.
                                                                </span></p>
                                                        <p class="c24"><span class="c1">Note we may require proof of
                                                                        your identity and full details
                                                                        of your request before we
                                                                        can process your complaint. We will investigate
                                                                        and attempt to resolve complaints
                                                                        and disputes regarding the
                                                                        use and disclosure of personal information
                                                                        within thirty (30) days under this
                                                                        Privacy Policy and following
                                                                        applicable laws and regulation. </span></p>
                                                        <h2 class="c17"><span>UPDATES TO THIS PRIVACY POLICY</span><span
                                                                        class="c33">&nbsp;</span>
                                                        </h2>
                                                        <p class="c4"><span class="c1">We may update this policy from
                                                                        time to time. Where there are
                                                                        changes in how we use
                                                                        your personal information, we will notify you by
                                                                        posting a prominent notice on our
                                                                        website. We encourage you to
                                                                        review the Privacy Policy regularly to stay
                                                                        informed about our information practices
                                                                        and the ways you can help
                                                                        protect your privacy.</span></p>
                                                        <p class="c15"><span>For more information, please contact:
                                                                </span><span class="c26 c49"><a class="c58"
                                                                                href="mailto:customercare@parallexbank.com">customercare@parallexbank.com</a></span><span>&nbsp;or
                                                                        call
                                                                </span><span class="c26 c49">0700 727 25539</span></p>
                                                </div>
                                        </div>


                                        <div class="mt-4 text-center">
                                                <button type="button" class="col-lg-5 btn btn-main btn-lg"
                                                        (click)="removeDisclaimerModal()">Accept
                                                        All</button>
                                                <button type="button" class="col-lg-5 btn btn-danger btn-lg  mx-2"
                                                        data-bs-dismiss="modal">Reject
                                                        All</button>
                                        </div>

                                </div>

                        </div>
                </div>
        </div>
</div>