<!-- pay salary -->
<div class="row" *ngIf="salaryPaymentStatus">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-body position-relative">
                <div class="w-100 mb-5 d-flex flex-column px-5 text-white"
                    style="height: 83px; background: linear-gradient(92.09deg, #242492 13.35%, #D1AE6C 114.27%); border-radius: 14px; box-shadow: 0px 0px 40px rgba(48, 73, 191, 0.04); place-content: center;">
                    <div class="mb-1">Payroll Schedules</div>
                    <h3 class="text-white">Disburse funds On The Go!</h3>
                </div>
                <img src="assets/images/salary-payment.svg"
                    style="position: absolute; width: 138.13px; height: 100px; top: 20px; right: 30px; " />

                <form [formGroup]="formAddSchedule" (ngSubmit)="createSalarySchedule()" class="p-5 w-100">
                    <div class="row g-3">
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect" formControlName="sourceAccountNumber"
                                    aria-label="Floating label select example"
                                    (change)="getSelectedAccountInformation($event.target)" required>
                                    <option value="">Select Account</option>
                                    <option *ngFor="let account of accounts" [value]="account.accountNumber"
                                        [attr.currency]="account.currencyCode" [attr.accountName]="account.accountName"
                                        [attr.accountNunber]="account.accountNunber">{{ account.accountNumber }} ({{
                                        account.customerName }} | N{{ account.availableBalance | number }})</option>
                                </select>
                                <label for="floatingSelect">Select Source Account</label>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-2" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect" formControlName="workFlowId"
                                    aria-label="Floating label select example">
                                    <option value="">Select Workflow</option>
                                    <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                                    </option>
                                </select>
                                <label for="floatingSelect">Workflow</label>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <input type="datetime-local" class="form-control" placeholder="Schedule Date & Time"
                                    id="sfloatingSelect" formControlName="startDate"  required>
                                <!-- <input type="text" class="form-control flatpickr-input"
                                    placeholder="Schedule Date & Time" data-provider="flatpickr" id="sfloatingSelect"
                                    data-date-format="d/m/y" data-enable-time="" readonly="readonly" formControlName="startDate"> -->
                                <label for="sfloatingSelect">Start Date</label>
                            </div>
                            <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Select the Date &
                                Time you want you want to start.</div>
                        </div>
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <select class="form-select" id="ffloatingSelect" formControlName="frequency"
                                    aria-label="Floating label select example" required>
                                    <option value="">Select Frequency</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                                <label for="ffloatingSelect">Frequency</label>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <select class="form-select" id="tfloatingSelect" formControlName="triggerType"
                                    aria-label="Floating label select example" required>
                                    <option value="">Select Trigger Type</option>
                                    <option value="manually">Manual</option>
                                    <!-- <option value="automatic">Automatic</option> -->
                                </select>
                                <label for="tfloatingSelect">Trigger Type</label>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="pfloatingInput"
                                    formControlName="numberOfBeneficairy" required
                                    placeholder="Enter number of beneficiaries" required>
                                <label for="pfloatingInput">Number Of Beneficiaries</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-2">
                            <div class="form-floating">
                                <textarea class="form-control h-auto" formControlName="discription" rows="5"
                                    id="difloatingInput" required></textarea>
                                <label for="difloatingInput">Narration</label>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="hstack gap-2 justify-content-center">
                        <button type="button" class="btn btn-light btn-lg fw-medium"
                            (click)="cancelSalaryPaymentStatus()" data-bs-dismiss="modal">
                            Close</button>
                        <button type="submit" class="btn btn-main fw-medium" data-bs-toggle="modal"> Continue</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- salary payment list -->
<div class="row" *ngIf="!salaryPaymentStatus">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">Salary Schedules</h4>
                <div class="flex-shrink-0">
                    <div class="btn-group" *ngIf="config.currentUserHasPermissionByKey('CanCreateSchedule') || !config.checkIfNotSingleSignatory(authorizationType)">
                        <button class="btn btn-sec d-flex align-items-center" type="button"
                            (click)="activateSalaryPaymentStatus()">
                            <i class="bx bx-plus fs-5 mr-2"></i> Create Salary Schedule
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="config.currentUserHasPermissionByKey('CanViewSchedule') || !config.checkIfNotSingleSignatory(authorizationType)">
                <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                    <li class="nav-item fs-5">
                        <a class="nav-link active" data-bs-toggle="tab" href="#all" role="tab" aria-selected="false">
                            All
                        </a>
                    </li>
                    <li class="nav-item fs-5" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                        <a class="nav-link" data-bs-toggle="tab" href="#pending" role="tab" aria-selected="false">
                            Pending
                        </a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="tab-pane active" id="all" role="tabpanel">
                        <div class="custom-datatable overflow-auto salary-payment-table w-100">

                            <table id="schedules" data-control-id='schedules' data-control-scope='transaction-history'
                                class="table datatable table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Account</th>
                                        <th>Frequency</th>
                                        <th>No of Beneficiaries</th>
                                        <th>Description</th>
                                        <th>Trigger Type</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of schedules">
                                        <td>{{ item?.dateCreated | date }}</td>
                                        <td>{{ item?.accountNumber }}</td>
                                        <td>{{ item?.frequency }}</td>
                                        <td>{{ item?.numberOfBeneficairy }}</td>
                                        <td>{{ item?.discription || '-' }}</td>
                                        <td>{{ item?.triggerType || '-' }}</td>
                                        <td>
                                            <i class="mdi mdi-check-bold fs-4 text-success"
                                                *ngIf="item?.status == 1"></i>
                                            <i class="mdi mdi-alert-remove fs-4 text-danger"
                                                *ngIf="item?.status < 0"></i>
                                            <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                                *ngIf="item?.status == 0 || item?.status == null"></i>
                                            <i class="mdi mdi-pencil-plus fs-4 text-primary pointer"
                                                *ngIf="item?.status == 2"></i>
                                        </td>
                                        <td>
                                            <!-- *ngIf="item?.status < 0 && config.currentUserHasPermissionByKey('RequestCorporateUserProfileApproval')" -->
                                            <div class="btn-group">
                                                <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                    id="defaultDropdown" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true" aria-expanded="false">
                                                    <i class="ri-menu-2-line"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status >= 0 && config.currentUserHasPermissionByKey('UpdateCorporateUserRole')" (click)="initiateCreateOrUpdateRole(item)">Update User Role</a></li> -->
                                                    <li><a class="dropdown-item pointer"
                                                            (click)="initiateAddBeneficiaries(item)">
                                                            Beneficiaries</a></li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.triggerType === 'manually' && (config.currentUserHasPermissionByKey('CanInitiateSchedule')|| !config.checkIfNotSingleSignatory(authorizationType))"
                                                              (click)="initiateScheduleInitiation(item)" >Initiate
                                                            Schedule</a></li>
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status < 0  && (config.currentUserHasPermissionByKey('CanDeactivateSchedule') || !config.checkIfNotSingleSignatory(authorizationType))"
                                                            (click)="initiateScheduleReactivate(item)">Reactivate</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status == 1 && (config.currentUserHasPermissionByKey('CanDeactivateSchedule') || !config.checkIfNotSingleSignatory(authorizationType))"
                                                            (click)="initiateScheduleDeactivate(item)">Deactivate</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="tab-pane" id="pending" role="tabpanel"
                        *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                        <div class="custom-datatable overflow-auto salary-payment-table w-100">

                            <table id="pending-schedules" data-control-id='pending-schedules'
                                data-control-scope='transaction-history'
                                class="table datatable table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Account</th>
                                        <th>Frequency</th>
                                        <th>No of Beneficiaries</th>
                                        <th>Description</th>
                                        <th>Trigger Type</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of pendingSchedules">
                                        <td>{{ item?.dateCreated | date }}</td>
                                        <td>{{ item?.accountNumber }}</td>
                                        <td>{{ item?.frequency }}</td>
                                        <td>{{ item?.numberOfBeneficairy }}</td>
                                        <td>{{ item?.discription || '-' }}</td>
                                        <td>{{ item?.triggerType || '-' }}</td>
                                        <td>
                                            <i class="mdi mdi-check-bold fs-4 text-success"
                                                *ngIf="item?.status == 1"></i>
                                            <i class="mdi mdi-alert-remove fs-4 text-danger"
                                                *ngIf="item?.status < 0"></i>
                                            <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                                *ngIf="item?.status == 0 || item?.status == null"></i>
                                            <i class="mdi mdi-pencil-plus fs-4 text-primary pointer"
                                                *ngIf="item?.status == 2"></i>
                                        </td>
                                        <td>
                                            <!-- *ngIf="item?.status < 0 && config.currentUserHasPermissionByKey('RequestCorporateUserProfileApproval')" -->
                                            <div class="btn-group">
                                                <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                    id="defaultDropdown" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true" aria-expanded="false">
                                                    <i class="ri-menu-2-line"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status >= 0 && config.currentUserHasPermissionByKey('UpdateCorporateUserRole')" (click)="initiateCreateOrUpdateRole(item)">Update User Role</a></li> -->
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status != 0 && config.currentUserHasPermissionByKey('CanRequestScheduleApproval')"
                                                            (click)="initiateScheduleRequestApproval(item)">Request
                                                            Approval</a></li>
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status == 0 && config.currentUserHasPermissionByKey('CanApprovedSchedule')"
                                                            (click)="initiateScheduleApprove(item)">Approve</a></li>
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status == 0 && config.currentUserHasPermissionByKey('CanDeclineSchedule')"
                                                            (click)="initiateScheduleDecline(item)">Decline</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Warning Alert -->
<div class="d-flex"
    *ngIf="!config.currentUserHasPermissionByKey('CanCreateSchedule') && config.checkIfNotSingleSignatory(authorizationType)"
    [innerHtml]="config.currentUserUnauthorizationContentUi()"></div>
<!-- payment approval -->
<div class="modal fade" id="modal-salary-approval" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Approve Transaction
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">
                <img src="assets/images/salary-payment-approval.svg" class="position-absolute top-0 end-0"
                    style="z-index: 30;" />
                <div class="d-flex mb-5">
                    <div class="m-4">
                        <div class="text-muted">Source Account</div>
                        <div class="fw-semibold fs-5">Elevation Church Ministries</div>
                        <div class="text-muted">5050890123</div>
                    </div>
                    <div class="m-4">
                        <div>
                            <div class="text-muted">Total transactions</div>
                            <div class="fw-semibold fs-5">12</div>
                        </div>

                        <div class="mt-5">
                            <div class="text-muted">Approval Level</div>
                            <div class="fw-semibold fs-5">3</div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive table-card position-relative" style="z-index: 40;">
                    <table class="table table-nowrap mb-0">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Receipient Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Zainab Amina</td>
                                <td>5050123456</td>
                                <td>Access Bank</td>
                                <td>March 2022 Salary Payment</td>
                                <td>520,000</td>
                            </tr>
                            <tr>
                                <td>Udoka Ezeukwu</td>
                                <td>5050123456</td>
                                <td>Zenith Bank</td>
                                <td>March 2022 Salary Payment</td>
                                <td>521,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mt-5 position-relative authorizations-container">
                    <div style="display: inline-block;">
                        <div class="fs-4 fw-medium">Authorizations</div>
                        <div class="mt-3">
                            <div class="acitivity-timeline acitivity-main">
                                <div class="acitivity-item d-flex">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle">
                                            <i class=" ri-check-line fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Victor Moses</h6>
                                        <p class="text-muted mb-1">Some comment </p>
                                        <small class="mb-0 text-muted">02:14 PM Today</small>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle">
                                            <i class=" ri-check-line fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Adebola Adeogun</h6>
                                        <p class="text-muted mb-1"> Some comment</p>
                                        <p class="mb-0 text-muted"><small>9:47 PM Yesterday</small></p>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle">
                                            <i class=" ri-check-line fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Babatunde Onih</h6>
                                        <p class="text-muted mb-1">Some comment.</p>
                                        <small class="mb-0 text-muted">25 Dec, 2021</small>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs acitivity-avatar">
                                            <div class="avatar-title rounded-circle text-white bg-primary">
                                                <i class=" ri-more-fill fs-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">You</h6>
                                        <p class="text-muted mb-2">Some comment</p>
                                        <small class="mb-0 text-muted">12 Dec, 2021</small>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs acitivity-avatar">
                                            <div class="avatar-title rounded-circle text-secondary bg-secondary">
                                                <i class="mdi mdi-sale fs-14"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Successful</h6>
                                        <p class="text-muted mb-2">Transaction Ref</p>
                                        <small class="mb-0 text-muted">12 Dec, 2021</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <div class="fs-6"><span class="text-muted">Transaction charges:</span> <span
                                class="text-primary">N25.00</span></div>
                        <div class="mt-3">
                            <div class="fs-5 text-muted">Total Amount:</div>
                            <div class="fs-4 text-primary">N1,564,987.00</div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-lg-6 text-right">
                        <button type="button" class="btn btn-main btn-lg mx-2 mr-10 w-50"
                            data-bs-target="#modal-rejection-reason" data-bs-toggle="modal"
                            data-bs-dismiss="modal">Reject</button>
                    </div>
                    <div class="col-lg-6">
                        <button type="submit" class="btn btn-success btn-lg w-50"
                            data-bs-target='#modal-contgratulations' data-bs-toggle="modal"
                            data-bs-dismiss="modal">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- payment approveal summary -->
<div class="modal fade" id="modal-salary-transaction-summary" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Transaction Summary
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="btn-group position-absolute" style="top: 40px; right: 20px;">
                    <button type="button" class="btn btn-main dropdown-toggle" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Export <i class="bx bx-chevron-down fs-5 position-relative" style="top: 3px;"></i>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">PDF</a>
                        <a class="dropdown-item" href="#">Spreadsheet</a>
                    </div>
                </div>

                <div class="d-flex mb-5">
                    <div class="m-4">
                        <div class="text-muted">Source Account</div>
                        <div class="fw-semibold fs-5">Elevation Church Ministries</div>
                        <div class="text-muted">5050890123</div>
                    </div>
                    <div class="m-4">
                        <div>
                            <div class="text-muted">Total transactions</div>
                            <div class="fw-semibold fs-5">12</div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive table-card position-relative" style="z-index: 40;">
                    <table class="table table-nowrap mb-0">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Receipient Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Zainab Amina</td>
                                <td>5050123456</td>
                                <td>Access Bank</td>
                                <td>March 2022 Salary Payment</td>
                                <td>520,000</td>
                            </tr>
                            <tr>
                                <td>Udoka Ezeukwu</td>
                                <td>5050123456</td>
                                <td>Zenith Bank</td>
                                <td>March 2022 Salary Payment</td>
                                <td>521,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mt-5 position-relative authorizations-container">
                    <div style="display: inline-block;">
                        <div class="fs-4 fw-medium">Authorizations</div>
                        <div class="mt-3">
                            <div class="acitivity-timeline acitivity-main">
                                <div class="acitivity-item d-flex">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle">
                                            <i class=" ri-check-line fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Victor Moses</h6>
                                        <p class="text-muted mb-1">Some comment </p>
                                        <small class="mb-0 text-muted">02:14 PM Today</small>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle">
                                            <i class=" ri-check-line fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Adebola Adeogun</h6>
                                        <p class="text-muted mb-1"> Some comment</p>
                                        <p class="mb-0 text-muted"><small>9:47 PM Yesterday</small></p>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle">
                                            <i class=" ri-check-line fs-5"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Babatunde Onih</h6>
                                        <p class="text-muted mb-1">Some comment.</p>
                                        <small class="mb-0 text-muted">25 Dec, 2021</small>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs acitivity-avatar">
                                            <div class="avatar-title bg-success text-white rounded-circle">
                                                <i class=" ri-check-line fs-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">You</h6>
                                        <p class="text-muted mb-2">Some comment</p>
                                        <small class="mb-0 text-muted">12 Dec, 2021</small>
                                    </div>
                                </div>
                                <div class="acitivity-item py-3 d-flex">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs acitivity-avatar">
                                            <div class="avatar-title bg-success text-white rounded-circle">
                                                <i class=" ri-check-line fs-5"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="mb-1">Successful</h6>
                                        <p class="text-muted mb-2">Transaction Ref</p>
                                        <small class="mb-0 text-muted">12 Dec, 2021</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute top-0 end-0">
                        <div class="fs-6"><span class="text-muted">Transaction charges:</span> <span
                                class="text-primary">N25.00</span></div>
                        <div class="mt-3">
                            <div class="fs-5 text-muted">Total Amount:</div>
                            <div class="fs-4 text-primary">N1,564,987.00</div>
                        </div>

                        <div class="mt-5">
                            <div class="fs-5 text-muted">Approval Status:</div>
                            <div class="fs-4 text-muted">
                                <span class="badge badge-soft-success fw-semibold">Approved</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-lg-6 text-right">
                        <button type="button" class="btn btn-light btn-lg mx-2 mr-10 w-50"
                            data-bs-dismiss="modal">Back</button>
                    </div>
                    <div class="col-lg-6">
                        <button type="submit" class="btn btn-success btn-lg w-50">View Receipt</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- congratulatory message -->
<div class="modal fade" id="modal-contgratulations" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/gif/1103-confetti-outline.gif" width="200">

                <div class="mt-4">
                    <h4 class="mb-3">Transaction Approved</h4>
                    <p class="text-muted mb-4"> The transaction was successfully approved</p>
                    <div class="hstack gap-2 justify-content-center">
                        <a href="javascript:void(0);" class="btn btn-link link-primary fw-medium"
                            data-bs-dismiss="modal">
                            <i class="ri-close-line me-1 align-middle"></i> Close
                        </a>
                        <a href="javascript:void(0);" class="btn btn-link link-success fw-medium ml-3"
                            data-bs-target="#modal-salary-transaction-summary" data-bs-toggle="modal"
                            data-bs-dismiss="modal">
                            <i class=" align-middle"></i> View Transaction Summary
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- rejection reason -->
<div class="modal fade" id="modal-rejection-reason" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form>
                    <div class="fw-semibold fs-3">Reject Transaction</div>
                    <div class="fs-6 mt-4">You are about to reject this transaction, Please provide your reason for
                        rejecting this transaction</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" style="padding-top: 70px;"></textarea>
                            <label for="cityfloatingInput">Enter your comments or reason for rejecting this transaction
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="submit" class="btn btn-main btn-lg w-50" data-bs-target="#modal-rejection"
                                data-bs-toggle="modal" data-bs-dismiss="modal">Reject</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- rejection message -->
<div class="modal fade" id="modal-rejection" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/gif/1140-error-outline.gif" width="200">

                <div class="mt-4">
                    <h4 class="mb-3">Transaction Rejected</h4>
                    <p class="text-muted mb-4"> The transaction was rejected</p>
                    <div class="hstack gap-2 justify-content-center">
                        <a href="javascript:void(0);" class="btn btn-link link-primary fw-medium"
                            data-bs-dismiss="modal"><i class="ri-close-line me-1 align-middle"></i> Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-schedule-reason" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form>
                    <div class="fw-semibold fs-3">{{reasonType}} schedule</div>
                    <div class="fs-6 mt-4">You are about to {{reasonType}}, Please provide your reason.</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" [formControl]="declineReason"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Enter your reason </label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="submit" class="btn btn-main btn-lg w-50"
                                (click)="reasonType === 'decline' ? declineSchedule() : deactivateSchedule()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-select-beneficiaries" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Beneficiaries
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form (ngSubmit)="saveBeneficiaries('add')">
                <div class="modal-body p-5">
                    <div class="row">
                        <div class="col-md-6" *ngFor="let stf of staffs">
                            <div class="beneficiary">
                                <input type="checkbox" [id]="stf?.employeeId" [checked]="stf?.checked"
                                    (change)="toggleStaffFromBeneficiaryList($event.target,stf)" >
                                <label for="">{{stf.name}}</label>
                                <div>
                                    <input type="text" class="form-control" [defaultValue]="stf?.amount"
                                        placeholder="Enter Amount" (change)="updateStaffAmount($event.target,stf)"
                                        [id]="'p-'+ stf?.employeeId">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="submit" class="btn btn-main btn-lg w-50" *ngIf="config.currentUserHasPermissionByKey('CanCreateScheduleBeneficiary') || !config.checkIfNotSingleSignatory(authorizationType) ">Continue</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="initiateSalarySchedule()">
                    <div class="row g-3">
                        <div class="col-lg-12 mb-2">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your otp">
                                <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2"
                                    data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50"
                                    data-bs-toggle="modal">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>