<div class="scrollable-content" *ngIf="!hasUploaded">
    <div class="card-body position-relative">
        <div class="w-100 mb-5 d-flex flex-column px-5 text-white"
            style="height: 83px; background: linear-gradient(92.09deg, #242492 13.35%, #D1AE6C 114.27%); border-radius: 14px; box-shadow: 0px 0px 40px rgba(48, 73, 191, 0.04); place-content: center;">
            <div class="mb-1">Bulk Upload</div>
            <h3 class="text-white">Send Bulk Payments In Minutes</h3>
        </div>
        <img src="assets/images/bulk-payment.svg"
            style="position: absolute; width: 138.13px; height: 100px; top: 20px; right: 30px; " />

        <form [formGroup]="formBulkPayment" (ngSubmit)="verifyBulkTransaction($event.target)" class="p-5 w-100">
            <div class="row g-3">
                <div class="col-lg-6 mb-2">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" formControlName="sourceAccountNumber"
                            aria-label="Floating label select example"
                            (change)="getSelectedAccountInformation($event.target)">
                            <option value="">Select Account</option>
                            <option *ngFor="let account of accounts" [value]="account.accountNumber"
                                [attr.currency]="account.currencyCode" [attr.accountName]="account.accountName"
                                [attr.accountNunber]="account.accountNunber">{{ account.accountNumber }} ({{
                                account.customerName }} | N{{ account.availableBalance | number }})</option>
                        </select>
                        <label for="floatingSelect">Select Source Account</label>
                    </div>
                </div>
                <!-- <div class="col-lg-6 mb-2">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option value="">Select Transfer Mode</option>
                        </select>
                        <label for="floatingSelect">Transfer Mode</label>
                    </div>
                </div> -->
                <div class="col-lg-6 mb-2" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" formControlName="workflowId"
                            aria-label="Floating label select example">
                            <option value="">Select Workflow</option>
                            <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                            </option>
                        </select>
                        <label for="floatingSelect">Workflow</label>
                    </div>
                </div>
                <div class="col-lg-12 mb-2">
                    <div class="form-floating">
                        <textarea class="form-control h-auto" rows="5" formControlName="narration"></textarea>
                        <label for="cityfloatingInput">Batch Narration (Optional)</label>
                    </div>
                </div>
                <div class="col-lg-12">
                    <label for="exampleInputtime" class="form-label position-relative w-100">
                        Bulk Upload File
                        <div class="position-absolute top-0 end-0">
                            <label class="color-base-green position-relative pointer mr-4"
                                (click)="fetchBankCodeList()"><i class="bx bx-download fs-5 position-relative"
                                    style="top: 2px;"></i> Download Bank
                                Codes List</label>
                            <label class=" color-base-green position-relative pointer"
                                (click)="fetchSampleTemplate()"><i class="bx bx-download fs-5 position-relative"
                                    style="top: 2px;"></i> Download
                                Template</label>
                        </div>
                    </label>
                    <div class="block p-5">
                        <input name="file" type="file" accept=".xlsx, .xls, .csv" multiple
                            (change)="onFileSelected($event)">
                    </div>

                </div>
                <!-- <div class="col-lg-12">
                    <label for="exampleInputtime" class="form-label">Approval Workflow</label>
                    <div class="form-check form-switch form-switch-lg" dir="ltr">
                        <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
                    </div>
                    <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Activating this will allow
                        the authorizer to review, stop and delete scheduled payments.</div>
                </div> -->
                <div class="col-lg-3 mt-5"></div>
                <div class="col-lg-6 mt-5">
                    <div class="text-center">
                        <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                        <button type="submit" class="btn btn-main btn-lg w-50"> Preview</button>
                    </div>
                </div>
                <div class="col-lg-3 mt-5"></div>
            </div>
        </form>
    </div>
</div>

<!-- salary payment list -->
<div class="scrollable-content" *ngIf="hasUploaded">
    <div class="row">
        <div class="col-sm-12 col-lg-12 col-xxl-12">
            <div class="card border-0">
                <div class="card-header border-0 align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1 fs-18px">Bulk Payment</h4>
                    <div class="flex-shrink-0">
                        <div class="btn-group">
                            <button class="btn btn-secondary d-flex align-items-center" type="button" (click)="back()">
                                Go Back
                            </button>
                            <button class="btn btn-main d-flex align-items-center" type="button" (click)="openConfirmModal()">
                                Initiate
                            </button>

                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card p-2">
                                <h5>VAT</h5>
                                <p class="fs-6">{{bulkData?.vat | currency : '₦'}}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card p-2">
                                <h5>FEE</h5>
                                <p class="fs-6">{{bulkData?.fee | currency : '₦'}}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card p-2">
                                <h5>CUMMULATIVE PRINCIPAL</h5>
                                <p class="fs-6">{{(bulkData?.totalAmount - bulkData?.fee - bulkData?.vat ) | currency :
                                    '₦'}}
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card p-2">
                                <h5>TOTAL TRANSFER</h5>
                                <p class="fs-6">{{bulkData?.totalAmount | currency : '₦'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="custom-datatable overflow-auto salary-payment-table w-100">

                        <table id="salary-payment" data-control-id='salary-payment'
                            data-control-scope='transaction-history'
                            class="table datatable table-striped table-bordered" style="width:100%">
                            <thead>
                                <tr>
                                    <th>Account Name</th>
                                    <th>Credit Account</th>
                                    <th>Amount</th>
                                    <th>Narration</th>
                                    <th>Bank Name</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let entry of bulkEntries">
                                    <td>
                                        <span *ngIf="entry.error.includes('duplicate')"
                                            class="text-danger">{{entry.accountName }}</span>
                                        <span *ngIf="!entry.error.includes('duplicate')">{{entry.accountName }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="entry.error.includes('duplicate')" class="text-danger">{{
                                            entry.creditAccount }}</span>
                                        <span *ngIf="!entry.error.includes('duplicate')">{{ entry.creditAccount
                                            }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="entry.error.includes('duplicate')"
                                            class="text-danger">{{entry.creditAmount | currency: 'NGN' }}</span>
                                        <span *ngIf="!entry.error.includes('duplicate')">{{entry.creditAmount |
                                            currency: 'NGN' }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="entry.error.includes('duplicate')" class="text-danger">{{
                                            entry.narration || '-' }}</span>
                                        <span *ngIf="!entry.error.includes('duplicate')">{{ entry.narration || '-'
                                            }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="entry.error.includes('duplicate')" class="text-danger">{{
                                            entry.bankName }}</span>
                                        <span *ngIf="!entry.error.includes('duplicate')">{{ entry.bankName }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="entry.error.includes('duplicate')" class="text-danger">{{
                                            entry.error|| '-' }}</span>
                                        <span *ngIf="!entry.error.includes('duplicate')">{{ entry.error|| '-' }}</span>
                                    </td>
                                </tr>
                                <!-- <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><b>Total</b></td>
                                <td>{{bulkAmount | currency: 'NGN' }}</td>
                            </tr> -->
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modal-confirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <div class="mt-4">
                    <h4 class="mb-3">Are you sure you want to proceed?</h4>
                    <p class="text-danger mb-4" *ngIf="bulkEntries[0]?.error.includes('duplicate')"> This entry has
                        duplicate transactions</p>
                    <br />
                    <div class="hstack gap-2 justify-content-center">
                        <button  class="btn btn-light btn-lg fw-medium" (click)="hideModalConfirm()"> Close</button>
                        <button class="btn btn-main fw-medium" (click)="openAuthModal()"> Continue</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="initiateBulkTransfer($event.target)">
                    <div class="row g-3" style="align-items: center;">
                        <div *ngIf="authLimit == 1" class="col-lg-5 mb-2 mx-0 px-0">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="pin" required
                                    placeholder="Enter your pin" maxlength="4"
                                    (input)="replaceLettersWithSpace($event,'pin')">
                                <label for="emailfloatingInput" class="text-uppercase">PIN</label>
                            </div>
                        </div>
                        <div *ngIf="authLimit == 1" class="col-lg-1 mx-2">
                            <h1> + </h1>
                        </div>
                        <div [class]="authLimit == 1 ? 'col-lg-5 mb-2 mx-0 px-0': 'col-lg-12 mb-2'">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your token" maxlength="8"
                                    (input)="replaceLettersWithSpace($event,'otp')">
                                <label for="emailfloatingInput" class="text-uppercase">Token</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2" (click)="hideAuthModal()">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>