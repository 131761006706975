import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LogService, UiService, DataTablesService } from '@app/services';
import { modal, find, store, config, routes } from '@app/config';


import { DatePipe } from '@angular/common';

import { saveAs } from 'file-saver';

// jquery operator
declare var $: any;

// declare template name
let templateName = 'schedules.component';

@Component({
	templateUrl: `./${templateName}.html`,
	styleUrls: [`./${templateName}.css`]
})

export class SalarySchedulesComponent implements OnInit {
	// class properties
	config = config
	payments: any = []
	salaryPaymentStatus: boolean = false
	corporateCustomerId: string = ""
	authorizationType: any
	accountsListSource: any = []
	workflows = []
	accounts = []
	schedules = []
	staffs = []
	pendingSchedules = []
	currentUser: string
	currentlySelectedSchedule: any
	reasonType: string;
	formAddSchedule: FormGroup
	formTransferOtp: FormGroup
	declineReason: FormControl = new FormControl('')
	beneficiaries: any[] = []
	today: any = new Date()?.toLocaleString()
	constructor(private table: DataTablesService,
		private ui: UiService,
		private logger: LogService,
		private fb: FormBuilder,
		private http: HttpClient
	) {
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')
		this.currentUser = store.get('userId')
	}

	ngOnInit() {
		this.formAddSchedule = this.fb.group({
			accountNumber: ['', Validators.required],
			frequency: ['', Validators.required],
			numberOfBeneficairy: ['', Validators.required],
			isSalary: ['true'],
			triggerType: ['', Validators.required],
			startDate: [this.today, Validators.required],
			discription: ['', Validators.required],
			workFlowId: ['']
		})

		// define form
		this.formTransferOtp = this.fb.group({
			otp: ['', Validators.required],
		})

		// initialize table
		this.table.init()

		if (config.currentUserHasPermissionByKey('CanCreateSchedule') || !config.checkIfNotSingleSignatory(this.authorizationType)) {
			this.fetchAllSchedules()
		}

	}

	// initialize dropzone
	dropzone() {
		setTimeout(() => {
			eval(`
				var dropzonePreviewNode = document.querySelector("#dropzone-preview-list");
				dropzonePreviewNode.id = "";
				var previewTemplate = dropzonePreviewNode.parentNode.innerHTML;
				dropzonePreviewNode.parentNode.removeChild(dropzonePreviewNode);
				var dropzone = new Dropzone(".dropzone", {
				    url: "https://httpbin.org/post",
				    method: "post",
				    previewTemplate: previewTemplate,
				    previewsContainer: "#dropzone-preview"
				});`)
		}, 10)
	}

	// initialize flatpickr
	flatpickr() {
		setTimeout(() => {
			eval(`document.querySelectorAll("[data-provider]").forEach(function(e) {
	      var t, a, o;
	      "flatpickr" == e.getAttribute("data-provider") 
	      	? (o = {}, (t = e.attributes)["data-date-format"] && (o.dateFormat = t["data-date-format"].value.toString()),
	      		t["data-enable-time"] && (o.enableTime = !0, o.dateFormat = t["data-date-format"].value.toString() + " H:i"),
	      		t["data-altFormat"] && (o.altInput = !0, o.altFormat = t["data-altFormat"].value.toString()),
	      		t["data-minDate"] && (o.minDate = t["data-minDate"].value.toString(),o.dateFormat = t["data-date-format"].value.toString()),
	      		t["data-maxDate"] && (o.maxDate = t["data-maxDate"].value.toString(), o.dateFormat = t["data-date-format"].value.toString()),
	      		t["data-deafult-date"] && (o.defaultDate = t["data-deafult-date"].value.toString(),o.dateFormat = t["data-date-format"].value.toString()),
	      		t["data-multiple-date"] && (o.mode = "multiple", o.dateFormat = t["data-date-format"].value.toString()), 
	      		t["data-range-date"] && (o.mode = "range", o.dateFormat = t["data-date-format"].value.toString()),
	      		t["data-inline-date"] && (o.inline = !0, o.defaultDate = t["data-deafult-date"].value.toString(), o.dateFormat = t["data-date-format"].value.toString()),
	      		t["data-disable-date"] && ((a = []).push(t["data-disable-date"].value), o.disable = a.toString().split(",")),
	      		flatpickr(e, o)) : "timepickr" == e.getAttribute("data-provider") && (a = {}, (o = e.attributes)["data-time-basic"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i"), o["data-time-hrs"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.time_24hr = !0), o["data-min-time"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.minTime = o["data-min-time"].value.toString()), o["data-max-time"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.minTime = o["data-max-time"].value.toString()), o["data-default-time"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.defaultDate = o["data-default-time"].value.toString()), o["data-time-inline"] && (a.enableTime = !0, a.noCalendar = !0, a.defaultDate = o["data-time-inline"].value.toString(), a.inline = !0), flatpickr(e, a))
	    })`)
		}, 10)
	}

	// activate salary payment
	activateSalaryPaymentStatus() {
		// activate status
		this.salaryPaymentStatus = true
		// initialize flatpickr
		this.flatpickr()
		// initialize dropzone
		this.dropzone()
		this.fetchAllCorporateAccounts()
	}

	// cancel salary payment
	cancelSalaryPaymentStatus() {
		// cancel status
		this.salaryPaymentStatus = false
		// initialize table
		this.table.init()
	}
	async fetchAllSchedules() {
		// loading
		this.ui.blockUiOn(`Fetching all schedules... please wait`)


		// network call
		this.http.get<any>(config.base + routes.salary.getSchedules, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data
					this.table.destroy(`schedules`)
					// pass on data
					this.schedules = data?.data
					// reinit table
					this.table.reInit(`schedules`)

					// notification
					this.ui.info(`Schedules loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				if (config.checkIfNotSingleSignatory(this.authorizationType)) {
					this.fetchPendingSchedules()
				}
			});
	}

	async fetchPendingSchedules() {
		// loading
		this.ui.blockUiOn(`Fetching pending schedules... please wait`)


		// network call
		this.http.get<any>(config.base + routes.salary.getPendingSchedules, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data
					this.table.destroy(`pending-schedules`)
					// pass on data
					this.pendingSchedules = data?.data
					// reinit table
					this.table.reInit(`pending-schedules`)

					// notification
					this.ui.info(`Pending Schedules loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
					this.ui.blockUiOff()
				}
			})
			.add(() => { });
	}

	// fetch corporate accounts
	async fetchAllCorporateAccounts() {
		// pass the profile
		let id = this.corporateCustomerId

		// loading
		this.ui.blockUiOn(`Fetching corporate accounts... please wait`)

		let payload = await store.preparePayload({
			id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.getCorporateAccounts + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// notification
					this.ui.info(`Accounts loaded successfully.`)

					// pass on data
					this.accounts = data?.records

					//fetch workflows

					// auto-select account
					// this.formFilter.patchValue({
					// 	account: this.accounts[0].accountNumber
					// })
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				if (config.checkIfNotSingleSignatory(this.authorizationType)) {
					this.fetchAllCorporateCustomerWorkflows()
				}
			});
	}

	// fetch all workflows
	async fetchAllCorporateCustomerWorkflows() {
		// loading
		this.ui.blockUiOn(`Fetching all workflows... please wait`)

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.workflows.getWorkflows + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// pass on data
					this.workflows = data

					// notification
					this.ui.info(`Workflows loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
					this.ui.blockUiOff()
				}
			})
			.add(() => { });
	}

	// get selected account information
	getSelectedAccountInformation(target: any) {
		// get index
		let controlIndex = target['selectedIndex']

		// check if index is 0
		if (controlIndex < 1) {
			// reset the lists
			this.accountsListSource = this.accounts
		}


		this.formAddSchedule.patchValue({
			// currency: target.options[controlIndex].getAttribute("currency"),
			accountNumber: target.value,
		})

		// unide anything hidden
		this.accountsListSource = this.accounts
	}

	async createSalarySchedule() {
		// pass the profile
		let id = this.corporateCustomerId

		const today = new Date()

		if (!this.formAddSchedule.valid) {
			this.ui.warning('Please complete all fields')
			return
		}
		if (config.checkIfNotSingleSignatory(this.authorizationType) && !this.formAddSchedule.value.workFlowId) {
			this.ui.warning('please select workflow');
			return
		}
		if (new Date(this.formAddSchedule.value.startDate) < today) {
			this.ui.warning('Start date cannot be a past date and time')
			return
		}

		// loading
		this.ui.blockUiOn(`Creating schedule... please wait`)



		let payload = await store.preparePayload({
			...this.formAddSchedule.value,
			corporateCustomerId: id,
			createdBy: this.currentUser,
			// 'a913c39f-1f66-4e46-b193-06d95636da5c'
		}, false)

		// network call
		this.http.post<any>(config.base + routes.salary.createSalarySchedule, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// notification
					this.ui.info(`schedule created successfully.`)
					this.cancelSalaryPaymentStatus()
					this.formAddSchedule.reset()
					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.fetchAllSchedules()
			});

	}

	initiateScheduleInitiation(s: any) {
		// pass on data
		this.currentlySelectedSchedule = s
		modal.show("#modal-authentication-token")
		// // approve role
		// this.initiateSalarySchedule()
	}

	initiateScheduleRequestApproval(s: any) {
		// pass on data
		this.currentlySelectedSchedule = s
		// approve role
		this.requestScheduleApproval()
	}
	// initiate approve schedule
	initiateScheduleApprove(s: any) {
		// pass on data
		this.currentlySelectedSchedule = s
		// approve role
		this.approveSchedule()
	}

	// initiate decline role
	initiateScheduleDecline(s: any) {
		// pass on data
		this.currentlySelectedSchedule = s
		this.reasonType = "decline"
		// reset form
		this.declineReason.reset()
		// bring up modal
		modal.show(`#modal-schedule-reason`)
	}

	initiateScheduleReactivate(s: any) {
		// pass on data
		this.currentlySelectedSchedule = s
		// approve role
		this.reactivateSchedule()
	}

	// initiate deactivate role
	initiateScheduleDeactivate(s: any) {
		// pass on data
		this.currentlySelectedSchedule = s
		this.reasonType = "deactivate"
		// reset form
		this.declineReason.reset()
		// bring up modal
		modal.show(`#modal-schedule-reason`)
	}


	async initiateSalarySchedule() {
		// blocker
		this.ui.blockUiOn(`initiating schedule... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: this.currentlySelectedSchedule.id,
			otp: this.formTransferOtp.value.otp
		})



		// network
		this.http.post<any>(config.base + routes.salary.initiateSalarySchedule, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`schedule successfully initiated.`)
					this.formTransferOtp.reset()
					// fetch schedules
					this.fetchAllSchedules()

				},
				error: e => {
					// error
					this.ui.error(e)
					this.ui.blockUiOff()
				}
			})
			.add(() => this.ui.blockUiOff())
	}

	async requestScheduleApproval() {
		// blocker
		this.ui.blockUiOn(`Requesting approval schedule... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: this.currentlySelectedSchedule.id
		})

		// network
		this.http.post<any>(config.base + routes.salary.requestScheduleApproval, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`approval requested for schedule.`)

				},
				error: e => {
					// error
					this.ui.error(e)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchAllSchedules()
			})
	}


	// approve 
	async approveSchedule() {
		// blocker
		this.ui.blockUiOn(`Approving schedule... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: this.currentlySelectedSchedule.id
		})

		// network
		this.http.post<any>(config.base + routes.salary.approveSalarySchedule, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`schedule successfully approved.`)

				},
				error: e => {
					// error
					this.ui.error(e)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchAllSchedules()
			})
	}

	// decline 
	async declineSchedule() {
		// check if form is valid
		if (!this.declineReason.valid) {
			// warning
			this.ui.warning(`Please provide reason for declining schedule!`)
			return
		}


		// blocker
		this.ui.blockUiOn(`Declining schedule... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: this.currentlySelectedSchedule.id,
			reason: this.declineReason.value
		})


		// network
		this.http.post<any>(config.base + routes.salary.declineSalarySchedule, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`schedule successfully declined.`)

					// dismiss form
					this.declineReason.reset()

					// dismiss modal
					modal.hide(`#modal-schedule-reason`)
				},
				error: e => {
					// error
					this.ui.error(e)
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchAllSchedules()
			})
	}

	async reactivateSchedule() {
		// blocker
		this.ui.blockUiOn(`Reactivating schedule... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: this.currentlySelectedSchedule.id
		})

		// network
		this.http.post<any>(config.base + routes.salary.reactivateSalarySchedule, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`Reactivated schedule.`)

				},
				error: e => {
					// error
					this.ui.error(e)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchAllSchedules()
			})
	}
	async deactivateSchedule() {
		// check if form is valid
		if (!this.declineReason.valid) {
			// warning
			this.ui.warning(`Please provide reason for deactivating schedule!`)
			return
		}

		// blocker
		this.ui.blockUiOn(`Deactivating schedule... please wait`)

		// define payload
		let payload = await store.preparePayload({
			id: this.currentlySelectedSchedule.id,
			reason: this.declineReason.value
		})



		// network
		this.http.post<any>(config.base + routes.salary.deactivateSalarySchedule, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// notification
					this.ui.success(`schedule successfully deactivated.`)

					// dismiss form
					this.declineReason.reset()

					// dismiss modal
					modal.hide(`#modal-schedule-reason`)
				},
				error: e => {
					// error
					this.ui.error(e)
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchAllSchedules()
			})
	}

	async fetchScheduleBeneficiaries() {
		// loading
		this.ui.blockUiOn(`Fetching all beneficiaries... please wait`)

		const payload = await store.preparePayload({
			scheduleId: this.currentlySelectedSchedule?.id,
		}, true)

		// network call
		this.http.get<any>(config.base + routes.salary.getBeneficiaries + '?scheduleId=' + payload?.scheduleId, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data
					this.table.destroy(`salary-payment`)

					// pass on data
					this.beneficiaries = data?.data



					this.table.reInit(`salary-payment`)

					// notification
					this.ui.info(`Staffs loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.beneficiaries = []
					this.ui.error(error)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchAllStaffs()
			});
	}

	// fetch all staffs
	async fetchAllStaffs() {
		// loading
		this.ui.blockUiOn(`Fetching all staffs... please wait`)


		// network call
		this.http.get<any>(config.base + routes.salary.getStaffs, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					this.table.destroy(`salary-payment`)
					// pass on data
					this.staffs = data?.data?.map((stf: any) => {
						for (let i = 0; i < this.beneficiaries?.length; i++) {
							if (stf.id === this.beneficiaries[i].employeeId) {
								return { employeeId: stf?.id, name: `${stf?.firstName} ${stf?.lastName}`, amount: this.beneficiaries[i].amount, checked: true }
							}
						}
						return { employeeId: stf?.id, name: `${stf?.firstName} ${stf?.lastName}`, amount: '', checked: false }
					})

					this.table.reInit(`salary-payment`)

					// notification
					this.ui.info(`Staffs loaded successfully.`)

					this.ui.blockUiOff()
				},
				error: error => {
					// error
					this.ui.error(error)
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				this.ui.blockUiOff()
			});
	}



	updateStaffAmount(e: any, s: any) {

		this.staffs = this.staffs?.map((stf: any) => {
			if (stf?.employeeId === s?.employeeId) {
				stf.amount = e?.value
				return stf
			}
			return stf
		})

	}

	toggleStaffFromBeneficiaryList(e: any, s: any) {
		const curr: any = document.getElementById(s?.employeeId)
		const p: any = document.getElementById(`p-${s?.employeeId}`)

		if (e.checked && this.beneficiaries.length > this.currentlySelectedSchedule?.numberOfBeneficairy) {
			curr.checked = false
			this.ui.warning('exceeded number of beneficiaries allowed for schedule')
			return
		}

		if (e.checked) {

			p.required = true
			this.beneficiaries.push(s)
		} else {
			this.beneficiaries = this.beneficiaries.filter((ben) => ben?.employeeId !== s?.employeeId)
		}
	}

	async initiateAddBeneficiaries(s) {
		this.currentlySelectedSchedule = s
		modal.show('#modal-select-beneficiaries')
		this.fetchScheduleBeneficiaries()
	}


	async saveBeneficiaries(type: string) {

		if (this.beneficiaries?.find((b) => !b.amount)) {
			this.ui.warning('Please enter an amount for all selected beneficiaries')
			return
		}
		if (this.beneficiaries.length > this.currentlySelectedSchedule.numberOfBeneficairy) {
			this.ui.warning('Selected beneficiaries cannot be greater than allowed number')
			return
		}
		this.ui.blockUiOn(`Saving... please wait`)

		const payload = await store.preparePayload({
			corporateCustomerId: this.corporateCustomerId,
			scheduleId: this.currentlySelectedSchedule?.id,
			beneficiaries: JSON.stringify(this.beneficiaries)
		})

		if (type === 'add') {
			// network call
			this.http.post<any>(config.base + routes.salary.addBeneficiaries, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// notification
						this.ui.info(`Saved successfully.`)
						modal.hide('#modal-select-beneficiaries')

						this.ui.blockUiOff()
					},
					error: error => {
						// error
						this.ui.error(error)
						this.ui.blockUiOff()
					}
				})
				.add(() => {
					this.ui.blockUiOff()
				});
		}
	}
}