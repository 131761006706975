import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// define template
let template = 'bills-payment.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class BillsPaymentComponent implements OnInit {
	constructor() {}

	ngOnInit () {
	}
}