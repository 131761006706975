<div class="scrollable-content row">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <!-- all users -->
            <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                <li class="nav-item fs-5 cursor-pointer" (click)="fetchPendingTransactions()">
                    <a class="nav-link active" data-bs-toggle="tab" href="#transfers" role="tab" aria-selected="false">
                        Single Transfers
                    </a>
                </li>
                <li class="nav-item fs-5 cursor-pointer" (click)="fetchPendingBulkTransactions()">
                    <a class="nav-link" data-bs-toggle="tab" href="#bulkTransfers" role="tab" aria-selected="false">
                        Bulk Transfers
                    </a>
                </li>
            </ul>
            <div class="card-body">
                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane active" id="transfers" role="tabpanel">
                        <div class="d-flex">
                            <div
                                class="custom-datatable overflow-auto custom-datatable-gradient  transaction-history-records w-100">

                                <table id="pending-transaction-records" data-control-id='pending-transaction-records'
                                    class="table datatable table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Date</th>
                                            <th>Narration</th>
                                            <th>Debit Account</th>
                                            <th>Amount</th>
                                            <th>No. Of Credits</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of pendingTransactions; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ item?.pendingTransaction.dateInitiated | date }}</td>
                                            <td>{{ item?.pendingTransaction.narration }}</td>
                                            <td>
                                                <div class="fw-semibold">{{ item?.pendingTransaction.debitAccountName ||
                                                    '-' }}</div>
                                                <div>{{ item?.pendingTransaction.debitAccountNumber || '-' }}</div>
                                            </td>
                                            <td>{{ item?.pendingTransaction.debitAmount | number }}</td>
                                            <td>{{ (item?.pendingTransaction.noOfCredits || 0) | number }}</td>
                                            <td>{{ item?.pendingTransaction.transferType || '-' }}</td>
                                            <td>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-light dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i class="ri-menu-2-fill"></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <!-- <a class="dropdown-item pointer"
                                                            (click)="fetchCreditAccountsForTransaction(item?.pendingTransaction)">View
                                                            Credit
                                                            Accounts</a> -->
                                                        <a class="dropdown-item pointer"
                                                            (click)="initiateFetchTransactionApprovalHistory(item)">Approval
                                                            History</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div class="tab-pane" id="bulkTransfers" role="tabpanel">
                        <div class="d-flex">
                            <div
                                class="custom-datatable overflow-auto custom-datatable-gradient  transaction-history-records w-100">

                                <table id="pending-bulk-records" data-control-id='pending-bulk-records'
                                    class="table datatable table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Date</th>
                                            <th>Narration</th>
                                            <th>Debit Account</th>
                                            <th>Amount</th>
                                            <th>No. Of Credits</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of pendingBulkTransactions; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ item?.pendingTransaction?.dateInitiated | date }}</td>
                                            <td>{{ item?.pendingTransaction?.narration }}</td>
                                            <td>
                                                <div class="fw-semibold">{{ item?.pendingTransaction?.debitAccountName || '-' }}</div>
                                                <div>{{ item?.pendingTransaction?.debitAccountNumber || '-' }}</div>
                                            </td>
                                            <td>{{ item?.pendingTransaction?.debitAmount | number }}</td>
                                            <td>{{ (item?.pendingTransaction?.noOfCredits || 0) | number }}</td>
                                            <td>{{ item?.pendingTransaction?.transferType || '-' }}</td>
                                            <td>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-light dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i class="ri-menu-2-fill"></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <!-- <a class="dropdown-item pointer"
                                                            (click)="fetchCreditAccountsForBulkTransaction(item)">View
                                                            Credit
                                                            Accounts</a> -->
                                                        <a class="dropdown-item pointer"
                                                            (click)="initiateFetchBulkTransactionApprovalHistory(item)">Approval
                                                            History</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- credit accounts -->
<!-- <div class="modal fade" id="modal-transaction-credit-accounts" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Credit Accounts
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-1">

                <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                    <table class="table datatable table-striped table-bordered w-100" id="transaction-credit-accounts"
                        data-control-id="transaction-credit-accounts-pending">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Account Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Bank Code</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of creditAccountsList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.creditAccountName }}</td>
                                <td>{{ item?.creditAccountNumber }}</td>
                                <td>{{ item?.creditBankName }}</td>
                                <td>{{ item?.creditBankCode }}</td>
                                <td>{{ item?.creditAmount | number }}</td>
                                <td>{{ item?.narration }}</td>
                                <td>{{ (item?.creditDate | date) || '-' }}</td>
                                <td>
                                    <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.creditStatus  == 1"></i>
                                    <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                        *ngIf="item?.creditStatus == 0 "></i>
                                    <i class="mdi mdi-alert-circle-outline fs-4 text-danger"
                                        *ngIf="item?.creditStatus > 1 "></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div> -->

<!-- payment approval -->
<div class="modal fade" id="modal-transaction-approval" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Approve Transaction
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">
                <div class="d-flex mb-5">
                    <div class="m-4 flex-grow-1">
                        <div class="text-muted">Source Account</div>
                        <div class="fw-semibold fs-5">{{ currentlySelectedTransaction?.debitAccountName || '-' }}</div>
                        <div class="text-muted">{{ currentlySelectedTransaction?.debitAccountNumber || '-' }}</div>
                    </div>
                    <div class="m-4 flex-grow-1">
                        <div>
                            <div class="text-muted">Total transactions</div>
                            <div class="fw-semibold fs-5">{{ creditAccountsList?.length }}</div>
                        </div>
                    </div>
                    <div class="m-4 flex-grow-1">
                        <div>
                            <div class="text-muted">Approval Levels</div>
                            <div class="fw-semibold fs-5">{{ approvalUsersList?.length }}</div>
                        </div>
                    </div>
                </div>

                <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                    <h5>
                        Credit Logs
                    </h5>
                    <table class="table datatable table-striped table-bordered w-100"
                        id="transaction-credit-accounts-approval"
                        data-control-id="transaction-credit-accounts-approval-l">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Account Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Bank Code</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of creditAccountsList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.creditAccountName }}</td>
                                <td>{{ item?.creditAccountNumber }}</td>
                                <td>{{ item?.creditBankName }}</td>
                                <td>{{ item?.creditBankCode }}</td>
                                <td>{{ item?.creditAmount | currency : 'NGN' }}</td>
                                <td>{{ item?.narration }}</td>
                                <td>{{ item?.creditDate | date }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div class="mt-5 position-relative d-flex authorizations-container">
                    <div class="flex-grow-1">
                        <div class="fs-4 fw-medium">Authorizations</div>
                        <div class="mt-3">
                            <div class="acitivity-timeline acitivity-main">
                                <div class="acitivity-item d-flex py-3 " *ngFor="let item of filteredApprovalUsersList">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle"
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) > item?.approvalLevel">
                                            <i class="ri-check-line fs-5"></i>
                                        </div>
                                        <div class="avatar-title rounded-circle text-white bg-primary"
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) == item?.approvalLevel">
                                            <i class="ri-more-fill fs-5"></i>
                                        </div>
                                        <div class="avatar-title rounded-circle text-secondary bg-secondary"
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) < item?.approvalLevel">
                                            <i class="mdi mdi-sale fs-14"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6
                                            class="mb-1 {{ ((currentlySelectedTransaction?.approvalStage || 0) > item?.approvalLevel) ? '' : 'mt-2' }}">
                                            {{ item?.approverName }}</h6>
                                        <div
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) > item?.approvalLevel">
                                            <p class="text-muted mb-1">{{ item?.comment }}</p>
                                            <small class="mb-0 text-muted">{{ item?.approvalDate | date }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1 text-right">
                        <div class="mt-5">
                            <div class="fs-5 text-muted">Total Amount:</div>
                            <div class="fs-4 text-danger">N {{ (currentlySelectedTransaction?.debitAmount || 0) | number
                                }}</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 mt-5">
                    <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <!-- *ngIf="currentUserIsAuthorizerAndShouldApprove && config.currentUserHasPermissionByKey('ApproveTransaction')" -->
                        <button type="submit" class="btn btn-danger btn-lg w-25 mx-2" data-bs-dismiss="modal"
                            (click)="initiateDeclineTransaction()"
                            *ngIf="currentUserIsAuthorizerAndShouldApprove">Decline</button>
                        <button type="submit" class="btn btn-main btn-lg w-25" data-bs-dismiss="modal"
                            (click)="initiateApproveTransaction()"
                            *ngIf="currentUserIsAuthorizerAndShouldApprove">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- payment bulk  approval -->
<div class="modal fade" id="modal-transaction-approval-bulk" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    Approve Transaction
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">
                <div class="d-flex mb-5">
                    <div class="m-4 flex-grow-1">
                        <div class="text-muted">Source Account</div>
                        <div class="fw-semibold fs-5">{{ currentlySelectedTransaction?.debitAccountName || '-' }}</div>
                        <div class="text-muted">{{ currentlySelectedTransaction?.debitAccountNumber || '-' }}</div>
                    </div>
                    <div class="m-4 flex-grow-1">
                        <div>
                            <div class="text-muted">Total transactions</div>
                            <div class="fw-semibold fs-5">{{ creditAccountsList?.length }}</div>
                        </div>
                    </div>
                    <div class="m-4 flex-grow-1">
                        <div>
                            <div class="text-muted">Approval Levels</div>
                            <div class="fw-semibold fs-5">{{ approvalUsersList?.length }}</div>
                        </div>
                    </div>
                </div>

                <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                    <h5>
                        Approval History
                    </h5>
                    <table class="table datatable table-striped table-bordered w-100"
                        id="transaction-credit-accounts-approval"
                        data-control-id="transaction-credit-accounts-approval">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">Account Name</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Bank Code</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Narration</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of creditAccountsList; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.creditAccountName }}</td>
                                <td>{{ item?.creditAccountNumber }}</td>
                                <td>{{ item?.creditBankName }}</td>
                                <td>{{ item?.creditBankCode }}</td>
                                <td>{{ item?.creditAmount | currency : 'NGN' }}</td>
                                <td>{{ item?.narration }}</td>
                                <td>{{ item?.creditDate | date }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mt-5 position-relative d-flex authorizations-container">
                    <div class="flex-grow-1">
                        <div class="fs-4 fw-medium">Authorizations</div>
                        <div class="mt-3">
                            <div class="acitivity-timeline acitivity-main">
                                <div class="acitivity-item d-flex py-3 " *ngFor="let item of filteredApprovalUsersList">
                                    <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div class="avatar-title bg-success text-white rounded-circle"
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) > item?.approvalLevel">
                                            <i class="ri-check-line fs-5"></i>
                                        </div>
                                        <div class="avatar-title rounded-circle text-white bg-primary"
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) == item?.approvalLevel">
                                            <i class="ri-more-fill fs-5"></i>
                                        </div>
                                        <div class="avatar-title rounded-circle text-secondary bg-secondary"
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) < item?.approvalLevel">
                                            <i class="mdi mdi-sale fs-14"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6
                                            class="mb-1 {{ ((currentlySelectedTransaction?.approvalStage || 0) > item?.approvalLevel) ? '' : 'mt-2' }}">
                                            {{ item?.approverName }}</h6>
                                        <div
                                            *ngIf="(currentlySelectedTransaction?.approvalStage || 0) > item?.approvalLevel">
                                            <p class="text-muted mb-1">{{ item?.comment }}</p>
                                            <small class="mb-0 text-muted">{{ item?.approvalDate | date }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1 text-right">
                        <div class="mt-5">
                            <div class="fs-5 text-muted">Total Amount:</div>
                            <div class="fs-4 text-danger">N {{ (currentlySelectedTransaction?.debitAmount || 0) | number
                                }}</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 mt-5">
                    <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-danger btn-lg w-25 mx-2" data-bs-dismiss="modal"
                            *ngIf="currentUserIsAuthorizerAndShouldApprove"
                            (click)="initiateDeclineBulkTransaction()">Decline</button>
                        <button type="submit" class="btn btn-main btn-lg w-25" data-bs-dismiss="modal"
                            *ngIf="currentUserIsAuthorizerAndShouldApprove"
                            (click)="initiateApproveBulkTransaction()">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- approval comment -->
<div class="modal fade" id="modal-approval-comment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formApprovalComment">
                    <div class="fw-semibold fs-3">Approval Comment</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="comment"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Please provide your comment (if any)</label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="button" class="btn btn-main btn-lg w-50" data-bs-dismiss="modal"
                                (click)="bringUpAutheticationModal()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- approval comment -->
<div class="modal fade" id="modal-approval-comment-bulk" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formApprovalComment">
                    <div class="fw-semibold fs-3">Approval Comment</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="comment"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Please provide your comment (if any)</label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="button" class="btn btn-main btn-lg w-50" data-bs-dismiss="modal"
                                (click)="bringUpBulkAutheticationModal()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- approval comment -->
<div class="modal fade" id="modal-decline-comment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formApprovalComment">
                    <div class="fw-semibold fs-3">Decline Comment</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="comment"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Please provide your comment (if any)</label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="button" class="btn btn-main btn-lg w-50" data-bs-dismiss="modal"
                                (click)="bringUpAutheticationModalDec()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- approval comment -->
<div class="modal fade" id="modal-decline-comment-bulk" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formApprovalComment">
                    <div class="fw-semibold fs-3">Decline Comment</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="comment"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Please provide your comment (if any)</label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="button" class="btn btn-main btn-lg w-50" data-bs-dismiss="modal"
                                (click)="bringUpBulkAutheticationModalDec()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="approveTransaction($event.target)">
                    <div class="row g-3" style="align-items: center;">

                        <div *ngIf="authLimit == 1" class="col-lg-5 mb-2 mx-0 px-0">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="pin" required
                                    placeholder="Enter your pin" maxlength="4"
                                    (input)="replaceLettersWithSpace($event,'pin')">
                                <label for="emailfloatingInput" class="text-uppercase">PIN</label>
                            </div>
                        </div>
                        <div *ngIf="authLimit == 1" class="col-lg-1 mx-2">
                            <h1> + </h1>
                        </div>
                        <div [class]="authLimit == 1 ? 'col-lg-5 mb-2 mx-0 px-0': 'col-lg-12 mb-2'">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your token" maxlength="8"
                                    (input)="replaceLettersWithSpace($event,'otp')">
                                <label for="emailfloatingInput" class="text-uppercase">Token</label>
                            </div>
                        </div>

                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2"
                                    data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- bulk authentication modal -->
<div class="modal fade" id="modal-authentication-token-bulk" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="approveBulkTransaction($event.target)">
                    <div class="row g-3" style="align-items: center;">

                        <div *ngIf="authLimit == 1" class="col-lg-5 mb-2 mx-0 px-0">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="pin" required
                                    placeholder="Enter your pin" maxlength="4"
                                    (input)="replaceLettersWithSpace($event,'pin')">
                                <label for="emailfloatingInput" class="text-uppercase">PIN</label>
                            </div>
                        </div>
                        <div *ngIf="authLimit == 1" class="col-lg-1 mx-2">
                            <h1> + </h1>
                        </div>
                        <div [class]="authLimit == 1 ? 'col-lg-5 mb-2 mx-0 px-0': 'col-lg-12 mb-2'">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your token" maxlength="8"
                                    (input)="replaceLettersWithSpace($event,'otp')">
                                <label for="emailfloatingInput" class="text-uppercase">Token</label>
                            </div>
                        </div>

                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2"
                                    data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-authentication-token-dec" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="declineTransaction($event.target)">
                    <div class="row g-3">
                        <div class="col-lg-12 mb-2">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your token" minlength="8" maxlength="8"
                                    (input)="replaceLettersWithSpace($event,'otp')">
                                <label for="emailfloatingInput" class="text-uppercase">Token</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2"
                                    data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- bulk authentication modal -->
<div class="modal fade" id="modal-authentication-token-bulk-dec" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
                    (ngSubmit)="declineBulkTransaction($event.target)">
                    <div class="row g-3">
                        <div class="col-lg-12 mb-2">
                            <div class="form-floating">
                                <input class="form-control" formControlName="otp" required type="password"
                                    placeholder="Enter your token" (input)="replaceLettersWithSpace($event,'otp')"  maxlength="8">
                                <label for="emailfloatingInput" class="text-uppercase">Please enter your token</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2"
                                    data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- view transaction record -->
<div class="modal fade" id="modal-view-transaction-history" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="border: none;" id="receipt-container">
            <div class="modal-header" style="background: #2e2dad;">
                <button type="button" *ngIf="!receiptDownloadingStatus" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" style="filter: invert(1); opacity: 1;"></button>
            </div>
            <div class="modal-body p-0">

                <div class="d-flex flex-column align-items-center text-center p-5" style="background: #2e2dad;">
                    <div>
                        <img src="assets/images/main-logo.png" style="height: 80px; width: auto;" />
                    </div>
                    <div class="mt-3">
                        <label class="text-white fs-5"
                            style="background: #d1ad6c; padding: 10px 15px; border-radius: 100px; -webkit-border-radius: 100px; -moz-border-radius: 100px;">You
                            transferred</label>
                    </div>
                    <div class="mt-3">
                        <label class="text-white display-4 fw-semibold">
                            <small class="fs-1" style="vertical-align: top; position: relative; top: 7px;">{{
                                currentlySelectedTransaction?.currency ? currentlySelectedTransaction?.currency + ' ' :
                                '' }}</small>{{ currentlySelectedTransaction?.tranAmout | currency : 'NGN' }}
                        </label>
                    </div>
                    <div class="mt-3 pb-4">
                        <label class="text-white fs-5">
                            {{ currentlySelectedTransaction?.tranDate | date : 'fullDate' }} at {{
                            currentlySelectedTransaction?.tranDate | date : 'shortTime' }}
                        </label>
                    </div>
                </div>

                <div class="d-flex p-5">

                    <table class="table table-striped table-bordered" id="transfer-summary-table">
                        <tbody>
                            <tr>
                                <td>Sender</td>
                                <td class="fw-semibold">
                                    <div class="text-dark mb-2">{{ currentlySelectedTransaction?.sourceAccountName }}
                                    </div>
                                    <div class="text-muted">{{ currentlySelectedTransaction?.sourceAccountNo }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Recipient</td>
                                <td class="fw-semibold">
                                    <div class="text-dark mb-2">{{ currentlySelectedTransaction?.destinationAcctName }}
                                    </div>
                                    <div class="text-muted mb-2">{{ currentlySelectedTransaction?.destinationAcctNo }}
                                    </div>
                                    <div class="text-muted">{{ currentlySelectedTransaction?.desctionationBank ||
                                        currentlySelectedTransaction?.destionationBank }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.tranAmout | currency : 'NGN' }}
                                </td>
                            </tr>
                            <tr>
                                <td>Transaction Type</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.tranType || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Narration</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.narration || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Reference</td>
                                <td class="fw-semibold">{{ currentlySelectedTransaction?.transactionReference ||
                                    currentlySelectedTransaction?.providerRef || '-' }}</td>
                            </tr>
                            <tr>
                                <td>Status</td>

                                <td class="fw-semibold">
                                    {{ currentlySelectedTransaction.transactionStatus }}
                                </td>

                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="text-center mt-10 mb-10" *ngIf="!receiptDownloadingStatus">
                    <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                    <div class="btn-group w-50" *ngIf="currentUser != config.users.sole">
                        <button class="btn btn-main btn-lg dropdown-toggle" type="button" id="defaultDropdown"
                            data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            Download <i class="bx bx-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="defaultDropdown" style="">
                            <li><a class="dropdown-item pointer" (click)="downloadTransactionReceipt()">As PDF</a></li>
                            <li><a class="dropdown-item pointer" (click)="downloadTransactionReceiptAsImage()">As
                                    Image</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>