import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';

// define template
let template = 'logout.component'

@Component({
	template: `<div class="p-2">Signing out... please wait</div>`
})
export class LogoutComponent {
	sessionTimeoutFlag: string = config.timeoutSessionFlag

	constructor(private router: Router) {
		// define status
		let status = false

		// check if session timeout flag is stored
		if (sessionStorage.getItem(this.sessionTimeoutFlag)) {
			// turn on status
			status = true
		}

		// clear store
		store.clear()

		// reset flag
		sessionStorage.setItem(this.sessionTimeoutFlag, '1')

		// redirect
		window.location.href = '/admin/login'
	}
}