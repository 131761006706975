<!-- salary payment list -->
<div class="row">
   <div class="col-sm-12 col-lg-12 col-xxl-12">
      <div class="card border-0">
         <div class="card-header border-0 align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1 fs-18px">Salary Staffs</h4>
            <div class="flex-shrink-0 d-flex gap-2" *ngIf="config.currentUserHasPermissionByKey('CanCreateStaff') || !config.checkIfNotSingleSignatory(authorizationType)">
               <!-- <div class="btn-group"> -->
               <button class="btn btn-main d-flex align-items-center" type="button" (click)="openStaffForm()">
                  <i class="bx bx-plus fs-5 mr-2"></i> Add Single Staff
               </button>
               <button class="btn btn-main d-flex ml-2 align-items-center" type="button" routerLink="/user/staffs-bulk">
                  <i class="bx bx-plus fs-5 mr-2"></i> Add Multiple Staffs
               </button>
               <!-- </div> -->
            </div>
         </div>

         <div class="card-body" *ngIf="config.currentUserHasPermissionByKey('CanViewStaff') || !config.checkIfNotSingleSignatory(authorizationType)">
            <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
               <li class="nav-item fs-5">
                  <a class="nav-link active" data-bs-toggle="tab" href="#all" role="tab" aria-selected="false">
                     All
                  </a>
               </li>
               <li class="nav-item fs-5" *ngIf="config.checkIfNotSingleSignatory(authorizationType)"> 
                  <a class="nav-link" data-bs-toggle="tab" href="#pending" role="tab" aria-selected="false">
                     Pending
                  </a>
               </li>
            </ul>
            <div class="tab-content">
               <div class="tab-pane active" id="all" role="tabpanel">
                  <div class="custom-datatable overflow-auto salary-payment-table w-100">

                     <table id="salary-payment" data-control-id='salary-payment'
                        data-control-scope='transaction-history' class="table datatable table-striped table-bordered"
                        style="width:100%">
                        <thead>
                           <tr>
                              <th>S/N</th>
                              <th>Staff Id</th>
                              <th>Name</th>
                              <th>Dept</th>
                              <th>Account</th>
                              <th>Bank Name</th>
                              <th>Salary</th>
                              <th>Grade</th>
                              <th>Description</th>
                              <th>Date Added</th>
                              <th>Status</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let item of staffs; let i = index">
                              <td>{{ i + 1 }}</td>
                              <td>{{ item?.staffId }}</td>
                              <td>{{ item?.firstName }} {{ item?.lastName }}</td>
                              <td>{{ item?.department }}</td>
                              <td>{{ item?.accountNumber }}</td>
                              <td>{{ item?.bankName || '-' }}</td>
                              <td>{{ item?.salaryAmount || '-' }}</td>
                              <td>{{ item?.gradeLevel || '-' }}</td>
                              <td>{{ item?.description || '-' }}</td>
                              <td>{{ item?.dateCreated | date }}</td>
                              <td>
                                 <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.status == 1"></i>
                                 <i class="mdi mdi-alert-remove fs-4 text-danger" *ngIf="item?.status < 0"></i>
                                 <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                    *ngIf="item?.status == 0 || item?.status == null"></i>
                                 <i class="mdi mdi-pencil-plus fs-4 text-primary pointer" *ngIf="item?.status == 2"></i>
                              </td>
                              <td>
                                 <!-- *ngIf="item?.status < 0 && config.currentUserHasPermissionByKey('RequestCorporateUserProfileApproval')" -->
                                 <div class="btn-group">
                                    <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                       id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true"
                                       aria-expanded="false">
                                       <i class="ri-menu-2-line"></i>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                       <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status >= 0 && config.currentUserHasPermissionByKey('UpdateCorporateUserRole')" (click)="initiateCreateOrUpdateRole(item)">Update User Role</a></li> -->
                                       <li><a class="dropdown-item pointer" (click)="openStaffForm(item)" *ngIf="config.currentUserHasPermissionByKey('CanCreateStaff') || !config.checkIfNotSingleSignatory(authorizationType)">Edit</a>
                                       </li>
                                       <li><a class="dropdown-item pointer" *ngIf="item?.status < 0 && (config.currentUserHasPermissionByKey('CanReactivateStaff') || !config.checkIfNotSingleSignatory(authorizationType))"
                                             (click)="initiateStaffReactivate(item)">Reactivate</a>
                                       </li>
                                       <li><a class="dropdown-item pointer" *ngIf="item?.status == 1 && (config.currentUserHasPermissionByKey('CanDeactivateStaff') || !config.checkIfNotSingleSignatory(authorizationType))"
                                             (click)="initiateStaffDeactivate(item)">Deactivate</a>
                                       </li>
                                    </ul>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>

                  </div>
               </div>
               <div class="tab-pane" id="pending" role="tabpanel">
                  <div class="custom-datatable overflow-auto salary-payment-table w-100">

                     <table id="pending-salary-payment" data-control-id='pending-salary-payment'
                        data-control-scope='transaction-history' class="table datatable table-striped table-bordered"
                        style="width:100%">
                        <thead>
                           <tr>
                              <th>S/N</th>
                              <th>Staff Id</th>
                              <th>Name</th>
                              <th>Dept</th>
                              <th>Account</th>
                              <th>Bank Name</th>
                              <th>Salary</th>
                              <th>Grade</th>
                              <th>Description</th>
                              <th>Date Added</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let item of pendingStaffs; let i = index">
                              <td>{{ i + 1 }}</td>
                              <td>{{ item?.staffId }}</td>
                              <td>{{ item?.firstName }} {{ item?.lastName }}</td>
                              <td>{{ item?.department }}</td>
                              <td>{{ item?.accountNumber }}</td>
                              <td>{{ item?.bankName || '-' }}</td>
                              <td>{{ item?.salaryAmount || '-' }}</td>
                              <td>{{ item?.gradeLevel || '-' }}</td>
                              <td>{{ item?.description || '-' }}</td>
                              <td>{{ item?.dateCreated | date }}</td>
                              <td>
                                 <!-- *ngIf="item?.status < 0 && config.currentUserHasPermissionByKey('RequestCorporateUserProfileApproval')" -->
                                 <div class="btn-group">
                                    <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                       id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true"
                                       aria-expanded="false">
                                       <i class="ri-menu-2-line"></i>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                       <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status >= 0 && config.currentUserHasPermissionByKey('UpdateCorporateUserRole')" (click)="initiateCreateOrUpdateRole(item)">Update User Role</a></li> -->
                                       <li><a class="dropdown-item pointer" *ngIf="item?.status != 0 && config.currentUserHasPermissionByKey('CanRequestStaffApproval')"
                                             (click)="initiateStaffRequestApproval(item)">Request
                                             Approval</a></li>
                                       <li><a class="dropdown-item pointer" *ngIf="item?.status == 0 && config.currentUserHasPermissionByKey('CanApproveStaff')"
                                             (click)="initiateStaffApprove(item)">Approve</a></li>
                                       <li><a class="dropdown-item pointer" *ngIf="item?.status == 0 && config.currentUserHasPermissionByKey('CanDeclineStaff')"
                                             (click)="initiateStaffDecline(item)">Decline</a>
                                       </li>
                                    </ul>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>

                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>
</div>
<!-- Warning Alert -->
<div class="d-flex"
    *ngIf="!config.currentUserHasPermissionByKey('CanViewStaff') && config.checkIfNotSingleSignatory(authorizationType)"
    [innerHtml]="config.currentUserUnauthorizationContentUi()"></div>
    
<!-- payment approval -->
<div class="modal fade" id="modal-salary-approval" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabel">
               Approve Transaction
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">
            <img src="assets/images/salary-payment-approval.svg" class="position-absolute top-0 end-0"
               style="z-index: 30;" />
            <div class="d-flex mb-5">
               <div class="m-4">
                  <div class="text-muted">Source Account</div>
                  <div class="fw-semibold fs-5">Elevation Church Ministries</div>
                  <div class="text-muted">5050890123</div>
               </div>
               <div class="m-4">
                  <div>
                     <div class="text-muted">Total transactions</div>
                     <div class="fw-semibold fs-5">12</div>
                  </div>

                  <div class="mt-5">
                     <div class="text-muted">Approval Level</div>
                     <div class="fw-semibold fs-5">3</div>
                  </div>
               </div>
            </div>

            <div class="table-responsive table-card position-relative" style="z-index: 40;">
               <table class="table table-nowrap mb-0">
                  <thead class="table-light">
                     <tr>
                        <th scope="col">Receipient Name</th>
                        <th scope="col">Account No</th>
                        <th scope="col">Bank Name</th>
                        <th scope="col">Narration</th>
                        <th scope="col">Amount</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>Zainab Amina</td>
                        <td>5050123456</td>
                        <td>Access Bank</td>
                        <td>March 2022 Salary Payment</td>
                        <td>520,000</td>
                     </tr>
                     <tr>
                        <td>Udoka Ezeukwu</td>
                        <td>5050123456</td>
                        <td>Zenith Bank</td>
                        <td>March 2022 Salary Payment</td>
                        <td>521,000</td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <div class="mt-5 position-relative authorizations-container">
               <div style="display: inline-block;">
                  <div class="fs-4 fw-medium">Authorizations</div>
                  <div class="mt-3">
                     <div class="acitivity-timeline acitivity-main">
                        <div class="acitivity-item d-flex">
                           <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div class="avatar-title bg-success text-white rounded-circle">
                                 <i class=" ri-check-line fs-5"></i>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Victor Moses</h6>
                              <p class="text-muted mb-1">Some comment </p>
                              <small class="mb-0 text-muted">02:14 PM Today</small>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div class="avatar-title bg-success text-white rounded-circle">
                                 <i class=" ri-check-line fs-5"></i>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Adebola Adeogun</h6>
                              <p class="text-muted mb-1"> Some comment</p>
                              <p class="mb-0 text-muted"><small>9:47 PM Yesterday</small></p>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div class="avatar-title bg-success text-white rounded-circle">
                                 <i class=" ri-check-line fs-5"></i>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Babatunde Onih</h6>
                              <p class="text-muted mb-1">Some comment.</p>
                              <small class="mb-0 text-muted">25 Dec, 2021</small>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0">
                              <div class="avatar-xs acitivity-avatar">
                                 <div class="avatar-title rounded-circle text-white bg-primary">
                                    <i class=" ri-more-fill fs-5"></i>
                                 </div>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">You</h6>
                              <p class="text-muted mb-2">Some comment</p>
                              <small class="mb-0 text-muted">12 Dec, 2021</small>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0">
                              <div class="avatar-xs acitivity-avatar">
                                 <div class="avatar-title rounded-circle text-secondary bg-secondary">
                                    <i class="mdi mdi-sale fs-14"></i>
                                 </div>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Successful</h6>
                              <p class="text-muted mb-2">Transaction Ref</p>
                              <small class="mb-0 text-muted">12 Dec, 2021</small>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="position-absolute top-0 end-0">
                  <div class="fs-6"><span class="text-muted">Transaction charges:</span> <span
                        class="text-primary">N25.00</span></div>
                  <div class="mt-3">
                     <div class="fs-5 text-muted">Total Amount:</div>
                     <div class="fs-4 text-primary">N1,564,987.00</div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-lg-6 text-right">
                  <button type="button" class="btn btn-main btn-lg mx-2 mr-10 w-50"
                     data-bs-target="#modal-rejection-reason" data-bs-toggle="modal"
                     data-bs-dismiss="modal">Reject</button>
               </div>
               <div class="col-lg-6">
                  <button type="submit" class="btn btn-success btn-lg w-50" data-bs-target='#modal-contgratulations'
                     data-bs-toggle="modal" data-bs-dismiss="modal">Approve</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- payment approval summary -->
<div class="modal fade" id="modal-salary-transaction-summary" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabel">
               Transaction Summary
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">

            <div class="btn-group position-absolute" style="top: 40px; right: 20px;">
               <button type="button" class="btn btn-main dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  Export <i class="bx bx-chevron-down fs-5 position-relative" style="top: 3px;"></i>
               </button>
               <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">PDF</a>
                  <a class="dropdown-item" href="#">Spreadsheet</a>
               </div>
            </div>

            <div class="d-flex mb-5">
               <div class="m-4">
                  <div class="text-muted">Source Account</div>
                  <div class="fw-semibold fs-5">Elevation Church Ministries</div>
                  <div class="text-muted">5050890123</div>
               </div>
               <div class="m-4">
                  <div>
                     <div class="text-muted">Total transactions</div>
                     <div class="fw-semibold fs-5">12</div>
                  </div>
               </div>
            </div>

            <div class="table-responsive table-card position-relative" style="z-index: 40;">
               <table class="table table-nowrap mb-0">
                  <thead class="table-light">
                     <tr>
                        <th scope="col">Receipient Name</th>
                        <th scope="col">Account No</th>
                        <th scope="col">Bank Name</th>
                        <th scope="col">Narration</th>
                        <th scope="col">Amount</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>Zainab Amina</td>
                        <td>5050123456</td>
                        <td>Access Bank</td>
                        <td>March 2022 Salary Payment</td>
                        <td>520,000</td>
                     </tr>
                     <tr>
                        <td>Udoka Ezeukwu</td>
                        <td>5050123456</td>
                        <td>Zenith Bank</td>
                        <td>March 2022 Salary Payment</td>
                        <td>521,000</td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <div class="mt-5 position-relative authorizations-container">
               <div style="display: inline-block;">
                  <div class="fs-4 fw-medium">Authorizations</div>
                  <div class="mt-3">
                     <div class="acitivity-timeline acitivity-main">
                        <div class="acitivity-item d-flex">
                           <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div class="avatar-title bg-success text-white rounded-circle">
                                 <i class=" ri-check-line fs-5"></i>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Victor Moses</h6>
                              <p class="text-muted mb-1">Some comment </p>
                              <small class="mb-0 text-muted">02:14 PM Today</small>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div class="avatar-title bg-success text-white rounded-circle">
                                 <i class=" ri-check-line fs-5"></i>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Adebola Adeogun</h6>
                              <p class="text-muted mb-1"> Some comment</p>
                              <p class="mb-0 text-muted"><small>9:47 PM Yesterday</small></p>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div class="avatar-title bg-success text-white rounded-circle">
                                 <i class=" ri-check-line fs-5"></i>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Babatunde Onih</h6>
                              <p class="text-muted mb-1">Some comment.</p>
                              <small class="mb-0 text-muted">25 Dec, 2021</small>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0">
                              <div class="avatar-xs acitivity-avatar">
                                 <div class="avatar-title bg-success text-white rounded-circle">
                                    <i class=" ri-check-line fs-5"></i>
                                 </div>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">You</h6>
                              <p class="text-muted mb-2">Some comment</p>
                              <small class="mb-0 text-muted">12 Dec, 2021</small>
                           </div>
                        </div>
                        <div class="acitivity-item py-3 d-flex">
                           <div class="flex-shrink-0">
                              <div class="avatar-xs acitivity-avatar">
                                 <div class="avatar-title bg-success text-white rounded-circle">
                                    <i class=" ri-check-line fs-5"></i>
                                 </div>
                              </div>
                           </div>
                           <div class="flex-grow-1 ms-3">
                              <h6 class="mb-1">Successful</h6>
                              <p class="text-muted mb-2">Transaction Ref</p>
                              <small class="mb-0 text-muted">12 Dec, 2021</small>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="position-absolute top-0 end-0">
                  <div class="fs-6"><span class="text-muted">Transaction charges:</span> <span
                        class="text-primary">N25.00</span></div>
                  <div class="mt-3">
                     <div class="fs-5 text-muted">Total Amount:</div>
                     <div class="fs-4 text-primary">N1,564,987.00</div>
                  </div>

                  <div class="mt-5">
                     <div class="fs-5 text-muted">Approval Status:</div>
                     <div class="fs-4 text-muted">
                        <span class="badge badge-soft-success fw-semibold">Approved</span>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-lg-6 text-right">
                  <button type="button" class="btn btn-light btn-lg mx-2 mr-10 w-50"
                     data-bs-dismiss="modal">Back</button>
               </div>
               <div class="col-lg-6">
                  <button type="submit" class="btn btn-success btn-lg w-50">View Receipt</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="modal-new-staff" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabel">
               Create Staff
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">

            <div class="d-flex">
               <form [formGroup]="formAddStaff" class="w-100 needs-validation" (ngSubmit)="createOrEditStaff()">
                  <div class="row g-3">
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="firstName"
                              placeholder="Staff First Name" required>
                           <label for="emailfloatingInput">First Name</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="lastName"
                              placeholder="Staff Last Name" required>
                           <label for="emailfloatingInput">Last Name</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="staffId"
                              placeholder="Enter Staff Id" required>
                           <label for="emailfloatingInput">Staff Id</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="department"
                              placeholder="Enter Staff Department" required>
                           <label for="emailfloatingInput">Department</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                              formControlName="bankCode"
                              (change)="getSelectedAccountInformation($event.target, 'interbank')" required>
                              <option value="">Select Bank</option>
                              <option *ngFor="let item of bankList" [value]="item?.institutionCode"
                                 [attr.bankCode]="item?.institutionCode" [attr.bankName]="item?.institutionName">
                                 {{ item?.institutionName }} </option>
                           </select>
                           <label for="floatingSelect">Select Bank</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="accountNumber"
                              placeholder="Staff Account Number" (keyup)="getAccountInformation($event.target,'inter')" required>
                           <label for="emailfloatingInput">Account Number</label>
                        </div>
                     </div>
                     <div class="col-lg-12 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" readonly formControlName="accountName">
                           <label for="emailfloatingInput">Account Name</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="salaryAmount"
                              placeholder="Enter Staff Salary" required>
                           <label for="emailfloatingInput">Salary Amount</label>
                        </div>
                     </div>
                     <div class="col-lg-6 mb-2">
                        <div class="form-floating">
                           <input type="text" class="form-control" formControlName="gradeLevel"
                              placeholder="Enter Grade" required>
                           <label for="emailfloatingInput">Grade Level</label>
                        </div>
                     </div>
                     <div class="col-lg-12 mb-2">
                        <div class="form-floating">
                           <textarea type="text" class="form-control" formControlName="description"
                              placeholder="Enter Description" required></textarea>
                           <label for="emailfloatingInput">Description</label>
                        </div>
                     </div>

                  </div>
                  <div class="modal-footer">
                     <div class="col-lg-12 mt-5">
                        <div class="text-center">
                           <button type="button" data-bs-dismiss="modal"
                              class="btn btn-light btn-lg mx-2">Cancel</button>
                           <button type="submit" class="btn btn-main btn-lg w-50">Save</button>
                        </div>
                     </div>
                  </div>
               </form>
            </div>

         </div>


      </div>
   </div>
</div>

<!-- congratulatory message -->
<div class="modal fade" id="modal-contgratulations" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/gif/1103-confetti-outline.gif" width="200">

            <div class="mt-4">
               <h4 class="mb-3">Transaction Approved</h4>
               <p class="text-muted mb-4"> The transaction was successfully approved</p>
               <div class="hstack gap-2 justify-content-center">
                  <a href="javascript:void(0);" class="btn btn-link link-primary fw-medium" data-bs-dismiss="modal">
                     <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <a href="javascript:void(0);" class="btn btn-link link-success fw-medium ml-3"
                     data-bs-target="#modal-salary-transaction-summary" data-bs-toggle="modal" data-bs-dismiss="modal">
                     <i class=" align-middle"></i> View Transaction Summary
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- rejection reason -->
<div class="modal fade" id="modal-rejection-reason" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <form>
               <div class="fw-semibold fs-3">Reject Transaction</div>
               <div class="fs-6 mt-4">You are about to reject this transaction, Please provide your reason for rejecting
                  this transaction</div>
               <div class="fs-5 mt-4">
                  <div class="form-floating" style="text-align: left;">
                     <textarea class="form-control h-auto" rows="5" style="padding-top: 70px;"></textarea>
                     <label for="cityfloatingInput">Enter your comments or reason for rejecting this transaction
                     </label>
                  </div>
               </div>
               <div class="col-lg-12 mt-5">
                  <div class="text-center">
                     <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                     <button type="submit" class="btn btn-main btn-lg w-50" data-bs-target="#modal-rejection"
                        data-bs-toggle="modal" data-bs-dismiss="modal">Reject</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- rejection message -->
<div class="modal fade" id="modal-rejection" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/gif/1140-error-outline.gif" width="200">

            <div class="mt-4">
               <h4 class="mb-3">Transaction Rejected</h4>
               <p class="text-muted mb-4"> The transaction was rejected</p>
               <div class="hstack gap-2 justify-content-center">
                  <a href="javascript:void(0);" class="btn btn-link link-primary fw-medium" data-bs-dismiss="modal"><i
                        class="ri-close-line me-1 align-middle"></i> Close</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="modal-staff-reason" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <form>
               <div class="fw-semibold fs-3">{{reasonType}} staff</div>
               <div class="fs-6 mt-4">You are about to {{reasonType}}, Please provide your reason.</div>
               <div class="fs-5 mt-4">
                  <div class="form-floating" style="text-align: left;">
                     <textarea class="form-control h-auto" rows="5" [formControl]="declineReason"
                        style="padding-top: 50px;"></textarea>
                     <label for="cityfloatingInput">Enter your reason </label>
                  </div>
               </div>
               <div class="col-lg-12 mt-5">
                  <div class="text-center">
                     <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                     <button type="submit" class="btn btn-main btn-lg w-50"
                        (click)="reasonType === 'decline' ? deactivateStaff() : deactivateStaff()">Continue</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

