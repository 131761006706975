<div class="scrollable-content">
	<div class="d-flex widgets-container">
		<div class="flex-grow-1 p-5 d-flex flex-column align-items-center position-relative">
			<img src="assets/images/main-logo.png" class="pointer" routerLink="/user/login" /><br /><br />
	
			<div class="mt-4 text-center">
				<span class="fw-light fs-2 text-center text-black">Please set a security question</span>
			</div><br />
	
			<div class="w-100 text-center">
				<form [formGroup]="formBankUserDetails" class="m-auto needs-validation"
					(ngSubmit)="changeSecurityQuestion($event.target)">
					<div class="row text-left mt-5">
	
						<div class="col-12">
							<div class="mb-3">
								<label class="form-label fw-semibold">Security Question 1</label>
								<select class="form-select" formControlName="SecurityQuestionId" required>
									<option value="">Select Question</option>
									<option *ngFor="let item of securityQuestions" [value]="item?.id">{{ item?.question }}</option>
								</select>
							</div>
						</div>
						<div class="col-12">
							<div class="mb-3">
								<label class="form-label fw-semibold">Answer 1</label>
								<input type="text" class="form-control" formControlName="Answer" required
									placeholder="Enter your Answer">
							</div>
						</div>
	
						<div class="col-12 mt-7">
							<div class="mb-3">
								<label class="form-label fw-semibold">Security Question 2</label>
								<select class="form-select" formControlName="SecurityQuestionId2" required
									(change)="detectQuestionSelected(2)">
									<option value="">Select Question</option>
									<option *ngFor="let item of securityQuestions" [value]="item?.id">{{ item?.question }}</option>
								</select>
							</div>
						</div>
						<div class="col-12">
							<div class="mb-3">
								<label class="form-label fw-semibold">Answer 2</label>
								<input type="text" class="form-control" formControlName="Answer2" required
									placeholder="Enter your Answer">
							</div>
						</div>
	
						<div class="col-12 mt-7">
							<div class="mb-3">
								<label class="form-label fw-semibold">Security Question 3</label>
								<select class="form-select" formControlName="SecurityQuestionId3" required
									(change)="detectQuestionSelected(3)">
									<option value="">Select Question</option>
									<option *ngFor="let item of securityQuestions" [value]="item?.id">{{ item?.question }}</option>
								</select>
							</div>
						</div>
						<div class="col-12">
							<div class="mb-3">
								<label class="form-label fw-semibold">Answer 3</label>
								<input type="text" class="form-control" formControlName="Answer3" required
									placeholder="Enter your Answer">
							</div>
						</div>
	
	
						<div class="col-12 my-7">
							<div class="col-12 border border-1 border-top"></div>
						</div>
	
						<div class="col-12">
							<div class="mb-3">
								<label class="form-label fw-semibold">Username</label>
								<input type="text" class="form-control" formControlName="UserName" readonly required
									placeholder="Enter your Username">
							</div>
						</div>
						<!-- <div class="col-12 mt-3">
						<div class="mb-3">
							 <label class="form-label fw-semibold">Customer ID</label>
							 <div class="input-group">
								 <input type="text" class="form-control" formControlName="CustomerId" readonly required placeholder="Enter your Customer ID">
							 </div>
						</div>
				  </div> -->
						<div class="col-12 mt-3">
							<div class="mb-3">
								<label class="form-label fw-semibold">Password</label>
								<div class="input-group">
									<input type="password" class="form-control" formControlName="Password" required
										placeholder="Enter your password">
									<span class="input-group-text pointer" id="toggle-password"><i class="ri-eye-line"></i></span>
								</div>
							</div>
						</div>
						<div class="col-lg-12 mt-3">
							<div class="text-end">
	
								<!-- regular utton -->
								<button type="submit" *ngIf="!operationProcessStatus" class="btn btn-main figma-dark w-100 mb-5">
									Continue
								</button>
	
								<!-- loader button -->
								<button type="button" *ngIf="operationProcessStatus" [disabled]="operationProcessStatus"
									class="btn btn-main figma-dark w-100 mb-5">
									<span *ngIf="operationProcessStatus" class="spinner-border spinner-border-sm mr-1" role="status"
										aria-hidden="true"></span> <span *ngIf="operationProcessStatus"> please wait...</span>
								</button>
	
							</div>
						</div>
					</div>
				</form>
			</div>
	
			<div class="footer-links p-2 mt-5">
				<a href="#">Company</a>
				<a href="#">About Us</a>
				<a href="#">Team</a>
				<a href="#">Products</a>
				<a href="#">Blogs</a>
			</div>
	
		</div>
		<img src="../../../../assets/images/svg/Arc.svg" class="arc" />
	
	</div>
</div>
