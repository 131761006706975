<div class="row">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">Manage Users</h4>
                <div class="flex-shrink-0">
                    <div class="btn-group">
                        <button class="btn btn-main" type="button"
                            *ngIf="config.currentUserHasPermissionByKey('CreateCorporateUserProfile')"
                            (click)="initiateCreateOrUpdateCorporateUser(true)">
                            <i class="bx bx-plus fs-5 mr-2 position-relative" style="top: 3px;"></i> Create New User
                        </button>
                        <button class="btn btn-light ml-2" type="button"
                            *ngIf="config.currentUserHasPermissionByKey('ViewCorporateRole')"
                            (click)="fetchBankUserRolesIntoList()">
                            <i class="mdi mdi-file-tree fs-5 mr-2 position-relative" style="top: 3px;"></i> Roles &
                            Permissions
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div *ngIf="config.currentUserHasPermissionByKey('ViewCorporateUserProfile')"
                    class="custom-datatable manage-workflow-table w-100">
                    <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                        <li class="nav-item fs-5">
                            <a class="nav-link active" data-bs-toggle="tab" href="#allusers" role="tab"
                                aria-selected="false">
                                All
                            </a>
                        </li>
                        <li class="nav-item fs-5">
                            <a class="nav-link" data-bs-toggle="tab" href="#pendingusers" role="tab"
                                aria-selected="false">
                                Pending
                            </a>
                        </li>
                    </ul>
                    <hr />
                    <div class="tab-content">
                        <div class="tab-pane active" id="allusers" role="tabpanel">
                            <table id="manage-users" data-control-id='manage-users'
                                class="table datatable table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Name</th>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Role</th>
                                        <th>Last Activity</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of corporateCustomerUsers; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>
                                            <div>{{ item?.firstName }} {{ item?.middleName ? item?.middleName?.split('')[0]
                                                + '.' : '' }} {{ item?.lastName }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item?.username }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item?.email }}</div>
                                        </td>
                                        <td>{{ item?.phone1 || '-' }}</td>
                                        <td>{{ item?.corporateRoleName || '-' }}</td>
                                        <td>{{ (item?.lastActivity | date) || '-' }}</td>
                                        <td>
                                            <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                                *ngIf="item?.status == 0 && item?.noOfWrongAttempts != 3"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Pending approval"></i>
                                            <i class="mdi mdi-lock-remove fs-4 text-danger"
                                                *ngIf="item?.status == 0 && item?.noOfWrongAttempts == 3"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Account deactivated due to wrong password entry"></i>
                                            <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.status == 1"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Approved"></i>
                                            <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.status == 3"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                                            <i class="mdi mdi-alert-remove fs-4 text-danger" *ngIf="item?.status < 0"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Deactivated"></i>
                                            <i class="mdi mdi-pencil-plus fs-4 text-primary pointer"
                                                *ngIf="item?.status == 2" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Being updated"></i>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                                <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                    id="defaultDropdown" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true" aria-expanded="false">
                                                    <i class="ri-menu-2-line"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <li><a class="dropdown-item pointer"
                                                            (click)="initiateCreateOrUpdateCorporateUser(false, item)">Edit</a>
                                                    </li>
                                                    <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status >= 0 && config.currentUserHasPermissionByKey('UpdateCorporateUserRole')" (click)="initiateCreateOrUpdateRole(item)">Update User Role</a></li> -->
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status == 1"
                                                            (click)="initiateCorporateUserProfileResetPassword(item)">Reset
                                                            Password</a></li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status < 0 && config.currentUserHasPermissionByKey('RequestCorporateUserProfileApproval')"
                                                            (click)="initiateCorporateUserProfileReactivate(item)">Request
                                                            Reactivation</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status == 1 && config.currentUserHasPermissionByKey('DeactivateCorporateUserProfile')"
                                                            (click)="initiateCorporateUserProfileDeactivate(item)">Deactivate</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status == 0 && item?.noOfWrongAttempts == 3 && config.currentUserHasPermissionByKey('EnableLoggedOutCorporateUser')"
                                                            (click)="initiateProfileEnableLoggedOutProfile(item)">Log
                                                            User Out</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-pane" id="pendingusers" role="tabpanel">
                            <table id="manage-users-pending" data-control-id='manage-users-pending'
                                class="table datatable table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Name</th>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Initiator</th>
                                        <th>Action</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of corporateCustomerUsersPending; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>
                                            <div>{{ item?.firstName }} {{ item?.middleName ? item?.middleName?.split('')[0]
                                                + '.' : '' }} {{ item?.lastName }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item?.username }}</div>
                                        </td>
                                        <td>
                                            <div>{{ item?.email }}</div>
                                        </td>
                                        <td>{{ item?.phone1 || '-' }}</td>
                                        <td>{{ item?.initiatorUsername || '-' }}</td>
                                        <td>{{ item?.action|| '-' }}</td>
                                        <td>
                                            <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                                *ngIf="item?.status == 0 && item?.noOfWrongAttempts != 3"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Pending approval"></i>
                                            <i class="mdi mdi-lock-remove fs-4 text-danger"
                                                *ngIf="item?.status == 0 && item?.noOfWrongAttempts == 3"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Account deactivated due to wrong password entry"></i>
                                            <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.status == 1"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Approved"></i>
                                            <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.status == 3"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                                            <i class="mdi mdi-alert-remove fs-4 text-danger" *ngIf="item?.status < 0"
                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                title="Deactivated"></i>
                                            <i class="mdi mdi-pencil-plus fs-4 text-primary pointer"
                                                *ngIf="item?.status == 2" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Being updated"></i>
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                                <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                    id="defaultDropdown" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true" aria-expanded="false">
                                                    <i class="ri-menu-2-line"></i>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                    <!-- <li><a class="dropdown-item pointer" (click)="initiateCreateOrUpdateCorporateUser(false, item)">Edit</a></li> -->
                                                    <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status >= 0 && config.currentUserHasPermissionByKey('UpdateCorporateUserRole')" (click)="initiateUpdateAccountLimit(item)">Update User Role</a></li> -->
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status == 2 && config.currentUserHasPermissionByKey('RequestCorporateUserProfileApproval')"
                                                            (click)="initiateCorporateUserProfileApprovalRequest(item)">Request
                                                            Approval</a></li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="((item?.status == 0 && (!item?.previousStatus || item?.previousStatus == 1 )) && item?.initiatorId == userId) && config.currentUserHasPermissionByKey('ApproveCorporateUserProfile')"
                                                            (click)="initiateCorporateUserProfileApprove(item)">Approve</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="(item?.status == 0 && (!item?.previousStatus || item?.previousStatus == 1 ) && item?.initiatorId == userId) && config.currentUserHasPermissionByKey('DeclineCorporateUserProfile')"
                                                            (click)="initiateCorporateUserProfileDecline(item)">Decline</a>
                                                    </li>
                                                    <li><a class="dropdown-item pointer" *ngIf="item?.status == 1"
                                                            (click)="initiateCorporateUserProfileResetPassword(item)">Reset
                                                            Password</a></li>
                                                    <li><a class="dropdown-item pointer"
                                                            *ngIf="item?.status == 0 && item?.noOfWrongAttempts == 3 && config.currentUserHasPermissionByKey('EnableLoggedOutCorporateUser')"
                                                            (click)="initiateProfileEnableLoggedOutProfile(item)">Log
                                                            User Out</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<!-- Warning Alert -->
<div class="d-flex" *ngIf="!config.currentUserHasPermissionByKey('ViewCorporateUserProfile')"
    [innerHtml]="config.currentUserUnauthorizationContentUi()"></div>
<!-- create user -->
<div class="modal fade" id="modal-new-corporate-user" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    {{ profileOperationStatusCreate ? 'Create' : 'Update' }} User
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formCreateCorporateUser" class="w-100 needs-validation"
                        (ngSubmit)="createOrUpdateCorporateUser($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect" formControlName="title" required
                                        aria-label="Floating label select example">
                                        <option value="">Select Title</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Prof">Prof</option>
                                        <option value="Engr">Engr</option>
                                        <option value="Honorable">Honorable</option>
                                    </select>
                                    <!-- [hidden]="item?.status != 1" -->
                                    <label>User Title</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="firstName" required
                                        placeholder="Enter First Name">
                                    <label>First Name</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="lastName" required
                                        placeholder="Enter Last Name">
                                    <label>Last Name</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="middleName"
                                        placeholder="Enter Middle Name">
                                    <label>Middle Name</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="username" required
                                        placeholder="Enter Username">
                                    <label>Username</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="email" required
                                        placeholder="Enter Email Address">
                                    <label>Email Address</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="phone" required
                                        placeholder="Enter Phone Number">
                                    <label>Phone Number</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2" *ngIf="profileOperationStatusCreate">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect" formControlName="corporateRoleId"
                                        required aria-label="Floating label select example">
                                        <option value="">Select Role</option>
                                        <option *ngFor="let item of corporateUserRoles" [value]="item?.id">{{
                                            item?.roleName }}</option>
                                    </select>
                                    <!-- [hidden]="item?.status != 1" -->
                                    <label>User Role</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="approvalLimit"
                                        placeholder="Enter Approval Limit">
                                    <label>Approval Limit</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                    <button type="reset" data-network-action="hide"
                                        class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- decline reason -->
<div class="modal fade" id="modal-corporate-user-decline-reason" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formDeclineCorporateUserProfileReason">
                    <div class="fw-semibold fs-3">Decline Corporate User</div>
                    <div class="fs-6 mt-4">You are about to decline this profile, Please provide your reason.</div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="reason"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Enter your reason for declining this profile </label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="submit" class="btn btn-main btn-lg w-50"
                                (click)="declineCorporateUserProfile()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- deactivate reason -->
<div class="modal fade" id="modal-corporate-user-deactivate-reason" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <form [formGroup]="formDeactivateCorporateUserProfileReason">
                    <div class="fw-semibold fs-3">Request Deactivattion</div>
                    <div class="fs-6 mt-4">You are about to request deactivation of this profile, Please provide your
                        reason.
                    </div>
                    <div class="fs-5 mt-4">
                        <div class="form-floating" style="text-align: left;">
                            <textarea class="form-control h-auto" rows="5" formControlName="reason"
                                style="padding-top: 50px;"></textarea>
                            <label for="cityfloatingInput">Enter your reason for deacivating this profile</label>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <div class="text-center">
                            <button type="button" data-bs-dismiss="modal"
                                class="btn btn-light btn-lg mx-2">Cancel</button>
                            <button type="submit" class="btn btn-main btn-lg w-50"
                                (click)="deactivateCorporateUserProfile()">Continue</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- CORPORATE USER ROLES -->
<!-- corporate user roles -->
<div class="modal fade" id="modal-corporate-user-roles" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="flex-grow-1">
                    <h4 class="modal-title" id="standard-modalLabel" style="margin: auto;">User Roles</h4>
                </div>
                <div class="flex-shrink-0 align-self-start">
                    <!-- <button type="button" class="btn btn-main" *ngIf="config.currentUserHasPermissionByKey('CreateCorporateRole')" (click)="initiateCreateOrUpdateRole(true)">
                        <i class="bx bx-plus fs-5 mr-2 position-relative" style="top: 3px;"></i> New Role
                    </button> -->
                    <button type="button" class="btn btn-light ml-2" data-bs-dismiss="modal">
                        Cancel
                    </button>
                </div>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">

                    <div class="custom-datatable overflow-auto manage-workflow-table w-100">

                        <table id="corporate-user-roles" data-control-id='corporate-user-roles'
                            class="table datatable table-striped table-bordered w-100" style="">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of userProfileRoles; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>
                                        <div>{{ item?.roleName }}</div>
                                    </td>
                                    <td>
                                        <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.status == 1"></i>
                                        <i class="mdi mdi-alert-remove fs-4 text-danger" *ngIf="item?.status < 0"></i>
                                        <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                            *ngIf="item?.status == 0 || item?.status == null"></i>
                                        <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.status == 2"></i>
                                    </td>
                                    <td>
                                        <div class="btn-group" *ngIf="item?.corporateCustomerId">
                                            <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                                id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true"
                                                aria-expanded="false">
                                                <i class="ri-menu-2-line"></i>
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                                <li><a class="dropdown-item pointer"
                                                        *ngIf="config.currentUserHasPermissionByKey('ViewCorporateRolePermissions')"
                                                        (click)="fetchAllPermissionsForRole(item)">Manage
                                                        Permissions</a></li>
                                                <li><a class="dropdown-item pointer" data-bs-dismiss="modal"
                                                        *ngIf="config.currentUserHasPermissionByKey('UpdateCorporateRole')"
                                                        (click)="initiateCreateOrUpdateRole(false, item)">Edit</a>
                                                </li>
                                                <!-- <li><a class="dropdown-item pointer" *ngIf="item?.status == 2 && config.currentUserHasPermissionByKey('RequestCorporateRoleApproval')" (click)="initiateRoleApprovalRequest(item)">Request Approval</a></li>
                                                <li><a class="dropdown-item pointer" *ngIf="(item?.status == 0 || item?.status == null) && config.currentUserHasPermissionByKey('7bf7dd5a-373e-4fd4-b352-fce61c6c9daf')" (click)="initiateRoleApprove(item)">Approve</a></li>
                                                <li><a class="dropdown-item pointer" *ngIf="(item?.status == 0 || item?.status == null) && config.currentUserHasPermissionByKey('DeclineCorporateRole')" (click)="initiateRoleDecline(item)" data-bs-dismiss="modal">Decline</a></li>
                                                <li><a class="dropdown-item pointer" *ngIf="item?.status < 0 && config.currentUserHasPermissionByKey('ActivateCorporateRole')" (click)="initiateRoleReactivate(item)">Reactivate</a></li>
                                                <li><a class="dropdown-item pointer" *ngIf="item?.status == 1" (click)="initiateRoleDeactivate(item)" data-bs-dismiss="modal">Deactivate</a></li> -->
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- user role permissions -->
<div class="modal fade" id="modal-corporate-user-role-permissions" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ currentlySelectedUserRole?.roleName }} Permissions
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form action="#" class="w-100 mt-5">
                        <div class="row g-3">
                            <div class="col-lg-4 mb-2" *ngFor="let item of userProfileRolePermissions; let i = index">
                                <div class="form-check form-check-success mb-3" data-bs-placement="top"
                                    [title]="item?.description || ''">
                                    <input class="form-check-input base-green" type="checkbox"
                                        [checked]="permittedAccessesByPermissionId.indexOf(item?.id) != -1"
                                        (change)="detectChangedPermission(item, $event.target)"
                                        id="formCheckPermissions{{i + 1}}">
                                    <label class="form-check-label" for="formCheckPermissions{{i + 1}}">
                                        {{ item?.name }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5"
                                *ngIf="config.currentUserHasPermissionByKey('AddCorporateRolePermissions')">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50"
                                        (click)="saveAllSelectedPermissionsForRole()">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    </div>
</div>

<!-- create user role -->
<div class="modal fade" id="modal-new-user-role" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">
                    {{ roleOperationStatusCreate ? 'New' : 'Update' }} Role
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form [formGroup]="formCreateNewRole" class="w-100 needs-validation"
                        (ngSubmit)="createOrUpdateUserRole($event.target)">
                        <div class="row g-3">
                            <div class="col-lg-12 mb-2">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="roleName" required
                                        placeholder="Enter Role Name">
                                    <label>Role Name</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" data-network-action="hide" class="btn btn-light btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                    <button type="reset" data-network-action="hide"
                                        class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50">Save & Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- account permissions -->
<div class="modal fade" id="modal-user-account-permissions" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Account Permissions
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5">

                <div class="d-flex">
                    <form action="#" class="w-100">
                        <div class="row g-3">
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example">
                                        <option value="">Select Profile Type</option>
                                    </select>
                                    <label for="floatingSelect">Profile Type</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example">
                                        <option value="">Select User Status</option>
                                    </select>
                                    <label for="floatingSelect">User Status</label>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-2">
                                <label for="exampleInputtime" class="form-label fw-semibold">Enable 2FA</label>
                                <div class="form-check form-switch form-switch-lg" dir="ltr">
                                    <input type="checkbox" checked class="form-check-input base-green"
                                        id="customSwitchsizelg">
                                </div>
                                <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Activating
                                    this will require the user complete all account related activities on the
                                    platform with Two Factor Authentication or token code.</div>
                            </div>
                            <div class="col-lg-12 mb-2">
                                <label for="exampleInputtime" class="form-label fw-semibold">User Access</label>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck8" checked>
                                    <label class="form-check-label" for="formCheck8">
                                        Can view account balance
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck9" checked>
                                    <label class="form-check-label" for="formCheck9">
                                        Can make Bulk Payment
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck10" checked>
                                    <label class="form-check-label" for="formCheck10">
                                        Can initiate Payment
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck11" checked>
                                    <label class="form-check-label" for="formCheck11">
                                        Can initiate Salary Payment
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck12" checked>
                                    <label class="form-check-label" for="formCheck12">
                                        Can Approve Transaction
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck13" checked>
                                    <label class="form-check-label" for="formCheck13">
                                        Transaction Type
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck14" checked>
                                    <label class="form-check-label" for="formCheck14">
                                        Can view Transaction
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck15" checked>
                                    <label class="form-check-label" for="formCheck15">
                                        Restrict user from generating bank statement
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck16" checked>
                                    <label class="form-check-label" for="formCheck16">
                                        Allow for multi-currency transactions
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck17" checked>
                                    <label class="form-check-label" for="formCheck18">
                                        Consumate transaction from international
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-check form-check-success mb-3">
                                    <input class="form-check-input base-green" type="checkbox" id="formCheck18" checked>
                                    <label class="form-check-label" for="formCheck18">
                                        Log Transaction Date & Time
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="text-center">
                                    <button type="button" class="btn btn-outline-secondary btn-lg mx-5"
                                        data-bs-dismiss="modal">Back</button>
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-primary btn-lg w-50">Save &
                                        Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>