<div>
   <!-- Nav tabs -->
   <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3 mx-5" role="tablist">
      <li class="nav-item">
         <a class="nav-link active" data-bs-toggle="tab" href="#main" role="tab" aria-selected="false">
            ON LENDING
         </a>
      </li>
      <li class="nav-item">
         <a class="nav-link" data-bs-toggle="tab" href="#reports" role="tab" aria-selected="false">
            ON LENDING REPORT
         </a>
      </li>
   </ul>
   <!-- Tab panes -->
   <div class="tab-content">
      <div class="tab-pane active" id="main" role="tabpanel">
         <div class="">
            <div class="onlending-actions">
               <button class="btn btn-light" (click)="fetchSampleTemplate()">Download Sample File</button>
               <button class="btn btn-main" (click)="onSelectInitiated()">Upload Beneficiaries Record</button>
               <!-- <button class="btn btn-light">Download KYC Form</button>
               <button class="btn btn-main">Download Lien Form</button> -->
            </div>

            <div class="mt-10">
               <div class="custom-datatable overflow-auto lending-batches-table w-100">

                  <table id="lending-batches" data-control-id='lending-batches' data-control-scope='lending-batches'
                     class="table datatable table-striped table-bordered" style="width:100%">
                     <thead>
                        <tr>
                           <th>S/N</th>
                           <th>Date</th>
                           <th>Total Amount</th>
                           <th>Total</th>
                           <th>Validity</th>
                           <th>Initiated</th>
                           <th>Status</th>
                           <th>Actions</th>

                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let item of allBatches; let i = index">
                           <td>{{ i + 1 }}</td>
                           <td>{{item?.dateInitiated | date}}</td>
                           <td>{{item?.totalAmount | number}}</td>
                           <td>{{item?.numberOfCredit | number}}</td>
                           <td>{{item?.validCount}} / {{item?.numberOfCredit | number}} </td>
                           <td>{{item?.initiatorUserName}}</td>
                           <td>
                              <i class="mdi mdi-alert-circle-outline fs-4 text-warning" *ngIf="item?.status == 0"
                                 data-bs-toggle="tooltip" data-bs-placement="top" title="Pending"></i>
                              <i class="mdi mdi-lock-remove fs-4 text-danger" *ngIf="item?.status == 2"
                                 data-bs-toggle="tooltip" data-bs-placement="top" title="Failed"></i>
                              <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.status == 1"
                                 data-bs-toggle="tooltip" data-bs-placement="top" title="Successful"></i>
                              <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.status == 3"
                                 data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                           </td>
                           <td>
                              <div class="btn-group">
                                 <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                    id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true"
                                    aria-expanded="false">
                                    <i class="ri-menu-2-line"></i>
                                 </button>
                                 <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                    <li><a class="dropdown-item pointer" data-bs-toggle="modal"
                                          (click)="openValidBatch(item)">Initiate Valid Batch</a> </li>
                                    <li><a class="dropdown-item pointer" (click)="downloadAllBatch(item)">Download All
                                          Batch</a> </li>
                                    <li><a class="dropdown-item pointer" (click)="downloadInvalidBatch(item)">Download
                                          Invalid Batch</a> </li>
                                    <!-- <li><a class="dropdown-item pointer">Delete Batch</a></li> -->
                                 </ul>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>

               </div>
            </div>
         </div>
      </div>
      <div class="tab-pane" id="reports" role="tabpanel">
         <!-- Nav tabs -->
         <!-- <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3 mx-5" role="tablist">
            <li class="nav-item">
               <a class="nav-link active" data-bs-toggle="tab" href="#report" role="tab" aria-selected="false">
                  Report Table
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" data-bs-toggle="tab" href="#extension" role="tab" aria-selected="false">
                  Extension Requests
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" data-bs-toggle="tab" href="#liquidation" role="tab" aria-selected="false">
                  (Pre) Liquidation Requests
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" data-bs-toggle="tab" href="#history" role="tab" aria-selected="false">
                  Transaction History
               </a>
            </li>

         </ul> -->
         <!-- Tab Panes -->
         <!-- <div class="tab-content"> -->
         <div class="tab-pane active" id="report" role="tabpanel">
            <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
               <table class="table datatable table-striped table-bordered w-100" id="report-table"
                  data-control-id="report-table">
                  <thead class="table-light">
                     <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Source Account</th>
                        <th scope="col">Total Payments</th>
                        <th scope="col">Initiated By</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Date</th>
                        <th>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of processedBatches; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item?.amount }}</td>
                        <td>{{ item?.sourceAccountNumber }}</td>
                        <td>{{ item?.totalPayment }}</td>
                        <td>{{ item?.initiatedBy }}</td>
                        <td>{{ item?.paymentType }}</td>
                        <td>{{ item?.date | date }}</td>
                        <td>
                           <div class="btn-group">
                              <button class="btn btn-light btn-icon dropdown-toggle" type="button" id="defaultDropdown"
                                 data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                 <i class="ri-menu-2-line"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                 <li><a class="dropdown-item pointer" data-bs-toggle="modal"
                                       (click)="openProcessedBatch(item)">View Beneficiaries</a> </li>
                                 <li><a class="dropdown-item pointer" data-bs-toggle="modal"
                                       (click)="openExtensionRequests(item)">View Extension Request</a> </li>
                                 <li><a class="dropdown-item pointer" data-bs-toggle="modal"
                                       (click)="openPreLiquidationRequests(item)">View (Pre)liquidation Request</a>
                                 </li>

                              </ul>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <div *ngIf="isLoadingProcessed" class="text-center">
                  <h1>Loading....</h1>
               </div>
            </div>
         </div>
         <!-- <div class="tab-pane" id="extension" role="tabpanel">

            </div>
            <div class="tab-pane" id="liquidation" role="tabpanel">

            </div>
         </div> -->
      </div>
   </div>
</div>

<!-- upload beneficiary -->
<div class="modal fade" id="modal-upload-ben" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title">
               UPLOAD PAYMENT
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <form [formGroup]="formUploadBeneficiary" class="p-5 w-100 needs-validation">

               <div class="col-lg-12 mb-2">
                  <div class="form-floating">
                     <select class="form-select" formControlName="SourceAccountNumber" required>
                        <option value="">Select Account</option>

                        <option *ngFor="let item of merchantAccountList" [value]="item?.accountNumber">{{
                           item?.accountNumber }}
                           ({{
                           item?.customerName }} | N{{ item?.availableBalance | number }})
                        </option>
                     </select>
                     <label>Select Merchant Account</label>
                  </div>
               </div>
               <div class="col-lg-12 mb-2">
                  <div class="form-floating">
                     <select class="form-select" formControlName="OperatingAccountNumber" required>
                        <option value="">Select Account</option>
                        <option *ngFor="let item of accountsList" [value]="item?.accountNumber">{{ item?.accountNumber }}
                           ({{
                           item?.customerName }} | N{{ item?.availableBalance | number }})
                        </option>
                     </select>
                     <label>Select Operating Account</label>
                  </div>
               </div>
               <div class="col-lg-12 mb-2" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                  <div class="form-floating">
                     <select class="form-select" formControlName="WorkflowId" required>
                        <option value="">Select Workflow</option>
                        <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                        </option>
                     </select>
                     <label for="floatingSelect">Workflow</label>
                  </div>
               </div>


               <div class="col-lg-12 mb-3">
                  <div class="form-floating">
                     <textarea class="form-control h-auto" formControlName="Narration" required rows="5"></textarea>
                     <label for="cityfloatingInput">Narration</label>
                  </div>
               </div>

               <div>
                  <!-- <label for="file">Upload your <b>beneficiaries record</b> (Excel Format)</label> -->
                  <div class="w-full bg-light border-secondary p-2 py-4">
                     <input name="file" id="file" type="file" accept=".xlsx, .xls, .csv" multiple
                        (change)="onFileSelected($event)">
                  </div>
               </div>
            </form>


         </div>
         <div class="modal-footer">
            <div class="text-center">
               <button type="submit" class="btn btn-main btn-lg w-100" (click)="uploadBeneficiaries()">Submit</button>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- initiate batch  -->
<div class="modal fade" id="modal-initiate-batch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabel">
               VALID PAYMENTS
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">
            <!-- <div class="d-flex mb-5">
            <div class="m-4 flex-grow-1">
               <div class="text-muted">No. of Payment</div>
               <div class="fw-semibold fs-5">0</div>
            </div>
            <div class="m-4 flex-grow-1">
               <div>
                  <div class="text-muted">Total Amount</div>
                  <div class="fw-semibold fs-5">0</div>
               </div>
            </div>
            <div class="m-4 flex-grow-1">
               <div>
                  <div class="text-muted">Total Charge</div>
                  <div class="fw-semibold fs-5">0</div>
               </div>
            </div>
         </div> -->

            <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
               <table class="table datatable table-striped table-bordered w-100" id="lending-batch"
                  data-control-id="lending-batch">
                  <thead class="table-light">
                     <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">Name</th>
                        <th scope="col">Account</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Narration</th>
                        <th scope="col">Repayment Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of validBatch; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item?.beneficiaryName }} </td>
                        <td>{{ item?.beneficiaryAccountNumber }}</td>
                        <td>{{ item?.amount | currency : 'NGN'}}</td>
                        <td>{{item?.narration}}</td>
                        <td>{{ item?.repaymentDate | date }}</td>
                     </tr>
                  </tbody>
               </table>
            </div>


            <div class="col-lg-12 mt-5">
               <div class="text-center">
                  <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn btn-main btn-lg w-25 mx-2" data-bs-toggle="modal"
                     data-bs-target="#modal-authentication-token">Initiate</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- view processed beneficiaried -->
<div class="modal fade" id="modal-processed-ben" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabel">
               Beneficiaries
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">
            <div class="card-body">
               <!-- Nav tabs -->
               <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                  <li class="nav-item fs-5">
                     <a class="nav-link active" data-bs-toggle="tab" href="#all" role="tab" aria-selected="false">
                        All
                     </a>
                  </li>
                  <li class="nav-item fs-5">
                     <a class="nav-link" data-bs-toggle="tab" href="#pending" role="tab" aria-selected="false">
                        Pending
                     </a>
                  </li>

               </ul>
               <div class="tab-content">
                  <div class="tab-pane active" id="all" role="tabpanel">
                     <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                        <table class="table datatable table-striped table-bordered w-100" id="processed-ben"
                           data-control-id="processed-ben">
                           <thead class="table-light">
                              <tr>
                                 <th scope="col"><input type="checkbox" name="" id=""
                                       (change)="toggleAllBeneficiary($event.target)"></th>
                                 <th scope="col">S/N</th>
                                 <th scope="col">Name</th>
                                 <th scope="col">Account</th>
                                 <th scope="col">Amount</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Repayment Date</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let item of processedBatchBen; let i = index">
                                 <td><input type="checkbox" [checked]="findBeneficiary(item?.id)"
                                       (change)="toggleFromBeneficiaryList($event.target,item)"></td>
                                 <td>{{ i + 1 }}</td>
                                 <td>{{ item?.beneficiaryName }} </td>
                                 <td>{{ item?.sourceAccountNumber }}</td>
                                 <td>{{ item?.amount | currency : 'NGN'}}</td>
                                 <td>
                                    {{item?.contractStatus == 0 ? 'Pending' : item?.contractStatus == 1 ? 'Processed' :
                                    item?.contractStatus == 2 ? 'Active' : item?.contractStatus == 3 ? 'Extended' :
                                    item?.contractStatus == 4 ? 'Partial Liquidation': item?.contractStatus == 5 ?
                                    'Liquidated' :item?.contractStatus == 6 ? 'Failed': item?.contractStatus == 7 ?
                                    'Declined' : ''}}
                                 </td>
                                 <td>{{ item?.repaymentDate | date }}</td>
                                 <td>
                                    <div class="btn-group">
                                       <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                          id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true"
                                          aria-expanded="false">
                                          <i class="ri-menu-2-line"></i>
                                       </button>
                                       <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                          <li
                                             *ngIf="(item?.contractStatus == 2 || item?.contractStatus == 3) && (config.currentUserHasPermissionByKey('CanRequestForApprovalOnlendingRepaymentDateExtension') || !config.checkIfNotSingleSignatory(authorizationType))">
                                             <a class="dropdown-item pointer" data-bs-toggle="modal"
                                                (click)="openExtensionRequest(item)">Date Extension Request</a>
                                          </li>
                                          <li
                                             *ngIf="(item?.contractStatus == 2 || item?.contractStatus == 3) && (config.currentUserHasPermissionByKey('CanInitiateOnlendingLiquidation') || !config.checkIfNotSingleSignatory(authorizationType))">
                                             <a class="dropdown-item pointer" data-bs-toggle="modal"
                                                (click)="openLiquidationType(item)">Pre-Liquidation Request</a>
                                          </li>
                                       </ul>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div class="col-lg-12 mt-5">
                        <div class="text-center">
                           <button type="button" class="btn btn-light btn-lg mx-2"
                              data-bs-dismiss="modal">Cancel</button>
                           <button type="button" class="btn btn-main btn-lg w-50"
                              *ngIf="config.currentUserHasPermissionByKey('CanInitiateOnlendingPaymentDisbursement') || !config.checkIfNotSingleSignatory(authorizationType)"
                              (click)="processDisbursement()">Initiate Disbursement </button>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane" id="pending" role="tabpanel">
                     <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                        <table class="table datatable table-striped table-bordered w-100" id="pen-processed-ben"
                           data-control-id="pen-processed-ben">
                           <thead class="table-light">
                              <tr>
                                 <th scope="col"><input type="checkbox" name="" id=""
                                       (change)="toggleAllBeneficiary($event.target)"></th>
                                 <th scope="col">S/N</th>
                                 <th scope="col">Name</th>
                                 <th scope="col">Account</th>
                                 <th scope="col">Amount</th>
                                 <th scope="col">Initiated By</th>
                                 <th scope="col">Action</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Repayment Date</th>
                                 <!-- <th></th> -->
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let item of processedBatchBenPending; let i = index">
                                 <td><input type="checkbox" [checked]="findBeneficiary(item?.id)"
                                       (change)="toggleFromBeneficiaryList($event.target,item)"></td>
                                 <td>{{ i + 1 }}</td>
                                 <td>{{ item?.beneficiaryName }} </td>
                                 <td>{{ item?.beneficiaryAccountNumber }}</td>
                                 <td>{{ item?.amount | currency : 'NGN'}}</td>

                                 <td>{{ item?.initiatedBy }}</td>
                                 <td>{{ item?.action }}</td>
                                 <td>
                                    {{item?.contractStatus == 0 ? 'Pending' : item?.contractStatus == 1 ? 'Processed' :
                                    item?.contractStatus == 2 ? 'Active' : item?.contractStatus == 3 ? 'Extended' :
                                    item?.contractStatus == 4 ? 'Partial Liquidation': item?.contractStatus == 5 ?
                                    'Liquidated' :item?.contractStatus == 6 ? 'Failed': item?.contractStatus == 7 ?
                                    'Declined' : ''}}
                                 </td>
                                 <td>{{ item?.repaymentDate | date }}</td>

                              </tr>
                           </tbody>
                        </table>
                        <div class="col-lg-12 mt-5">
                           <div class="text-center">
                              <button type="button" class="btn btn-light btn-lg mx-2" data-bs-toggle="modal"
                                 *ngIf="config.currentUserHasPermissionByKey('CanApproveOnlendingPaymentDisbursement')"
                                 data-bs-target="#modal-disbursement-decline-reason">Decline</button>
                              <button type="button" class="btn btn-main btn-lg w-50" data-bs-toggle="modal"
                                 *ngIf="config.currentUserHasPermissionByKey('CanApproveOnlendingPaymentDisbursement')"
                                 data-bs-target="#modal-authentication-token-approve-d">Approve Disbursement </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="modal-ext-requests" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabenl">
               Extension Requests
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">
            <div class="card-body">
               <!-- Nav tabs -->
               <ul class="nav nav-pills arrow-navtabs nav-warning mb-4 border-bottom-0" role="tablist">
                  <li class="nav-item fs-5">
                     <a class="nav-link active" data-bs-toggle="tab" href="#all-ext" role="tab" aria-selected="false">
                        All
                     </a>
                  </li>
                  <li class="nav-item fs-5">
                     <a class="nav-link" data-bs-toggle="tab" href="#pending-ext" role="tab" aria-selected="false">
                        Pending
                     </a>
                  </li>

               </ul>
               <hr>
               <div class="tab-content">
                  <div class="tab-pane active" id="all-ext" role="tabpanel">
                     <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                        <table class="table datatable table-striped table-bordered w-100" id="extension-table"
                           data-control-id="extension-table">
                           <thead class="table-light">
                              <tr>
                                 <th scope="col">S/N</th>
                                 <th scope="col">Name</th>
                                 <th scope="col">Account</th>
                                 <th scope="col">Amount</th>
                                 <th scope="col">Status</th>
                                 <th scope="col">Repayment Date</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let item of extensionRequests; let i = index">
                                 <td>{{ i + 1 }}</td>
                                 <td>{{ item?.beneficiaryName }} </td>
                                 <td>{{ item?.beneficiaryAccountNumber }}</td>
                                 <td>{{ item?.amount | currency : 'NGN'}}</td>
                                 <td>
                                    <i class="mdi mdi-alert-circle-outline fs-4 text-warning"
                                       *ngIf="item?.contractStatus == 0" data-bs-toggle="tooltip" data-bs-placement="top"
                                       title="Pending"></i>
                                    <i class="mdi mdi-lock-remove fs-4 text-danger" *ngIf="item?.contractStatus == 2"
                                       data-bs-toggle="tooltip" data-bs-placement="top" title="Failed"></i>
                                    <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.contractStatus == 1"
                                       data-bs-toggle="tooltip" data-bs-placement="top" title="Successful"></i>
                                    <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.contractStatus == 3"
                                       data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                                 </td>
                                 <td>{{ item?.repaymentDate | date }}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div class="tab-pane" id="pending-ext" role="tabpanel">
                     <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
                        <table class="table datatable table-striped table-bordered w-100" id="pending-extension-table"
                           data-control-id="pending-extension-table">
                           <thead class="table-light">
                              <tr>
                                 <th scope="col">S/N</th>
                                 <th scope="col">Name</th>
                                 <th scope="col">Account</th>
                                 <th scope="col">Amount</th>
                                 <th scope="col">Initiated By</th>
                                 <th scope="col">Action</th>
                                 <th scope="col">Repayment Date</th>
                                 <th scope="col">Extension Date</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let item of pendingExtensionRequests; let i = index">
                                 <td>{{ i + 1 }}</td>
                                 <td>{{ item?.beneficiaryName }} </td>
                                 <td>{{ item?.beneficiaryAccountNumber }}</td>
                                 <td>{{ item?.amount | currency : 'NGN'}}</td>

                                 <td>{{ item?.initiatedBy }}</td>
                                 <td>{{ item?.action }}</td>

                                 <td>{{ item?.repaymentDate | date }}</td>
                                 <td>{{ item?.extensionDate | date }}</td>
                                 <td>
                                    <div class="btn-group">
                                       <button class="btn btn-light btn-icon dropdown-toggle" type="button"
                                          id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true"
                                          aria-expanded="false">
                                          <i class="ri-menu-2-line"></i>
                                       </button>
                                       <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
                                          <li>
                                             <a class="dropdown-item pointer" data-bs-toggle="modal"
                                                data-bs-target="#modal-authentication-token-approve-e"
                                                *ngIf="config.currentUserHasPermissionByKey('CanApproveOnlendingRepaymentDateExtension')"
                                                (click)="openExtension(item)">Approve</a>
                                          </li>
                                          <li><a class="dropdown-item pointer" data-bs-toggle="modal"
                                                data-bs-target="#modal-extension-decline-reason"
                                                *ngIf="config.currentUserHasPermissionByKey('CanDeclineOnlendingRepaymentDateExtension')"
                                                (click)="openExtension(item)">Decline</a> </li>
                                       </ul>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>


<div class="modal fade" id="modal-preliq-requests" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="myExtraLargeModalLabel">
               (Pre)Liquidation Requests
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body p-5">
            <div class="custom-datatable overflow-auto transaction-history-records w-100 mt-10">
               <table class="table datatable table-striped table-bordered w-100" id="liquidation-table"
                  data-control-id="liquidation-table">
                  <thead class="table-light">
                     <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">Name</th>
                        <th scope="col">Account</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Repayment Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of  preliquidationrequests; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item?.beneficiaryName }} </td>
                        <td>{{ item?.sourceAccountNumber }}</td>
                        <td>{{ item?.amount | currency : 'NGN'}}</td>
                        <td>
                           <i class="mdi mdi-alert-circle-outline fs-4 text-warning" *ngIf="item?.contractStatus == 0"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="Pending"></i>
                           <i class="mdi mdi-lock-remove fs-4 text-danger" *ngIf="item?.contractStatus == 2"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="Failed"></i>
                           <i class="mdi mdi-check-bold fs-4 text-success" *ngIf="item?.contractStatus == 1"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="Successful"></i>
                           <i class="mdi mdi-alert-octagram fs-4 text-danger" *ngIf="item?.contractStatus == 3"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="Declined"></i>
                        </td>
                        <td>{{ item?.repaymentDate | date }}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/special/enter-otp.jpg" width="400">

            <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation" (ngSubmit)="initiateBatch()">
               <div class="row g-3">
                  <div class="col-lg-12 mb-2">
                     <div class="form-floating">
                        <input type="password" class="form-control" formControlName="otp" required
                           placeholder="Enter your otp">
                        <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                     </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                     <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-main btn-lg w-50" data-bs-toggle="modal">Continue</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- authentication modal DISBURSEMENT -->
<div class="modal fade" id="modal-authentication-token-d" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/special/enter-otp.jpg" width="400">

            <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation" (ngSubmit)="initiateDisbursement()">
               <div class="row g-3">
                  <div class="col-lg-12 mb-2">
                     <div class="form-floating">
                        <input type="password" class="form-control" formControlName="otp" required
                           placeholder="Enter your otp">
                        <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                     </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                     <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-main btn-lg w-50" data-bs-toggle="modal">Continue</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- approve disbursement -->
<div class="modal fade" id="modal-authentication-token-approve-d" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/special/enter-otp.jpg" width="400">

            <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation" (ngSubmit)="approveDisbursement()">
               <div class="row g-3">
                  <div class="col-lg-12 mb-2">
                     <div class="form-floating">
                        <input type="password" class="form-control" formControlName="otp" required
                           placeholder="Enter your otp">
                        <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                     </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                     <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-main btn-lg w-50" data-bs-toggle="modal">Continue</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- decline reason -->
<div class="modal fade" id="modal-disbursement-decline-reason" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <form [formGroup]="formApprovalComment">
               <div class="fw-semibold fs-3">Decline Disbursement</div>
               <div class="fs-6 mt-4">You are about to decline, Please provide your reason.</div>
               <div class="fs-5 mt-4">
                  <div class="form-floating" style="text-align: left;">
                     <textarea class="form-control h-auto" rows="5" formControlName="comment"
                        style="padding-top: 50px;"></textarea>
                     <label for="cityfloatingInput">Enter your reason for declining disbursement </label>
                  </div>
               </div>
               <div class="col-lg-12 mt-5">
                  <div class="text-center">
                     <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                     <button type="submit" class="btn btn-main btn-lg w-50"
                        (click)="declineDisbursement()">Continue</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- approve EXTENSION -->
<div class="modal fade" id="modal-authentication-token-approve-e" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/special/enter-otp.jpg" width="400">

            <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation" (ngSubmit)="approveExtension()">
               <div class="row g-3">
                  <div class="col-lg-12 mb-2">
                     <div class="form-floating">
                        <input type="password" class="form-control" formControlName="otp" required
                           placeholder="Enter your otp">
                        <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                     </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                     <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-main btn-lg w-50" data-bs-toggle="modal">Continue</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- decline reason -->
<div class="modal fade" id="modal-extension-decline-reason" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <form [formGroup]="formApprovalComment">
               <div class="fw-semibold fs-3">Decline Extension</div>
               <div class="fs-6 mt-4">You are about to decline, Please provide your reason.</div>
               <div class="fs-5 mt-4">
                  <div class="form-floating" style="text-align: left;">
                     <textarea class="form-control h-auto" rows="5" formControlName="comment"
                        style="padding-top: 50px;"></textarea>
                     <label for="cityfloatingInput">Enter your reason for declining extension </label>
                  </div>
               </div>
               <div class="col-lg-12 mt-5">
                  <div class="text-center">
                     <button type="button" data-bs-dismiss="modal" class="btn btn-light btn-lg mx-2">Cancel</button>
                     <button type="submit" class="btn btn-main btn-lg w-50"
                        (click)="declineExtension()">Continue</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<!-- preliquidation auth -->
<div class="modal fade" id="modal-authentication-token-l" data-bs-backdrop="static" data-bs-keyboard="false"
   tabindex="-1" aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/special/enter-otp.jpg" width="400">

            <form [formGroup]="formTransferOtp" class="p-5 w-100 needs-validation"
               (ngSubmit)="iniateLiquidationRequest()">
               <div class="row g-3">
                  <div class="col-lg-12 mb-2">
                     <div class="form-floating">
                        <input type="password" class="form-control" formControlName="otp" required
                           placeholder="Enter your otp">
                        <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                     </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                     <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-main btn-lg w-50" data-bs-toggle="modal">Continue</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="modal-extension-date" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title">
               Extension Request
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <form [formGroup]="formExtensionDate" (ngSubmit)="iniateExtensionRequest()"
               class="p-5 w-100 needs-validation">

               <div class="col-lg-12 mb-2">
                  <div class="form-floating">
                     <!-- <select class="form-select" formControlName="date" required>
                     <option value="">Select Date</option>
                     <option *ngFor="let item of extensionDates" [value]="item?.key">{{ item?.name }}
                     </option>
                  </select> -->
                     <input type="date" class="form-control" formControlName="date" required>
                     <label for="numloatingInput" class="text-uppercase">Select Date</label>
                  </div>
               </div>
               <div class="modal-footer">
                  <div class="text-center">
                     <button type="submit" class="btn btn-main btn-lg w-100">Submit</button>
                  </div>
               </div>
            </form>
         </div>

      </div>
   </div>
</div>

<div class="modal fade" id="modal-liquidation-type" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title">
               (Pre)Liquidation Type
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <form [formGroup]="formLiquidationType" class="p-5 w-100 needs-validation">

               <div class="col-lg-12 mb-2">
                  <div class="form-floating">
                     <select class="form-select" formControlName="type" required>
                        <option value="">Select Type</option>
                        <option *ngFor="let item of preliquidationType" [value]="item?.key">{{ item?.name }}
                        </option>
                     </select>
                     <label>Select Type</label>
                  </div>
               </div>
               <div class="col-lg-12 mb-2" *ngIf="formLiquidationType.value.type == 'Partial_Liquidation'">
                  <div class="form-floating">
                     <input type="number" class="form-control" formControlName="amount" required
                        placeholder="Enter Amount" required>
                     <label for="numfloatingInput" class="text-uppercase">Amount</label>
                  </div>
               </div>
               <div class="modal-footer">
                  <div class="text-center">
                     <button type="submit" class="btn btn-main btn-lg w-100" data-bs-toggle="modal"
                        data-bs-target="#modal-authentication-token-l">Submit</button>
                  </div>
               </div>
            </form>
         </div>

      </div>
   </div>
</div>