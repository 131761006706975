import { Component, OnInit } from '@angular/core';
import { DataTablesService, UiService, Rogue, LogService } from '@app/services';
import { config, routes, find, modal, store } from '@app/config';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
// define template
let template = 'cheque-book.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class ChequeBookComponent implements OnInit {
	chequeStatus = false;
	currentUser: any;
	corporateCustomerId: any;
	currentUserId: any;
	chequeBooks: any;
	branches: any
	selectedBranch: any
	formRequestChequeBook: FormGroup
	accounts: any;

	constructor(private table: DataTablesService,
		private http: HttpClient,
		private ui: UiService,
		private logger: LogService,
		private fb: FormBuilder) {
		// get user
		this.currentUser = config.currentUser()
		// get corporate id
		this.corporateCustomerId = store.get('corporateCustomerId')
		// get user id
		this.currentUserId = store.get('userId')
	}
	ngOnInit() {
		this.formRequestChequeBook = this.fb.group({
			accountNumber: [''],
			corporateCustomerId: [this.corporateCustomerId],
			accountType: [''],
			pickUpBranch: [''],
			branchId: [''],
			numberOfLeave: ['']
		})

		this.fetchBranches()
	}
	changeStatus() {
		this.chequeStatus = !this.chequeStatus
	}

	async fetchBranches() {
		// progress
		this.ui.blockUiOn(`Fetching Branches... please wait`)

		// network call
		this.http.get<any>(config.base + routes.branches.getAllBranches, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// pass data
					this.branches = data?.data
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => {
				this.ui.blockUiOff()
				this.fetchAllCorporateAccounts()
			});

	}

	// fetch corporate accounts
	async fetchAllCorporateAccounts() {
		// pass the profile
		let id = this.corporateCustomerId

		// loading
		this.ui.blockUiOn(`Fetching corporate accounts... please wait`)

		let payload = await store.preparePayload({
			id
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.getCorporateAccounts + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// notification
					this.ui.info(`Accounts loaded successfully.`)
					// pass on data
					this.accounts = data?.records
					// auto-select account
					this.formRequestChequeBook.patchValue({
						accountNumber: this.accounts[0].accountNumber
					})
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => {
				this.ui.blockUiOff()
				this.fetchChequeBook()
			});
	}

	async fetchChequeBook() {
		// progress
		this.ui.blockUiOn(`Fetching Cheque Books... please wait`)

		// network call
		this.http.get<any>(config.base + routes.chequeBook.getAllCheques, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// destroy table
					this.table.destroy(`cheque-book`)

					// pass data
					this.chequeBooks = data?.data

					// reinit table
					this.table.reInit(`cheque-book`)
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => this.ui.blockUiOff());

	}

	async requestChequeBook() {
		// progress
		this.ui.blockUiOn(`Requesting Cheque Book... please wait`)
		let payload = this.formRequestChequeBook.value
		payload = await store.preparePayload(payload)

		// network call
		this.http.post<any>(config.base + routes.chequeBook.requestChequeBook, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// check data returned
					if (data) {
						// this.charges = data
						// notification
						this.ui.info(`Chequebook successfully requested.`)
						this.formRequestChequeBook.reset()
						this.changeStatus()
						// bring up otp screen
						// this.bringUpAutheticationModal()
					}
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			}).add(() => this.ui.blockUiOff());

	}
	// get selected account information
	getSelectedAccountInformation(target: any) {
		let controlIndex = target['selectedIndex']
		const accountType = target.options[controlIndex].getAttribute("accountType");
		this.formRequestChequeBook.patchValue({
			account: target['value'],
			accountType
		})
	}
	// get selected account information
	getSelectedBranchInformation(target: any) {
		let controlIndex = target['selectedIndex']
		this.selectedBranch = target.options[controlIndex].getAttribute("branchAddress");
		const branchId = target.options[controlIndex].getAttribute("branchId");
		this.formRequestChequeBook.patchValue({
			pickUpBranch: target['value'],
			branchId
		})
	}


	// bring up otp screen
	bringUpAutheticationModal() {
		// show modal
		modal.show(`#modal-authentication-token`)
	}
}