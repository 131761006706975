<!-- payment -->
<!-- pay salary -->
<div class="scrollable-content" *ngIf="chequeStatus">
    <div class="row">
        <div class="col-sm-12 col-lg-12 col-xxl-12">
            <div class="card border-0">
                <div class="card-body position-relative">
                    <div class="w-100 mb-5 d-flex flex-column px-5 text-white"
                        style="height: 83px; background: linear-gradient(92.09deg, #242492 13.35%, #D1AE6C 114.27%); border-radius: 14px; box-shadow: 0px 0px 40px rgba(48, 73, 191, 0.04); place-content: center;">
                        <div class="mb-1">Cheque Book</div>
                        <h3 class="text-white">Request a Cheque Book</h3>
                    </div>
                    <img src="assets/images/bills-payment.svg"
                        style="position: absolute; width: auto; height: 100px; top: 20px; right: 30px; " />

                    <form [formGroup]="formRequestChequeBook" (ngSubmit)="requestChequeBook()" class="p-5 w-100">
                        <div class="row g-3">
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" formControlName="accountNumber"
                                        (change)="getSelectedAccountInformation($event.target)" id="floatingSelect"
                                        aria-label="Floating label select example">
                                        <option *ngIf="!accounts.length" value="">Select Account</option>
                                        <option *ngFor="let item of accounts" [value]="item?.accountNumber"
                                            [attr.accountType]="item?.accountType">{{
                                            item?.accountNumber }}</option>
                                    </select>
                                    <label for="floatingSelect">Request with</label>
                                </div>
                                <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">This account will
                                    be used to request cheque book</div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <select class="form-select" formControlName="pickupBranch"
                                        (change)="getSelectedBranchInformation($event.target)" id="floatingSelect2"
                                        aria-label="Floating label select example">
                                        <option *ngIf="!branches.length" value="">Select Branch</option>
                                        <option *ngFor="let item of branches" [value]="item?.branchName"
                                            [attr.branchAddress]="item?.branchAddress" [attr.branchId]="item?.branchCode">
                                            {{ item?.branchName }}</option>
                                    </select>
                                    <label for="floatingSelect">Pick Up Branch</label>
                                </div>
                                <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">
                                    <b>Branch Address :: </b> {{selectedBranch}}
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="number" class="form-control" placeholder="Amount (e.g. 0.00)"
                                        formControlName="numberOfLeave">
                                    <label>Number of leaves</label>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 mb-2">
                                <div class="form-floating">
                                    <input type="number" class="form-control" placeholder="Mobile Number">
                                    <label>Mobile Number (+234)</label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <label for="exampleInputtime" class="form-label">Save phone number for next time</label>
                                <div class="form-check form-switch form-switch-lg" dir="ltr">
                                    <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
                                </div>
                            </div> -->
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-6 mt-5">
                                <div class="text-center">
                                    <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                    <button type="submit" class="btn btn-main btn-lg w-50"
                                        data-bs-toggle="modal">Continue</button>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-5"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- salary payment list -->
<div class="row" *ngIf="!chequeStatus">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 fs-18px">Cheque Book History</h4>
                <div class="flex-shrink-0">
                    <div class="btn-group">
                        <button class="btn btn-main d-flex align-items-center" type="button" (click)="changeStatus()">
                            <i class="bx bx-plus fs-5 mr-2"></i> Request Cheque Book
                        </button>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="custom-datatable overflow-auto w-100">

                    <table id="cheque-book" data-control-id='cheque-book' data-control-scope='transaction-history'
                        class="table datatable table-striped table-bordered" style="width:100%">
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Account Number</th>
                                <th>Account Type</th>
                                <th>Pick Up Branch</th>
                                <th>Number Of Leaves</th>
                                <th>Approval Username</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of chequeBooks; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.accountNumber }}</td>
                                <td>{{ item?.accountType }}</td>
                                <td>{{ item?.pickupBranch || '-' }}</td>
                                <td>{{ item?.numberOfLeave || '-' }}</td>
                                <td>{{ item?.approvalUsername || '-' }}</td>
                                <td>{{ item?.status || '-' }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>



<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-modal="true" role="dialog" style="display: none;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/images/special/enter-otp.jpg" width="400">

                <form class="p-5 w-100 needs-validation">
                    <div class="row g-3">
                        <div class="col-lg-12 mb-2">
                            <div class="form-floating">
                                <input type="password" class="form-control" formControlName="otp" required
                                    placeholder="Enter your otp">
                                <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="text-center">
                                <button type="button" class="btn btn-light btn-lg mx-2"
                                    data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-main btn-lg w-50"
                                    data-bs-toggle="modal">Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>