// get node object
let el = document.querySelector(window.atob(String('iUHgsYm9keQ==').substr(5)))

export const environment = {
  production: true,
  // remoteBase: window.atob(String(el.getAttribute('data-action-context')).substr(5)),
  // remoteBase: `https://icorporatebankingapiv2.parallexbank.com:8443`,
  // remoteAuth: `https://icorporatebankingapiv2.parallexbank.com:8443`,
  remoteBase: `https://icorporatebankingapi.parallexbank.com:8443`,
  remoteAuth: `https://icorporatebankingapi.parallexbank.com:8443`,
};
