<div class="" *ngIf="!hasUploaded">
   <div class="card-body position-relative">
      <div class="w-100 mb-5 d-flex flex-column px-5 text-white"
         style="height: 83px; background: linear-gradient(92.09deg, #242492 13.35%, #D1AE6C 114.27%); border-radius: 14px; box-shadow: 0px 0px 40px rgba(48, 73, 191, 0.04); place-content: center;">
         <div class="mb-1">Upload Staff</div>
         <h3 class="text-white">Add Staffs To Payroll In Minutes</h3>
      </div>
      <img src="assets/images/bulk-payment.svg"
         style="position: absolute; width: 138.13px; height: 100px; top: 20px; right: 30px; " />

      <form (ngSubmit)="verifyBulkTransaction($event.target)" class="p-5 w-100">
         <div class="row g-3">
            <!-- <div class="col-lg-6 mb-2">
                   <div class="form-floating">
                       <select class="form-select" id="floatingSelect" formControlName="sourceAccountNumber"
                           aria-label="Floating label select example"
                           (change)="getSelectedAccountInformation($event.target)">
                           <option value="">Select Account</option>
                           <option *ngFor="let account of accounts" [value]="account.accountNumber"
                               [attr.currency]="account.currencyCode" [attr.accountName]="account.accountName"
                               [attr.accountNunber]="account.accountNunber">{{ account.accountNumber }} ({{
                               account.customerName }} | N{{ account.availableBalance | number }})</option>
                       </select>
                       <label for="floatingSelect">Select Source Account</label>
                   </div>
               </div> -->
            <!-- <div class="col-lg-6 mb-2">
                   <div class="form-floating">
                       <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                         <option value="">Select Transfer Mode</option>
                       </select>
                       <label for="floatingSelect">Transfer Mode</label>
                   </div>
               </div> -->
            <!-- <div class="col-lg-6 mb-2" *ngIf="config.checkIfNotSingleSignatory(authorizationType)">
                   <div class="form-floating">
                       <select class="form-select" id="floatingSelect" formControlName="workflowId"
                           aria-label="Floating label select example">
                           <option value="">Select Workflow</option>
                           <option *ngFor="let item of workflows" [value]="item?.id">{{ item?.name }}
                           </option>
                       </select>
                       <label for="floatingSelect">Workflow</label>
                   </div>
               </div>
               <div class="col-lg-12 mb-2">
                   <div class="form-floating">
                       <textarea class="form-control h-auto" rows="5" formControlName="narration"></textarea>
                       <label for="cityfloatingInput">Batch Narration (Optional)</label>
                   </div>
               </div> -->
            <div class="col-lg-12">
               <label for="exampleInputtime" class="form-label position-relative w-100">
                  Bulk Upload File
                  <div class="position-absolute top-0 end-0">
                     <label class=" color-base-green position-relative pointer" (click)="fetchSampleTemplate()"><i
                           class="bx bx-download fs-5 position-relative" style="top: 2px;"></i> Download
                        Template</label>
                  </div>
               </label>
               <div class="block p-5">
                  <input name="file" type="file" accept=".xlsx, .xls, .csv" multiple (change)="onFileSelected($event)">
               </div>

            </div>
            <!-- <div class="col-lg-12">
                   <label for="exampleInputtime" class="form-label">Approval Workflow</label>
                   <div class="form-check form-switch form-switch-lg" dir="ltr">
                       <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
                   </div>
                   <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Activating this will allow
                       the authorizer to review, stop and delete scheduled payments.</div>
               </div> -->
            <div class="col-lg-3 mt-5"></div>
            <div class="col-lg-6 mt-5">
               <div class="text-center">
                  <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                  <button type="submit" class="btn btn-main btn-lg w-50"> Preview</button>
               </div>
            </div>
            <div class="col-lg-3 mt-5"></div>
         </div>
      </form>
   </div>
</div>

<!-- salary payment list -->
<div class="row" *ngIf="hasUploaded">
   <div class="col-sm-12 col-lg-12 col-xxl-12">
      <div class="card border-0">
         <div class="card-header border-0 align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1 fs-18px">Staff list</h4>
            <div class="flex-shrink-0">
               <div class="btn-group">
                  <button class="btn btn-secondary d-flex align-items-center" type="button" (click)="back()">
                     Go Back
                  </button>
                  <button class="btn btn-main d-flex align-items-center" type="button"
                     data-bs-target="#modal-authentication-token" data-bs-toggle="modal">
                     Initiate
                  </button>

               </div>
            </div>
         </div>

         <div class="card-body">
            <div class="custom-datatable overflow-auto salary-payment-table w-100">

               <table id="salary-payment" data-control-id='salary-payment' data-control-scope='transaction-history'
                  class="table datatable table-striped table-bordered" style="width:100%">
                  <thead>
                     <tr>
                        <th>Staff Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Dept</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Bank Name</th>
                        <th>Amount</th>
                        <th>Grade</th>
                        <th>Narration</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let entry of bulkEntries">
                        <td>{{entry.staffId}}</td>
                        <td>{{entry.firstName}}</td>
                        <td>{{entry.lastName}}</td>
                        <td>{{entry.department}}</td>
                        <td>{{entry.accountName}}</td>
                        <td>{{entry.accountNumber}}</td>
                        <td>{{entry.bankName}}</td>
                        <td>{{entry.salaryAmount}}</td>
                        <td>{{entry.gradeLevel}}</td>
                        <td>{{entry.description}}</td>
                     </tr>
                     <!-- <tr>
                               <td></td>
                               <td></td>
                               <td></td>
                               <td></td>
                               <td><b>Total</b></td>
                               <td>{{bulkAmount | currency: 'NGN' }}</td>
                           </tr> -->
                  </tbody>
               </table>

            </div>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="modal-confirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <div class="mt-4">
               <h4 class="mb-3">Are you sure you want to proceed?</h4>
               <!-- <p class="text-danger mb-4" *ngIf="bulkEntries[0]?.error.includes('Already Exist')"> This entry has
                  duplicate transactions</p> -->
               <br />
               <div class="hstack gap-2 justify-content-center">
                  <button href="javascript:void(0);" class="btn btn-light btn-lg fw-medium" data-bs-dismiss="modal">
                     Close</button>
                  <button class="btn btn-main fw-medium" (click)="initiateBulkAdd()">
                     Continue</button>

               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- processing message -->
<div class="modal fade" id="modal-processing" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <div class="spinner-border text-primary" role="status" style="width: 4rem; height: 4rem;">
               <span class="sr-only">Loading...</span>
            </div>

            <div class="mt-4">
               <h4 class="mb-3">Processing!</h4>
               <p class="text-muted mb-4"> Initiating bulk transaction.. please wait</p>
               <div class="hstack gap-2 justify-content-center">
                  <a href="javascript:void(0);" class="btn btn-link link-primary fw-medium" data-bs-dismiss="modal"><i
                        class="ri-close-line me-1 align-middle"></i> Close</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- authentication modal -->
<div class="modal fade" id="modal-authentication-token" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
   aria-modal="true" role="dialog" style="display: none;">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-body text-center p-5">
            <img src="assets/images/special/enter-otp.jpg" width="400">

            <form [formGroup]="formOtp" class="p-5 w-100 needs-validation">
               <div class="row g-3">
                  <div class="col-lg-12 mb-2">
                     <div class="form-floating">
                        <input type="password" class="form-control" formControlName="otp" required
                           placeholder="Enter your otp">
                        <label for="emailfloatingInput" class="text-uppercase">Please enter your otp</label>
                     </div>
                  </div>
                  <div class="col-lg-12 mt-5">
                     <div class="text-center">
                        <button type="button" class="btn btn-light btn-lg mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-main btn-lg w-50" data-bs-toggle="modal" data-bs-target="#modal-confirm">Continue</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
<!-- <div class="col-lg-6 mb-2">
   <div class="col-lg-12">
       <label for="exampleInputtime" class="form-label position-relative w-100">
           Upload Payment File
           <label class="position-absolute top-0 end-0 text-muted"><i
                   class="bx bx-download fs-5 position-relative" style="top: 2px;"></i> Download
               Salary Payment
               Template</label>
       </label>
       <div class="dropzone">
           <div class="fallback">
               <input name="file" type="file" multiple="multiple">
           </div>
           <div class="dz-message needsclick">
               <div class="mb-3">
                   <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
               </div>

               <h4>Drop files here or click to upload.</h4>
           </div>
       </div>
       <div class="position-relative">
           <label class="form-check-label mt-1 text-muted">Max. file size: 2MB</label>
           <label class="form-check-label mt-1 text-muted position-absolute top-0 end-0">Allowed
               formats (.pdf,
               .Csv, .Xls)</label>
       </div>

       <ul class="list-unstyled mb-0" id="dropzone-preview">
           <li class="mt-2" id="dropzone-preview-list">
               <div class="border rounded">
                   <div class="d-flex p-2">
                       <div class="flex-shrink-0 me-3">
                           <div class="avatar-sm bg-light rounded">
                               <img data-dz-thumbnail class="img-fluid rounded d-block" src="#"
                                   alt="Dropzone-Image" />
                           </div>
                       </div>
                       <div class="flex-grow-1">
                           <div class="pt-1">
                               <h5 class="fs-14 mb-1" data-dz-name>&nbsp;</h5>
                               <p class="fs-13 text-muted mb-0" data-dz-size></p>
                               <strong class="error text-danger" data-dz-errormessage></strong>
                           </div>
                       </div>
                       <div class="flex-shrink-0 ms-3">
                           <button data-dz-remove class="btn btn-sm btn-primary">Delete</button>
                       </div>
                   </div>
               </div>
           </li>
       </ul>
   </div>
   <div class="col-lg-12">
       <label for="exampleInputtime" class="form-label">Approval Notification</label>
       <div class="form-check form-switch form-switch-lg" dir="ltr">
           <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
       </div>
       <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Activating this will
           notify and
           allow the authorizer to review, stop and delete scheduled payments.</div>
   </div>
   <div class="col-lg-12">
       <label for="exampleInputtime" class="form-label">Reoccuring Payment</label>
       <div class="form-check form-switch form-switch-lg" dir="ltr">
           <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
       </div>
       <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">Activating this will
           automatically re-initiate the transaction on the set date and will also notify the
           authorizer to
           review and Approve this payments.</div>
   </div>
   <div class="col-lg-3 mt-5"></div>
   <div class="col-lg-6 mt-5">
       <div class="text-center">
           <button type="button" class="btn btn-outline-secondary btn-lg mx-2 mr-10"
               (click)="cancelSalaryPaymentStatus()">Cancel</button>
           <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
           <button type="submit" class="btn btn-main btn-lg w-50"
               data-bs-target="#modal-salary-approval" data-bs-toggle="modal">Continue</button>
       </div>
   </div>
   <div class="col-lg-3 mt-5"></div>
</div> -->