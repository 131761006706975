<!-- payment -->
<div class="row">
    <div class="col-sm-12 col-lg-12 col-xxl-12">
        <div class="card border-0">
            <div class="card-body position-relative">
                <div class="w-100 mb-5 d-flex flex-column px-5 text-white" style="height: 83px; background: linear-gradient(92.09deg, #242492 13.35%, #D1AE6C 114.27%); border-radius: 14px; box-shadow: 0px 0px 40px rgba(48, 73, 191, 0.04); place-content: center;">
                    <div class="mb-1">Bill Payment</div>
                    <h3 class="text-white">Utility Bill Payment</h3>
                </div>
                <img src="assets/images/bills-payment.svg" style="position: absolute; width: auto; height: 100px; top: 20px; right: 30px; " />

                <form action="#" class="p-5 w-100">
                    <div class="row g-3">
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                  <option value="">Select Account</option>
                                </select>
                                <label for="floatingSelect">Pay With</label>
                            </div>
                            <div class="form-check-label mt-1 text-muted" for="customSwitchsizelg">This account will be debited</div>
                        </div>
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                  <option value="">Select Operator</option>
                                </select>
                                <label for="floatingSelect">Network Operator</label>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <input type="number" class="form-control" placeholder="Amount (e.g. 0.00)">
                                <label>Amount (e.g. 0.00)</label>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-2">
                            <div class="form-floating">
                                <input type="number" class="form-control" placeholder="Mobile Number">
                                <label>Mobile Number (+234)</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <label for="exampleInputtime" class="form-label">Save phone number for next time</label>
                            <div class="form-check form-switch form-switch-lg" dir="ltr">
                                <input type="checkbox" class="form-check-input base-green" id="customSwitchsizelg">
                            </div>
                        </div>
                        <div class="col-lg-3 mt-5"></div>
                            <div class="col-lg-6 mt-5">
                            <div class="text-center">
                                <button type="reset" class="btn btn-light btn-lg mx-2">Reset</button>
                                <button type="submit" class="btn btn-main btn-lg w-50">Continue</button>
                            </div>
                        </div>
                        <div class="col-lg-3 mt-5"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>