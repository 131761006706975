import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataTablesService, UiService, LogService, Rogue } from '@app/services';
import { config, find, store, routes, modal } from '@app/config'
import { HttpClient } from '@angular/common/http';

import * as html2pdf from '../../../../assets/js/html2pdf.bundle.js';
import * as html2canvas from '../../../../assets/js/html2canvas.min.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;
declare var bootstrap: any;
// define template
let template = 'dashboard.component'

@Component({
	templateUrl: `${template}.html`,
	styleUrls: [`${template}.css`]
})
export class DashboardComponent implements OnInit {

	currentUser: any
	userName: any
	config = config
	users: any = config.users
	transactions: any = []
	bulkTransactions: any = []
	currentlySelectedTransaction: any
	pendingTransactions: any = []
	pendingBulkTransactions: any = []
	corporateCustomerId: string = "";
	accountsList: any = []
	dashboardInfo: any
	authorizationType: any
	isBalanceVisible: boolean = true;
	loadState: string = '';
	today: Date = new Date()
	receiptDownloadingStatus: boolean
	accountsListSource: any = []
	accountsListDestination: any = []

	formTransferInterbank: FormGroup
	formTransferOtp: FormGroup
	workflows: any = []
	charges: any
	bankList: any = []
	formTransferIntrabank: FormGroup
	formTransferOwnAccount: FormGroup
	destinationAccountDetails: any
	transferPayloadIntrabank: any
	transferPayloadInterbank: any
	transferPayloadOwnBank: any
	activeTransferTypeName: string
	workflowApproversList: any = []
	authLimit: number

	constructor(private table: DataTablesService, private ui: UiService, private http: HttpClient, private logger: LogService, private fb: FormBuilder) {
		// get user
		this.currentUser = config.currentUser()
		this.userName = store.get('customerIdentity')

		// get corporate id
		this.corporateCustomerId = store.get('corporateCustomerId')
		this.authorizationType = store.get('authorizationType')
	}


	ngOnInit() {

		setTimeout(() => {
			const carousel = new bootstrap.Carousel('#carousellanding', {
				interval: 3000
			});
			carousel.cycle(); // Start cycling
		}, 10)

		// initialize table
		this.table.init()


		// define form
		this.formTransferInterbank = this.fb.group({
			sourceAccountNumber: ['', Validators.required],
			sourceBankName: [''],
			sourceAccountName: [''],
			destinationAccountNumber: ['', Validators.required],
			destinationBankName: ['', Validators.required],
			destinationBankCode: ['', Validators.required],
			destinationAccountName: [''],
			workflowId: [''],
			amount: ['', Validators.required],
			narration: ['', Validators.required],
			transactionType: [''],
			otp: [''],
			pin: [''],
		})

		this.formTransferInterbank.valueChanges.subscribe(form => {
			if (form.amount) {
				this.formTransferInterbank.patchValue({
					// amount: this.currencyPipe.transform(form.amount.replace(/\D/g, '').replace(/^0+/, ''), '', '', '1.0-0')
					amount: config.addCommas(form.amount.replace(/[^\d.]/g, ''))
				}, { emitEvent: false })
			}
		})

		// define form
		this.formTransferOtp = this.fb.group({
			otp: ['', Validators.required],
			pin: [''],
		})

		if (config.currentUserHasPermissionByKey('ViewCorporateAccount') || !config.checkIfNotSingleSignatory(this.authorizationType)) {
			this.fetchDashboardInfo()
		}

		// initialize flatpickr
		Rogue.Flatpickr.init()
	}



	replaceLettersWithSpace(event: any, key: string) {
		const inputElement = event.target;
		const inputValue = inputElement.value;

		// Replace letters with spaces using a regular expression
		const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

		// Update the input value
		inputElement.value = sanitizedValue;

		// Update the model variable
		this.formTransferOtp.patchValue({
			[key]: sanitizedValue
		})
	}

	// fetch accounts list
	async fetchDashboardInfo() {
		// loading
		this.ui.blockUiOn(`Fetching dashboard details... please wait`)

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.dashboard.dashboardDetails, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data
					//  
					// pass on data
					this.accountsList = data?.accounts.filter((item: any) => item?.accountNumber != "")
					this.workflows = data.workflows
					this.dashboardInfo = data

					// notification
					this.ui.info(`loaded successfully.`)

					// unblock
					this.ui.blockUiOff()

					// fetch workflows
				},
				error: error => {
					// error
					this.ui.error(error)

					// unblock
					this.ui.blockUiOff()
				}
			})
			.add(() => {
				// fetch history
				this.fetchInterBankList()
			});
	}
	async fetchTransactions() {
		// progress
		// this.ui.blockUiOn(`Fetching transactions... please wait`)
		this.loadState = "Fetching......"

		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		})



		// network call
		this.http.get<any>(config.base + routes.transactions.transactionLogs, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// destroy table
					this.table.destroy(`dashboard-transaction-history`)
					// pass data
					this.transactions = data.slice(0, 5)
					// reinit table
					this.table.reInit(`dashboard-transaction-history`)

					// notification
					this.ui.success(`Transactions successfully fetched.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => {
				this.ui.blockUiOff()
				this.loadState = ""
				this.fetchBulkTransactions()

				// if (this.currentUser != this.users.sole || !config.checkIfNotSingleSignatory(this.authorizationType)) {
				// 	this.fetchPendingTransactions()
				// }
			});
	}
	// fetch pending transactions 
	async fetchPendingTransactions() {
		// progress
		// this.ui.blockUiOn(`Fetching pending transactions... please wait`)
		this.loadState = "Fetching......"

		// define payload
		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		})

		// network call
		this.http.get<any>(config.base + routes.transactions.pendingTransactionLogs, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log


					// destroy table
					this.table.destroy(`dashboard-pending-history`)
					// pass data
					this.pendingTransactions = data.slice(0, 5)
					// reinit table
					this.table.reInit(`dashboard-pending-history`)

					// notification
					this.ui.success(`Pending transactions successfully fetched.`)
				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => {

				this.fetchPendingBulkTransactions()
				this.loadState = ""
				this.ui.blockUiOff()
			});
	}

	async fetchPendingBulkTransactions() {
		this.loadState = "Fetching......"

		// define payload
		let payload = {
			id: this.corporateCustomerId
		}

		// progress
		// this.ui.blockUiOn(`Fetching pending bulk transactions... please wait`)

		// network call
		this.http.get<any>(config.base + routes.transactions.bulkPendingTransaction, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					this.logger.log(data)

					// destroy table
					this.table.destroy(`pending-bulk-records`)

					// pass data
					this.pendingBulkTransactions = data.slice(0, 5)

					// reinit table
					this.table.reInit(`pending-bulk-records`)

					// notification
					if (!this.pendingBulkTransactions.length) this.ui.warning(`No pending bulk transactions available.`)
					else this.ui.success(`Pending bulk transactions successfully fetched.`)

				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => {
				this.loadState = ""
				this.ui.blockUiOff()
			});

	}

	async fetchBulkTransactions() {
		this.loadState = "Fetching......"

		// progress
		// this.ui.blockUiOn(`Fetching pending transactions... please wait`)
		// define payload
		let payload = await store.preparePayload({
			id: this.corporateCustomerId
		}, true)

		// network call
		this.http.get<any>(config.base + routes.transactions.bulkTransactionHistory + `?corporateCustomerId=${payload.id}`, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// log
					// pass data
					this.bulkTransactions = data.slice(0, 5)

					// destroy table
					this.table.destroy(`bulk-transaction-history`)

					// reinit table
					this.table.reInit(`bulk-transaction-history`)

					// notification
					this.ui.success(`Transactions successfully fetched.`)
					this.loadState = ""

				},
				error: error => {
					// error
					this.ui.error(error)

				}
			}).add(() => this.ui.blockUiOff());
	}

	// fetch inter bank list
	fetchInterBankList() {
		// loading
		this.ui.blockUiOn(`Fetching Bank list... please wait`)

		// network call
		this.http.post<any>(config.base + routes.transactions.interbankList, {}, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// pass on data
					this.bankList = [...data?.data?.banks]
					// notification
					this.ui.info(`Banks loaded successfully.`)

					// unblock
					this.ui.blockUiOff()
				},
				error: error => {
					// error  
					this.ui.error(error)


				}
			})
			.add(() => {
				// unblock
				this.ui.blockUiOff()
				if (this.currentUser != this.users.sole || !config.checkIfNotSingleSignatory(this.authorizationType)) {
					this.fetchTransactions()
				}
			});
	}

	// view details
	viewTransactionDetails(transaction: any) {
		// pass data
		this.currentlySelectedTransaction = transaction

		// modal
		modal.show(`#modal-view-transaction-history`)
	}

	// view details
	viewTransactionDetailsBulk(transaction: any) {
		// pass data
		this.currentlySelectedTransaction = transaction

		// modal
		modal.show(`#modal-view-transaction-history-bulk`)
	}

	// download receipt
	downloadTransactionReceipt() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container');
			// get opts
			var opt = {
				margin: 1,
				filename: 'transaction-receipt.pdf',
				image: { type: 'jpeg', quality: 1 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
			};
			// get workder
			let worker = html2pdf()
			// chain the worker functions and process the file to be downloaded
			worker.set(opt).from(element, 'element').save().then(() => {
				// change status
				this.receiptDownloadingStatus = false
			})
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptAsImage() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container');

			html2canvas(element, {
				scrollY: -window.scrollY,
				useCORS: true,
			}).then(canvas => {
				// create element
				let a = document.createElement('a')
				// set href attribute of anchor tag with datauri
				a.setAttribute('href', canvas.toDataURL('image/jpeg', 1.0))
				// set download attribute with name
				a.setAttribute('download', 'transaction-receipt.jpg')
				// add element to document body
				document.body.appendChild(a)
				// trigger click on anchor tag
				a.click()
				// remove anchor tag from document
				document.body.removeChild(a)
				// change status
				this.receiptDownloadingStatus = false
			});
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptBulk() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history-bulk').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container-bulk');
			// get opts
			var opt = {
				margin: 1,
				filename: 'transaction-receipt.pdf',
				image: { type: 'jpeg', quality: 1 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
			};
			// get workder
			let worker = html2pdf()
			// chain the worker functions and process the file to be downloaded
			worker.set(opt).from(element, 'element').save().then(() => {
				// change status
				this.receiptDownloadingStatus = false
			})
		}, 1000)
	}

	// download receipt
	downloadTransactionReceiptAsImageBulk() {
		// change status
		this.receiptDownloadingStatus = true

		// scroll up
		find(document.documentElement).get(0).scrollTop = 0
		find('#modal-view-transaction-history-bulk').get(0).scrollTop = 0

		// timeout
		setTimeout(() => {
			// convert beneficiary card to pdf and download
			var element = document.getElementById('receipt-container-bulk');

			html2canvas(element, {
				scrollY: -window.scrollY,
				useCORS: true,
			}).then(canvas => {
				// create element
				let a = document.createElement('a')
				// set href attribute of anchor tag with datauri
				a.setAttribute('href', canvas.toDataURL('image/jpeg', 1.0))
				// set download attribute with name
				a.setAttribute('download', 'transaction-receipt.jpg')
				// add element to document body
				document.body.appendChild(a)
				// trigger click on anchor tag
				a.click()
				// remove anchor tag from document
				document.body.removeChild(a)
				// change status
				this.receiptDownloadingStatus = false
			});
		}, 1000)
	}


	// get selected account information
	getSelectedAccountInformation(target: any, key: string) {
		// get index
		let controlIndex = target['selectedIndex']

		// check if index is 0
		if (controlIndex < 1) {
			// reset the lists
			this.accountsListSource = this.accountsList
			this.accountsListDestination = this.accountsList
		}

		// check the key
		switch (key) {
			case 'own-source':
				// patch values
				this.formTransferOwnAccount?.patchValue({
					sourceBankName: target.options[controlIndex].getAttribute("bankName"),
					sourceAccountName: target.options[controlIndex].getAttribute("accountName"),
				})

				// hide source from destination
				this.accountsListDestination = this.accountsList?.filter((item: any) => item?.accountNumber != this.formTransferOwnAccount.value.sourceAccountNumber)
				// unide anything hidden
				this.accountsListSource = this.accountsList
				// check if selected destination no is same source
				if (this.formTransferOwnAccount.value.destinationAccountNumber == this.formTransferOwnAccount.value.sourceAccountNumber) {
					// patch values
					this.formTransferOwnAccount.patchValue({
						destinationAccountNumber: '',
						destinationBankName: '',
						destinationAccountName: '',
					})
				}
				break
			case 'interbank':
				// patch values
				this.formTransferInterbank.patchValue({
					destinationBankName: target.options[controlIndex].getAttribute("bankName"),
					destinationBankCode: target.options[controlIndex].getAttribute("bankCode"),
				})
				break
		}
	}

	// get account information
	async getAccountInformation(target: any, type?: string) {
		// get account number
		let account = String(target.value).replace(/\s*/g, "")

		// check length of account number
		if (account.length != 10) {
			// empty store
			this.destinationAccountDetails = {}
			return
		}
		// progress
		this.ui.blockUiOn(`Fetching account information... please wait`)

		// define payload
		let payload = await store.preparePayload({
			accountNumber: String(account)
		}, true)

		let payloadInterBank = {
			accountNumber: String(account),
			destinationBankCode: String(this.formTransferInterbank.value.destinationBankCode),
		}

		let encryptedInterBankPayload = await store.preparePayload({
			accountNumber: String(account),
			destinationBankCode: String(this.formTransferInterbank.value.destinationBankCode),
		})



		// network call
		if (type === "inter") {
			this.http.post<any>(config.base + routes.transactions.interbankNameEnquiry, encryptedInterBankPayload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// log


						// pass on data
						this.destinationAccountDetails = data?.data

						// insert destination account name
						this.formTransferInterbank.patchValue({
							destinationAccountName: this.destinationAccountDetails.accountName,
							destinationAccountNumber: payloadInterBank.accountNumber,
							destinationBankName: this.formTransferInterbank.value.destinationBankName || ''
						})

						// notification
						this.ui.success(`Name enquiry was successful.`)
					},
					error: error => {
						// error
						this.ui.error(error)

					}
				}).add(() => this.ui.blockUiOff());
		} else {
			this.http.get<any>(config.base + routes.manageAccounts.customerNameEnquiry + `?accountNumber=${payload.accountNumber}`, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// log


						// pass on data
						this.destinationAccountDetails = data?.data

						// insert destination account name
						this.formTransferIntrabank.patchValue({
							destinationAccountName: this.destinationAccountDetails.accountName,
							destinationBankName: this.destinationAccountDetails.bankName || ''
						})

						// notification
						this.ui.success(`Name enquiry was successful.`)
					},
					error: error => {
						// error
						this.ui.error(error)

					}
				}).add(() => this.ui.blockUiOff());
		}
	}

	// initiate intra bank transfer
	initiateInterbankTransfer(target: any) {

		// pass transfer type name
		this.activeTransferTypeName = 'interbank'

		// get form
		let form = find(target)
		// validate form
		if (!this.formTransferInterbank.valid) {
			// warning
			this.ui.warning(`Please fill all required fields.`)
			Rogue.Form.flag(form)
			return
		}

		// get values
		let values = this.formTransferInterbank.value

		// check that not same account
		if (values.sourceAccountNumber == values.destinationAccountNumber) {
			// warning
			this.ui.warning(`Transfer to the same account is not possible.`)
			return
		}

		// define payload
		this.transferPayloadInterbank = {
			sourceAccountNumber: values.sourceAccountNumber,
			sourceBankName: values.sourceBankName || '',
			sourceAccountName: values.sourceAccountName,
			destinationAccountNumber: String(values.destinationAccountNumber),
			destinationBankName: values.destinationBankName || '',
			destinationBankCode: values.destinationBankCode || '',
			destinationAccountName: values.destinationAccountName || '',
			amount: config.removeCommas(values.amount),
			narration: values.narration,
			workflowId: values.workflowId,
			corporateCustomerId: this.corporateCustomerId,
			transactionType: values.destinationBankCode != "000030" ? 'Inter_Bank_Transfer' : 'Intra_Bank_Transfer',
			otp: '',
		}

		// log data
		this.logger.log(this.transferPayloadInterbank)

		// verify transaction
		this.verifyTransaction()
	}

	// verify transaction
	async verifyTransaction() {
		// define payload
		let payload: any

		// loading
		this.ui.blockUiOn(`Verifying transaction... please wait`)

		let destinationAccountNumber;

		// check transfer type name
		switch (this.activeTransferTypeName) {
			case 'interbank':
				// pass data
				payload = await store.preparePayload(this.transferPayloadInterbank)
				destinationAccountNumber = store.encrypt(this.transferPayloadInterbank.destinationAccountNumber, true)
				payload = { ...payload, destinationAccountNumber }
				break
		}


		const u = this.transferPayloadInterbank.destinationBankCode != "000030" ? routes.transactions.verifyInterTransaction : routes.transactions.verifyIntraTransaction
		// network call
		if (this.activeTransferTypeName == "interbank") {
			this.http.post<any>(config.base + u, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// output data


						// check data returned
						if (data) {
							this.charges = data
							this.authLimit = data?.authLimitIsEnable

							// notification
							this.ui.info(`Transaction successfully verified. Please provide your otp.`)

							// bring up otp screen
							this.bringUpAutheticationModal()
						}
					},
					error: error => {
						// check for error
						this.ui.error(error)

					}
				})
				.add(() => this.ui.blockUiOff());
		} else {
			this.http.post<any>(config.base + routes.transactions.verifyIntraTransaction, payload, config.httpOptions())
				.subscribe({
					next: (data: any) => {
						// output data


						// check data returned
						if (data) {
							this.authLimit = data?.authLimitIsEnable
							// notification
							this.ui.info(`Transaction successfully verified. Please provide your otp.`)

							// bring up otp screen
							this.bringUpAutheticationModal()
						}
					},
					error: error => {
						// check for error
						this.ui.error(error)
					}
				})
				.add(() => this.ui.blockUiOff());
		}

	}

	// bring up otp screen
	bringUpAutheticationModal() {
		// show modal
		modal.show(`#modal-authentication-token`)
	}

	// attempt transaction
	async attemptTransaction(target: any) {
		// get form
		let form = find(target)


		// validate otp and pin first
		if (this.authLimit == 1 && !this.formTransferOtp.value.pin) {
			this.ui.warning(`Please provide your pin!`)
			Rogue.Form.flag(form)
			return
		}
		if (!this.formTransferOtp.value.otp) {
			// warning
			this.ui.warning(`Please provide your Token!`)
			Rogue.Form.flag(form)
			return
		}

		if (this.authLimit == 1 && this.formTransferOtp.value.pin?.length != 4) {
			this.ui.warning(`Pin must be 4 digits!`)
			return
		}

		if (this.formTransferOtp.value.otp?.length != 8) {
			// warning
			this.ui.warning(`Token must be 8 digits!!`)
			return
		}

		// loading
		this.ui.blockUiOn(`Initiating transaction... please wait`)

		// define payload
		let payload: any

		// check transfer type name
		switch (this.activeTransferTypeName) {
			case 'interbank':
				// pass data
				payload = this.transferPayloadInterbank
				break
		}

		const destinationAccountNumber = store.encrypt(payload.destinationAccountNumber, true)
		// add otp
		payload.otp = this.formTransferOtp.value.otp
		payload.pin = this.formTransferOtp.value.pin
		payload.authLimitIsEnable = this?.authLimit


		payload = await store.preparePayload(payload)

		payload = { ...payload, destinationAccountNumber }
		// log
		this.logger.log(payload.otp + payload.pin)



		// network call
		const u = this.transferPayloadInterbank.destinationBankCode != "000030" ? routes.transactions.initiateInterTransaction : routes.transactions.initiateIntraTransaction;
		this.http.post<any>(config.base + u, payload, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// output data

					// notification
					this.ui.info(`Transaction successfully initiated.`)

					// hide modal
					modal.hide(`#modal-authentication-token`)
					// reset otp 
					this.formTransferOtp.reset()

					// congratulations modal
					modal.show(`#modal-initiate-contgratulations`)


					// reset forms
					this.formTransferInterbank.reset()

					// patch values
					this.formTransferInterbank.patchValue({
						sourceAccountNumber: '',
						destinationAccountNumber: ''
					})

					// empty store for destination data
					this.destinationAccountDetails = null

					// reset otp form
					this.formTransferOtp.reset()
				},
				error: error => {
					// error
					this.ui.error(error)
				}
			})
			.add(() => this.ui.blockUiOff());



	}
	// toggle password
	toggleSensitive() {
		// timeout
		setTimeout(() => {
			this.isBalanceVisible = !this.isBalanceVisible
		}, 10)
	}
}