// jquery operator
declare var $: any

// define app object
export const App = {
	// toggle password reveal
	togglePasswordReveal: (target: any): void => {
		// define states
		let states: any = { 'password': 'text', 'text': 'password', 'bx bx-hide': 'bx bx-show', 'bx bx-show': 'bx bx-hide' }
		// get parent
		let parent = $(target).parents('div.input-group')
		// get input control
		let control = parent.find('input[data-context="password"]')
		// change state of control
		control.attr('type', states[control.attr('type')])
		// change trigger state
		parent.find('i[class]').attr('class', states[parent.find('i[class]').attr('class')])
	},
}

// define rogue object
export const Rogue = {
	// form actions
	Form: {
		// flag form errors
		flag(form: any) {
			// add validation class
			form.addClass('was-validated')
			// check if form has been calibrated
			if (form.hasClass('needs-validation') && !form.attr('data-controls-calibrated')) {
				// add flag
				form.attr('data-controls-calibrated', '')
				// calibrate
				form.find('input, select').on('focus blur', function (e: any) {
					// check if validation flag is present
					if ($(e.target).parents('form.was-validated').length) {
						// remove validation flag
						$(e.target).parents('form').removeClass('was-validated')
					}
				})
			}
		},
		// reset form
		reset: (form: any) => {
			// get form
			let obj = form
			// get elements
			let elements = Array.from(obj.find('input:not(:hidden), select, textarea'))
			// loop through elements
			for (let i = 0; i < elements.length; i++) {
				// get current element
				let el = elements[i]
				// validate
				if (($(el).val() !== null
					&& $(el).val().toString().trim().length > 0
					&& $(el).get(0).toString() === '[object HTMLInputElement]')
					&& !$(el).hasClass('-skip-value-reset')) {
					$(el).val('');
				} else if (($(el).val() !== null
					&& $(el).val().toString().trim().length > 0
					&& $(el).get(0).toString() === '[object HTMLTextAreaElement]')
					&& !$(el).hasClass('-skip-value-reset')) {
					$(el).val('');
				} else if (($(el).get(0).toString() === '[object HTMLSelectElement]'
					&&
					($(el).val() !== null
						&& ($(el).val().toString().trim().length > 0
							|| $(el).val().toString().trim() !== '0'))
					&& !$(el).hasClass('-skip-value-reset'))) {
					($(el).children('option[value="0"]').length)
						? $(el).val('0').trigger('change')
						: ($(el).children('optgroup').length
							? $(el).val($(el).children('optgroup:eq(0)').find('option:eq(0)').attr('value')).trigger('change')
							: $(el).val($(el).children('option:eq(0)').attr('value')).trigger('change'));
				}
			}
		}
	},

	// flatpickr
	Flatpickr: {
		// initialize
		init() {
			// settimeout
			setTimeout(() => {
				eval(`document.querySelectorAll("[data-provider]").forEach(function(e) {
		      var t, a, o;
		      "flatpickr" == e.getAttribute("data-provider")
		      	? (o = {}, (t = e.attributes)["data-date-format"] && (o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-enable-time"] && (o.enableTime = !0, o.dateFormat = t["data-date-format"].value.toString() + " H:i"),
		      		t["data-altFormat"] && (o.altInput = !0, o.altFormat = t["data-altFormat"].value.toString()),
		      		t["data-minDate"] && (o.minDate = t["data-minDate"].value.toString(),o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-maxDate"] && (o.maxDate = t["data-maxDate"].value.toString(), o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-deafult-date"] && (o.defaultDate = t["data-deafult-date"].value.toString(),o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-multiple-date"] && (o.mode = "multiple", o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-range-date"] && (o.mode = "range", o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-inline-date"] && (o.inline = !0, o.defaultDate = t["data-deafult-date"].value.toString(), o.dateFormat = t["data-date-format"].value.toString()),
		      		t["data-disable-date"] && ((a = []).push(t["data-disable-date"].value), o.disable = a.toString().split(",")),
		      		flatpickr(e, o)) : "timepickr" == e.getAttribute("data-provider") && (a = {}, (o = e.attributes)["data-time-basic"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i"), o["data-time-hrs"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.time_24hr = !0), o["data-min-time"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.minTime = o["data-min-time"].value.toString()), o["data-max-time"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.minTime = o["data-max-time"].value.toString()), o["data-default-time"] && (a.enableTime = !0, a.noCalendar = !0, a.dateFormat = "H:i", a.defaultDate = o["data-default-time"].value.toString()), o["data-time-inline"] && (a.enableTime = !0, a.noCalendar = !0, a.defaultDate = o["data-time-inline"].value.toString(), a.inline = !0), flatpickr(e, a))
		    })`)
			}, 100)
		},
	},
	// detect multiple tabs opened
	DetectMultiTabOpened: {
		init(router: any) {
			// broadcast that your're opening a page.
			sessionStorage.setItem('XSecOpenMainPageStart', String(Date.now()))
			// define callback
			var onsessionStorageEvent = function (e) {
				// check for the first key
				if (e.key == "XSecOpenMainPageStart") {
					// listen if anybody else opening the same page!
					sessionStorage.setItem('XSecOpenMainPageStartDuplicate', String(Date.now()))
					// bring up resource unavailable screen
					router.navigate(['/user/login'])
				}
				// check for the first key
				if (e.key == "XSecOpenMainPageStartDuplicate") {
				}
			}
			// add event listener
			window.addEventListener('storage', onsessionStorageEvent, false);
		}
	},

}