import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { config, routes, find, modal, store } from '@app/config';

// jquery operator
declare var $: any;

// configuration options
interface ITimeoutServiceOptions {
	storeName?: string;
	timeoutInSeconds?: number;
	redirectUrl?: string;
	router?: Router,
	actionIndicatorStoreName?: string
}

@Injectable({ providedIn: 'root' })
export class TimeoutService {
	options: ITimeoutServiceOptions
	defaultInnerOptions = {
		seed: 'timeoutServiceObject',
		timeout: 1000
	}
	timeoutObject: any

	constructor(
		private http: HttpClient,

	) { }

	// watch for activity
	start(options?: ITimeoutServiceOptions) {
		// configure
		this.options = {
			storeName: options.storeName || window.btoa(this.defaultInnerOptions.seed).replace('=', '').toUpperCase().split('').reverse().join(''),
			timeoutInSeconds: options.timeoutInSeconds || 10,
			redirectUrl: options.redirectUrl || '/user/login',
			router: options.router,
			actionIndicatorStoreName: options.actionIndicatorStoreName
		}

		// flush store
		this.flushStore()

		// initialize store with data
		this.refreshState()

		// listen to document events / minus visibilitychange
		$(document).on('focus blur mouseenter mousein mouseleave mouseover mouseup mousedown keyup keypress keydown change', (e) => {
			// check if idle
			if (this.isSystemIdle()) {
				// flush and redirect
				this.flushStoreAndRedirect()
			} else this.refreshState()
		})
		// settimeout
		this.timeoutObject = setTimeout(() => this.scrutinize(), this.defaultInnerOptions.timeout)
	}

	// scrutinize activity
	scrutinize() {
		// check if system is idle
		if (this.isSystemIdle()) {
			// flush and redirect
			this.flushStoreAndRedirect()
		} else {
			// cycle back to construct after time
			this.timeoutObject = setTimeout(() => this.scrutinize(), this.defaultInnerOptions.timeout)
		}
	}

	// flush store
	flushStore(): void {
		// unset store
		sessionStorage.removeItem(this.options.storeName)
	}

	// flush store and redirect
	flushStoreAndRedirect(): void {
		// check if recent action store name is set
		if (this.options.actionIndicatorStoreName) sessionStorage.setItem(this.options.actionIndicatorStoreName, '1')
		// network
		this.http.post<any>(config.auth + routes.authentication.logout, {}, config.httpOptions())
			.subscribe({
				next: (data: any) => {
					// unset store
					sessionStorage.removeItem(this.options.storeName)
					if (this.options.router) this.options.router.navigate([this.options.redirectUrl])
					else $(window).get(0).location.href = this.options.redirectUrl
					sessionStorage.clear()
				},
				error: e => {
					// error
					// this.ui.error(e)
					// unset store
					sessionStorage.removeItem(this.options.storeName)
					if (this.options.router) this.options.router.navigate([this.options.redirectUrl])
					else $(window).get(0).location.href = this.options.redirectUrl
					sessionStorage.clear()
				}
			}).add(() => { })
		sessionStorage.removeItem(this.options.storeName)
		// check if router valid
		if (this.options.router) this.options.router.navigate([this.options.redirectUrl])
		else $(window).get(0).location.href = this.options.redirectUrl
		sessionStorage.clear()
	}

	// get time in seconds
	getTimeInSeconds() {
		return ((new Date()).getTime() / 1000).toFixed(0)
	}

	// renew state
	refreshState(): void {
		// create timeout data object
		let timeoutServiceObj = { time: this.getTimeInSeconds() }
		// store timeout object
		sessionStorage.setItem(this.options.storeName, JSON.stringify(timeoutServiceObj))
	}

	// check if idle
	isSystemIdle(): boolean {
		// get store
		let store = sessionStorage.getItem(this.options.storeName)

		// check if store doesnt exists
		if (!store) {
			// clear timeout
			clearTimeout(this.timeoutObject)
			return false
		}

		// get object
		let timeoutServiceObj = JSON.parse(store)

		// get time in seconds
		let currentTimeInSeconds: any = this.getTimeInSeconds()

		// check if time is active and if time has actually lapsed
		if ((currentTimeInSeconds - timeoutServiceObj.time) > this.options.timeoutInSeconds) return true

		// not idle
		return false
	}
}